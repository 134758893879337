import * as yup from "yup";
import { TITLE_PATTERN_VALIDATOR } from "helpers/validators";
import { EMAIL_PATTERN_VALIDATOR } from "helpers/validators";

export const ContactValidation = yup.object().shape({
    name: yup
        .string()
        .matches(
            TITLE_PATTERN_VALIDATOR.value,
            TITLE_PATTERN_VALIDATOR.errorMessage
        )
        .required(),
    email: yup
        .string()
        .matches(
            EMAIL_PATTERN_VALIDATOR.value,
            EMAIL_PATTERN_VALIDATOR.errorMessage
        )
        .required(),
    // subject: yup.string().required(),
    message: yup.string().required(),
});