import BaseService from "../BaseService";
import { API_BASE_URL_ENV } from "../../helpers/common";
import http from "../HttpService";

const apiEndpoint = API_BASE_URL_ENV() + "/client/order";
const apiFirstStepEndpoint = API_BASE_URL_ENV() + "/client/steps/first-step";
const apiSecondStepEndpoint = API_BASE_URL_ENV() + "/client/steps/second-step";
const apiFourthStepEndpoint = API_BASE_URL_ENV() + "/client/steps/fourth-step";


export default class extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  firstStepEdit(data) {
    return http.post(`${API_BASE_URL_ENV()}/website/order/request-first-step-edit`, data);
  }
  
  getFirstStepData(id, params = {}) {
    return http.get(`${apiFirstStepEndpoint}/${id}`, { params });
  }

  getSecondStepData(id, params = {}) {
    return http.get(`${apiSecondStepEndpoint}/${id}`, { params });
  }

  getFourthStepData(id, params = {}) {
    return http.get(`${apiFourthStepEndpoint}/${id}`, { params });
  }

  buyHosting(id, data) {
    return http.put(`${apiFourthStepEndpoint}/${id}/buy-hosting`, data);
  }

  updateContent(id, data) {
    return http.put(`${apiEndpoint}/${id}`, data);
  }

  updateOrder(id, data) {
    return http.put(`${apiEndpoint}/${id}`, data);
  }

}
