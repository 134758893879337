import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import TemplateService from "services/Admin/TemplateService";
import { SuccessOk } from "configs/statusCode";
import { toast } from "react-toastify";
import axios from "axios";
// import { Link } from "react-router-dom";
// import { adminPrefix } from "configs/routePrefix";

const Filterswrapper = ({ AddHandler, title, subTitle, addbtnName, children }) => {
    const [ fileUploaded, setFileUploaded] = useState('')
    const templateService = new TemplateService()
    const cancelTokenSource = useRef();

    const importHandler = (e) => {
        let files = e.target.files
        const filesData = Object.values(files)
        setFileUploaded(filesData)
        cancelTokenSource.current = axios.CancelToken.source();
        if (filesData.length) {
            filesData.map(async (file) => {
                templateService.import(file).then(res => {
                    if (res.status === SuccessOk){
                        toast?.success("File Imported Successfully")
                    }
                }).catch(() => toast.error('Upload Failed'))
                setFileUploaded('')
            })
        }
    }
    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div>
                    <h4 className="fw-bolder">{title}</h4>
                    <p className="text-muted mb-3">{subTitle}</p>
                </div>
                <div className="mb-3 px-2 px-md-0 d-flex btnHide">
                    {title === 'Templates' &&
                    <>
                        <div className="position-relative me-3">
                            <button className="btn btn-success px-4 mb-3 mb-lg-0 w-100 btn-hover mb-0 btn-upload " id='import' 
                                style={{height:'45px', marginRight: '16px', padding: '10px 1.5rem'}}>
                                Import  </button>
                            <input 
                                htmlFor='import'
                                style={{
                                    opacity: '0',
                                    position: 'absolute',
                                    left: '0',
                                    top: '0',
                                    width: '100%',
                                    height: '74%',
                                    cursor: 'pointer',
                                    zIndex: '999',
                                }}
                                value={fileUploaded}
                                type="file" 
                                name="upfile"
                                accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                                onChange={importHandler} />
                        </div>
                        {/* <div>
                            <Link className="btn btn-secondary py-2 px-3" to={`${adminPrefix}/templates/add-templates`} style={{height:'45px', marginRight: '16px', padding: '10px 1.5rem'}}>
                            + Add Templates
                            </Link>
                        </div> */}
                    </>
                    }
                    {title !== 'Projects' && <button className="btn btn-primary height-45" onClick={AddHandler}>
                        <i className="fa fa-plus me-1 px-2"></i>
                        <span>{addbtnName}</span>
                    </button>}
                </div>
            </div>
            <div>
                {children}
            </div>
        </>
    );
};

Filterswrapper.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    addbtnName: PropTypes.func,
    AddHandler: PropTypes.func,
    children: PropTypes.any,
};


export default Filterswrapper