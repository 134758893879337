import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, Label, Form } from "reactstrap";
import PropTypes from "prop-types";
import arrowImg from "assets/images/arrow.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContentValidator } from "FormValidations/Client/Dashboard/content";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import StepsService from "services/Client/StepsService";
import { SuccessOk } from "configs/statusCode";
import { toast, ToastContainer } from "react-toastify";
import FileUpload from "components/Common/file-upload/file-upload.component";

const AddPageContentModal = ({ modalContent, toggle, projectData ,callSecondStep }) => {
  const stepsService = new StepsService();
  const [uploadState, setUploadState] = useState(false);
  const [formData, setFormData] = useState({
    documents: []
  });
  const {
    handleSubmit, register, formState: { errors }, setValue
    // , watch
  } = useForm({
    resolver: yupResolver(ContentValidator), defaultValues: {
      name: "", description: "", reference_url: ""
    }
  });

  const updatedAttachment = (attachments) => {
    setFormData((data) => ({
        ...data, documents: [...formData.documents, ...attachments]
      }));
  };

  const deleteAttachment = (Items) => {
       let updatedDocs = formData.documents.filter((item) => item.id != Items[0].id);
      setFormData((data) => ({ ...data, documents: updatedDocs }));
   
  };

  useEffect(() => {
    setValue("title");
    setValue("description");
    setValue("reference_url");
  }, []);

  const handleValidSubmit = (formState) => {
    const data = {
      order_id: projectData.id,
      reference_url: formState.reference_url,
      title: formState.title,
      description: formState.description,
      media_id: formData.documents[0]?.id
    };
    stepsService.update("second-step", data).then((res) => {
      if (res.status === SuccessOk) {
        toast.success("Project content successfully submitted");
        toggle();
        callSecondStep();
      } else {
        toast.error("Whoops something went wrong");
      }
    });
  };

  return (<>
    <ToastContainer/>
    <Modal
      isOpen={modalContent}
      className="modal-dialog-scrollable modal-lg"
      toggle={() => toggle()}
    >
      <ModalHeader
        className="modalHead me-2"
        toggle={() => toggle()}
      >
        <div>
          <h6 className="fw-bold">Insert The Content</h6>
          <p className="font-14 text-muted mb-0">
            Fill information about your business and goals for this website
          </p>
        </div>
      </ModalHeader>
      <ModalBody className="border-0 py-2">
        <div>
          <Form
            onSubmit={handleSubmit(handleValidSubmit)}
            className="g-3 needs-validation"
          >
            <div>
              <div className="row">
                <div className="col">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item pb-3 ps-0">
                      <div className="pb-3">
                        <div className="form-group w-100">
                          <label className="pb-2">Title</label>
                          <input
                            placeholder={"title"}
                            name={`title`}
                            {...register(`title`)}
                            type="text"
                            className={`form-control height-42${errors.title ? "is-invalid" : ""}`}
                          />
                          {errors.title?.message && (<ErrorMessage
                            message={errors.title?.message}
                          />)}
                        </div>
                      </div>

                      <div className="form-group">
                        <div>
                          <Label> Description</Label>
                          <textarea
                            rows="4"
                            name={`description`}
                            {...register(`description`)}
                            placeholder="3 Fill information about your business and goals for this website and this data can vary and time can be from."
                            className={`form-control ${errors.description ? "is-invalid" : ""}`}
                          ></textarea>
                        </div>
                        {errors.description?.message && (<ErrorMessage
                          message={errors.description?.message}
                        />)}
                      </div>
                      <div className="sectionUpload pt-3">
                        <FileUpload
                          accept=".pdf,.doc,.docx"
                          label="Attach a file content"
                          attachmentType="documents"
                          attachmentData={[...formData.documents]}
                          updatedAttachment={updatedAttachment}
                          deleteAttachment={deleteAttachment}
                          setUploadState={setUploadState}

                        />
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <hr className="w-100"/>
                        <span>OR</span>
                        <hr className="w-100"/>
                      </div>
                      <p className="text-center "></p>
                      <div className="pb-3 mt-2">
                        <div className="form-group w-100">
                          <label className="pb-2">Google Document URL</label>
                          <input
                            placeholder={"reference_url"}
                            name={`reference_url`}
                            {...register(`reference_url`)}
                            type="text"
                            className={`form-control height-42${errors.reference_url ? "is-invalid" : ""}`}
                          />
                          {/* {errors.reference_url?.message && (<ErrorMessage
                            message={errors.reference_url?.message}
                          />)} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                className="btn btn-primary d-block ms-auto text-start height-42 mb-3"
                type="submit"
                onClick={() => {
                  console.log(errors);
                }}
                disabled={uploadState ? true : false}
              >
                Continue
                <img className="float-end pt-2 ps-5" src={arrowImg} alt=""/>
              </button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  </>);
};

AddPageContentModal.propTypes = {
  projectData: PropTypes.object, 
  toggle: PropTypes.func, 
  callSecondStep: PropTypes.func, 
  modalContent: PropTypes.bool
};

export default AddPageContentModal;
