import React, { useState, useEffect } from 'react'
import {
    Modal,
    ModalHeader, ModalBody, Label, Row, Col,
} from "reactstrap"
import Select from "react-select";
import PropTypes from 'prop-types';
import arrowImg from "assets/images/arrow.png"
import SuccessModal from "components/Common/SuccessModal";
import { AddManagerValidator } from 'FormValidations/Admin/Manager/Add';
import { useForm, } from "react-hook-form"; //Controller
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-toastify";
import { SuccessCreated, SuccessOk } from "configs/statusCode";
import ManagerService from "services/Admin/ManagerService"

const rolesOptions = [
    { label: "Admin", value: "Admin" },
    { label: "Account Manager", value: "Account Manager" },
    { label: "Developer", value: "Developer" },
    { label: "Copy Writer", value: "Copy Writer" },
]

const ModalAdd = ({ modalAdd, toggleAdd, setGetData, editItem }) => {
    const {
        handleSubmit,
        register,
        setValue,
        // control,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur' ,
        resolver: yupResolver(AddManagerValidator)
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(true);
    const [error, setError] = useState({})
    const [formData, setFormData] = useState({
        role: '',
        calendlyAcc: '',
        calendlyToken: ''
    })
    const [modalSuccess, setModalSuccess] = useState(false);
    const toggleSuccess = () => {
        setModalSuccess(!modalSuccess);
        setGetData(true);
    };

    useEffect(() => {
        if (editItem) {
            new ManagerService().find(editItem.id).then(res => {
                setValue("name", res.data.name)
                // setValue("role", {
                //     label: res.data.role,
                //     value: res.data.role,
                // })
                setValue("email", res.data.email)
                setStatus(res.data.status)
                setFormData({...formData , 
                    role: {
                        label: res.data.role,
                        value: res.data.role,
                    }, 
                    calendlyAcc: res.data?.calendly_data?.calendlyAcc || '',
                    calendlyToken: res.data?.calendly_data?.calendlyToken || ''
                })
            })
        }
    }, [])

    const handleValidSubmit = async (values) => {
        if(!formData.role){
            setError({...error, role: true})
            return
        }
        if(!formData?.calendlyAcc || !formData?.calendlyToken){
            if(formData.role?.value === 'Account Manager' || formData.role?.value === 'Admin'){
                let errors = {}
                if(!formData?.calendlyAcc) errors['calendlyAcc'] = true
                if(!formData?.calendlyToken) errors['calendlyToken'] = true
                setError({...error, ...errors})
                return
            }
        }
        
        setIsSubmitting(true);
        let requestData = {
            ...values,
            role: formData.role.value, //values.role.value,
            calendly_data: {
                calendlyAcc: formData?.calendlyAcc,
                calendlyToken: formData?.calendlyToken,
            },
            status
        };

        try {
            if (editItem) {
                const userData = await new ManagerService().update(editItem.id, requestData);
                if (userData.status == SuccessOk) {
                    toggleAdd();
                    setGetData(true);
                    toast.success("Manager Updated Successfully");
                }
            } else {
                const userData = await new ManagerService().create(requestData);
                if (userData.status == SuccessCreated) {
                    setModalSuccess(true);
                }
            }
            setIsSubmitting(false);
        } catch (err) {
            setIsSubmitting(false);
            toast.error(err.response.data.error);
        }
        // toggleAdd();
    };

    return (
        <Modal isOpen={modalAdd} className="modal-lg container" toggle={() => toggleAdd()}>
            <ModalHeader className='modalHead me-2' toggle={() => toggleAdd()}>
                <div>
                    <h6 className='fw-bold'>{editItem ? "Edit a new Team Member" : "Add a Team Member"}</h6>
                    <p className='font-14 text-muted mb-0'>Fill information about your business and goals for this website</p>
                </div>

            </ModalHeader>
            <ModalBody className='border-0 py-4'>
                <form className="g-3 needs-validation" onSubmit={handleSubmit(handleValidSubmit)}>
                    <Row>
                        <Col lg={6}>
                            <div className='pb-2'>
                                <Label>Team Member Name</Label>
                                <input
                                    {...register("name")}
                                    placeholder="Name"
                                    className={`form-control height-47 ${errors ?.name && "border-danger" }`}
                                    type="text"
                                />
                            </div>
                            {errors ?.name && <ErrorMessage message={errors ?.name ?.message} />}

                        </Col>
                        <Col lg={6}>
                            <div className='pb-2'>
                                <Label>Email address</Label>
                                <input
                                    {...register("email")}
                                    className={`form-control height-47 ${errors ?.email && "border-danger" }`}
                                    type="email"
                                    placeholder="Email Address"
                                />
                            </div>
                            {errors ?.email && <ErrorMessage message={errors ?.email ?.message} />}

                        </Col>
                        <Col lg={6}>
                            <div className='pb-2'>
                                <Label>Role</Label>
                                {/* <Controller
                                    name="role"
                                    control={control}
                                    id='ro'
                                    render={({ field }) => <Select
                                        {...field}
                                        options={rolesOptions}
                                        className={`heightSelect ${errors ?.role && "select-border" }`}
                                        
                                    />}
                                /> */}
                                {/* {errors ?.role && <ErrorMessage message={errors.role.message} />} */}
                                <Select
                                    name='role'
                                    options={rolesOptions}
                                    className={`heightSelect ${error?.role && "select-border" }`}
                                    value={formData.role}
                                    onChange={e=>{
                                        setFormData({...formData, role: e})
                                        setError({...error, role: false})
                                    }}
                                />
                                {error ?.role && <ErrorMessage message={'role is a required field'} />}
                            </div>
                        </Col>
                        
                        {(formData.role?.value === 'Account Manager' || formData.role?.value === 'Admin') && <>
                        <Col md={6}>
                            <div className='pb-2'>
                                <Label>Calendly Link</Label>
                                <input
                                    name='calendly'
                                    placeholder="Calendly Link"
                                    className={`form-control height-47 ${error?.calendlyAcc && "border-danger" }`}
                                    type="text"
                                    onChange={e=> {
                                        setFormData({...formData, calendlyAcc: e.target.value})
                                        setError({...error, calendlyAcc: false})
                                    }}
                                    value={formData.calendlyAcc}
                                />
                            </div>
                            {error ?.calendlyAcc && <ErrorMessage message={'calendly link is a required field'} />}
                        </Col>
                        <Col md={12}>
                            <div className='pb-2'>
                                <Label>Calendly Token</Label>
                                <input
                                    name='calendlyToken'
                                    placeholder="Calendly Token"
                                    className={`form-control height-47 ${error?.calendlyToken && "border-danger" }`}
                                    type="text"
                                    onChange={e=> {
                                        setFormData({...formData, calendlyToken: e.target.value})
                                        setError({...error, calendlyToken: false})
                                    }}
                                    value={formData.calendlyToken}
                                />
                            </div>
                            {error ?.calendlyToken && <ErrorMessage message={'calendly token is a required field'} />}
                        </Col>
                        </>}
                        <Col
                            lg={6}
                            className="my-2 justify-content-between align-items-center"
                        >
                            <label className="form-check-label d-inline">
                                Status
                            </label>
                            <div className="form-check form-switch py-2">
                                <input
                                    className={`form-check-input`}
                                    type="checkbox"
                                    id="status"
                                    checked={status}
                                    onChange={() => setStatus(c => !c)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className='pb-0 mt-3 text-end'>
                        <div className='row w-100'>
                            <div className='col-md-12'>
                                <button
                                    className='btn btn-outline-primary height-42 me-3 px-3'
                                    onClick={() => toggleAdd()}
                                    type="submit"
                                >
                                    Cancel
                                 </button>
                                <button
                                    className="btn btn-primary px-3 ms-auto text-start height-42"
                                    type="submit"
                                    disabled={isSubmitting ? true : false}
                                >
                                    {editItem ? "Edit" : "Continue"}
                                    <img className='float-end pt-2 ps-5' src={arrowImg} alt="" />
                                </button>
                            </div>
                        </div>
                        {/* <button type='button' className='btn btn-light' onClick={toggleAdd}>Cancel</button> */}
                    </div>
                    <SuccessModal
                        modalSuccess={modalSuccess}
                        entiityName="Team Member"
                        toggleSuccess={() => {
                            toggleSuccess(), toggleAdd();
                        }}
                    />
                </form>
            </ModalBody>
        </Modal>
    );
}
ModalAdd.propTypes = {
    modalAdd: PropTypes.bool,
    toggleAdd: PropTypes.func,
    setGetData: PropTypes.func,
    editItem: PropTypes.object,
};
export default ModalAdd;
