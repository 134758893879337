import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import documentImage from "../../../../assets/images/doc.png";
const ModalPreview = ({ modalPreview, toggleModalPreview, item }) => {
  console.log('item is',item)
  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={modalPreview}
        className="modal-dialog-scrollable modal-lg container"
        toggle={() => toggleModalPreview()}
      >
        <ModalHeader
          className="modalHead me-2"
          toggle={() => toggleModalPreview()}
        >
          <div>
            <h6 className="fw-bold">Preview Page Content</h6>
          </div>
        </ModalHeader>
        <ModalBody className="border-0 py-4">
            <div className="row">
                <div className="col-lg-6">
                    <h6 className="fw-bold">Title</h6>
                    <p>{item.title}</p>
                </div>
                <div className="col-lg-6">
                    <h6 className="fw-bold">Description</h6>
                    <p>{item.description}</p>
                </div>
            </div>
            <div className="pb-3">
                <h6 className="fw-bold">Google Document URL</h6>
                <a href={item.reference_url} target="_blank" rel="noreferrer">
                    {item.reference_url}
                </a>
            </div>
            <h6 className="fw-bold">Documents</h6>
            <img src={documentImage} className="me-2" alt="not found" />
            <a href={item.media.path} target="_blank" rel="noreferrer">
                Preview Content{" "}
            </a>
        </ModalBody>
      </Modal>
    </>
  );
};
ModalPreview.propTypes = {
  toggleModalPreview: PropTypes.func,
  modalPreview: PropTypes.bool,
  item: PropTypes.object
};

export default ModalPreview;
