import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import successImg from "assets/images/success.png";
import closeImg from "assets/images/close.png";

const SuccessModal = ({ modalSuccess, toggleSuccess, entiityName, edit }) => {
  return (
    <Modal isOpen={modalSuccess} toggle={() => toggleSuccess()}>
      <ModalHeader className="modal-header border-0">
        <button className="btn border-0">
          <img src={closeImg} height="25" onClick={toggleSuccess} />
        </button>
      </ModalHeader>
      <ModalBody className="border-0 text-center pb-5 pt-0">
        <img src={successImg} className="img-fluid mb-4" />
        {!edit ? (
          <h5 className="py-2 fw-bold">
            {entiityName} has been succesfuly added to system{" "}
          </h5>
        ) : (
          <h5 className="py-2 fw-bold">
            {entiityName} has been Updated succesfuly{" "}
          </h5>
        )}
        <p className="pb-2">
          {`You currently can browse more projects, please create one to help the designer understand more of what you need`}
        </p>
        <button
          className="btn btn-outline-primary px-5"
          onClick={toggleSuccess}
        >
          Back to {entiityName}s
        </button>
      </ModalBody>
    </Modal>
  );
};
SuccessModal.propTypes = {
  entiityName: PropTypes.string,
  modalSuccess: PropTypes.bool,
  toggleSuccess: PropTypes.func,
  edit: PropTypes.bool,
};
export default SuccessModal;
