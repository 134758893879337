import React, { useEffect, useState } from "react";
import Header from "components/Website/Layouts/Header/index";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from 'react-i18next';
import "./style.scss"
import Footer from "components/Website/Layouts/Footer";
import PlanService from "services/Website/PlanService";
import { SuccessOk } from "configs/statusCode";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { appendFilters } from "store/Auth/actions";
import FaqPayment from "components/Website/Plans/FaqPayment";

// const defaultColor = 'bgInfo'

const Subscriptions = () => {
  const [plans, setPlans] = useState([])
  // const [height, setHeight] = useState('')
  const planService = new PlanService()
  const dispatch = useDispatch()
  const { t } = useTranslation();

  // const calcHeight = (data) => {
  //   let filterArray = data?.map(res=> res?.features?.length)
  //   let maxHeight = Math.max(...filterArray)
  //   setHeight(maxHeight*40)
  // }

  useEffect(()=>{
    planService.getList().then(res=>{
      if(res && res?.status === SuccessOk){
        let data = res.data?.map(item=>{
          return{
            ...item,
            has_content: false,
            has_second_lang: false,
            total: item?.price?.find(res => res?.currency === item?.currency)?.amount 
          }
        })
        // calcHeight(data)
        setPlans(data)
      }
    })
  },[])

let history = useHistory();
  const handleSubmit = (e, plan)=>{
    e.preventDefault()
    let selectedPlan = {...plan, basic: true}
    const planString = JSON.stringify(selectedPlan);
    let langNumber = plan.has_content ? "2" : "1" //content ? "1" : "0";
    let stripe_id =  (plan.has_second_lang && plan.has_content) ? 
                  plan.basic_content_second?.find(res => res.currency === plan.currency)?.stripe_id : 
                  plan.has_second_lang ? plan.basic_second?.find(res => res.currency === plan.currency)?.stripe_id : 
                  plan.has_content ? plan.basic_content?.find(res => res.currency === plan.currency)?.stripe_id : plan.price.stripe_id;
    dispatch(appendFilters({selectedPlan, stripe_id, langNumber}));
    localStorage.setItem("plan", planString);
    localStorage.setItem("stripe_id", stripe_id);
    localStorage.setItem("langNumber", langNumber);
    history.push("/templates", plan)
  }

  return (
    <div className="websitPages">
      <Header />
      <div className="pt-5 mt-3">
        <div className="container-fluid px-md-5 pt-5">
          <div className="d-md-flex align-items-center justify-content-between">
            <div>
              <h4 className="pt-3 fw-bold textDarkPrimar">{t("Tailored pricing to fit your needs")}</h4>
              <p className="textSecondary">
              {t("Select from our range of plans to build a package that's right for you.")}
              </p>
            </div>
            <Link
              to={"/templates"}
              className="btn btn-primary font-14 ms-2 d-flex height-47 align-items-center mb-2 px-xl-3 px-lg-2"
            >
              {t("Back To Templets")}{" "}
              <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i>
            </Link>
          </div>
          <div className="row pt-5">
            {plans?.map((plan, index)=>{
              return <div key={index} className="col-lg-4 col-md-6 pb-5 pb-lg-3 mt-5 mb-3">
              <div className="card border-0 h-100 rounded-3 position-relative cardStyle">
                <div className="card-body h-100">
                  <div 
                    className={`absoluteEnterprise p-3`} 
                    style={{
                      backgroundColor: plan?.color,
                      boxShadow: '0px 3px 20px 0px #0000001F',
                      borderRadius: '8px'
                    }}>
                    <h1 className="fw-bold textBlueDark">${plan.total} <span className="badge bg-primary font-14 fw-light rounded-pill px-4 ms-auto float-end mt-3">{plan?.pages_num?.name?.en !== "One Page" && 'From'} {plan?.pages_num?.name?.en}</span></h1>
                    <h5 className="fw-bold textBlueDark">{plan?.name?.en}</h5>
                    <p className="textBlueDark opacity-75 mb-0 minHeight">{plan?.description?.en}</p>
                    <p className="position-absolute saveDiscount end-0"><span className="badge bgLightSuccess font-14 fw-bold px-4">75% OFF</span></p>
                  </div>
                  <form className="pt-3 h-100" onSubmit={e=> handleSubmit(e,plan)}>
                  <div className="mb-2 pt-5 mt-5">
                      <input
                        className="form-check-input me-2"
                        id={`content${index+1}`}
                        // {...register("checkbox")}
                        onChange={e=>{
                          let update = plans?.map((item,ind)=>{
                            if(index === ind){
                              return{
                                ...item,
                                has_content: e.target.checked,
                                total: e.target.checked ? (item?.content_price?.find(res => res.currency === item.currency)?.amount+item.total) : (item.total-item?.content_price?.find(res => res.currency === item.currency)?.amount)
                              }
                            } else{
                              return item
                            }
                          })
                          setPlans(update)
                        }}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor={`content${index+1}`}>
                        Include Content (copywriting)
                      </label>
                    </div>
                    {/* <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="firstLang"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="firstLang">
                        With One Language (English)
                      </label>
                    </div> */}
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id={`secondLang${index+1}`}
                        // {...register("checkbox")}
                        onChange={e=>{
                          let update = plans?.map((item,ind)=>{
                            if(index === ind){
                              return{
                                ...item,
                                has_second_lang: e.target.checked,
                                total: e.target.checked ? (item?.second_lang_price?.find(res => res.currency === item.currency)?.amount+item.total) : (item.total-item?.second_lang_price?.find(res => res.currency === item.currency)?.amount)
                              }
                            } else{
                              return item
                            }
                          })
                          setPlans(update)
                        }}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor={`secondLang${index+1}`}>
                        Make my Website Bilingual(English/Arabic)
                      </label>
                    </div>
                    <hr />
                    <div style={{paddingBottom: '100px'}}> {/*style={{height: `${height}px`}}> */}
                      {plan?.features?.map((feature, index)=>{
                        return <p className="font-size-14 fw-medium" key={index}>
                          {feature?.active ? <i className="fa fa-check text-primary me-2"></i> : <i className="fa fa-close text-danger me-2"></i>} {feature.feature}
                        </p>
                      })}
                    </div>
                    <button 
                      className={`btn font-14 height-62 mb-2 text-start bg-dark text-white`}
                      style={{
                        position: 'absolute', 
                        bottom: '16px', 
                        width: '93%',
                        fontWeight: '800'
                      }}
                    >
                      {t("Continue with this plan")}{" "}
                      <i 
                        className="fa fa-long-arrow-right float-end pt-1"
                        style={{marginRight: '24px'}}
                      ></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            })}
            {/* <div className="col-lg-4 col-md-6 pb-5 pb-lg-3 mb-5 mb-lg-0">
              <div className="card border-0 rounded-3 position-relative" data-aos='fade-up' data-aos-duration='1000'>
                <div className="card-body">
                  <div className="absoluteEnterprise p-3 rounded-3 bgInfo">
                    <h1 className="fw-bold textBlueDark">$200 <span className="badge bg-primary font-14 fw-light rounded-pill px-4 ms-auto float-end mt-3">From 2-5 Page</span></h1>
                    <h5 className="fw-bold textBlueDark">Enterprise</h5>
                    <p className="textBlueDark opacity-75 mb-0">That will Be Changed after customize your paln</p>
                  </div>
                  <form className="pt-3" onSubmit={handleSubmit()}>
                  <div className="mb-2 pt-5 mt-5">
                      <input
                        className="form-check-input me-2"
                        id="content"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="content">
                        Include Content (copywriting)
                      </label>
                    </div>
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="firstLang"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="firstLang">
                        With One Language (English)
                      </label>
                    </div>
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="secondLang"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="secondLang">
                        With Two Language (English/Arabic)
                      </label>
                    </div>
                    <hr />
                    <p>
                      <i className="fa fa-check text-primary me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-check text-primary me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-close text-danger me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-close text-danger me-2"></i> Lorem
                    </p>
                    <button className="btn btn-primary font-14 w-100 height-62 mb-2 text-start">
                      {t("Continue with this plan")}{" "}
                      <i className="fa fa-long-arrow-right float-end pt-1"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 pb-5 pb-lg-3 mb-5 mb-lg-0">
              <div className="card border-0 rounded-3 position-relative" data-aos='fade-up' data-aos-duration='1500'>
                <div className="card-body">
                  <div className="absoluteEnterprise p-3 rounded-3 bgLightGreen">
                    <h1 className="fw-bold textBlueDark">$200 </h1>
                    <h5 className="fw-bold textBlueDark">Enterprise</h5>
                    <p className="textBlueDark opacity-75 mb-0">That will Be Changed after customize your paln</p>
                  </div>
                  <form className="pt-3" onSubmit={handleSubmit()}>
                  <div className="mb-2 pt-5 mt-5">
                      <input
                        className="form-check-input me-2"
                        id="content2"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="content2">
                        Include Content (copywriting)
                      </label>
                    </div>
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="firstLang2"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="firstLang2">
                        With One Language (English)
                      </label>
                    </div>
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="secondLang2"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="secondLang2">
                        With Two Language (English/Arabic)
                      </label>
                    </div>
                    <hr />
                    <p>
                      <i className="fa fa-check text-primary me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-check text-primary me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-close text-danger me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-close text-danger me-2"></i> Lorem
                    </p>
                    <button className="btn btnSuccess font-14 w-100 height-62 mb-2 text-start">
                      {t("Continue with this plan")}{" "}
                      <i className="fa fa-long-arrow-right float-end pt-1"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 pb-5 pb-lg-3 mb-5 mb-lg-0">
              <div className="card border-0 rounded-3 position-relative" data-aos='fade-up' data-aos-duration='2000'>
                <div className="card-body">
                  <div className="absoluteEnterprise p-3 rounded-3 bgdarkGreen">
                    <h1 className="fw-bold textBlueDark">$200 </h1>
                    <h5 className="fw-bold textBlueDark">Enterprise</h5>
                    <p className="textBlueDark opacity-75 mb-0">That will Be Changed after customize your paln</p>
                  </div>
                  <form className="pt-3" onSubmit={handleSubmit()}>
                  <div className="mb-2 pt-5 mt-5">
                      <input
                        className="form-check-input me-2"
                        id="content3"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="content3">
                        Include Content (copywriting)
                      </label>
                    </div>
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="firstLang3"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="firstLang3">
                        With One Language (English)
                      </label>
                    </div>
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="secondLang3"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="secondLang3">
                        With Two Language (English/Arabic)
                      </label>
                    </div>
                    <hr />
                    <p>
                      <i className="fa fa-check text-primary me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-check text-primary me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-close text-danger me-2"></i> Lorem
                    </p>
                    <p>
                      <i className="fa fa-close text-danger me-2"></i> Lorem
                    </p>
                    <button className="btn btn-dark font-14 w-100 height-62 mb-2 text-start">
                      {t("Continue with this plan")}{" "}
                      <i className="fa fa-long-arrow-right float-end pt-1"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
          <div className="card mb-3">
            <div className="card-body pt-lg-5 px-lg-5">
              <div className='text-center pb-lg-3'>
                <h3 className='fw-bold textBlueDark'>Frequently Asked Questions</h3>
                <p className='text-muted smallText'>Have a question? We have got you covered. </p>
              </div>
              <FaqPayment />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
Subscriptions.propTypes = {
  history: PropTypes.object,

};
export default Subscriptions;
