import React from "react";
import PropTypes from "prop-types";

const ErrorMessageList = ({ error }) => {
  return (
    <ul className="list-unstyled">
      <li>
        {error.error}
      </li>
    </ul>
  );
};

ErrorMessageList.propTypes = {
    error: PropTypes.object,
};

export default ErrorMessageList;
