import React, { useEffect, useState } from "react";
import docImage from "assets/images/load.gif"
import { useForm } from "react-hook-form";
import OrderService from "../../../../services/Admin/OrderService";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const ThirdStepData = ({ projectData, updateProjectData }) => {
  const [numOfDays, setNumOfDays] = useState('')
  const [startDate, setStartDate] = useState('')
  const [date, setDate] = useState('');
  const {
    handleSubmit, register
  } = useForm({});
  const orderService = new OrderService();

  useEffect(()=>{
    if (projectData?.third_step_data?.reservation_date) {
      let data = projectData?.third_step_data?.reservation_date
      setDate(data)
    }
  })
  const sendDesignUrl = async (values) => {
    let data ={ 
      ...values
    }
    orderService.sendDesignUrl(projectData.id, data).then(res=>{
      if (res.status === 200) {
        toast.success("Your Design url Sent successfully");
        updateProjectData({ third_step_data_status: 3 });
      }
    }).catch(()=> {
      toast.error("The design feedback url format is invalid.")
    })
  };

  const sendDateAndNumber = (e) => {
    e.preventDefault()
    if(!startDate){
      toast.error('Select Start Date')
      return
    }
    let data ={ 
      third_step_data:{
        start_date: startDate,
        number_of_days: numOfDays,
      },
      design_feedback_url: "",
      third_step_status: 2
    }
    orderService.updateOrder(projectData.id, data).then(res=>{
      if (res.status === 200) {
        toast.success("Your Data Sent successfully");
        updateProjectData({ third_step_data_status: 2 });
      }
    }).catch(()=> {
      toast.error("The design feedback url format is invalid.")
    })
  }

  const  convertTo12HourFormat = (time24) => {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(':');

    // Convert hours to 12-hour format
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    // Return the formatted time
    if(minutes < 10){
      return `${formattedHours}:0${minutes} ${ampm}`;
    }
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  return (<div>
      {projectData.third_step_data_status === 0 && <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <h6 className="text-primary">Send Design URL</h6>
        {!!projectData?.third_step_data?.reservation_date && (new Date(projectData?.third_step_data?.reservation_date?.split('T')[0]) >= new Date() ? <div className="col-lg-12 ">
          <h6 className="mb-2">
            <span className="text-black font-size=14">
             Waiting for the scheduled meeting time at ( {convertTo12HourFormat(`${new Date(date).getHours()}:${new Date(date).getMinutes()}`)} ) 
             and on ( {new Date(date).getFullYear()}-{new Date(date).getMonth()+1}-{new Date(date).getDate()} ) ${projectData.assignee?.role === 'Account Manager' ? `with the account manager: ${projectData?.assignee?.name}` : ''}
            </span>
          </h6>
        </div> : <h6 className="mb-2">
            <span className="text-black font-size=14">
            the number of working days and the start date must be entered based on the meeting
            </span>
          </h6>)}
        <form
          onSubmit={sendDateAndNumber}
          className="formFeedback"
        >
          <div className="row pt-2">
            <div className="col-md-6 mb-3">
              <label className="text-black-50">Number of Days</label>
              <input 
                type='number' 
                value={numOfDays} 
                required
                min={1}
                className="form-control height-50"
                placeholder="Number of Days"
                onChange={(e)=> setNumOfDays(e.target.value)} />
            </div>
            <div className="col-md-6">
            <label className="text-black-50">Start Date</label>
              <input
                name="startDate"
                value={startDate}
                type="date"
                min={`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`}
                className="form-control height-50"
                onChange={(e)=> setStartDate(e.target.value)} 
              />
            </div>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn btn-primary w-100 height-50"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>}
      
      {projectData.third_step_data_status === 1 && <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <h6 className="fw-bold mb-1">
                <img src={docImage} alt="" width={50} height={40}/>
                <span className="fw-bold text-primary font-size=14">
                    Completed
                  </span>
                </h6>
            </div>
            <div className="col-lg-5 text-end">
                <label htmlFor="contained-button-file">
                <a href={projectData?.design_feedback_url} target="_blank" rel="noreferrer" className="btn btn-primary font-16 ms-2 height-42 px-5" >
                  Preview design
                </a>
              </label>
            </div>
          </div>
      </div>}

      {projectData.third_step_data_status === 2 &&
        <form
          onSubmit={handleSubmit(sendDesignUrl)}
          className="formFeedback"
        >
          <div className="card boxShadow pt-2 px-3 mb-2 pb-3">
          <h6 className="text-primary pt-2">Send Design URL</h6>
          <div className="row pt-2">
            {/* <div className="col-md-4 pb-2">
              <input 
                type='number' 
                value={numOfDays} 
                required
                min={1}
                className="form-control height-50"
                placeholder="Number of Days"
                onChange={(e)=> setNumOfDays(e.target.value)} />
            </div> */}
            <div className="col-md-9 pb-2">
              <input
                {...register("design_feedback_url", {
                  required: true
                })}
                type="text"
                className="form-control height-50"
                placeholder="Please Enter Preview Link"
              />
            </div>
            <div className="col-md-3">
              <button
                type="submit"
                className="btn btn-primary w-100 height-50"
              >
                Send Preview link
              </button>
            </div>
          </div>
          </div>
      </form>}

      {projectData.third_step_data_status === 3 &&
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <h6 className="fw-bold mb-1">
                <img src={docImage} alt="" width={50} height={50}/>
                <span className="fw-bold text-primary font-size=14">
                    {projectData.third_step_data_status === 1 ? "Completed" : (projectData.third_step_data_status === 2 ? `Waiting for writing a content` :
                      (projectData.third_step_data_status === 3 ? "Waiting for Client FeedBack" : "Waiting for writing a content"))}
                  </span>
                </h6>
            </div>
            <div className="col-lg-5 text-end">
                <label htmlFor="contained-button-file">
                <a
                  href={projectData?.design_feedback_url}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary font-16 ms-2 height-42 px-5 "
                >
                  Preview design
                </a>
              </label>
            </div>
          </div>
        </div>
      }

    </div>);
};
ThirdStepData.propTypes = {
  projectData: PropTypes.object, updateProjectData: PropTypes.func
};
export default ThirdStepData;