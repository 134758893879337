import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Progress } from "reactstrap";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"
import { appendFilters } from "store/Auth/actions"
import domainImage from "assets/images/domain.png"
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const Domain = ({ submitHandler, setStep, back}) => {
  const [completed, setCompleted] = useState(85);
  const has_domain = useSelector(state => state.Auth.has_domain)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setInterval(() => setCompleted(95), 1000);
  }, []);

  const onRadioBtnClick = (itemSelected) => {
    dispatch(appendFilters({ has_domain: itemSelected }))
  };

  const handleSubmit = () => {
    if (has_domain) {
      submitHandler()
    } else {
      toast.error("Answer the question")
    }
  }

  return (
    <div className="card mb-4 mt-3">
      <div className="card-header border-0 p-0">
        <Progress value={completed} />
      </div>
      <div className="card-body text-center pt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={domainImage} className="img-fluid" alt="domain" />
            <h3 className="pb-2 pt-3 fw-bold">
              {t("Do you have a domain name or would you like to purchase?")}
            </h3>
            <p className="text-muted pb-3">
              {t("Information of websites you visit. Use tools for lead generation,market")} <br /> {t("analysis and competitor research.")}
            </p>
            <form>
              <ButtonGroup className="d-grid copyRight">
                <Button
                  color="outline-secondary text-start ps-5"
                  className="mb-3"
                  onClick={() => onRadioBtnClick(1)}
                  active={has_domain === 1}
                >
                  {/* <i className="fa fa-check-circle me-2"></i> */}
                  <span>{t("Yes, I have a domain")}</span>
                </Button>
                <Button
                  color="outline-secondary text-start ps-5"
                  onClick={() => onRadioBtnClick(2)}
                  active={has_domain === 2}
                >
                  {/* <i className="fa fa-check-circle me-2"></i> */}
                  <span>{t("No, I would like a domain for me")}</span>
                </Button>
              </ButtonGroup>
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button
                  className="btn btnHeightLg px-3 py-2"
                  onClick={() => setStep(back)}
                  type={"button"}
                >
                   {t("Back")} {i18n.language !== "ar" ? <i className="fa fa-sign-out-alt px-2 rotateDegree float-start pt-1 pe-3"></i>
                    : <i className="fa fa-sign-out-alt px-2 float-end pt-1 ps-3"></i>}
                </button>
                <button
                  className="btn btn-primary btnHeightLg px-3 py-2"
                  onClick={handleSubmit}
                  type="button"
                >
                  {t("Continue")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i>
                    : <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
Domain.propTypes = {
  submitHandler: PropTypes.func,
  setStep: PropTypes.func,
  back: PropTypes.string
};

export default Domain;
