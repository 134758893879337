import React, { useState } from "react"
import CategoryService from "services/Admin/CategoryService"
import { Container, Table } from 'reactstrap'
import ModalAdd from "components/Admin/Category/ModalAdd"
import Paginator from 'components/Common/Paginator/Paginator'
import './style.scss'
import noProject from "assets/images/noProject.png"
import { Circles } from "react-loader-spinner"
import CardItem from "components/Admin/Category/CardItem"
import { MetaTags } from "react-meta-tags"

const Category = () => {
    const [getData, setGetData] = useState(true)
    const categoryService = new CategoryService()
    const [categories, setCategories] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [editItem, setEditItem] = useState(null)
    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
    })
    const toggleAdd = () => {
        setEditItem(null)
        setModalAdd(!modalAdd)
    }

    return (
        <>
            <MetaTags>
                <title> Categories </title>
            </MetaTags>
            {getData &&
                <div className='loaderStyle'>
                    <Circles color='#00BFFF' />
                </div>
            }
            <div className="admin-container">
                <Container fluid className="bgGray pb-5 pt-3 px-lg-5">
                    <div className="d-md-flex d-block justify-content-between">
                        <h3 className="mb-3">All Categories ({pageOptions.totalSize})</h3>
                        <button className="btn btn-primary mb-3 height-45" onClick={() => {
                            toggleAdd()
                        }}>
                            <i className="fa fa-edit fa-lg me-1"></i> Create Category</button>
                    </div>
                    {categories.length ? (
                        <div className="table-responsive bg-white p-2 w-100">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map((category, index) =>
                                        <CardItem
                                            key={index}
                                            index={index}
                                            category={category}
                                            setEditItem={setEditItem}
                                            setModalAdd={setModalAdd}
                                            setGetData={setGetData}
                                        />
                                    )}
                                </tbody>
                            </Table>
                        </div>) : (
                            <div className="text-center">
                                <img src={noProject} alt="" />
                                <p className="pt-2 mb-2"> Thers is No Categories </p>
                                <button className="btn btn-primary" onClick={() => {
                                    toggleAdd()
                                }}> Create Category
                                        </button>
                            </div>
                        )}
                    {modalAdd && (
                        <ModalAdd
                            editItem={editItem}
                            modalAdd={modalAdd}
                            toggleAdd={toggleAdd}
                            setGetData={setGetData}
                        />
                    )}
                    <div className="bg-white mt-3">
                        <div className="d-flex justify-content-end" md={8} sm={12}>
                            <Paginator
                                service={categoryService}
                                pageOptions={pageOptions}
                                setData={(data) => {
                                    setCategories([...data])
                                    setGetData(false)
                                }}
                                setPageOptions={setPageOptions}
                                filtersParams={null}
                                shouldGetData={getData}
                                setShouldGetData={setGetData}
                            />
                        </div>
                    </div>

                </Container>
            </div>
        </>
    )
}

export default Category
