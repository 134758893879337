import React from "react"
// import { Row } from "reactstrap"
import { Helmet } from "react-helmet";
// import Navbar from "components/Website/Layouts/Navbar";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { appendFilters } from "store/Auth/actions"
import PropTypes from 'prop-types';

const TemplatePreview = ({history ,location}) => {
const selectedTemplate=location.state.selectedTemplate
    const { t ,i18n } = useTranslation();
    const data = useSelector((state) => state.Auth);
    const dispatch = useDispatch()

    return (
        <>
            {/* <Navbar /> */}
            <Helmet>
                <title>Choose your website template</title>
                <meta name="description" content="Explore wesite template , browse design template" />
            </Helmet>
            <div className="websitPages" style={{width:"100vw",height:"87vh"}}>
                <iframe src={"https://www.yallakora.com/"} height={"100%"} width={"100%"}  />
                <div style={{ position: "fixed", width: "100%", height: "70px", padding: "5px", bottom: "0px" }}>
                <p className="text-end mb-1">
                <button
                  onClick={() => {
                    dispatch(appendFilters({ template: selectedTemplate }))
                    let type
                    if (data ?.website_type === 0) {
                      type = 0
                    } else {
                      type = data ?.type ?.value
                    }
                    history.push(`plans?industry=${data.industry ?.value}&size=${data.size ?.value}&website_type=${type}&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}&template=${selectedTemplate}`)
                  }}
                  className="btn btn-primary btnHeightLg px-3 py-2 text-start">
                  {t("Continue with this template")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i>
                    : <i className="fa fa-long-arrow-left pe-3 float-start pt-1 me-5"></i>}
                </button>
              </p>
                 </div>
            </div>
        </>
    )
}

TemplatePreview.propTypes = {
    history: PropTypes.object,
    location :PropTypes.any
  
  };
export default TemplatePreview