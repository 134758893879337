import PropTypes from "prop-types";
import docImage from "../../../../assets/images/doc.png";
import load from "../../../../assets/images/load.gif";
import EditPageContentModal from "./EditPageContentModal";
import AddPageContentModal from "./AddPageContentModal";
import documentImage from "../../../../assets/images/doc.png";
import React, { useEffect, useState } from "react";
// import StepsService from "../../../../services/Client/StepsService";
import OrderServices from "../../../../services/Client/OrderServices";
import { SuccessOk } from "../../../../configs/statusCode";
import { toast } from "react-toastify";
import ModalPreview from "./ModalPreview";
import { useTranslation } from 'react-i18next';
import { PopupButton } from "react-calendly";
import { CardText } from "reactstrap";
// import { API_CALENDLY_URL_ENV } from "helpers/common";
// import axios from "axios";

const contentIsIncluded = ({ projectData, secondStepData, callSecondStep, toggleAccordion, updateProjectData,setCompleted }) => {
  // const [firstLangPrice, setFirstLangPrice] = useState("");
  // const [secondLangPrice, setSecondLangPrice] = useState("");
  const [addPageContent, setAddPageContent] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [modalContent, setModalContent] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [date, setDate] = useState('');
  const [isAllConfirmed, setIsAllConfirmed] = useState(false);
  const [url, setUrl] = useState('');
  // const stepsService = new StepsService();
  const orderServices = new OrderServices();
  
  const { t } = useTranslation();
  // const toggleModalContent = () => {
  //   setModalContent(!modalContent);
  // };
  // const toggleModalPreview = () => {
  //   setModalPreview(!modalPreview);
  // };

  useEffect(() => {
    if (secondStepData && secondStepData[0]?.reservation_date) {
      let data = secondStepData[0]?.reservation_date
      setDate(data)
    }
    console.log(isAllConfirmed,setEditItem)
  }, [projectData, secondStepData]);
  
  useEffect(()=>{
    setUrl(projectData.assignee?.calendly_data?.calendlyAcc)
  },[])

  useEffect(() => {
    if(secondStepData) {
      const notConfirmed = secondStepData?.without_content?.filter(item => item.status !== 1);
      if (notConfirmed?.length) setIsAllConfirmed(false); else setIsAllConfirmed(true);
    }
  }, [secondStepData]);

  // const confirmPageContent = async (id) => {
  //   const res = await stepsService.confirmPageContent("second-step", { id });
  //   if (res.status === SuccessOk) {
  //     toast.success("Project content Confirmed successfully");
  //     callSecondStep();
  //   } else {
  //     toast.error("Whoops something went wrong");
  //   }
  // };

  const confirmSecondStep = (id) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const dateAfterOneDay = currentDate.toISOString().split('T')[0];

    let maximamDays;
    if(projectData.subscription_plan?.features[0]?.feature?.match(/(\d+)-(\d+)/)?.length > 0){
      // eslint-disable-next-line no-unused-vars
      maximamDays = projectData.subscription_plan?.features[0].feature?.match(/(\d+)-(\d+)/)[0].split('-')[1]
    }

    let data = {
      step_confirmed: "second_step_status",
      third_step_data: {
        start_date: dateAfterOneDay, 
        number_of_days: maximamDays
      },
      third_step_status: 2
    }
    orderServices.updateContent(id, data).then(res=>{
      if (res.status === SuccessOk) {
        updateProjectData({ 
          second_step_status: 1, 
          third_step_data: {
            start_date: dateAfterOneDay, 
            number_of_days: maximamDays
          },
          third_step_status: 2 
        });
        toast.success("Project content Confirmed successfully");
        callSecondStep();
        setCompleted(prev => prev+25);
        toggleAccordion("3")
      } else {
        toast.error("Whoops something went wrong");
      }
    })
  };
  
  // const getDate = async (eventUrl) => {
  //   let token = 'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk3NDYyNzI2LCJqdGkiOiI2NWI2NmY5ZS1hMjQ3LTQ1NzktOTRiMy1lNDVkYTljYWM2NTkiLCJ1c2VyX3V1aWQiOiI3MmNjYTYzMS0yNjFmLTRlNmItYmNiYi1kOGQyNzhkNzUxN2MifQ.xvmZ-KcTU6zGGEe0MkfZPmWzHiIMs-8rl2KChfCPcmbvRbb6-Q6UldgN6Y_rhxYLmuxH3jV5siLvnEIWp_aVMA' //JSON.parse(localStorage.getItem('auth'))

  //   axios.get(eventUrl,{
  //     headers: {
  //         'Authorization':'Bearer ' + token//.access_token
  //     }
  //   })
  //   // .then(res=> {
  //     // let inputDate = res?.data?.resource?.start_time
  //     // const originalDate = new Date(inputDate);
  //     // const timeZone = "Africa/Cairo";

  //     // const options = {
  //     //   year: 'numeric',
  //     //   month: 'numeric',
  //     //   day: 'numeric',
  //     //   hour: 'numeric',
  //     //   minute: 'numeric',
  //     //   second: 'numeric',
  //     //   timeZone,
  //     //   timeZoneName: 'short',
  //     // };

  //     // const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  //     // const formattedDateTime = dateTimeFormat.format(originalDate);
  //   // })
  // }

  const  convertTo12HourFormat = (time24) => {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(':');

    // Convert hours to 12-hour format
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    // Return the formatted time
    if(minutes < 10){
      return `${formattedHours}:0${minutes} ${ampm}`;
    }
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  return (<>
    <div className="px-lg-4">
      {/* <p className="textRed font-14 mb-2 fw-bold">
        <i className="fas fa-info-circle"></i> {t("Content not included in your plan, you provide content.")}
      </p> */}
      {/* <button className="btn btnYellow text-danger font-14 ms-2 height-42 mb-2 mt-3 mt-md-1">
        Purchase content for one Lang{" "}
        {`${firstLangPrice.currency} ${firstLangPrice.amount}`}
      </button> */}
      {/* <button className="btn btnYellow text-danger font-14 ms-2 height-42 mb-2 mt-3 mt-md-1">
        Purchase content for two Langs{" "}
        {`${secondLangPrice.currency} ${secondLangPrice.amount}`}
      </button> */}
    </div>

    {projectData.second_step_status === 0 && (projectData.assignee.role === 'Account Manager' ? <div className="card mb-3 mt-3 mx-lg-4">
      <div className="card-body">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <h6 className="fw-bold">
              <img src={docImage} className="pe-2" alt=""/>
              Let’s get started...
            </h6>
            <p className="text-muted font-14 mb-2 ps-4">
            Discuss your brand`s style with us. Schedule a session with our account manager.
            </p>
          </div>
          <div className="col-lg-6 text-lg-end session pt-lg-4 pt-2">
            {(!!url && Number(projectData?.session_reserved) === 0) && 
            <PopupButton url={url} rootElement={document.getElementById("root")}
              text="Schedule Briefing Session" 
            />}
          </div>
        </div>
      </div>
    </div> : <div className="card mb-3 mt-3 mx-lg-4">
      <div className="card-body">
        <div className="row justify-content-between">
          <div className="col-lg-9 d-flex align-items-center">
            <img src={load} width={50} height={40} alt=""/>
            <p className="fw-bold ps-1 mb-0 text-black font-size=14">
            Kindly wait until an account manager is assigned to your project...
            </p>
          </div>
        </div>
      </div>
    </div>)}

    {projectData.second_step_status === 3 && <div className="card mb-3 mt-3 mx-lg-4">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
                <h6 className="fw-bold mb-0 d-flex align-items-center gap-1 pt-1">
                  <img
                    src={load}
                    alt=""
                    width={50}
                    height={40}
                  />
                  <span className="fw-bold font-size=14">
                    {secondStepData && new Date(secondStepData[0]?.reservation_date) <= new Date() ? "Waiting Content" : 
                    <>Waiting for the scheduled meeting time at
                    ( <span className="text-primary">{convertTo12HourFormat(`${new Date(date).getHours()}:${new Date(date).getMinutes()}`)} </span> ) 
                      and on 
                    ( <span className="text-primary">{new Date(date).getFullYear()}-{new Date(date).getMonth()+1}-{new Date(date).getDate()}</span> ) {projectData.assignee?.role === 'Account Manager' ? <>with the account manager: <span className="text-primary">{projectData?.assignee?.name}</span></> : ''}
                    </>}
                  </span>
                </h6>
                <p className="fw-bold ps-lg-5 ms-1 mb-2 text-primary font-size=14">
                  {secondStepData && new Date(secondStepData[0]?.reservation_date) <= new Date() ? "You’ll receive an email once the content is ready." : ''}
                </p>
                <p className="fw-bold ps-lg-5 ms-1 mb-0 text-dark font-size=14">
                  {secondStepData && new Date(secondStepData[0]?.reservation_date) <= new Date() ? "Check back soon." : ''}
                </p>
                {/* <p className="text-primary"> You’ll receive an email once the content is ready</p> */}
                    
                    {/* {secondStepData && new Date(secondStepData[0]?.reservation_date) <= new Date() ? "Hold on as we prepare your content" : `Waiting for the scheduled meeting time at ( ${secondStepData[0]?.reservation_date?.split('T')[1]?.split('.')[0]} ) on ( ${secondStepData[0]?.reservation_date?.split('T')[0]} ) ${projectData.assignee?.role === 'Account Manager' ? `with the account manager: ${projectData?.assignee?.name}` : ''}`} */}
              </div>
        </div>
      </div>
    </div>}

    {/* {secondStepData?.without_content?.length ? ("") : (<div className="card mb-3 mt-3 mx-lg-4">
      <div className="card-body">
        {!!projectData?.assignee?.name && <div className="row justify-content-between">
          <div className="col-lg-6">
            <h6 className="fw-bold">
              <img src={docImage} className="pe-2" alt=""/>
              {t("Reserving Session")}
            </h6>
            <p className="text-muted font-14 mb-2 ps-4">
              {t("Fill information about Metting so we can understand what you want to acheive with it")}
            </p>
          </div>
          <div className="col-lg-6 text-lg-end session pt-lg-4 pt-2">
            {(!!url && (projectData?.session_reserved === "0" || projectData?.session_reserved === 0)) && 
            <PopupButton url={url}
              rootElement={document.getElementById("root")}
              text="Start Your Session" 
            />}
            {(projectData?.session_reserved === 1 || projectData?.session_reserved === '1') && <p className="text-muted font-14 mb-2 ps-4">
              {t("Waiting Meeting and Content")}
            </p>}
          </div>
        </div>}
        {projectData?.assignee?.length === 0 && <div className="row">
          <div className="col-lg-7 ">
                <h6 className="fw-bold mb-0">
                  <img
                    src={docImage}
                    className="pe-2"
                    alt=""
                    width={30}
                    height={30}
                  />
                  <span className="fw-bold ps-3 text-primary font-size=14">
                    Waiting for Assign Account Developer{" "}
                  </span>
                </h6>
                <p className="fw-bold font-size-14 ps-5">Check back soon</p>
              </div>
        </div>}
      </div>
    </div>)} */}
    <div>
      <div className="d-md-flex justify-content-between">
        {modalContent && (<EditPageContentModal
          item={editItem}
          projectData={projectData}
          modalContent={modalContent}
          toggleModalContent={() => {
            callSecondStep();
            setModalContent(!modalContent);
          }}
        />)}
        {addPageContent && (<AddPageContentModal
          projectData={projectData}
          modalContent={addPageContent}
          callSecondStep={callSecondStep}
          toggle={() => {
            callSecondStep();
            setAddPageContent(!addPageContent);
          }}
        />)}
      </div>
      <div className="justify-content-between px-lg-4">
        {(projectData.second_step_status === 2 || projectData.second_step_status === 1) && (
          <div className="card boxShadow w-100 mb-3">
            <div className="card-body">
              <h6 className="fw-bold pb-2">
                <img src={documentImage} className="pe-2"/> {t("Website Pages for each page")}
              </h6>
              <div className="card mt-2">
                  <div className="card-body px-3">
                    <div className="d-md-flex justify-content-between">
                      <div className="mb-2 mb-md-0">
                        <span className="fw-bold me-3 me-md-0">Content File</span>
                        <span className="badge badgeCompleted mx-md-4">{t("Ready")}</span> 
                        {projectData.second_step_status === 2 && <span className="badge badgeWarning mx-lg-4">{t("You should confirm this page")}</span>}
                      </div>
                      {secondStepData && <p className="mb-0">
                      <a href={secondStepData[0]?.reference_url} target="_blank" rel="noreferrer" className="btn btn-outline-primary font-14 text-capitalize"> 
                            <i className="fa fa-eye me-2"></i>{t("Preview content")}
                          </a>
                      </p>}
                    </div>
                    {projectData.second_step_status === 2 && <div>
                      <p className="text-muted pt-3 mb-1">Preview your content and add your comments and suggestions directly within the document.</p>  
                      <p className="text-muted mb-0">Your suggestions make the content creation process faster and more efficient.</p>  
                    </div>}
                  </div>
                </div>

              {projectData.second_step_status === 2 && <div className="d-lg-flex pt-3 align-items-center">
                <button className="btn btn-primary w-100 font-14 height-42 me-2 mb-3 mb-lg-0"
                        onClick={() => confirmSecondStep(projectData.id)}>I`m Happy with this Content 
                </button>
                {(!!url && Number(projectData?.session_reserved) === 0 ) && <div className="session2 w-100">
                  <PopupButton url={url} 
                    rootElement={document.getElementById("root")}
                    text="Schedule Content Revision Session" 
                  />
                </div>}
                {/* {Number(projectData?.session_reserved) === 1 && <p className="text-muted font-14 mb-2 ps-4">
                  {t("Waiting Meeting and Content")}
                </p>} */}
              </div>}

            </div>
          </div>)}
      </div>
      <CardText className="px-md-4" style={{color:'var(--bs-green)'}}>
        <i className="fas fa-info-circle"></i> 
        <span className="font-14 ps-2 fw-bold">
        Your copywriting plan includes up to {projectData?.subscription_plan?.words_num} words. 
        </span>
      </CardText>
    </div>
    {modalPreview && (<ModalPreview
      item={editItem}
      modalPreview={modalPreview}
      toggleModalPreview={() => {
        setModalPreview(!modalPreview);
      }}
    />)}
  </>);
};

contentIsIncluded.propTypes = {
  projectData: PropTypes.object,
  callSecondStep: PropTypes.func,
  toggleAccordion: PropTypes.func,
  updateProjectData: PropTypes.func,
  secondStepData: PropTypes.any,
  setCompleted: PropTypes.any
};

export default contentIsIncluded;