import React, { useState } from "react"
import Navbar from "components/Website/Layouts/Header"
import Domain from "components/Website/Steps/Domain";
import TypeWebsite from "components/Website/Steps/TypeWebsite";
import Pages from "components/Website/Steps/Pages";
import Briefly from "components/Website/Steps/Briefly";
import Business from "components/Website/Steps/Business";
import Industry from "components/Website/Steps/Industry";
import PropTypes from 'prop-types';
import "./style.scss"
import CopyRight from "components/Website/Steps/CopyRight";
import Footer from "components/Website/Layouts/Footer";
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet";
 
const CreateWebsite = ({ history }) => {
    const [step, setStep] = useState("Industry")
    const data = useSelector(state => state.Auth)

    const submitHandler = () =>{
        let type
        if (data.website_type === 0) {
            type = 0
        } else {
            type = data.type.value
        }
        history.push(`templates?industry=${data.industry.value}&size=${data.size.value}&website_type=${type}&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}`)
    }

    return (
        <>
            <Navbar />
            <Helmet>
                <title>Create your website</title>
                <meta name="description" content="choose your website category , website number of page  and descrip your business " />
            </Helmet>
            <div className='websitPages'>
                <div className="container pt-5 mt-5">
                    {step === "Industry" && <Industry setStep={setStep} history={history} next={'Business'} />}
                    {step === "Business" && <Business setStep={setStep} next='Briefly' back='Industry' />}
                    {step === "Briefly" && <Briefly  setStep={setStep} next={'Pages'} back={'Business'} />}
                    {step === "Pages" && <Pages setStep={setStep} next={'CopyRight'} back={'Briefly'} />}
                    {step === "CopyRight" && <CopyRight setStep={setStep} next={'TypeWebsite'} back={'Pages'} />}
                    {step === "TypeWebsite" && <TypeWebsite  setStep={setStep} next={'Domain'} back={'CopyRight'} />}
                    {step === "Domain" && <Domain submitHandler={submitHandler} setStep={setStep} back={'TypeWebsite'} />}
                    <p className="font-14 text-muted d-none d-md-block">Fill information about your business and goals for this website, this data can vary and time can be from 30 mins to 4 hours </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

CreateWebsite.propTypes = {
    history: PropTypes.object,

};
export default CreateWebsite
