import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/admin/user/profile";

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
    getProfileData() {
        return http.get(this.apiEndpoint);
    }
    profileUpdate(data) {
        return http.post(this.apiEndpoint, data);
    }
}
