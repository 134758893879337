import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "reactstrap";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"
import { validatorBusinessName } from "FormValidations/Website/steps"
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { appendFilters } from "store/Auth/actions"
// import arrowImage from "assets/images/arrow.png"
import businessImage from "assets/images/business.png"
import { useTranslation } from 'react-i18next';

const Business = ({ setStep, next, back }) => {
  const { register, handleSubmit, setValue, formState: { errors }, } = useForm({ resolver: yupResolver(validatorBusinessName) });
  const data = useSelector(state => state.Auth)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  const handleValidSubmit = (data) => {
    dispatch(appendFilters({ business_name: data.name }))
    setStep(next)
  }
  const [completed, setCompleted] = useState(15);

  useEffect(() => {
    setValue("name", data.business_name);
    setInterval(() => setCompleted(30), 1000);
  }, []);

  return (
    <div className="card mb-4 mt-3">
      <div className="card-header border-0 p-0">
        <Progress value={completed} />
      </div>
      <div className="card-body text-center pt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={businessImage} className="img-fluid" alt="business" />
            <h3 className="pb-2 pt-3 fw-bold">{t("Enter your business Name")}</h3>
            <p className="text-muted pb-3">
              {t("Information of websites you visit. Use tools for lead generation,market")}<br /> {t("analysis and competitor research.")}
            </p>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
              <input
                {...register("name")}
                placeholder={t("Type Business Name")}
                className={`form-control btnHeightLg ${
                  errors ?.name && "border-danger"
                }`}
              />
              {errors ?.name && <ErrorMessage message={errors ?.name ?.message} />}
              
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button
                  className="btn btnHeightLg px-3 py-2"
                  onClick={() =>setStep(back)}
                  type={"button"}
                >
                   {t("Back")} {i18n.language !== "ar" ? <i className="fa fa-sign-out-alt px-2 rotateDegree float-start pt-1 pe-3"></i>
                    : <i className="fa fa-sign-out-alt px-2 float-end pt-1 ps-3"></i>}
                </button>
                <button
                  className="btn btn-primary btnHeightLg px-3 py-2"
                  type={"submit"}
                >
                  {t("Continue")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i>
                    : <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Business.propTypes = {
  setStep: PropTypes.func,
  next: PropTypes.string, 
  back: PropTypes.string

};
export default Business;
