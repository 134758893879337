import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/admin/settings/subscription-plans";
const apiToggleStatusEndpoint = API_BASE_URL_ENV() + "/admin/settings/toggle-activation/subscription-plan";

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    toggleStatus(id) {
        return http.post(`${apiToggleStatusEndpoint}/${id}`);
    }
}