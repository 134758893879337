import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const ChatItem = ({ message,uid }) => {
    const [messageData, setMessageData] = useState(null)
    useEffect(() => {
        setMessageData(message)
    }, [message])

    function secondsToDate(seconds) {
        const milliseconds = seconds * 1000;
        const date = new Date(milliseconds);
      
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
      
        return date.toLocaleString("en-US", options);
    }

    return (
        <div className={`w-100 d-flex message flex-column ${message.uid === uid ? "right" : ""}`}>
            <div className="w-100">
                <div className="me-2 ms-2">
                    {message?.type === 'image' ? <div className="mt-2  pt-1 overflow-hidden" style={{ width:"275px" ,float: message.uid === uid ? "right" : "left" }}>
                            <img 
                                src={messageData?.text} 
                                alt={message?.type} 
                                className='w-100'
                            />
                    </div> : <div className="mt-2 p-2 pt-1 overflow-hidden" style={{ width:"200px" ,color: message.uid === uid ? "white" : "#63697b", float: message.uid === uid ? "right" : "left", background: message.uid === uid ? "linear-gradient(103deg, #3f60fe 1%, #3e1648 145%)" : "#eaecf2", borderRadius: "5px" }}>
                        <p className="my-1 font-14">{messageData ?.text}</p>
                    </div> }
                </div>
            </div>
            <div className="font-size-12 w-100  mb-2 d-block pe-2 ps-2" style={{ textAlign: message.uid === uid ? "right" : "left" }} >
                {secondsToDate(messageData?.createdAt?.seconds)}
            </div>
        </div>
    )
}

ChatItem.propTypes = {
    message: PropTypes.any,
    uid: PropTypes.any
};
export default ChatItem;
