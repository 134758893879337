// import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
// import { BriefValidator } from "FormValidations/Client/Dashboard/Brief";
import { useEffect, useMemo, useState } from "react";
// import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import FileUpload from "components/Common/file-upload/file-upload.component";
import { IMAGE } from "Enums/FileTypeEnum";
import Select from "react-select";
import '../style.scss'
import StepsService from "services/Client/StepsService";
import { SuccessCreated } from "configs/statusCode";
import { toast } from "react-toastify";
import countryList from 'react-select-country-list'
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const plans = [
    {name: 'One Page', min: 1, max: 1},
    {name: '2-5 pages', min: 2, max: 5},
    {name: '6-10 pages', min: 6, max: 10},
    {name: '11-20 pages', min: 11, max: 20},
    {name: 'More than 20 pages', min: 20, max: 40},
]
const typesOptions = [
    {label: 'Portfolio', value: 'Portfolio'},
    {label: 'Landing Page', value: 'Landing Page'},
    {label: 'Website', value: 'Website'},
    {label: 'Personal & Blog', value: 'Personal & Blog'},
    {label: 'Events & Conference', value: 'Events & Conference'},
    {label: 'Other', value: 'other'},
]
const languagesOptions = [
    {label: 'Arabic', value: 'arabic'},
    {label: 'English', value: 'english'},
]
const FirstStepForm = ({...location}) => {
    const { t } = useTranslation();
    const [projectData, setProjectData] = useState({})
    const [pagesNumber, setPagesNumber] = useState({
        min: '',
        max: ''
    })
    const [formData, setFormData] = useState({
        business_name: '',
        type: '',
        business_location: '',
        business_description: '',
        other_type: '',
        media_id: "", 
        media_id_path: "",
        existing_website: "",
        documents: [],
        contact_email: '',
        social: [
            { name: "facebook", link: "" },
            { name: "instagram", link: "" },
            { name: "linkedIn", link: "" },
        ],
        pages_num: '',
        pages: [
            {title: '', description: ''},
            {title: '', description: ''},
        ],
        name: '',
        second_language: ''
    });
    // const [planOptions, setPlanOptions] = useState([])
    const LocationOptions = useMemo(() => countryList().getData(), [])
    const stepsService = new StepsService();
    let [step, setStep] = useState(1)
    const [uploadState, setUploadState] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    let [errors, setErrors] = useState()
    let errorText = 'This Field is Required'
//    const {
//         //handleSubmit, //formState: { errors }
//     } = useForm({resolver: yupResolver(BriefValidator)});

    useEffect(()=>{
        setStep(1)
        setProjectData(location.location?.state?.data)
        
        if(location?.location?.state?.edit === 'edit'){
            let firstStep = location?.location?.state?.firstStepData
            let data = {
                ...firstStep,
                type: typesOptions?.filter(res=> res?.label === firstStep?.type),
                business_location: LocationOptions?.filter(res=> res?.label === firstStep?.business_location),
                media_id: firstStep?.media?.id, 
                media_id_path: firstStep?.media?.path,
                social: firstStep?.social_media_links?.length > 0 ? firstStep?.social_media_links?.map((item) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                  
                    return { name: key, link: value };
                }) : formData.social,
                language: {
                    label: firstStep?.language,
                    value: firstStep?.language,
                },
                // second_language: {
                //     label: firstStep?.second_language,
                //     value: firstStep?.second_language,
                // },
                pages_num: {
                    label : location?.location?.state?.data?.subscription_plan?.pages_num?.name?.en,
                    value : location?.location?.state?.data?.subscription_plan?.pages_num?.name?.en
                }
                // pages_num: plans?.filter(res=> res.min <= +firstStep.pages_num && res.max >= +firstStep.pages_num)?.map(res=>{
                //     return {
                //         ...res,
                //         label: res.name
                //     }
                // })
            }
            setFormData(data)

            let plan = location?.location?.state?.data?.subscription_plan?.pages_num?.name?.en
            let filter =  plans.filter(res=> res.name === plan)

            if(filter?.length > 0 && location?.location?.state?.edit === 'edit' ){
                setPagesNumber({
                    min: filter[0].min,
                    max: filter[0].max
                })
            }
        } else{
            let plan = location?.location?.state?.data?.subscription_plan?.pages_num?.name?.en
            let filter =  plans.filter(res=> res.name === plan)

            if(filter?.length > 0 && !location?.location?.state?.edit ){
                setPagesNumber({
                    min: filter[0].min,
                    max: filter[0].max
                })
                if(filter[0]?.name === 'Single Page' || filter[0]?.name === 'One Page'){
                    setFormData({...formData, pages: [{title: '', description: ''}], pages_num: {label: plan , value: plan}})
                    return
                } else{
                    const newArray = [];
                    for (let i = 0; i < filter[0].min; i++) {
                        newArray.push({title: '', description: ''});
                    }
                    setFormData({...formData, pages: [...newArray], pages_num: {label: plan , value: plan}})
                }
            } else{
                setFormData({...formData, pages_num: {label: plan , value: plan}})
            }
        }
    },[])

    const updatedAttachment = (attachments, type) => {
        if (type === "documents") {
          setFormData((data) => ({
            ...data, documents: [...formData.documents, ...attachments]
          }));
          setErrors({...errors, documents: false})
        } else {
          const mediaObj = {
            [`${type}`]: attachments[0]?.id, [`${type}_path`]: attachments[0]?.path
          };
          setFormData({ ...formData, ...mediaObj });
          setErrors({...errors, media_id: false})
        }
    };
    
    const deleteAttachment = (Items, type) => {
        if (type === "documents") {
          let updatedDocs = formData.documents.filter(item => item.id !== Items[0].id);
          setFormData((data) => ({ ...data, documents: updatedDocs }));
        } else {
          const mediaObj = {
            [type]: null, [`${type}_path`]: null
          };
          setFormData({ ...formData, ...mediaObj });
        }
    };

    function validateUrls(input) {
        // Split the input string into an array of URLs
        const urls = input.split(',').map(url => url.trim());
      
        // Validate each URL
        const isValid = urls.every(url => {
          try {
            new URL(url);
            return true; // URL is valid
          } catch (error) {
            return false; // URL is not valid
          }
        });
      
        return isValid;
    }
    
    const nextStep = () =>{
        // const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
        if(!formData.business_name || !formData.type || !formData.business_description || 
            (formData.type?.label === 'Other' && !formData.other_type) || (!!formData.existing_website && !validateUrls(formData.existing_website)) || (!!formData.contact_email && !emailRegex.test(formData.contact_email))){
                let err = {
                    business_name: !formData.business_name,
                    type: !formData.type,
                    // business_location: !formData.business_location,
                    business_description: !formData.business_description,
                    // documents: !formData.documents?.length,
                    // media_id: !formData.media_id,
                    contact_email: (!!formData.contact_email && !emailRegex.test(formData.contact_email)),
                }
                if(!!formData.existing_website && !validateUrls(formData.existing_website)) err['existing_website']=true
                // if(formData.existing_website && !urlRegex.test(formData.existing_website)) err['existing_website']=true
                if(formData.type?.label === 'Other' && !formData.other_type) err['other_type'] = true
                setErrors(err)
                return
        }
        setStep(2)
        setErrors({})
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    const handleValidSubmit = (e) => {
        e.preventDefault();
        if( !formData.language ||
            !formData.pages_num || 
            formData.pages?.filter(res=> !res.title || !res.description)?.length >0
        ){
            let err ={
                language: !formData.language ,
                // second_language: projectData?.lang_count === 2 ? !formData.second_language : false,
                pages_num: !formData.pages_num,
                pages: formData.pages?.filter(res=> !res.title || !res.description)?.length > 0 ? formData.pages?.map(res=> {
                    return{
                        title: !res.title,
                        description: !res.description
                    }
                }) : []
            }
            
            setErrors(err)
            return
        }
        
        setIsSubmited(true);
        const data = {
          order_id: Number(location?.location?.state?.order_id),
          business_name: formData.business_name,
          business_location: formData.business_location?.label,
          type: formData.type?.value === 'other' ? formData.other_type : formData.type?.label,   
          contact_email: formData.contact_email,
          business_description: formData.business_description,
          media_id: formData.media_id,
          existing_website: formData.existing_website,
          name: formData.name,
          language: formData.language.value,
          pages_num: formData.pages.length,
          pages: formData.pages,
          social_media_links: formData.social?.filter(res=> !!res.link)?.map((item) => {
            return {
              [item.name]: item.link
            };
          })
        };
        if(formData?.documents?.length > 0) data['documents'] = formData.documents?.map((item) => item.id)
        if(projectData?.lang_count === 2 || projectData?.lang_count === '2') {
            const lang_two = languagesOptions?.filter(lan=> lan.value !== formData.language?.value) 
            data['second_language'] = lang_two[0].value
        }

        stepsService.update("first-step", data).then((res) => {
          if (res.status === SuccessCreated) {
            toast.success("Project Brief Successfully Submitted");
            location.history.push(`/client/project/${location?.location?.state?.order_id}`)
            // updateProjectData({ first_step_status: 1 });
            // toggle();
            // callFirstStep();
            // setCompleted(prev => prev+25);
          } else {
            toast.error("Please Enter the Required Fields");
          }
          setIsSubmited(false)
        })
    };

    const handleInput = (e)=> {
        setFormData({...formData, [e.target.name]: e.target.value})
        setErrors({...errors, [e.target.name]: false})
    }

    return <div className="firstStep">
        <div className="container" style={{marginTop: '100px'}}>
            <div>
                <h4 className="title">Share Your business Info</h4>
                <p className="subTitle">Help us build your website by sharing some details</p>
            </div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between header">
                        <div>
                            {step===1 ? <h5>Onboard Us to Your Business</h5> : <h5>Website information</h5>}
                            {step===1 ? <p className="text-muted">If we were a potential customer or client, how would you introduce and explain your services to us?</p> : <p className="text-muted">Share your business details and help us build your website</p>}
                        </div>
                        <span className="formNum">
                            {step}/2
                        </span>
                    </div>
                    <form
                        className="g-3 needs-validation"
                        // onSubmit={handleSubmit(handleValidSubmit)}
                    >
                        {step ===1 && <>
                        <span style={{color: '#3F5FFE'}}>Business Information </span>
                        <Row className='mt-2'>
                            <Col lg={4}>
                                <Label className={`${errors?.business_name ? "text-danger" : ''}`}>Business Name*</Label>
                                <input
                                    onChange={e=> handleInput(e)}
                                    name='business_name'
                                    placeholder="Business Name"
                                    value={formData.business_name}
                                    className={`form-control height-47 ${errors?.business_name ? "border-danger" : ''}`}
                                />
                                {errors?.business_name && (<ErrorMessage message={errorText} />)}
                            </Col>
                            <Col lg={4}>
                                <Label className={`${errors?.type ? "text-danger" : ''}`}>What kind of website do you want to create?*</Label>
                                <Select
                                    placeholder="Choose from list"
                                    options={[...typesOptions.filter(res=> res?.label !== formData?.type?.label)]}
                                    className={`heightSelect borderSelect ${errors?.type ? "border-danger" : ''}`}
                                    onChange={(data) => {
                                        setFormData({...formData, type: data})
                                        setErrors({...errors, type: false})
                                    }}
                                    value={formData.type}
                                />
                                {errors?.type && (<ErrorMessage message={errorText} />)}
                            </Col>
                            {formData.type?.label === 'Other' && <Col lg={4}>
                                <Label className={`${errors?.other_type ? "text-danger" : ''}`}>Type*</Label>
                                <input
                                    onChange={e=> handleInput(e)}
                                    name='other_type'
                                    placeholder="Type Name"
                                    value={formData.other_type}
                                    className={`form-control btnHeightLg ${errors?.other_type ? "border-danger" : ''}`}
                                />
                                {errors?.other_type && (<ErrorMessage message={errorText} />)}
                            </Col>}
                            <Col lg={4}>
                                <Label className={`${errors?.business_location ? "text-danger" : ''}`}>Business location</Label>
                                <Select
                                    placeholder="Choose from list"
                                    name={`business_location`}
                                    options={[...LocationOptions.filter(res=> res?.label !== formData?.business_location?.label)]}
                                    className="heightSelect borderSelect"
                                    onChange={(data) => {
                                        setFormData({...formData, business_location: data})
                                        setErrors({...errors, business_location: false})
                                    }}
                                    value={formData.business_location}
                                />
                                {errors?.business_location && (<ErrorMessage message={errorText} />)}
                            </Col>
                            <Col md={12} className='mt-3 border-div'>
                                <Label className={`${errors?.business_description ? "text-danger" : ''}`}>Tell Us About Your Business* </Label>
                                <textarea
                                    rows="4"
                                    onChange={e=> handleInput(e)}
                                    name='business_description'
                                    value={formData.business_description}
                                    placeholder="Briefly share what you do, who you serve, and the core idea behind your product or service.                                    "
                                    className={`form-control ${errors?.business_description && "border-danger"}`}
                                ></textarea>
                                {errors?.business_description && (<ErrorMessage message={errorText} />)}
                            </Col>
                        </Row>
                        <span style={{color: '#3F5FFE'}}>Upload Supporting Documents</span>
                        
                        <Row className="mt-3">
                            <Col lg={8}>
                                <div className="pb-3">
                                    <div className="pb-2 pt-3">
                                    <Label className={`${errors?.documents ? "text-danger" : ''}`}>Add reference files</Label>
                                    <p className="mb-2 pb-2" style={{fontSize:'14px'}}>You can attach any PDFs or PowerPoints that provide more details about your business e.g. any existing marketing materials or branding guidelines to help us understand your brand better.</p>
                                    <FileUpload
                                        accept=".pdf,.doc,.docx,.pptx,.ppt"
                                        // label=""
                                        attachmentType="documents"
                                        multiple
                                        attachmentData={[...formData.documents]}
                                        updatedAttachment={updatedAttachment}
                                        deleteAttachment={deleteAttachment}
                                        setUploadState={setUploadState}
                                    />
                                    {/* {errors?.documents && (<ErrorMessage message={errorText} />)} */}
                                    </div>
                                </div>
                                <div className="pb-3">
                                    <p style={{color: '#3F5FFE'}}>Share Competitor Websites</p>
                                    <div className="pb-2">
                                    <Label>Do you have an existing website? If yes, please share the URL.</Label>
                                    <p style={{fontSize:'14px'}}>
                                        List some websites you admire (they help us get a feel for your industry). 
                                    </p>
                                    <p className="textRed fw-bold"><i className="fa fa-info-circle"></i>  Note: This is for understanding, not replication </p>
                                    <input
                                        name='existing_website'
                                        type='text'
                                        value={formData.existing_website}
                                        className={`form-control btnHeightLg ${errors?.existing_website && "border-danger"}`}
                                        placeholder={`Enter full URLs separated by commas, e.g. https://www.example1.com, https://www.example2.com`}
                                        onChange={e => handleInput(e)}
                                    />
                                    {errors?.existing_website && (<ErrorMessage message={'Invalid Url Value'} />)}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sectionUpload width-100 m-auto">
                                    <Label className={`${errors?.media_id ? "text-danger" : ''}`}>Upload your logo</Label>
                                    <Label className="minHeightLabel d-flex justify-content-center align-items-center text-dark">Adding your logo can help you stand out. If you have no logo yet, you can still send it to your account manager later.</Label>
                                    <FileUpload
                                        accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                                        attachmentType="media_id"
                                        attachmentData={[{id: formData.media_id, path: formData.media_id_path}]}
                                        updatedAttachment={updatedAttachment}
                                        deleteAttachment={deleteAttachment}
                                        setUploadState={setUploadState}
                                        hasError={isSubmited && !formData.media_id}
                                        file_type={IMAGE}
                                        // className={`form-control height-47 ${errors?.media_id ? "border-danger" : ""}`}
                                    />
                                    {/* {errors?.media_id && (<ErrorMessage message={errorText} />)} */}
                                </div>
                            </Col>
                        </Row>
                        {/* <span style={{color: '#3F5FFE'}}>Additional Information</span>
                        <Row>
                            <Col md={4}>
                                <Label>{t("Your Name")}</Label>
                                <input
                                    onChange={e=> handleInput(e)}
                                    value={formData.name}
                                    name='name'
                                    type="text"
                                    className={`form-control btnHeightLg`}
                                    placeholder="Your Name"
                                />
                            </Col>
                            <Col md={4}>
                                <Label className={`${errors?.contact_email ? "text-danger" : ''}`}>{t("Business Email Address")}</Label>
                                <input
                                    onChange={e=> handleInput(e)}
                                    type="email"
                                    name='contact_email'
                                    value={formData.contact_email}
                                    className={`form-control btnHeightLg ${errors?.contact_email ? "border-danger" : ''}`}
                                    placeholder="Your Email"
                                />
                                {errors?.contact_email && (<ErrorMessage message={'Please match the requested format'} />)}
                            </Col>
                        </Row> */}

                        <Row className="mt-4">
                            <div className="modal-footer pb-0 mt-3 ps-lg-0">
                                <div className="row w-100 justify-content-between pt-3">
                                <div className="col-lg-8 d-none d-lg-grid">
                                    <div className="text-muted mb-1 font-14 d-flex">
                                        <span><i className="fa fa-info-circle fa-lg"></i></span>
                                        <span className="px-2">
                                            {t("Once you hit the 'Submit' button, you won't be able to make any changes. Take your time to review your responses and make sure everything is accurate.")}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-2">
                                    <button
                                    className="btn btn-primary text-start height-42 w-100 d-flex justify-content-between align-items-center"
                                    type="button"
                                    onClick={nextStep}
                                    disabled={uploadState ? true : false}
                                    >
                                    {" "}
                                    <span>Continue</span>
                                    
                                    <i className="fa fa-long-arrow-right"></i>
                                    </button>
                                </div>
                                </div>
                                {/* <button type='button' className='btn btn-light' onClick={toggleAdd}>Cancel</button> */}
                            </div>
                        </Row>
                        </>}
                        {step === 2 && <>
                        <Row>
                            {parseInt(projectData?.lang_count) ===1 &&<Col md={8} className='mb-3'>
                                <Label className={`${errors?.language ? "text-danger" : ''}`}>What`s the language of the site?*</Label>
                                <Select
                                    placeholder="Choose from list"
                                    name={`language`}
                                    options={languagesOptions}
                                    className="borderSelect btnHeightLg"
                                    onChange={(data) => {
                                        setFormData({...formData,language: data}) 
                                        setErrors({...errors, language: false})
                                    }}
                                    value={formData.language}
                                />
                                {errors?.language && (<ErrorMessage message={errorText}/>)}
                            </Col>}
                            {parseInt(projectData?.lang_count) ===2 && <>
                            <Col md={8} className='mb-3'>
                                <Label className={`${errors?.language ? "text-danger" : ''}`}>Choose the default language for the site*</Label>
                                <Select
                                    placeholder="Choose from list"
                                    name={`language`}
                                    options={languagesOptions}
                                    className="borderSelect"
                                    onChange={(data) => {
                                        setFormData({...formData,language: data}) 
                                        setErrors({...errors, language: false})
                                    }}
                                    value={formData.language}
                                />
                                {errors?.language && (<ErrorMessage message={errorText}/>)}
                            </Col>
                            {/* <Col md={8} className='mb-3'>
                                <Label className={`${errors?.second_language ? "text-danger" : ''}`}>Choose the second language of the site*</Label>
                                <Select
                                    placeholder="Choose from list"
                                    name={`second_language`}
                                    options={languagesOptions}
                                    className="borderSelect"
                                    onChange={(data) => {
                                        setFormData({...formData,second_language: data}) 
                                        setErrors({...errors, second_language: false})
                                    }}
                                    value={formData.second_language}
                                />
                                {errors?.second_language && (<ErrorMessage message={errorText}/>)}
                            </Col> */}
                            </>}
                            <Col md={8} className='mb-3'>
                                <Label className={`${errors?.pages_num ? "text-danger" : ''}`}>How many pages will your website need?</Label>
                                <Select
                                    placeholder="Choose from list"
                                    name={`pages_num`}
                                    options={[]} //{planOptions}
                                    className="heightSelect borderSelect"
                                    isDisabled
                                    // onChange={(data) => {
                                    //     setFormData({...formData, pages_num: data}) 
                                    //     setErrors({...errors, pages_num: false})
                                    // }}
                                    value={formData.pages_num}
                                />
                                {errors?.pages_num && (<ErrorMessage message={errorText}/>)}
                                <p className="text-muted pt-3">
                                    Let’s start organizing your content. If you need some guidance on structuring your material, check out our <Link target="blank" to={'/site-map'}>Site Map Guide</Link> 
                                </p>
                            </Col>
                            {formData.pages?.map((page, index)=>{
                                return <Col md={8} key={index} className='mb-3' style={{
                                    background: '#EFF2F6',
                                    padding: '1rem',
                                    position: 'relative',
                                    borderRadius: '5px'
                                }}>
                                <div>
                                    <Label className="text-black">Page {index+1}*</Label>
                                    <div className='mb-2'>
                                        <input
                                            type='text'
                                            onChange={e=>{
                                                let update = formData.pages?.map((item, ind)=>{
                                                    if(index === ind){
                                                        return{
                                                            ...item,
                                                            title: e.target.value
                                                        }
                                                    } else{
                                                        return item
                                                    }
                                                })
                                                let updateErrors = errors.pages?.map((item, ind)=>{
                                                    if(index === ind){
                                                        return{
                                                            ...item,
                                                            title: false
                                                        }
                                                    } else{
                                                        return item
                                                    }
                                                })
                                                setErrors({...errors, pages: updateErrors})
                                                setFormData({...formData, pages: update})
                                            }}
                                            value={page.title}
                                            placeholder="Page Title"
                                            // required
                                            className={`form-control height-47 ${(errors?.pages?.length && errors?.pages[index]?.title) ? "border-danger" : ''}`}
                                        /> 
                                    </div> 
                                    {(errors?.pages?.length >0 && errors?.pages[index]?.title) && <ErrorMessage message={errorText}/> }
                                    <div>
                                        <textarea
                                            onChange={e=>{
                                                let update = formData.pages?.map((item, ind)=>{
                                                    if(index === ind){
                                                        return{
                                                            ...item,
                                                            description: e.target.value
                                                        }
                                                    } else{
                                                        return item
                                                    }
                                                })
                                                let updateErrors = errors.pages?.map((item, ind)=>{
                                                    if(index === ind){
                                                        return{
                                                            ...item,
                                                            description: false
                                                        }
                                                    } else{
                                                        return item
                                                    }
                                                })
                                                setErrors({...errors, pages: updateErrors})
                                                setFormData({...formData, pages: update})
                                            }}
                                            // required
                                            value={page.description}
                                            placeholder="Page Description"
                                            className={`form-control height-100 ${(errors?.pages?.length && errors?.pages[index]?.description) && "border-danger"}`}
                                        />
                                        {(errors?.pages?.length >0 && errors?.pages[index]?.description) && <ErrorMessage message={errorText}/> }
                                    </div>                        
                                </div>
                                {index+1 > pagesNumber?.min && <button 
                                        className="btn btn-danger w-auto position-absolute"
                                        style={{right: '16px'}}
                                        type='button'
                                        onClick={()=>{
                                            let filter = formData.pages?.filter((_, ind)=> ind !== index)
                                            setFormData({...formData, pages: [...filter]})
                                        }}
                                    >
                                        <i className="fa fa-times"></i>
                                    </button>}
                                </Col>
                            })}
                            {formData.pages?.length < pagesNumber?.max && <div>
                                <button 
                                    type='button' 
                                    className="btn btn-secondary"
                                    onClick={()=> setFormData({...formData, pages: [...formData.pages, {title: '', description: ''}]})}
                                >
                                    + Add More Pages
                                </button>
                            </div>}

                            <div className="mt-4">
                                <label>Where can we find you on social media?</label>
                                <Row className="mt-2">
                                    {formData.social?.map((social, index)=>{
                                        return <Col md={4} key={index}>
                                            <label className={`text-capitalize ${(errors?.social?.length && errors?.social[index].link) ? "text-danger" : ''}`}>{social.name}</label>
                                            <input
                                                name={social.name}
                                                type='text'
                                                className={`form-control btnHeightLg ${(errors?.social?.length && errors?.social[index].link) ? "border-danger" : ''}`}
                                                placeholder={`Add ${social.name} link`}
                                                onChange={e =>{
                                                    let update = formData.social?.map((item, ind)=>{
                                                        if(ind === index){
                                                            return{
                                                                ...item,
                                                                link: e.target.value
                                                            }
                                                        } else{
                                                            return item
                                                        }
                                                    })
                                                    setFormData({...formData, social: update})
                                                }}
                                                value={social?.link}
                                            />
                                        </Col>
                                    })}
                                </Row>
                            </div>
                        </Row>
                        <Row className="mt-4">
                            <div className="modal-footer pb-0 mt-3 ps-lg-0">
                                <div className="row w-100 justify-content-between pt-3">
                                <div className="col-lg-6 d-none d-lg-grid">
                                    <p className="text-muted mb-1 font-14">
                                    <i className="fa fa-info-circle fa-lg"></i> Once you hit the `Submit` button, you won`t be able to make any changes. Take your time to review your responses and make sure everything is accurat
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="d-flex gap-2">
                                        <button
                                        className="btn btn-success text-start height-42 w-100 d-flex justify-content-between align-items-center"
                                        type="button"
                                        onClick={()=>{
                                            setStep(1);
                                            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                        }}
                                        >
                                            <i className="fa fa-long-arrow-left"></i>
                                            <span>Back</span>
                                        </button>
                                        <button
                                        className="btn btn-primary d-flex text-start height-42 w-100 align-items-center justify-content-between"
                                        type="submit"
                                        onClick={handleValidSubmit}
                                        >
                                        {" "}
                                        <span>Submit</span>
                                        
                                        <i className="fa fa-long-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                                </div>
                                {/* <button type='button' className='btn btn-light' onClick={toggleAdd}>Cancel</button> */}
                            </div>
                        </Row>
                        </>}
                    </form>
                </CardBody>
            </Card>
        </div>
    </div>
}
export default FirstStepForm;