import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Modal,
    ModalHeader, 
    ModalFooter,
    ModalBody,
} from "reactstrap"
import PropTypes from 'prop-types';

const ModalChangeStatus = ({ modal, toggle,loading, changeStatus}) => {
    return (
        <Modal isOpen={modal} className="modal-dialog-scrollable modal-md container" toggle={() => toggle()}>
            <ModalHeader className='modalHead me-2' toggle={() => toggle()}>
                <div>
                    <h6 className='fw-bold'>Update Status</h6>
                </div>
            </ModalHeader>
            <ModalBody>
                <p className='text-muted'>Are you sure to update status?</p>
            </ModalBody>
            <ModalFooter className='border-0 py-4'>
                <div className='d-flex justify-content-between w-100'>
                    <button className='btn btn-secondary' onClick={() => toggle()}>
                        Cancel
                    </button>
                    <button className='btn btn-primary' disabled={loading} onClick={changeStatus}>
                        Submit
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
ModalChangeStatus.propTypes = {
    modal: PropTypes.bool,
    toggle: PropTypes.func,
    changeStatus: PropTypes.func,
    loading: PropTypes.any,
};

export default ModalChangeStatus;