import React from 'react'
import Faq from "./Faq"
import imgCircles from "assets/images/twoCircles.png";

const AskedQuestions = () => {
  return (
    <div className='pb-md-5 pb-4 px-2' data-aos='fade-up' data-aos-duration='1000'>
      <div className='container-fluid pt-5 askQues'>
        <div className='card mt-md-5 border-0'>
          <div className='card-body border-0 shadow-0 position-relative px-lg-5'>
            <img src={imgCircles} className='position-absolute top-0 start-0 imgPills' />
            <div className='text-center pt-5'>
              <h3 className='fw-bold textBlueDark'>Frequently Asked Questions</h3>
              <p className='text-muted smallText'>Have a question? We have got you covered. </p>
            </div>
            <div className='pt-md-4'>
              <Faq/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AskedQuestions