import React, { useState } from "react";
import PlanService from "services/Admin/PlanService"
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import DeleteModal from 'components/Common/DeleteModal';
import PropTypes from 'prop-types';
import ModalChangeStatus from "components/Common/ModalChangeStatus";
import { SuccessOk } from "configs/statusCode";
import { toast } from "react-toastify";
import CanNotDeleteModal from "../ModalCanNotDelete";
// import framedot from 'assets/images/framedot.png';

const CardItem = ({ plan, index, setModalAdd, setEditItem, setGetData ,setViewItem }) => {
    const planService = new PlanService();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [cantDeleteModal, setcantDeleteModal] = useState(false);
    const [status, setStatus] = useState(plan?.activated);
    const [deletedItem, setDeletedItem] = useState(null);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseModal = (action) => {
        setDeleteModal(false);
        if (action === "delete") {
            setGetData(true)
        }
    };
    const style ={
        padding: '4px',
        marginRight: '8px',
        borderRadius: '4px'
    }

    const changeStatus = ()=>{
        setLoading(true)
        planService.toggleStatus(plan.id).then(res=>{
          if (res?.status == SuccessOk) {
            setStatus(!status);
            toast.success(`Plan Status Updated Successfully`);
            setModalStatus(false)
          }
          setLoading(false)
        })
      }

    return (
        <>
            <tr key={index} className="py-2 border-bottom">
                <td className="ps-4">{plan.id}</td>
                <td>{plan.name.en}</td>
                {/* <td>{plan.expected_delivery_duration} day</td> */}
                <td>
                    {plan.price?.filter(price=> price.currency.toLowerCase() === 'usd')?.map((price,index) => (
                        <span style={{...style, background: '#90EE90'}} key={index}>{price.amount} <small>{price.currency}</small> </span>
                    ))}
                    {plan.price?.filter(price=> price.currency.toLowerCase() === 'sar')?.map((price,index) => (
                        <span style={{...style, background: 'rgb(223 138 247)'}} key={index}>{price.amount} <small>{price.currency}</small> </span>
                    ))}
                    {plan.price?.filter(price=> price.currency.toLowerCase() === 'aed')?.map((price,index) => (
                        <span style={{...style, background: '#FF7F7F'}} key={index}>{price.amount} <small>{price.currency}</small> </span>
                    ))}
                </td>
                <td>
                    <span 
                        onClick={()=> setModalStatus(true)}
                        className={`${status ? "textSuccess" : 'text-danger'} fw-bold cursor-pointer`}>
                        {status ? 'Active' : 'Deactive'}
                    </span>
                </td>
                {/* <td><span className="textRed fw-bold">Danger</span></td> */}
                <td className="inlinTableDropdown">
                    <Dropdown color="white" className="dropdownWidth" isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color="white" className="py-0">
                            {/* <img src={framedot} alt="" /> */}
                            <button className="btn btn-outline-primary px-5">More</button>
                        </DropdownToggle>
                        <DropdownMenu>
                        <DropdownItem className="text-primary" onClick={() => {
                                setViewItem(plan);
                                setModalAdd((s) => !s)
                            }}>View Plan</DropdownItem>
                            <DropdownItem className="text-primary" onClick={() => {
                                setEditItem({...plan, type: 'edit'});
                                setModalAdd((s) => !s)
                            }}>Edit Plan</DropdownItem>
                            <DropdownItem className="text-primary" onClick={() => {
                                setEditItem({...plan, type: 'add'});
                                setModalAdd((s) => !s)
                            }}>Add a new as a copy</DropdownItem>
                            <DropdownItem className="text-danger" onClick={() => {
                                setDeleteModal(!deleteModal);
                                setDeletedItem(plan);
                            }}>Delete Plan</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </td>
            </tr>
            <DeleteModal
                open={deleteModal}
                titleMsg={plan.name.en}
                deletedItem={deletedItem}
                modelService={planService}
                onCloseModal={(action) => handleCloseModal(action)}
                setcantDeleteModal={setcantDeleteModal}
            />
            {cantDeleteModal && <CanNotDeleteModal
                open={cantDeleteModal}
                onCloseModal={() => setcantDeleteModal(false)}
            />}
            {modalStatus && 
                <ModalChangeStatus 
                    modal={modalStatus} 
                    toggle={()=> setModalStatus(false)}
                    loading={loading}
                    changeStatus={changeStatus}
                />
            }
        </>
    );
};
CardItem.propTypes = {
    plan: PropTypes.object,
    index: PropTypes.number,
    setModalAdd: PropTypes.func,
    setEditItem: PropTypes.func,
    setViewItem: PropTypes.func,
    setGetData: PropTypes.func
};

export default CardItem;
