import React, {useState} from "react";
import BlogService from "services/Admin/BlogService";
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Container, Row,Col, CardText
} from 'reactstrap';
import DeleteModal from 'components/Common/DeleteModal';
import PropTypes from 'prop-types';
import imageAvt from "assets/images/avatar.png";
import "./style.scss";
import { imageOnError } from "helpers/common";

const CardItem = ({blog, index, setModalAdd, setEditItem, setGetData}) => {
    const blogService = new BlogService();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [deletedItem, setDeletedItem] = useState(null);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [deleteModal, setDeleteModal] = useState(false);

    const handleCloseModal = (action) => {
        setDeleteModal(false);
        if (action === "delete") {
            setGetData(true)
        }
    };
    return (
        <div className="styleCard">
            <div className="card template-item">
                <Container fluid>
                    <Row key={index} className="template-border justify-content-around">
                        <Col md={8} lg={8} >
                            <div className="d-md-flex py-3">
                                <div className="p-3 bg-template me-md-3 rounded-3">
                                    <img src={blog?.media?.path ? blog?.media?.path : imageAvt } className="imageTemplate" onError={imageOnError} alt="" />
                                </div>
                                <div>
                                    <CardText tag="h6" className="text-capitalize pt-md-2 pt-3 fw-bold">{blog.title.en}</CardText>
                                    <CardText tag="p" className="text-primary">
                                    <span className="pe-2 border-bottom border-primary text-primary me-2" >Agriculture</span>
                                    <span className="pe-2 border-bottom border-primary text-primary me-2" >Agriculture</span>
                                    <span className="pe-2 border-bottom border-primary text-primary" >Agriculture</span>
                                    {/* {template?.categories.map((category)=>{
                                        return <span className="pe-2 border-bottom border-primary text-primary" key={category.id}>{category.name}</span>
                                    }) } */}
                                    </CardText>
                                    {/* <CardText tag="p" className="text-muted">{blog.description.en}</CardText> */}
                                </div>
                            </div>
                        </Col>
                        <Col md={2} lg={2} className="pt-md-4 pt-2 px-2 mb-3">
                            {/* <button onClick={(e) => { e.stopPropagation(); window.open(template.url) }} 
                              className="btn btn-primary d-block m-auto fw-bold font-14 btnWidth height-42 pt-2"
                              >View Blog</button> */}
                            <div className="removebtnhover position-relative">
                                <Dropdown className="dropdownWidth w-100" isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle className="border-0">
                                        <button className="btn btnSearch fw-bold font-14 height-42">More Options</button>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className="text-primary" onClick={() => {
                                            setEditItem(blog);
                                            setModalAdd((s) => !s)
                                        }}><i className="fa fa-link"></i> Edit/Update Blog details</DropdownItem>
                                        <DropdownItem className="text-danger" onClick={() => {
                                            setDeleteModal(!deleteModal);
                                            setDeletedItem(blog);
                                        }}><i className="fas fa-trash-can"></i> Delete Blog from System</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>   
            <DeleteModal
                open={deleteModal}
                titleMsg={deletedItem?.title.en}
                deletedItem={deletedItem}
                modelService={blogService}
                onCloseModal={(action) => handleCloseModal(action)}
            />
        </div>
    );
};
CardItem.propTypes = {
    blog: PropTypes.object,
    index: PropTypes.number,
    setModalAdd: PropTypes.func,
    setEditItem: PropTypes.func,
    setGetData: PropTypes.func
};

export default CardItem;
