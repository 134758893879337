import React, { useState } from "react";

import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  Nav,
  NavbarBrand,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader
} from "reactstrap";
import logo from "../../../assets/images/logo.svg";
import "./style.css";
import { NavLink } from "react-router-dom";
import { adminPrefix } from "configs/routePrefix";
import userImage from "assets/images/user.png";
// import notificationImage from "assets/images/notification.png";
import { GET_AUTH_USER } from "../../../helpers/common";

const Header = () => {
  // Collapse isOpen State
  //   const [isOpen, setIsOpen] = useState(false);
  const [show, setIsShow] = useState(false);
  const userRole = GET_AUTH_USER();
  const toggle = () => setIsShow((prevState) => !prevState);
  return (
    <div>
      <Navbar light className="navbarDashboard px-2 py-3" fixed="top" expand="lg">
        <NavbarBrand href="/">
          <img src={logo} alt="" className="imgLogo"/>
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setIsShow(!show);
          }}
        />
        <Collapse className="ps-3" navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink to={`${adminPrefix}/dashboard`} className="nav-link">
                Dashboard
              </NavLink>
            </NavItem>
                <NavItem>
                  <NavLink to={`${adminPrefix}/templates`} className="nav-link">
                    Templates
                  </NavLink>
                </NavItem>
            {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
                <NavItem>
                  <NavLink to={`${adminPrefix}/plans`} className="nav-link">
                    Plans & Payments
                  </NavLink>
                </NavItem>
              : null}
              {(userRole.role === "Admin" || userRole.role === "Account Manager")?
            <NavItem>
              <NavLink to={`${adminPrefix}/clients`} className="nav-link">
                Registered Users
              </NavLink>
            </NavItem> : null }
            <NavItem>
              <NavLink to={`${adminPrefix}/projects`} className="nav-link">
                Projects
              </NavLink>
            </NavItem>
            {(userRole.role === "Admin") ?
              <NavItem>
                <NavLink to={`${adminPrefix}/managers`} className="nav-link">
                  Team Members
                </NavLink>
              </NavItem> : null}
          </Nav>
          <Nav className="ms-auto" navabr>
            <NavItem className="pe-3">
              <NavLink to="/admin/chat" className="nav-link">
                <i className="far fa-comment-alt fa-lg pt-2"></i>
              </NavLink>
            </NavItem>
            {/* <NavItem className="pe-3">
              <NavLink to={``} className="nav-link position-relative ps-0">
                <span className="badge bg-primary rounded-circle absoluteNumber">
                  5
                </span>
                <img src={notificationImage} className="pe-1 pt-1"/>
              </NavLink>
            </NavItem> */}
          </Nav>
          <UncontrolledDropdown navbar>
            <DropdownToggle className="text-dark ps-0" nav caret>
              <img src={userImage} alt="" className="pe-2"/> {userRole.role}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <NavLink
                  className="text-dark text-decoration-none d-block py-0"
                  to="/admin/profile"
                >
                  Profile
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  className="text-dark text-decoration-none d-block py-0"
                  to="/admin/logout"
                >
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Collapse>
      </Navbar>
      <Offcanvas isOpen={show}>
        <OffcanvasHeader toggle={toggle}>
          <img src={logo} alt="" className="imgLogo"/>
        </OffcanvasHeader>
        <OffcanvasBody className="navbarDashboard">
          <Nav navbar>
            <NavItem>
              <NavLink to={`${adminPrefix}/dashboard`} className="nav-link">
                Dashboard
              </NavLink>
            </NavItem>
                <NavItem>
                  <NavLink to={`${adminPrefix}/templates`} className="nav-link">
                    Templates
                  </NavLink>
                </NavItem>
            {(userRole.role === "Admin" || userRole.role === "Account Manager")  ?
                <NavItem>
                  <NavLink to={`${adminPrefix}/plans`} className="nav-link">
                    Plans & Payments
                  </NavLink>
                </NavItem>
              : null}
              {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
            <NavItem>
              <NavLink to={`${adminPrefix}/clients`} className="nav-link">
                Registered Users
              </NavLink>
            </NavItem> : null }
            <NavItem>
              <NavLink to={`${adminPrefix}/projects`} className="nav-link">
                Projects
              </NavLink>
            </NavItem>
            {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
              <NavItem>
                <NavLink to={`${adminPrefix}/managers`} className="nav-link">
                  Team Members
                </NavLink>
              </NavItem> : null}
            {/* <NavItem className="pe-3">
              <NavLink to={``} className="nav-link position-relative ps-0">
                <span className="badge bg-primary rounded-circle absoluteNumber">
                  5
                </span>
                <img src={notificationImage} className="pe-1"/>
              </NavLink>
            </NavItem> */}
            <UncontrolledDropdown navbar>
              <DropdownToggle className="text-dark ps-0" nav caret>
                <img src={userImage} alt="" className="pe-2"/> {userRole.role}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink
                    className="text-dark text-decoration-none d-block py-0"
                    to="/admin/profile"
                  >
                    Profile
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="text-dark text-decoration-none d-block py-0"
                    to="/admin/logout"
                  >
                    Logout
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default Header;
