import { ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./style.css";
import PropTypes from "prop-types";

const InjectedCheckoutForm = ({data ,history,clientSecret}) => {
  return (<ElementsConsumer>
      {({ stripe, elements }) => (<CheckoutForm clientSecret={clientSecret} data={data} stripe={stripe} elements={elements} history={history}/>)}
    </ElementsConsumer>);
};
InjectedCheckoutForm.propTypes = {
  data: PropTypes.any,
  history:PropTypes.any,
  clientSecret:PropTypes.any,
};
export default InjectedCheckoutForm;
