import React, { useState } from 'react'
import Navbar from "components/Website/Layouts/Header"
import { useForm } from "react-hook-form";
import "./style.scss"
import GeneralFooter from 'components/Website/Layouts/Footer/GeneralFooter'
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactValidation } from 'FormValidations/Website/Contact'
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage'
import ContactService from 'services/Website/ContactService'
import { SuccessOk } from "configs/statusCode";
import imgCall from "assets/images/call_us.png"
import imgContact from "assets/images/chat.png"
import imgClock from "assets/images/alarm.png"
// import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import Success from './Success';

const Contact = () => {
  const [modal, setModal] = useState(false)
  const {
    handleSubmit,
      register,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(ContactValidation),
    });
    const handleValidSubmit = async (values) => {
      let requestData = {
        ...values,
      };
      const userData = await new ContactService().contactUs(requestData);
      if (userData.status == SuccessOk) {
        setModal(true)
        //toast.success("Message Sent Successfully");
      }
    };
    const { t } = useTranslation();
    return (
      <div>
        <Navbar />
        <div className="websitPages pt-5 mt-3">
          <div className="container-fluid px-md-5 py-5">
          <h4 className="pt-3 fw-bold textDarkPrimary">{t("We'd love to hear from you!")}</h4>
          <p className="font-size-14 text-muted pb-2">
            {t("Whether you have a question or a suggestion, we are always here to help.")}
          </p>
            <div className="card shadow my-3">
              <div className="card-body rounded-3 p-md-3">
                {/* <div className='text-center'>
                                <h4 className="pb-3 fw-bold">Contact Us</h4>
                              </div> */}
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-lg-0">
                    <img src={imgCall} className='img-fluid mt-4 mx-auto d-block' />
                  </div>
                  <div className="col-lg-6">
                    <div className="pt-4">
                      <form
                        className="needs-validation"
                        onSubmit={handleSubmit(handleValidSubmit)}
                      >
                        <div className="pb-2">
                          <label className='textPrim fw-bold'>{t("Name")}</label>
                          <input
                            {...register("name")}
                            className={`form-control height-47 font-12`}
                            placeholder='Enter Your Name Please'
                            type="text"
                          />
                          {errors ?.name && <ErrorMessage message={errors ?.name ?.message} />}
                        </div>
                        <div className="pb-2">
                          <label className='textPrim fw-bold'>{t("Email")}</label>
                          <input
                            {...register("email")}
                            className={`form-control height-47 font-12`}
                            placeholder='Enter Your Email'
                            type="email"
                          />
                          {errors ?.email && <ErrorMessage message={errors ?.email ?.message} />}
                        </div>
                        {/* <div className="pb-2">
                          <label>{t("Subject")}</label>
                          <input
                            {...register("subject")}
                            className={`form-control height-47`}
                            type="text"
                          />
                          {errors ?.subject && <ErrorMessage message={errors ?.subject ?.message} />}
                        </div> */}
                        <div className="pb-2">
                          <label className='textPrim fw-bold'>{t("Message")}</label>
                          <textarea
                            {...register("message")}
                            placeholder='Enter Your message here'
                            rows="5"
                            cols=""
                            className="form-control font-12"
                          />
                          {errors ?.message && <ErrorMessage message={errors ?.message ?.message} />}
                        </div>
                        <p className="text-center pt-3">
                          <button className="btn btn-primary w-100 height-50 rounded-3 px-5">
                            {t("Send Message")}
                          </button>
                        </p>
                      </form>
                      <div className='d-flex gap-2 pt-3'>
                        <div>
                          <img src={imgClock} className='img-fluid' />
                        </div>
                        <div className='text-center'>
                          <h6 className='font-14 textVeryDark fw-bold'>Our team is available Monday - Friday: 11:00 AM - 7:00 PM GST.</h6>
                          <p className='text-muted font-14 fw-bold'>If you contact us outside of these hours, we will get back to you as soon as possible.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 pt-lg-5 mt-lg-5 d-none d-lg-block'>
                    <div className='pt-lg-5'>
                      <img src={imgContact} className='img-fluid mt-lg-5 mx-auto d-block' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GeneralFooter/>
        </div>
        {modal && <Success modalAdd={modal} toggleAdd={()=> setModal(false)} />}
      </div>
    );
}
 
export default Contact;