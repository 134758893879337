import React from "react"
import Header from "./Header"
// import Footer from "./Footer"
import PropTypes from 'prop-types';

const Layout = (props) => {
    return (
        <>

            <div id="layout-wrapper">
                <Header />
                <div  >
                    {props.children}
                </div>
                {/* <Footer /> */}
            </div>
        </>
    )
}


Layout.propTypes = {
    children: PropTypes.any,
};
export default  Layout
