import React, { useState , useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  Label,
  // FormGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { ProfileValidator } from "FormValidations/Admin/Profile/Profile";
import { yupResolver } from "@hookform/resolvers/yup";
import ProfileService from "services/Client/ProfileService";
// import { SuccessCreated } from "configs/statusCode";
import { toast } from 'react-toastify';
import { authenticate } from "store/Auth/actions";
import { useDispatch } from "react-redux";
import Password from "./Password";

const Profile = () => {
  const profileService = new ProfileService();
  const [profileData, setProfileData] = useState({});
  const [changePassword, setChangePassword] = useState(false);
  const getProfileDetails = async () => {
    const res = await profileService.getProfileData();
    setProfileData(res.data);
  };
  const dispatch = useDispatch()
  useEffect(() => {
    getProfileDetails();
  }, []);

  // const [isPasswordShown, setIsPasswordShown] = useState(false);
//   const [formData,setFormData] = useState([]);
  // const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);

  // const togglePassword = () => {
  //   setIsPasswordShown(!isPasswordShown);
  // };

  // const toggleConfirmedPassword = () => {
  //   setIsConfirmPasswordShown(!isConfirmPasswordShown);
  // };
  const { handleSubmit, register, formState: {errors} } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(ProfileValidator),
  });

  const handleValidSubmit = () => {
    const data = {
      name: profileData.name,
      email: profileData.email,
      // password: profileData.password,
      // password_confirmation: profileData.password_confirmation
    };

    profileService.profileUpdate(data).then((res) => {
      if (res.status === 200) {
        toast.success("Profile Updated Successfully");
        dispatch(authenticate({
          user_name: profileData.name,
          email: profileData.email
        }));
        // getProfileDetails();
      }
      else {
        toast.error("Please enter the required fields");
      }
    }).catch(error=>{
        toast.error(error?.response?.data?.error)
    })
  };
  const handleFieldChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };
  return (
    <div className="websitPages pt-5 mt-3">
      <Container fluid className="px-md-5 pt-4">
        <Row className="justify-content-between">
          <Col lg={8} md={7}>
            <div className="d-flex">
              <div>
                <h2 className="gText">Profile</h2>
                <p className="text-muted">Set your Name, Email, Password and Confirm Password</p>
              </div>
            </div>
          </Col>
        </Row>
        <Card className="pt-2 px-3">
          <CardText tag="h6">Profile Info</CardText>
          <CardBody>
            <form
              className="g-3 needs-validation"
              onSubmit={handleSubmit(handleValidSubmit)}
            >
              <Row>
                <Col lg={6}>
                  <div className="pb-2">
                    <Label>Name</Label>
                    <input
                    // name="name"
                      {...register("name")}
                      placeholder="Name"
                      value={profileData?.name}
                      className={`form-control height-47 ${
                        errors?.name && "border-danger"
                      }`}
                      onChange={(e) => handleFieldChange(e)}
                      type="text"
                    />
                  </div>
                  {errors?.name && (
                    <ErrorMessage message={errors?.name?.message} />
                  )}
                </Col>
                <Col lg={6}>
                  <div className="pb-2">
                    <Label>Email address</Label>
                    <input
                      {...register("email")}
                      value={profileData?.email}
                      className={`form-control height-47 ${
                        errors?.email && "border-danger"
                      }`}
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => handleFieldChange(e)}
                    />
                  </div>
                  {errors?.email && (
                    <ErrorMessage message={errors?.email?.message} />
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col md="6" className="pt-1">
                  <div className="position-relative">
                    <FormGroup>
                      <label>Password</label>
                      <input
                        {...register("password")}
                        className={`form-control height-47 ${
                          errors?.password && "border-danger"
                        }`}
                        // name="password"
                        placeholder="********"
                        label="Password"
                        //   readOnly
                        onFocus={() =>
                          document
                            .getElementById("password")
                            .removeAttribute("readonly")
                        }
                        type={isPasswordShown ? "text" : "password"}
                        onChange={(e) => {
                          handleFieldChange(e, "password");
                        }}
                      />
                      {errors?.password && (
                        <ErrorMessage message={errors?.password?.message} />
                      )}

                      {isPasswordShown ? (
                        <i
                          onClick={togglePassword}
                          className="fa fa-eye-slash fa-lg input-addon pt-3"
                        ></i>
                      ) : (
                        <i
                          onClick={togglePassword}
                          className="fa fa-eye fa-lg input-addon pt-3"
                        ></i>
                      )}
                    </FormGroup>
                  </div>
                </Col>
                <Col md="6" className="pt-1">
                  <div className="position-relative">
                    <FormGroup>
                      <label>Confirm Password</label>
                      <input
                        {...register("password_confirmation")}
                        className={`form-control height-47 ${
                          errors?.password_confirmation && "border-danger"
                        }`}
                        // name="password_confirmation"
                        placeholder="********"
                        //   readOnly
                        onFocus={() =>
                          document
                            .getElementById("password_confirmation")
                            .removeAttribute("readonly")
                        }
                        type={isConfirmPasswordShown ? "text" : "password"}
                        onChange={(e) => {
                          handleFieldChange(e, "password_confirmation");
                        }}
                      />
                      {errors?.password_confirmation && (
                        <ErrorMessage
                          message={errors?.password_confirmation?.message}
                        />
                      )}
                      {isConfirmPasswordShown ? (
                        <i
                          onClick={toggleConfirmedPassword}
                          className="fa fa-eye-slash fa-lg input-addon pt-3"
                        ></i>
                      ) : (
                        <i
                          onClick={toggleConfirmedPassword}
                          className="fa fa-eye fa-lg input-addon pt-3"
                        ></i>
                      )}
                    </FormGroup>
                  </div>
                </Col>
              </Row> */}
              <button className="btn btn-primary px-4 height-42">Save</button>
            </form>
            <p className='mt-3 cursor-pointer' onClick={()=> setChangePassword(prev=> !prev)}>
              Change my account password {changePassword ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
            </p>

            {changePassword && <Password
              profileData={profileData} 
              setChangePassword={setChangePassword}
              handleFieldChange={e => handleFieldChange(e)} 
              getProfileDetails={getProfileDetails} 
            />}

          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Profile;
