import * as yup from "yup";

export const validatorIndustry = yup.object().shape({
    industry: yup.object().required().typeError('Industry is required')
});

export const validatorSize= yup.object().shape({
    size: yup.object().required().typeError('Size is required')
});
export const validatorBusinessName = yup.object().shape({
    name: yup
    .string()
    .required(),
 
});
export const validatorBusinessDescription = yup.object().shape({
    description: yup.string().required(),

 
});