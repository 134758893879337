import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, ModalHeader, ModalBody, Container, Row, Col, InputGroup, InputGroupText } from "reactstrap";
import PropTypes from "prop-types";
import "./style.scss";
import OrderService from "../../../../services/Admin/OrderService";
import { toast } from 'react-toastify';

const ModalAssign = ({ modalAssign, toggleAssign, editItem, setGetData }) => {
  const orderService = new OrderService();
  const [keyword, setKeyword] = useState("");
  const [teamMembers, setTeamMembers] = useState("");
  const [formState, setFormState] = useState({
    keyword: ""
  });

  const assignProject = (id) => {
    orderService.assign(editItem.id, { "assignee_id": id });
    toast("Project Assigned successfully");
    setGetData(prev=> !prev)
    toggleAssign();
  };
  // if (assignProject.success === success){
  // } 
  const getTeamManagers = async () => {
    const res = await orderService.getDropDown("assignee", {status: 1});
    let data = []
    console.log(editItem, res.data.data)
    if(editItem?.assignee?.id){
      data = res.data.data?.filter(item=> item.id !== editItem?.assignee?.id)
    } else{
      data = res.data.data
    }
    setTeamMembers(data);
  };

  useEffect(() => {
    getTeamManagers().catch(e => console.log(e));
  }, []);

  return (<Modal
    isOpen={modalAssign}
    className="modal-dialog-scrollable modal-lg container"
    toggle={() => toggleAssign()}
  >
    <ModalHeader className="modalHead me-2" toggle={() => toggleAssign()}>
      <div>
        <h6 className="fw-bold">Add Zero Gravity to an account manager</h6>
        <p className="font-14 text-muted mb-0">
          Fill information about your business and goals for this website
        </p>
      </div>
    </ModalHeader>
    <ModalBody className="border-0 py-4">
      <Container>
        <Row>
          <Col lg={9} md={8} sm={8} xs={12}>
            <InputGroup className="inputGroup mb-2 px-2 px-md-0">
              <InputGroupText
                addonType="prepend"
                className="bg-white iconSearch"
              >
                <i className="fa fa-search fa-lg"></i>
              </InputGroupText>
              <input
                value={formState.keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className={`form-control border-start-0 btnHeightLg mb-2`}
                placeholder="Search Account Managers by Name"
                type="text"
              />
            </InputGroup>
          </Col>
          <Col lg={3} md={4} sm={4} xs={12}>
            <div className="px-2 px-md-0">
              <button
                type="button"
                onClick={() => {
                  setFormState((state) => ({ ...state, keyword }));
                }}
                className="btn btn-outline-primary btnHeightLg px-3 w-100"
              >
                <i className="fa fa-search me-2"></i>
                Search
              </button>
            </div>
          </Col>
        </Row>
        <div className="table-responsive mt-3">
          <table className="table table-borderless bg-white">
            <thead className="border-0 rounded-3"
                   style={{ background: "#edf0f3", height: "62px", verticalAlign: "middle" }}>
            <tr>
              <th className="rounded-start opacity-75 ps-4">Name</th>
              <th className="opacity-75">Projects</th>
              <th className="rounded-end opacity-75"></th>
            </tr>
            </thead>
            <tbody>
            {teamMembers.length && teamMembers.map(item => (<tr className="py-2 border-bottom" key={item.id}>
              <td className="ps-4">{item.name}</td>
              <td>{item.projects} Project</td>
              <td>
                <button className="btn btn-primary" onClick={() => assignProject(item.id)}>Assign a project</button>
              </td>
            </tr>))}
            </tbody>
          </table>
        </div>
      </Container>
    </ModalBody>
  </Modal>);
};
ModalAssign.propTypes = {
  modalAssign: PropTypes.bool,
  toggleAssign: PropTypes.func,
  setGetData: PropTypes.func,
  editItem: PropTypes.string
};

export default ModalAssign;
