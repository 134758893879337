import React from 'react'
import imgSuccess from "assets/images/tick.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import PropTypes from 'prop-types';

const Success = ({modalAdd, toggleAdd}) => {
    const history= useHistory()
    return (
        <Modal isOpen={modalAdd} className="modal-dialog-scrollable modal-md container" toggle={() => toggleAdd()}>
            <ModalBody className='border-0'>
                <div className='card cardShadow text-center py-3'>
                    <img src={imgSuccess} className="img-fluid d-block mx-auto" />
                    <h4 className='fw-bold pt-4'>Your Message has been Sent successfully</h4>
                    <p>
                        <button 
                            className='btn btn-primary mt-4 px-4 btnHeightLg'
                            onClick={()=> history.push(`/home`)}
                        >
                            Go to Home Page <i className='fa fa-long-arrow-right mx-2'></i>
                        </button>
                    </p>
                </div>
            </ModalBody>
        </Modal>
    )
}
Success.propTypes = {
    modalAdd: PropTypes.bool,
    toggleAdd: PropTypes.func,
};

export default Success