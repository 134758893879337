import React from "react";
import "./styles.scss";
import Navbar from "components/Website/Layouts/Header";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import GeneralFooter from "components/Website/Layouts/Footer/GeneralFooter";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="websitPages mt-5 pt-5">
        <div className="pt-4">
          <div className="container-fluid px-md-5">
            <h3 className="fw-bold headersColor pb-3">
              {t("Terms and Conditions of Webkicks")}
            </h3>
            <p className="font-18 muteSubtitle fw-medium">
              {t(
                "Welcome to Webkicks! These are the terms and conditions ('T&Cs') that govern the use of Webkicks and all its related sites and services by any user."
              )}
            </p>
            <p className="font-18 muteSubtitle fw-medium">
              {t(
                "By using the Webkicks website (www.webkicks.co), you agree to these T&Cs. If you do not agree, please do not access or use the site. The T&Cs apply to all users in relation to services provided through the website or otherwise interacting with Webkicks. The User agrees and accepts that the use of the Website and the Services provided by Webkicks is at the sole liability of the User, and further acknowledges that Webkicks disclaims all representations and warranties of any kind, whether expressed or implied."
              )}
            </p>
            <p className="font-18 muteSubtitle fw-medium">
              {t(
                "These T&Cs come into effect once you accept a service through the Website or any other form of communication with Webkicks. Additionally, it is important to review and agree with our Privacy Policy as it is an integral part of these terms and conditions."
              )}
            </p>
            <p className="font-18 muteSubtitle fw-medium">
              {t(
                "If you have any questions or concerns regarding these terms and conditions, please contact us at support@webkicks.co."
              )}
            </p>

            <div className="disclaimer mt-5">
              <h4 className="fw-bold headersColor pb-2">Disclaimer</h4>
              <p className="muteSubtitle font-18">{t('The services are provided "as is" and "as available." Webkicks disclaims all representations and warranties, express, implied, or statutory, not expressly set out in these terms, including the implied warranties of supplier ability, fitness for a particular purpose, and non-infringement. In addition, Webkicks makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the services or any services requested through the use of the services, or that the services will be uninterrupted or error-free. Webkicks does not guarantee the quality, suitability, or ability of the suppliers. You agree that the entire risk arising from the use of services remains solely with you, to the maximum extent permitted under applicable law.')}</p>
              <p className="muteSubtitle font-18">{t('The information contained in the website is for general information purposes only. Webkicks endeavors to keep the information up to date and correct, but makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.')}</p>
              <p className="muteSubtitle font-18 mb-0" >{t('All accounts registered with Webkicks are continuously tracked for security purposes and performance reasons. It is expressly made clear to you hereby that Webkicks does not own any account of any kind.')}</p>
            </div>
            <h4 className="fw-bold headersColor pt-5 pb-3">{t("Definitions")}</h4>
            
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Website/Site:</span> The website or online presence created for the User by Webkicks on its platform.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">User:</span> Refers to individuals or entities who access or use the Webkicks website and its services and authorized to access the Website.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Services:</span> The designs via templates, copywriting of the website, developing of wordpress websites from templates, hosting and its related maintenance by Webkicks along with any related services availed from it.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Webkicks/We/Us/Our:</span> Webkicks and its associates involved in providing the Services.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">You/Your:</span> The person or entity availing the Services by creating an account on the Webkicks platform.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Account:</span> The account created by the User on the Webkicks platform to avail the Services.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Dedicated Account Manager:</span> The designated point of contact allocated by Webkicks to the User for communication and assistance.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Template:</span> The pre-designed layouts/themes available on the Webkicks platform for building Websites.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Content:</span> Any text, graphics, photos, multimedia or other materials uploaded or provided by the User for use on the Website.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Platform: </span>The Webkicks website, application and infrastructure used for providing the Services.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Hosting:</span> The storage of the website files on a server to make it accessible online.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Go Live:</span> When the website is published publicly on the Internet after completion of design/development.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Launch:</span> Same as Go Live, refers to making the website accessible publicly online.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Browser:</span> Application software used to access and view websites like Chrome, Firefox, Safari etc.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Device:</span> Equipment used to access websites like desktop computers, laptops, mobile phones, tablets etc.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Responsiveness:</span> Ability of a website to adapt layout and interface based on the type of device/browser used to access it.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Analytics:</span> Tools/services that help track visitor behavior and usage patterns on a website.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Updates:</span> Changes/modifications made to the website after initial launch.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Maintenance:</span> Ongoing support provided for the live website like security patches, upgrades etc.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Subscription:</span> Payment made once for building websites or periodically to avail ongoing services like hosting.
            </p>
            <p className="font-18 muteSubtitle">
              <span className="define fw-medium">Applicable Laws: </span>means the laws of the Arab Republic of United Arab Emirates
            </p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Applicability")}</h4>
            <p className="font-18 muteSubtitle">{t('These Terms and Conditions (“T&Cs”) are a legal agreement between Webkicks and its users who avail any of the services provided by Webkicks.')}</p>
            <p className="font-18 muteSubtitle">The T&Cs shall apply to any individual or entity that:</p>
            <ul>
              <li className="muteSubtitle font-18">Creates an account on the Webkicks platform</li>
              <li className="muteSubtitle font-18">Uses the website designing, development or other services offered by Webkicks</li>
              <li className="muteSubtitle font-18">Interacts with Webkicks via any means of communication</li>
              <li className="muteSubtitle font-18">Makes payments to Webkicks for availing its services</li>
              <li className="muteSubtitle font-18">Accesses any website designed/hosted by Webkicks</li>
            </ul>
            <p className="font-18 muteSubtitle">By using the Webkicks website or availing any of the Services offered, the user confirms they have read, understood and agrees to be bound by the T&Cs. If the user does not agree with these T&Cs, they shall refrain from using the website or Services.</p>
            <p className="font-18 muteSubtitle">The T&Cs will also apply to any entity (company, partnership firm, sole proprietor etc.) that avails the Services through an authorized individual. The concerned individual warrants they have authority to agree to the T&Cs on behalf of such entities.</p>
            <p className="font-18 muteSubtitle">{("Webkicks may revise the T&Cs from time to time at its discretion. The user's continued use of the Services will signify acceptance of the revised T&Cs. Users are advised to regularly check the website for any amendments. In case of any conflict or inconsistency between the English version and translated versions of these T&Cs, the English version shall prevail.")}</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Accounts and Registration")}</h4>
            <p className="font-18 muteSubtitle">Account registration requires you to submit to webkicks certain personal information, including but not limited to your company name, address, commercial register, and mobile phone number, you agree to maintain true and accurate, complete, and up to date information in your Account. You are responsible for all activity that occurs under your Account, and as such, you agree to maintain the security of your Account username and password at all times, unless otherwise permitted by webkicks in writing.</p>
            <p className="font-18 muteSubtitle">User accounts are not transferable. You agree to reimburse webkicks for any improper, unauthorized or illegal use of your account by you or by any person obtaining access to the Website, services or otherwise by using your designated username and password, whether or not you authorized such access.</p>
            <p className="font-18 muteSubtitle">If webkicks suspects, at its sole discretion, that any of the information you provided is untrue, inaccurate, incomplete, or not current, without prejudice to any other rights and remedies of webkicks under these Terms and Conditions or under the Applicable Laws, We have the right to suspend, or limit your access to the Website and its Services.</p>
            <p className="font-18 muteSubtitle">webkicks may (in its sole discretion and at any time), make any inquiries it considers necessary (whether directly or through a third party), and request that you provide a response with further information or documentation, including without limitation to verify your identity and/or ownership of your financial instruments. Without limiting the foregoing, if you are a business entity or registered on behalf of a business entity such information or documentation may include your trade license, other incorporation documents and/or documentation showing any persons authority to act on your behalf. You agree to provide any information and/or documentation to webkicks upon such requests. You acknowledge and agree that if you do not, webkicks without liability may limit, suspend or withdraw your access to the Website. We also reserve the right to cancel unconfirmed / unverified accounts or accounts that have been inactive for a long time.</p>
            <p className="font-18 muteSubtitle">By completing your registration, you acknowledge having read, understood and agreed to be bound by these Terms and Conditions, along with the Privacy Policy, as they may be amended from time to time, which are hereby incorporated and made an integral part hereof.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Eligibility")}</h4>
            <p className="font-18 muteSubtitle">The Services are available for users/businesses operating globally. The users must be at least 18 years of age, or the age of legal majority in your jurisdiction, whichever is greater to create an Account and avail the Services. Webkicks reserves the right to refuse Services to anyone it deems ineligible.</p>
            <p className="font-18 muteSubtitle">If you are registering as a business entity, you represent that you have the authority to bind that entity to Terms and Conditions and that you and the business entity will comply with all applicable laws and terms governing the use of the Website.</p>
            <p className="font-18 muteSubtitle">{t("Users must provide accurate information about their business and itself. False details may lead to termination of Services. The content and material provided by the user for use on their website must be original and clear from any copyright or intellectual property issues. Webkicks won't be responsible for any legal issues arising from user-provided content.")}</p>
            <p className="font-18 muteSubtitle">{t("Users agree that their website won't be used for any unlawful, harassing, defamatory, abusive, threatening, harmful, offensive or illegal purpose. Website building is subject to availability of templates and features compatible with the user's requirements. Webkicks may refuse Services in case requirements are beyond its capabilities. Please review our refunds policy.")}</p>
            <p className="font-18 muteSubtitle">Services may also be declined if completion of the requested work is not considered feasible by Webkicks within a reasonable time frame. Webkicks reserves the right to modify the eligibility conditions from time to time at its sole discretion. Users must re-check and agree to any changed terms.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Description of Services")}</h4>
            <p className="font-18 muteSubtitle">Webkicks offers the following website design and development services through its online platform:</p>
            <p className="font-18 muteSubtitle">Template Selection: Ability to choose from 100+ professionally designed website templates.</p>
            <p className="font-18 muteSubtitle">{t("Customization: Customization of the selected template colors, images, content as per the user's requirements.")}</p>
            <p className="font-18 muteSubtitle">Content Creation: Option to have Webkicks in-house team draft custom content like about us, services pages etc.</p>
            <p className="font-18 muteSubtitle">Website Development: Coding and programming of the website by expert developers as per the selected template and customization using Wordpress.</p>
            <p className="font-18 muteSubtitle">Domain Name Registration: Option to register or transfer desired domain name during website creation.</p>
            <p className="font-18 muteSubtitle">Website Hosting: Hosting of the completed website on Webkicks secure servers for access over the internet free of charge for one year.</p>
            <p className="font-18 muteSubtitle">Content Management: Ability to independently manage content after Go-Live using standard CMS like WordPress.</p>
            <p className="font-18 muteSubtitle">Maintenance & Support: Post-launch technical support and issue resolution for 1 year as per our SLA.</p>
            <p className="font-18 muteSubtitle">Updates & Backups: Periodic software updates and daily backups of website files for data protection.</p>
            <p className="font-18 muteSubtitle">SEO Optimization: Basic on-page optimization of the website for organic search visibility.</p>
            <p className="font-18 muteSubtitle">SSL Certificate: Complimentary SSL certificate installation for a secure website.</p>
            <p className="font-18 muteSubtitle">Responsive Design: Completely responsive website accessible seamlessly on any device.</p>
            <p className="font-18 muteSubtitle">The above core services are included in the one-time website building fee. Any additional services will be chargeable as per our published rates.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Electronic Communications")}</h4>
            <p className="font-18 muteSubtitle">By creating an Account, you agree that you are communicating with us electronically. Therefore, you consent to receive periodic communications from us. webkicks will communicate with you via e-mail or may send you information via text messages (SMS), as well as by posting notices on the Website as part of the normal business operation of your use of the Services. You acknowledge that opting out of any of the said means of communication may affect your use of the Services. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. webkicks will request your agreement during the registration process to send you promotional emails or notifications related to the Website and its services. If, at any time, you decide that you do not wish to receive promotional emails, you can opt out of receiving such promotional emails by clicking on the link at the bottom of any promotional email.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Copyrights and Trademarks")}</h4>
            <p className="font-18 muteSubtitle">All content included on the Website, including but not limited to text, graphics, webkicks logos, button icons, images, audio clips, digital downloads, data compilations and software, is the property and copyright work of webkicks or its licensors and is protected by copyright, trademarks, patents or other intellectual property rights and laws. The compilation of the content on the Website is the exclusive property and copyright of webkicks and is protected by copyright, trademarks, patents or other intellectual property rights and laws. webkicks and related logos are the trademarks of webkicks. webkicks trademarks may not be used in connection with any product or service that is not webkicks nor in any manner that disparages or discredits webkicks.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Use of the Website")}</h4>
            <p className="font-18 muteSubtitle">Subject to the Users compliance with these Terms and Conditions, webkicks grants the User a limited, nonexclusive, non-sub licensable, revocable, non-transferable license to: - Access and use the Services solely in connection with the provision of the Services; - Access and use any content, information and related materials that may be made available through the Services; </p>
            <p className="font-18 muteSubtitle">Any rights not expressly granted herein are reserved by webkicks and may be revoked at any time without notice to the customer. The User shall not use any trademark or any intellectual property rights belonging to webkicks from any portion of the Services, and shall not reproduce, modify, prepare, publicly, perform, transmit, stream, broadcast, or otherwise exploit the Services except as expressly permitted by webkicks in writing, and shall not decompile, reverse engineer or disassemble the Services, and shall not link to mirror or frame any portion of the Services or launch any programs or scripts for the purpose of scraping, indexing, surveying or otherwise data mining any portion of the Services, or unduly burdening, or hindering the operation and/or functionality of any aspect of the Services or attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks. The User may not post in the product review section for the listed products, or anywhere else on the site (as determined at our sole discretion): solicitation, advertising, foul language, profanities, obscenities, culturally offensive material, religiously offensive material, critical political content, material that may threaten the public interest or national security, defamatory or libelous harassment, or other content that may be offensive or indecent.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Payment Terms")}</h4>
            <p className="font-18 muteSubtitle">We accept major credit and debit cards as payment, including Visa, Mastercard, American Express, Discover, JCB, UnionPay and Elo. Billing currency will be AED for UAE residents, SAR for KSA residents, or USD for others.</p>
            <p className="font-18 muteSubtitle">100% of the total project cost is required as an advance payment before project initiation. Invoices are for record purposes only, as full payment is required in advance. Any additional work over and above the original agreed scope will require separate advance payment based on our rates.</p>
            <p className="font-18 muteSubtitle">Refunds, if applicable, will be provided as per our Cancellation and Refund Policy shared with the customer upfront. Revisit our Refund Policy.</p>
            <p className="font-18 muteSubtitle">Disputes, if any, should be notified in writing before project commencement. Customers will bear legal costs in case of disputes arising out of non-payment of advance amount.</p>
            <p className="font-18 muteSubtitle">We reserve the right to modify or update our payment options and policies without prior notice.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Refund Policy")}</h4>
            <p className="font-18 muteSubtitle">Webkicks is committed to customer satisfaction. However, once work begins on a website project, certain costs are incurred which are non-refundable. Our refund policy is as follows:</p>
            <p className="font-18 muteSubtitle">For packages including content creation services:</p>
            <ul>
              <li className="font-18 muteSubtitle">A full refund is available if requested before the initial content briefing meeting between client and content strategists.</li>
              <li className="font-18 muteSubtitle">A 50% refund is available if requested after the initial content briefing meeting.</li>
            </ul>
            <p className="font-18 muteSubtitle">For packages without content creation:</p>
            <ul>
              <li className="font-18 muteSubtitle">A full refund is available if requested before the client uploads any content assets to the website builder dashboard to begin the building process.</li>
            </ul>
            <p className="font-18 muteSubtitle">For all packages:</p>
            <ul>
              <li className="font-18 muteSubtitle">{t("No refund will be provided once website building by our development team has begun (step 3 is open in the project's dashboard).")}</li>
              <li className="font-18 muteSubtitle">{t("To request a refund, email support@webkicks.co including 'Refund Request' in the subject line. Allow up to 21 business days for processing. No refunds will be provided for requests made after a website's launch/go-live date. Requests should include the project ID to speed up processing.")}</li>
              <li className="font-18 muteSubtitle">Webkicks reserves the right to modify this refund policy at any time. Please check the website for updates.</li>
            </ul>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Intellectual Property")}</h4>
            <p className="font-18 muteSubtitle">Ownership of Work Product</p>
            <p className="font-18 muteSubtitle">All designs, web pages, content, code, software, tools, products, documentation and other materials developed or provided by Webkicks as part of the services (“work product”) shall remain the sole and exclusive property of Webkicks.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Client Content")}</h4>
            <p className="font-18 muteSubtitle">Client shall retain all right, title and interest in and to all logos, text, graphics, photos, sounds, videos or other creative content or information submitted by Client to Webkicks for use in performance of the services (“client content”), including all intellectual property rights therein. Client grants Webkicks a limited, non-exclusive license to copy, reproduce, store and display the client content as incorporated into the work product.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Trademarks")}</h4>
            <p className="font-18 muteSubtitle">{t("The display of any party's trademarks in the work product or client content does not grant either party the right to use the other party's trademarks in any manner without prior written")}</p>
            <p className="font-18 muteSubtitle">{t("permission. Use of Client's trademarks will comply with Client's trademark usage guidelines as provided.")}</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Restrictions on Use")}</h4>
            <p className="font-18 muteSubtitle">Client shall not: (i) sell, sublicense or distribute the work product; (ii) copy the work product except as permitted to use the website; (iii) reverse engineer or attempt to extract the source code; (iv) modify or create derivative works of the work product or remove any proprietary notices.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Ownership of Website Domain and Server Infrastructure")}</h4>
            <p className="font-18 muteSubtitle">Client will own and control the domain name and have ownership rights over content added. However, Webkicks will retain ownership of any code, server side scripts, databases or applications used to run the website.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Transfer of Rights")}</h4>
            <p className="font-18 muteSubtitle">Upon full payment of all fees, Webkicks shall assign over the ownership rights to the website code on request. However, Webkicks shall retain the right to freely use and modify templates, scripts and other non-unique elements across other client projects.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Violations")}</h4>
            <p className="font-18 muteSubtitle">If any intellectual property violations occur via the website or content, Client shall be solely responsible for policing and enforcing its own rights and for complying with applicable laws. Webkicks assumes no responsibility or liability in this regard.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Confidentiality")}</h4>
            <p className="font-18 muteSubtitle">In the course of using the website or services, both parties may have access to or be exposed to materials and information that are confidential to the other.</p>
            <p className="font-18 muteSubtitle">{t('"Confidential Information" refers to non-public business, technological and financial information disclosed by one party to the other, which is marked or designated as confidential or which would reasonably be considered confidential given the nature of the information and circumstances of disclosure.')}</p>
            <p className="font-18 muteSubtitle">Confidential Information does not include information that:</p>
            <p className="font-18 muteSubtitle">(i) is already publicly known at the time of disclosure;</p>
            <p className="font-18 muteSubtitle">(ii) becomes publicly known through no fault of the receiving party;</p>
            <p className="font-18 muteSubtitle">{t("(iii) is already in the receiving party's possession without obligation of confidentiality;")}</p>
            <p className="font-18 muteSubtitle">{t("(iv) is independently developed by the receiving party without use of the disclosing party's Confidential Information; or")}</p>
            <p className="font-18 muteSubtitle">(v) is required to be disclosed by law.</p>
            <p className="font-18 muteSubtitle">The receiving party agrees:</p>
            <ul>
              <li className="font-18 muteSubtitle">{t("Not to disclose Confidential Information to any third party without permission via email from the disclosing party's account manager")}</li>
              <li className="font-18 muteSubtitle">To only use Confidential Information for purposes of using the website/services</li>
              <li className="font-18 muteSubtitle">To maintain confidentiality and security of Confidential Information using same standard as its own confidential information</li>
              <li className="font-18 muteSubtitle">To promptly notify the disclosing party upon discovery of any unauthorized use or disclosure.</li>
            </ul>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Limitation of Liability")}</h4>
            <p className="font-18 muteSubtitle">In no event shall Webkicks, its directors, employees, partners, agents, affiliates, or suppliers be liable for any loss, claim, damages, or costs of any kind (including legal fees) whether direct, indirect, incidental, consequential, exemplary or otherwise, arising from or related to:</p>
            <ul>
              <li className="font-18 muteSubtitle">The use or inability to use the website, services, or any content or features provided therein</li>
              <li className="font-18 muteSubtitle">Unauthorized access to or alteration of user transmissions or data, or any information that is the result of the data transfer or storage</li>
              <li className="font-18 muteSubtitle">Interruptions, deletion of files, errors, defects, delays in operation or transmission, or any failure of performance</li>
              <li className="font-18 muteSubtitle">Communication line failure, unlawful third-party interception of data, transmission of viruses or other damaging components</li>
              <li className="font-18 muteSubtitle">Any content submitted, posted, transmitted or otherwise made available through the services by any user</li>
              <li className="font-18 muteSubtitle">Conduct or statements of any third party on, through or related to the website or services</li>
              <li className="font-18 muteSubtitle">The deletion of, corruption of, or failure to store, any user content or other communication or material maintained or transmitted by the website or services</li>
            </ul>
            <p className="font-18 muteSubtitle">{t("Webkicks' total aggregate liability arising from or related to this agreement is limited to the total amount paid by the user in the 1 month period preceding the event giving rise to liability.")}</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Warranties")}</h4>
            <p className="font-18 muteSubtitle">Webkicks warrants that it will perform any services hereunder in a timely, workmanlike and professional manner in accordance with generally accepted industry standards for similar services.</p>
            <p className="font-18 muteSubtitle">Except for the foregoing express warranty, to the maximum extent permitted by applicable law, Webkicks and its licensors disclaim all other warranties and conditions, whether express or implied, including, but not limited to, implied warranties, conditions or other terms of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <p className="font-18 muteSubtitle">{t('Webkicks does not warrant that the platform or services will meet your requirements or be error free. The platform and services are provided "as is" and "as available" without warranty of any kind. Any material downloaded or otherwise obtained through the use of the services is done at your own discretion and risk and you are solely responsible for any damage to your computer system or other device or loss of data that results from the download of any such material.')}</p>
            <p className="font-18 muteSubtitle">No oral or written statement or advice provided by Webkicks, its employees, licensors, affiliates, contractors or the like shall create a warranty. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.</p>
            <p className="font-18 muteSubtitle">This warranty gives you specific legal rights and you may also have other legal rights that vary from jurisdiction to jurisdiction.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Indemnification")}</h4>
            <p className="font-18 muteSubtitle">{t("By accessing or using this website or services through your account, you as the authorized account holder agree to indemnify, defend and hold Webkicks harmless from any and all third party losses, liabilities, damages, costs or expenses (including reasonable attorneys' fees) resulting from:")}</p>
            <p className="font-18 muteSubtitle">{t("(i) Client's use of the website and/or any client content;")}</p>
            <p className="font-18 muteSubtitle">(ii) Any violation of third party rights by any client content, including but not limited to intellectual property rights violations, rights of publicity/privacy, and illegal, defamatory or obscene material;</p>
            <p className="font-18 muteSubtitle">(iii) Any breach of this Agreement by Client, including breaches of the representations and warranties herein;</p>
            <p className="font-18 muteSubtitle">(iv) Claims arising from errors, omissions or inaccuracies in client content placed on the website or provided to Webkicks;</p>
            <p className="font-18 muteSubtitle">(v) Any failure by Client to comply with applicable laws.</p>
            <p className="font-18 muteSubtitle">{t("Webkicks shall promptly notify Client of any claim for which it seeks indemnification. Client shall have sole control over the defense and settlement of such claim provided it does not adversely impact Webkicks' rights or obligations hereunder.")}</p>
            <p className="font-18 muteSubtitle">Webkicks shall have the right to participate in the defense of any such claim using counsel of its own choosing at its own expense. Client shall not accept any settlement that imposes liability or obligations on Webkicks without its prior consent through an exchanged email with the account manager responsible for your project.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Termination")}</h4>
            <p className="font-18 muteSubtitle">Webkicks may terminate your access to and use of the Services and/or your Webkicks account at any time, with or without cause, with or without notice, effective immediately. This means Webkicks has the right to:</p>
            <p className="font-18 muteSubtitle">Prevent you from accessing the Services,</p>
            <p className="font-18 muteSubtitle">Deactivate or delete your Webkicks account and all related information and data, and Bar any further access to or use of such information and data.</p>
            <p className="font-18 muteSubtitle">If your account or access is terminated or cancelled, Webkicks may, at its discretion, delete any data, files or other information associated with your account as part of the termination process. Third parties may also be notified of the termination as needed.</p>
            <p className="font-18 muteSubtitle">Upon request via email, users may also request deletion of their personal data and permanent removal of their Webkicks account within 30 days of terminating their account and discontinuing use of the Services.</p>
            <p className="font-18 muteSubtitle">{t("Webkicks will use commercially reasonable efforts to fully remove all data associated with the terminated user's account from active systems and backups within this timeframe, except as required to be retained by applicable law or to complete any incomplete projects or fulfill contractual responsibilities.")}</p>
            <p className="font-18 muteSubtitle">However, users are advised that due to technical limitations, certain account information may remain in encrypted backups for disaster recovery purposes or be anonymized for analytics and aggregate reporting. Terminated users also acknowledge that data removal cannot be guaranteed from all systems.</p>
            <p className="font-18 muteSubtitle">If you wish to terminate your Webkicks account, you may simply discontinue using the Services and your account will be deactivated. The Terms of Service sections regarding ownership, warranty disclaimers, and liability will continue to apply even after termination.</p>
            <p className="font-18 muteSubtitle">{t("Webkicks reserves the right to suspend or limit a user's access to the Services if the user materially breaches these Terms or utilizes the Services for any illegal activity. Webkicks also reserves the right to cancel unpaid or incomplete orders in the case of material breach or upon termination of the user account.")}</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Dispute Resolution")}</h4>
            <p className="font-20 muteSubtitle fw-bol">Informal Resolution</p>
            <p className="font-18 muteSubtitle">If a dispute arises between you and webkicks, our goal is to resolve it in a manner that is fair to all parties. In the first instance, you should contact us and try to resolve any dispute informally. You or webkicks may propose mediation as an alternative in accordance with the paragraph below. If a dispute is not resolved informally within 30 days, the parties agree to use mediation or arbitration in Sharjah, UAE in accordance with this Dispute Resolution Section.</p>
            <p className="font-20 muteSubtitle fw-bold">Mediation</p>
            <p className="font-18 muteSubtitle">If a dispute is not resolved informally, you and webkicks agree to first try in good faith to resolve it by mediation administered by the Sharjah Chamber of Commerce & Industry under its Mediation Rules before resorting to arbitration or litigation. Mediation fees shall be shared equally between the parties.</p>
            <p className="font-20 muteSubtitle fw-bold">Arbitration</p>
            <p className="font-18 muteSubtitle">If any unresolved controversy or claim arising out of or relating to this Agreement cannot be submitted to mediation, such dispute shall be finally settled by arbitration administered by the Sharjah Chamber of Commerce & Industry under its Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered by any court having jurisdiction. The number of arbitrators shall be one. The place of arbitration shall be Sharjah, UAE.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Changes to Terms")}</h4>
            <p className="font-18 muteSubtitle">Webkicks reserves the right to update and change these Terms and Conditions of Service from time to time without notice. Any changes will be posted on our website and the amended Terms will be effective immediately.</p>
            <p className="font-18 muteSubtitle">It is the responsibility of the Client to regularly check our website for any changes to the Terms. Continued use of the service following any changes shall constitute your consent to such changes.</p>
            <p className="font-18 muteSubtitle">{t("If the Client does not agree with the amended Terms, they may request to terminate their contract by notifying us in writing. However, the existing Terms shall continue to govern the Client's use of the service until the termination date.")}</p>
            <p className="font-18 muteSubtitle">We will also endeavor to notify any major changes through email communication. However, the Client is responsible for ensuring they have provided correct, active contact information on their account.</p>
            <p className="font-18 muteSubtitle">Webkicks may amend these Terms at any time by posting the amended Terms on the Webkicks website. Amendments will be effective upon posting to the website. Webkicks will also notify registered customers of any amendments via email. However, the amended Terms posted on the website will be controlling regardless of whether an email notification is received. Customers should periodically check the website for any updated Terms. Notices will describe how amended Terms impact existing and new projects and services. Amended Terms will only apply to new projects and services subscribed to following the changes, unless otherwise specified in the amendment notice.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Entire Agreement")}</h4>
            <p className="font-18 muteSubtitle">These Terms and Conditions of Service constitute the entire agreement between you and Webkicks regarding the use of this website and services. It supersedes any prior agreements, representations or understandings between you and Webkicks.</p>
            <p className="font-18 muteSubtitle">Other policies may apply to specific features, services or materials available on the website including our <Link to={"/privacy-policy"}>Privacy Policy</Link> . These Terms of Service may also be supplemented with additional terms and conditions as agreed for special products and services offered through this website or affiliated websites.</p>
            <p className="font-18 muteSubtitle">In the event of a conflict between these Terms and any additional supplemental terms, the supplemental terms shall prevail with respect to the specific products or services. You should review our website and all applicable supplemental terms carefully before using our website or services.</p>
            <p className="font-18 muteSubtitle">In simpler terms:</p>
            <ul>
              <li className="font-18 muteSubtitle">These Terms reflect the complete agreement between user and Webkicks regarding use of site/services</li>
              <li className="font-18 muteSubtitle">Additional policies like Privacy Policy may also apply</li>
              <li className="font-18 muteSubtitle">Specific services can have additional terms that override these general Terms</li>
            </ul>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Severability")}</h4>
            <p className="font-18 muteSubtitle">If any part of these Terms and Conditions of Service is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the portions, and the remaining portions shall remain in full force and effect. Failure of Webkicks to enforce any of the provisions hereof shall not be construed to be a waiver of such provisions or of the right of Webkicks thereafter to enforce each and every such provision. In simpler terms:</p>
            <ul>
              <li className="font-18 muteSubtitle">If any part is found invalid or unenforceable, it will be adjusted to be as close as possible to the original intent under applicable laws</li>
              <li className="font-18 muteSubtitle">The rest of the terms will still apply</li>
              <li className="font-18 muteSubtitle">Not enforcing a term in one instance does not mean Webkicks cannot enforce it in the future</li>
            </ul>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Force Majeure")}</h4>
            <p className="font-18 muteSubtitle">Webkicks shall not be liable for any delay or failure in performance due to causes beyond its reasonable control including but not limited to acts of God, acts of civil or military authority, fires, epidemics, floods, earthquakes, riots, wars, sabotage, governmental actions, or inability to obtain equipment, materials, or appropriate labor.</p>
            <p className="font-18 muteSubtitle">{t("Webkicks' performance shall be excused and deemed suspended during the continuation of such event or events and, if Webkicks' performance is substantially prevented for a period of more than thirty (30) days, either party may terminate this agreement upon seven (7) days' written notice to the other party.")}</p>
            <p className="font-18 muteSubtitle">In the event that either party is prevented or delayed from fulfilling its contractual obligations due to an event of force majeure, it shall notify the other party promptly following the date of commencement of the event of force majeure of the extent to which it is prevented from performing any of its obligations under the agreement and the likely duration of such non-performance.</p>
            <p className="font-18 muteSubtitle">The affected party shall take all reasonable steps to resume complete performance of this agreement as soon as practicable and shall keep the other party fully informed of developments every thirty (30) days.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Governing Law and Jurisdiction")}</h4>
            <p className="font-18 muteSubtitle">These Terms of Use and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the United Arab Emirates.</p>
            <p className="font-18 muteSubtitle">The courts of the Emirate of Sharjah shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms of Use or their subject matter or formation (including non-contractual disputes or claims).</p>
            <p className="font-18 muteSubtitle">Notwithstanding the foregoing, we shall retain the right to bring proceedings against you for breach of these conditions in your country of residence or any other relevant country. If your country of residence regulates any part of the conditions so that they are unenforceable such part will be superseded by provisions of local law only to the extent necessary.</p>
            <p className="font-18 muteSubtitle">The governing law and jurisdiction clauses shall not limit our right to take proceedings against you in any other jurisdictions. The United Nations Convention on Contracts for the International Sale of Goods is expressly excluded.</p>
            <p className="font-18 muteSubtitle">We may revise these terms of use at any time by amending this page. You should check this page from time to time to take notice of any changes we made, as they are binding on you. Some of the provisions contained in these terms of use may also be superseded by provisions or notices published elsewhere on our site.</p>
            <p className="font-18 muteSubtitle">Entire Agreement This Agreement along with webkicks Privacy Policy, as may be updated from time to time and posted at [xxxx], constitutes the complete agreement and understanding between us with respect to the Service and supersedes any other form of Agreement</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">Contact Information</h4>
            <p className="muteSubtitle font-18">Email: support@webkicks.co</p>
            <p className="muteSubtitle font-18">Webkicks<br/> Sharjah Media City (Shams) </p>
            <p className="muteSubtitle font-18">Business Hours: <br/>Monday - Friday: 11:00 AM - 7:00 PM GST <br/> Saturday - Sunday: Closed</p>
            <p className="muteSubtitle font-18">If you have any concerns about our privacy practices, please contact our team at support@webkicks.co.</p>
            <p className="muteSubtitle font-18">A member of our team will respond to address your questions or resolve any issues you are experiencing. We take privacy seriously and want to ensure we respect your rights and comply with all applicable laws. For security inquiries, please contact us during business hours via email at support@webkicks.co.</p>
            <p className="muteSubtitle font-18">For emergency issues outside of business hours, please leave a detailed message including your name, account information, description of the issue, and contact details. We will respond as soon as possible.</p>
            <p className="muteSubtitle font-18 pb-5">We will use commercially reasonable efforts to respond to all inquiries promptly. However, response times may vary depending on the nature and complexity of the issue.</p>
          </div>
        </div>
      </div>
      <GeneralFooter/>
    </>
  );
};

Terms.propTypes = {
  direction: PropTypes.any,
  t: PropTypes.any,

};
export default Terms;
