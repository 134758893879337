import React, { useState , useEffect} from "react";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  Nav,
  NavbarBrand,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";

import logo from "assets/images/logo.svg";
import "./style.scss";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import userImage from "assets/images/user.png";
import languageImage from "assets/images/wideGray.svg";
// import ProfileService from "services/Client/ProfileService";

const languages = [
  { name: "English", code: "en" },
  { name: "العربية", code: "ar", dir: "rtl" },
];
const Header = () => {
  const [show, setIsShow] = useState(false);
  const toggle = () => setIsShow((prevState) => !prevState);
  const { t, i18n } = useTranslation();
  const authData = useSelector((state) => state.Auth);
  const [shadow,setShadow] = useState({className:'noShadowGenereal'})
  const handleScroll = () => { 
    if (document.documentElement.scrollTop > 20) {
      setShadow({
         className: 'headerShadowGeneral'
       })
     }
     else{
      setShadow({
        className: 'noShadowGenereal'
      })
     } 
   };
   useEffect(() => {
      window.onscroll = () => handleScroll()
    }, []);
  // const profileService = new ProfileService();
  // const [profileData, setProfileData] = useState({});
  // const getProfileDetails = async () => {
  //   const res = await profileService.getProfileData();
  //   setProfileData(res.data);
  //   getProfileDetails();
  // };
  // useEffect(() => {
  //   getProfileDetails();
  // }, []);
  // console.log(profileData);
  return (
    <div>
      <Navbar light className={`headerGeneral py-3 bg-white ${shadow.className}`} fixed="top" expand="lg">
        <div className="container-fluid d-flex align-items-center justify-content-between px-lg-3 px-0">
          <NavbarBrand href="/home">
            <img src={logo} alt="" className="imgLogoGeneral" />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsShow(!show);
            }}
          />
          <Collapse className="ps-3" navbar>
            <Nav className="me-auto d-flex" navbar>
              <NavItem>
                <NavLink to={`/home`} className="nav-link">
                  {t("Home")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`/templates`} className="nav-link">
                  {t("Templates")}
                </NavLink>
              </NavItem> 
              <NavItem>
                <NavLink to={`/subscriptions`} className="nav-link">
                  {t("Subscriptions & Plans")}
                </NavLink>
              </NavItem>
              {authData.userType === "user" && authData.token?.length > 0 ?
              <NavItem>
                <NavLink to={`/client/projects`} className="nav-link">
                  {t("Projects")}
                </NavLink>
              </NavItem> : ""}
              <NavItem>
                <NavLink to={`/contact-us`} className="nav-link">
                  {t("Contact us")}
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to={`/blog`} className="nav-link">
                  {t("Blog")}
                </NavLink>
              </NavItem> */}
            </Nav>
            {authData.userType === "user" && authData.token.length > 0 ? (
              <Nav className="align-items-center">
              <UncontrolledDropdown inNavbar nav>
              <DropdownToggle disabled nav>
                <span className="font-size-14 d-flex">
                  <span>EN</span>
                  <img src={languageImage} className="imgLanguage img-fluid mx-2"/>
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                {languages.map((lang, index) => {
                  return (
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        i18n.changeLanguage(lang.code);
                      }}
                    >
                      {lang.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
              <UncontrolledDropdown className="userDropdown" navbar>
                <DropdownToggle className="ps-0 d-flex align-items-center text-muted fw-medium text-black" nav caret>
                  <img src={userImage} alt="" className="pe-2" />{" "}
                  {/* <i className="fa fa-user-circle fa-lg mx-2 text-white"></i> */}
                  {authData.user_name}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      className="text-decoration-none d-block py-0 text-dark"
                      to="/profile"
                    >
                      Profile
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      className="text-decoration-none d-block py-0 text-dark"
                      to="/logout"
                    >
                      Logout
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              </Nav>
            ) : (
              <Nav className="ms-auto dropHome gap-3 gap-lg-0 d-lg-flex align-items-lg-center" navbar>
                <NavItem className="p-0">
                  <UncontrolledDropdown disabled inNavbar nav>
                    <DropdownToggle nav>
                      <span className="font-size-14 d-flex">
                        <span>EN</span>
                        <img src={languageImage} className="imgLanguage img-fluid mx-2"/>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      {languages.map((lang, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              i18n.changeLanguage(lang.code);
                            }}
                          >
                            {lang.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
                <Link to="/login">
                  <button className="btn btnHeightLg text-dark opacity-75 fw-bold px-4 shadow-none">
                    {t("Log In ")}
                  </button>
                </Link>
                <Link to="/register">
                  <button className="btn btn-primary btnHeightLg fw-bold px-4">
                    {t("Create Account")}
                  </button>
                </Link>
              </Nav>
            )}
          </Collapse>
        </div>
      </Navbar>
      <Offcanvas isOpen={show}>
        <OffcanvasHeader toggle={toggle}>
          <img src={logo} alt="" className="imgLogo" />
        </OffcanvasHeader>
        <OffcanvasBody className="navbarDashboard">
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink to={`/home`} className="nav-link">
                {t("Home")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={`/templates`} className="nav-link">
                {t("Templates")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={`/subscriptions`} className="nav-link">
                {t("Subscriptions & Plans")}
              </NavLink>
            </NavItem>
            {authData.userType === "user" && authData.token.length > 0 ?
            <NavItem>
              <NavLink to={`/client/projects`} className="nav-link">
                {t("Projects")}
              </NavLink>
            </NavItem>: ""}
            <NavItem>
              <NavLink to={`/contact-us`} className="nav-link">
                {t("Contact us")}
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink to={`/blog`} className="nav-link">
                {t("Blog")}
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  <span className="font-size-14"> {t("lang")} </span>
                </DropdownToggle>
                <DropdownMenu right>
                  {languages.map((lang, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          i18n.changeLanguage(lang.code);
                        }}
                      >
                        {lang.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem> */}
          </Nav>
          {authData.userType === "user" && authData.token.length > 0 ? (
            <UncontrolledDropdown navbar>
              <DropdownToggle className="text-dark ps-0" nav caret>
                <img src={userImage} alt="" className="pe-2" />{" "}
                {authData.user_name}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink
                    className="text-dark text-decoration-none d-block py-0"
                    to="/profile"
                  >
                    Profile
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="text-dark text-decoration-none d-block py-0"
                    to="/logout"
                  >
                    Logout
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <Nav className="mx-auto" navbar>
              <Link to="/login">
                <button className="btn btn-outline-primary btnHeightLg fw-bold px-4 mt-3 w-100">
                  {t("Login to your account")}
                </button>
              </Link>
              <Link to="/register">
                <button className="btn btn-primary btnHeightLg fw-bold px-4 mt-3 w-100">
                  {t("Create new account")}
                </button>
              </Link>
            </Nav>
          )}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};
export default Header;
