import React, { useState } from "react";

import { CardBody, Collapse } from "reactstrap";
import { Link } from "react-router-dom";

const Faq = () => {
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col9, setcol9] = useState(false);
  const [col10, setcol10] = useState(false);

  function t_col1() {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }

  function t_col2() {
    setcol1(false);
    setcol2(!col2);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }

  function t_col3() {
    setcol1(false);
    setcol2(false);
    setcol3(!col3);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }

  function t_col4() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(!col4);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }
  function t_col5() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(!col5);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }
  function t_col6() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(!col6);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }
  function t_col7() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(!col7);
    setcol8(false);
    setcol9(false);
    setcol10(false);
  }
  function t_col8() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(!col8);
    setcol9(false);
    setcol10(false);
  }
  function t_col9() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(!col9);
    setcol10(false);
  }
  function t_col10() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol8(false);
    setcol9(false);
    setcol10(!col10);
  }

  return (
    <React.Fragment>
      <div>
        <div id="gen-ques-accordion" className="accordion custom-accordion">
          <div className="mb-3 pt-4">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col1();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">How long does it take to create a website using your platform?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col1 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>

            <Collapse isOpen={col1}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">Most basic websites can be completed within 1-2 weeks. More complex sites may take 3-6 weeks. Timelines depend on the number of pages, number of sections in each page, amount of content, and customization needs. </p>
                <p className="text-muted">For more accurate estimated delivery timeframes expected for delivery, check our plans <Link to={'/plans'}>here.</Link></p>
                <p className="text-muted">Our dedicated account managers can also provide a customized project timeline estimate once we have discussed your business goals and technical requirements in more detail. Overall, we strive for efficient and timely website launches while ensuring quality.</p>
                <p className="text-muted">Please note that the speed of website completion relies on your prompt actions and feedback. To expedite the process, we urge you to actively monitor your dashboard and registered email.</p>
              </CardBody>
            </Collapse>
          </div>
          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col2();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">What kind of support do you offer to customers who need help building their site?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col2 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col2}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted pt-3">We understand that everyone has different needs and preferences, which is why we offer a range of services to ensure a smooth and successful experience.</p>
                <ul className="pt-3 text-muted">
                  <li className="pb-3">Dedicated Account Manager: Each customer is paired with a knowledgeable account manager who will be their guide from start to finish. Whether it is answering questions, providing guidance, or offering creative direction, our account managers are here to support you every step of the way.</li>
                  <li className="pb-3">Business and Copywriting Briefing Meetings: (An) initial briefing meeting(s) is/are held to discuss goals, preferences and gather any creative direction or branding assets from the client. This service is part of packages that include our copywriting support.</li>
                  <li className="pb-3">Copywriting Feedback Sessions: During content development -depending on your package- our account manager schedules feedback sessions to share progress and collect input to ensure the final content meets your needs. </li>
                  <li className="pb-3">Website Feedback Sessions: After your website has been built, you can schedule feedback sessions to request the edits you need and make sure that your website is fit and ready to go live.</li>
                  <li className="pb-3">Second Language: Our team can assist you in creating bilingual websites, allowing you to reach a wider audience and enhance your global presence. We currently offer Arabic and English languages.</li>
                  <li className="pb-3">Free Hosting:  We offer 1-year free hosting services to all our customers. Enjoy the peace of mind knowing that your website is secure, reliable, and always accessible to your visitors at no extra cost.</li>
                </ul>
                <p className="text-muted">Our goal is to make the website building process fully collaborative. Briefing meetings and feedback touchpoints allow for close communication between customer and account manager every step of the way.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col3();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">What payment options do you accept?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col3 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col3}>
              <CardBody className="px-0 pt-2">
                <p className="mb-0 text-muted">We accept payments made via major credit cards, and debit cards: Visa, Mastercard, American Express, Discover, JCB, UnionPay, and Elo.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col4();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">Can I customize the design of my website beyond the templates provided?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col4 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col4}>
              <CardBody className="px-0 pt-2">
                <ul className="pt-3 text-muted">
                  <li className="pb-3">While our website builder includes a wide range of professionally designed ready-made templates to choose from, customization options are limited to what can be achieved within each template design.</li>
                  <li className="pb-3">Our templates allow for adjusting colors, fonts, layouts and adding or removing certain sections. However, you will not be able to customize elements like page headers, footers, button styles beyond the existing options and sections.</li>
                  <li className="pb-3">If you have found a template elsewhere that closely aligns with your needs, we may be able to modify it for you. Fill out our request form, and our account managers will assess the feasibility.</li>
                  <li className="pb-3">{("Don't miss out on this opportunity for a seamless website building experience. Submit your request today and lets make it happen. To access the form, please click here.")}</li>
                </ul>
                <p className="text-muted">{("If you have found a template elsewhere that closely aligns with your needs, we may be able to modify it for you. Fill out our request form, and our account managers will assess the feasibility. Don't miss out on this opportunity for a seamless website building experience. Submit your request today and let's make it happen. To access the form.")} <Link to={"/templates"} target="_blank">Click Here</Link></p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col5();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">What if I want a refund?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col5 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col5}>
              <CardBody className="px-0 pt-2">
                <p className="pt-3 pb-3 text-muted">{("We understand that sometimes plans change, and we're happy to provide a refund within certain limits. Here's our refund policy:")}</p>
                <p className="pb-3 fw-bold">{("For packages that include our content creation services:")}</p>
                <ul className="pt-2 text-muted">
                  <li className="pb-3">A full refund is available if requested before the initial content briefing meeting between the client and our content strategists.</li>
                  <li className="pb-3">A 50% refund is available if requested after the initial content briefing meeting is held.</li>
                </ul>
                <p className="pb-3 fw-bold">For packages that do not include our content creation services:</p>
                <ul className="text-muted">
                  <li className="pb-3">A full refund is available if requested before the client uploads any of their own content assets to the website builder dashboard to begin the building process.</li>
                </ul>
                <p className="pb-3 fw-bold">For all packages:</p>
                <ul className="text-muted">
                  <li className="pb-3">No refund will be provided once the website building process has begun by our development team(step 3 is open in the project’s dashboard).</li>
                </ul>
                <p className="text-dark">We must receive refund requests by email to support@webkicks.co. Please allow up to 21 business days for processing. No refunds will be provided for requests made after website launch or go-live dates. To speed up the process, it’s preferable to add “Refund Request for (project id)” in the subject line.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col6();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">Is your website builder optimized for search engines and mobile devices? Is the website responsive for mobile?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col6 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col6}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">Yes, all our templates are fully responsive and optimize layout/design for viewing on any device like mobile phones or tablets. We will make sure that the content is SEO friendly.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col7();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">How secure is my website data/account?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col7 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col7}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">We take regular backups, utilize SSL certificates, and have robust security protocols to protect client data and websites from threats.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col8();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">Do you offer ongoing website support?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col8 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col8}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">At present, all of our website packages include support throughout the initial build process to help with setup, content input, and launching the site. Additionally, technical support for any hosting or website issues is provided for the entire duration of the included 1 year of hosting.</p>
                <p className="text-muted">This means if any technical problems arise with your hosted website within that first year - such as server errors, broken links, plugin conflicts etc. - our team will troubleshoot and resolve the issues at no extra cost.</p>
                <p className="text-muted">We are currently developing new website support plans that will allow clients to purchase ongoing website support or one-time support cases after launch.</p>
                <p className="text-muted">The forthcoming plans will provide ongoing minor updates like content changes, as well as assistance troubleshooting any technical problems through phone, email or chat with an account manager.</p>
                <p className="text-muted">Our goal is to introduce ongoing affordable support options for customers who want peace of mind knowing help is available down the road. For now, our dedicated account managers are available to resolve issues that arise on a case by case basis.</p>
                <p className="text-muted">As we continue improving our services, onboarding new tools, and iterating based on feedback, a suite of ongoing and one-time support packages will be released soon to complement our existing website builds.</p>
              </CardBody>
            </Collapse>
          </div>
          
          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col9();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">How long is the hosting included for?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col9 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col9}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">Our packages include 1 year of free shared hosting with our preferred provider. Hosting can be renewed after the initial period for an annual fee.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col10();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">What file types can be uploaded for images/graphics?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col10 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col10}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">We accept JPG, PNG, SVG and GIF files. Recommended image sizes are 1200x630px or larger for hero images and 600x400px or smaller for other photos.</p>
                <p className="text-muted">We also recommend using the MP4 format and aim for a 16:9 aspect ratio at resolutions like 1080p or 720p. Try to keep file sizes under 100MB for smooth playback.</p>
              </CardBody>
            </Collapse>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
