import PropTypes from "prop-types";
import docImage from "../../../../assets/images/doc.png";
import documentImage from "../../../../assets/images/doc.png";
import React, { useEffect, useState } from "react";
import OrderService from "../../../../services/Admin/OrderService";
import { toast } from "react-toastify";
import { SuccessOk } from "configs/statusCode";

const contentIsNotIncluded = ({ secondStepData,projectData, updateProjectData }) => {
  const [data, setData] = useState({});
  const orderService = new OrderService();

  useEffect(()=>{
    setData(secondStepData)
  },[])

  const requestToEdit = async (id) => {
    const res = await orderService.secondStepRequestToEdit(id);
    if (res.status === SuccessOk) {
      const updatedData = [...data];
      const index = updatedData.findIndex(item => item.id === id);
      if (index > -1) updatedData[index].status = res.data[0].status;
      setData([...updatedData]);

      toast.success("Your Request Sent Successfully");
      updateProjectData({ second_step_data_status: 3 });
    }
  };

  const confirmContent = async (status) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const dateAfterOneDay = currentDate.toISOString().split('T')[0];

    let maximamDays;
    if(projectData.subscription_plan?.features[0]?.feature?.match(/(\d+)-(\d+)/)?.length > 0){
      // eslint-disable-next-line no-unused-vars
      maximamDays = projectData.subscription_plan?.features[0]?.feature?.match(/(\d+)-(\d+)/)[0].split('-')[1]
    }

    let data1 = {
      "step": 'second_step_status', 
      "status": status,
    }
    let data2= {
      third_step_data: {
        start_date: dateAfterOneDay, 
        number_of_days: maximamDays
      },
      third_step_status: 2
    }

    Promise.all([orderService.changeStepStatus(projectData.id, data1),orderService.updateOrder(projectData.id, data2)]).then((res)=>{
      if (res[0].status === 200 && res[1].status === 200) {
        toast.success("Confirm Content Successfully");
        updateProjectData({ 
          second_step_data_status: status ,
          ...data2
        });
      }
    }).catch(()=> {
      toast.error("Error Occurred")
    })
  };

  return (<>
    <div className="px-lg-4">
      {/* <p className="text-warning font-14 mb-2 fw-bold">
        <i className="fas fa-info-circle"></i> Content not included in your plan, you provide content.
      </p> */}
      {/* <button className="btn btnYellow text-danger font-14 ms-2 height-42 mb-2 mt-3 mt-md-1">
        Purchase content for one Lang{" "}
        {`${firstLangPrice.currency} ${firstLangPrice.amount}`}
      </button>
      <button className="btn btnYellow text-danger font-14 ms-2 height-42 mb-2 mt-3 mt-md-1">
        Purchase content for two Langs{" "}
        {`${secondLangPrice.currency} ${secondLangPrice.amount}`}
      </button> */}
    </div>
    
    {(projectData?.second_step_data_status === 0 || data?.length === 0) && (<div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row justify-content-between">
        <div className="col-lg-12 text-center">
          <img src={docImage} alt=""/>
          <h6 className="fw-bold pt-3">Waiting For Client Content Brief</h6>
          <p className="f-14 ps-4 ms-2 mb-0 text-black-50">
            Fill information about your business and goals for this website, this data can vary and time
            can
            be from 30 mins to 4 hours
          </p>
        </div>
      </div>
    </div>)}
    <div>
      <div className="justify-content-between px-lg-4">
        {(data && data?.length > 0 && projectData?.second_step_data_status !==0) && (
          <div className="card boxShadow w-100 mb-3">
            <div className="card-body">
              <h6 className="fw-bold pb-2">
                <img src={documentImage} className="pe-2"/> Website Pages for each page
              </h6>
              {data?.map((item, index) => {
                return (
                <div className="card mt-2" key={index}>
                  <div className="card-body d-flex justify-content-between p-0">
                    <div>
                      <span className="fw-bold">{item.title}</span>
                    </div>
                    <p className="mb-0">
                      {item.status === 1 ? <>
                        <button
                          onClick={() => requestToEdit(item.id)}
                          className="btn btn-primary font-14 me-2"
                        >
                          <i className="fa fa-edit pe-2"></i>Request to Edit
                        </button>
                        <a className="btn btn-outline-primary px-4" target="_blank" rel="noreferrer"
                          href={item?.reference_url}><i className="fa fa-eye"></i> Preview</a>
                      </> : ""}
                    </p>
                  </div>
                  {((item?.reference_url || item.media?.path)) && <div className="card-body d-flex justify-content-between px-3 py-4">
                        <div className="d-flex" style={{gap: '12px'}}>
                          {item?.reference_url && <a href={item?.reference_url} className='btn btn-outline-primary px-4 d-block' target="blank">View Reference Content</a>}
                          {item.media?.path && <a href={item.media?.path} className='btn btn-outline-primary px-4d-block' target="blank">Download Media Content</a>}
                        </div>
                        {projectData?.second_step_data_status !== 1 &&<div>
                          <button
                            onClick={() => confirmContent(1)}
                            className="btn btn-primary font-14 me-2"
                          >
                            Confirm
                          </button>
                        </div>}
                      </div>}
                </div>);
              })}
            </div>
          </div>) }
      </div>
    </div>
  </>);
};

contentIsNotIncluded.propTypes = {
  secondStepData: PropTypes.any,
  projectData: PropTypes.any,
  updateProjectData: PropTypes.func
};

export default contentIsNotIncluded;