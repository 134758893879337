import BaseService from "../BaseService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/website/blog";
 
export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
 
    
}
