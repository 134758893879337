import React from "react";
import businessImage from "assets/images/card.png";
import websiteImage from "assets/images/store.png";
import fileImage from "assets/images/documents.png";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { adminPrefix } from "configs/routePrefix";

const FirstStepData = ({ data, projectData,orderId }) => {
  const history = useHistory()

  return (
    <>
      <div className="pt-1">
        <div className="row">
          <div className="col-md-12">
            <div className="card boxShadow">
              <div className="card-body pb-0">
                <div className="row justify-content-between">
                  <div className="col-md-8">
                    <div className="pb-2 d-sm-flex">
                      <div className="pb-2 minWidthCol">
                        <img src={businessImage} alt=""/>
                        <span className="text-muted pe-4 font-14"> Business Name </span>
                      </div>
                      <div className="pb-2">
                        <span className="fw-bold font-14">{data.business_name}</span>
                      </div>
                    </div>
                    <div className="pb-2 d-sm-flex">
                      <div className="pb-2 minWidthCol">
                        <img src={websiteImage} alt=""/>
                        <span className="text-muted pe-4 font-14"> Website Type </span>
                      </div>
                      <div className="pb-2">
                        {/* <span className="fw-bold font-14">{data.website_type}</span> */}
                        <span className="fw-bold font-14">{data?.type}</span>
                      </div>
                    </div>
                    {/* <div className="pb-2 d-sm-flex">
                      <div className="pb-2 minWidthCol">
                        <img src={fileImage} alt=""/>
                        <span className="text-muted pe-4 font-14"> File(s) </span>
                      </div>
                      <div className="pb-2 overflow-auto">
                        {data.documents.length ? data.documents.map(document => (
                          <span className="fw-bold font-14" key={document.id}>
                        <a href={document.path} target="_blank" rel="noreferrer">File Content </a>
                      </span>)) : null}
                      </div>
                    </div> */}
                    <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <i className="fa-solid fa-envelope text-muted font-20" style={{opacity: '.75'}}></i>
                    <span className="text-muted pe-4 font-14"> Business Email </span>
                  </div>
                  <div className="pb-2">
                    <span className="fw-bold font-14">{data?.contact_email}</span>
                  </div>
                </div>
                <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <img src={fileImage} alt=""/>
                    <span className="text-muted pe-4 font-14">Number of Pages</span>
                  </div>
                  <div className="pb-2">
                    <span className="fw-bold font-14">{data?.pages_num}</span>
                  </div>
                </div>
                  </div>
                  <div className="col-md-3">
                    {/* <button onClick={() => { setModalDetails()}} className="btn btn-outline-primary height-42 w-100 mb-2"><i className="fa fa-eye pe-2"></i>Preview
                    </button> */}
                    {/* {projectData.request_edit === 1 ?
                      < button className="btn btn-primary height-42 w-100 mb-2" onClick={() => {
                        changeStepStatus(orderId);
                      }}>Allow Edits
                      </button> : null} */}
                      <button
                        onClick={()=>{
                          history.push(`${adminPrefix}/project/${projectData.id}/first-step`, {order_id: orderId, data: projectData,edit: parseInt(data?.first_step_status) === 1 ? 'view' : 'edit',firstStepData: data})
                        }}
                        className="btn btn-primary px-4"
                        >
                        {parseInt(data?.first_step_status) === 1 ? 'View Client Brief' : 'Edit Brief'}
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
};
FirstStepData.propTypes = {
  data: PropTypes.object,
  // updateProjectData: PropTypes.func,
  // setModalDetails: PropTypes.func, 
  orderId: PropTypes.number, 
  projectData: PropTypes.any
};

export default FirstStepData;