import React from "react";
import MetaTags from "react-meta-tags";

const Register = () => {
    return (
        <>
            <MetaTags>
                <title> sign up </title>
            </MetaTags>Ø
            <div>
                <div>
                    sign up
                </div>
            </div>
        </>
    );
};
export default Register;

