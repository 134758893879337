import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { adminAuthRoutes, adminRoutes } from "./routes/adminRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import AdminLayout from "./components/Admin/Layout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "react-toastify/dist/ReactToastify.css";
import { adminPrefix } from "./configs/routePrefix";
import { websiteAuthRoutes } from "./routes/websiteRoutes";
import { clientAuthRoutes, clientRoutes } from "./routes/clientRoutes";
import NonAuthClientLayout from "./components/Client/NonAuthClientLayout";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.scss";
import { useTranslation } from "react-i18next";
import "./i18n";
import AOS from "aos";
import "aos/dist/aos.css";
 import { useDispatch } from "react-redux";
import {  authenticate } from "store/Auth/actions";
import ScrollToTop from "components/Common/ScrollToTop";
 
 
const App = () => {
  AOS.init();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    getLang();
  }, []);
  useEffect(() => {
    getAuthData();
  }, []);
 

  const getAuthData = async () => {
    const token = await localStorage.getItem("token");
    const auth = await localStorage.getItem("auth");
    const authParsed = JSON.parse(auth);

    if (token && authParsed) {
      dispatch(authenticate({
        token: token, user_name: authParsed?.name, userType: authParsed?.user_type, email: authParsed?.email
      }));
    }
  };

  const getLang = async () => {
    const lang = await localStorage.getItem("i18nextLng");
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage("en");
    }
  };
  return (<React.Fragment>
    <div
      className={`${i18n.language !== "ar" ? "" : "ar-wrapper"}`}
    >
      <ToastContainer/>
      <Router>
        <ScrollToTop />
        <Switch>
          {adminAuthRoutes.map((route, idx) => (<Authmiddleware
            path={adminPrefix + route.path}
            routePrefix={adminPrefix}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            panel="admin"
            isAuthProtected={false}
          />))}

          {adminRoutes.map((route, idx) => (<Authmiddleware
            path={adminPrefix + route.path}
            routePrefix={adminPrefix}
            layout={AdminLayout}
            component={route.component}
            key={idx}
            panel="admin"
            isAuthProtected={true}
            exact
          />))}

          {websiteAuthRoutes.map((route, idx) => (<Authmiddleware
            path={route.path}
            routePrefix={""}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            panel="user"
            isAuthProtected={false}
            exact
          />))}


          {clientAuthRoutes.map((route, idx) => (<Authmiddleware
            path={route.path}
            routePrefix={""}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            panel="client"
            isAuthProtected={false}
            exact
          />))}

          {clientRoutes.map((route, idx) => (<Authmiddleware
            path={route.path}
            routePrefix={""}
            layout={NonAuthClientLayout}
            component={route.component}
            key={idx}
            panel="client"
            isAuthProtected={true}
            exact
          />))}

        </Switch>
      </Router>
    </div>
  </React.Fragment>);
};

export default App;


