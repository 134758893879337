import React, { useState , useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { ProfileValidator } from "FormValidations/Admin/Profile/Profile";
import { yupResolver } from "@hookform/resolvers/yup";
import ProfileService from "services/Admin/ProfileService";
// import { SuccessCreated } from "configs/statusCode";
import { toast } from 'react-toastify';
import Password from "./Password";
import { useSelector } from "react-redux";

const Profile = () => {
  const profileService = new ProfileService();
  const [profileData, setProfileData] = useState({});
  const auth = useSelector(state=> state.Auth)
  const [error, setError] = useState({})
  const [changePassword, setChangePassword] = useState(false);
  const getProfileDetails = async () => {
    const res = await profileService.getProfileData();
    setProfileData({
      ...res.data
    });
  };
  useEffect(() => {
    getProfileDetails();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(ProfileValidator),
  });

  const handleValidSubmit = () => {
    if(auth?.email === "admin@gmail.com"){
      if(!profileData?.calendly_data?.calendlyAcc || !profileData?.calendly_data?.calendlyToken){
          let errors = {}
          if(!profileData?.calendly_data?.calendlyAcc) errors['calendlyAcc'] = true
          if(!profileData?.calendly_data?.calendlyToken) errors['calendlyToken'] = true
          setError({...error, ...errors})
          return
      }
  }
    const data = {
      name: profileData.name,
      email: profileData.email,
      password: profileData.password,
      password_confirmation: profileData.password_confirmation
      // status: formData.status,
      // category_value_id: formState.type.value,
    };
    if(auth?.email === "admin@gmail.com") data['calendly_data'] = profileData?.calendly_data  

    profileService.profileUpdate(data).then((res) => {
      if (res?.status === 200) {
        toast.success("ProFile Data successfully Updated");
        getProfileDetails();
      }
      else {
        toast.error("Please enter the required fields");
      }
    }).catch(error=>{
      toast.error(error?.response?.data?.error)
  })
  };

  const handleFieldChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  return (
    <div className="websitPages pt-5 mt-3">
      <Container fluid className="px-md-5 pt-4">
        <Row className="justify-content-between">
          <Col lg={8} md={7}>
            <div className="d-flex">
              <div>
                <h2 className="gText">Profile</h2>
                <p className="text-muted">Set your Name, Email, Password and Confirm Password</p>
              </div>
            </div>
          </Col>
        </Row>
        <Card className="pt-2 px-3">
          <CardText tag="h6">Profile Info</CardText>
          <CardBody>
            <form
              className="g-3 needs-validation"
              onSubmit={handleSubmit(handleValidSubmit)}
            >
              <Row>
                <Col lg={6}>
                  <div className="pb-2">
                    <Label>Name</Label>
                    <input
                    // name="name"
                      {...register("name")}
                      placeholder="Name"
                      value={profileData?.name}
                      className={`form-control height-47 ${
                        errors?.name && "border-danger"
                      }`}
                      onChange={(e) => handleFieldChange(e)}
                      type="text"
                    />
                  </div>
                  {errors?.name && (
                    <ErrorMessage message={errors?.name?.message} />
                  )}
                </Col>
                <Col lg={6}>
                  <div className="pb-2">
                    <Label>Email address</Label>
                    <input
                    // name="email"
                      {...register("email")}
                      value={profileData?.email}
                      className={`form-control height-47 ${
                        errors?.email && "border-danger"
                      }`}
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => handleFieldChange(e)}
                    />
                  </div>
                  {errors?.email && (
                    <ErrorMessage message={errors?.email?.message} />
                  )}
                </Col>
                {auth?.email === "admin@gmail.com" && <>
                        <Col md={6}>
                            <div className='pb-2'>
                                <Label>Calendly Link</Label>
                                <input
                                    name='calendly'
                                    placeholder="Calendly Link"
                                    className={`form-control height-47 ${error?.calendlyAcc && "border-danger" }`}
                                    type="text"
                                    onChange={e=> {
                                        setProfileData({...profileData, calendly_data: {...profileData.calendly_data ,calendlyAcc: e.target.value}})
                                        setError({...error, calendlyAcc: false})
                                    }}
                                    value={profileData?.calendly_data?.calendlyAcc}
                                />
                            </div>
                            {error ?.calendlyAcc && <ErrorMessage message={'calendly link is a required field'} />}
                        </Col>
                        <Col md={12}>
                            <div className='pb-2'>
                                <Label>Calendly Token</Label>
                                <input
                                    name='calendlyToken'
                                    placeholder="Calendly Token"
                                    className={`form-control height-47 ${error?.calendlyToken && "border-danger" }`}
                                    type="text"
                                    onChange={e=> {
                                      setProfileData({...profileData, calendly_data: {...profileData.calendly_data ,calendlyToken: e.target.value}})
                                      setError({...error, calendlyToken: false})
                                    }}
                                    value={profileData?.calendly_data?.calendlyToken}
                                />
                            </div>
                            {error ?.calendlyToken && <ErrorMessage message={'calendly token is a required field'} />}
                        </Col>
                        </>}
              </Row>
              <button className="btn btn-primary px-4 height-42">Save</button>
            </form>

            <p className='mt-3 cursor-pointer' onClick={()=> setChangePassword(prev=> !prev)}>
              Change my account password {changePassword ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
            </p>

            {changePassword && <Password 
              profileData={profileData} 
              setChangePassword={setChangePassword}
              handleFieldChange={e => handleFieldChange(e)} 
              getProfileDetails={getProfileDetails} 
            />}
            
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Profile;
