import { LinkAuthenticationElement, PaymentElement, useElements, useStripe,  } from "@stripe/react-stripe-js";
// import CardSection from "./CardSection";
import  visa  from 'assets/images/visa.svg';
import PropTypes from "prop-types";
import paymentImage from "assets/images/payments.png";
import OrderService from "services/Website/OrderService";
import { Col } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import defaultImage from "assets/images/avatar.png";
import { Circles } from "react-loader-spinner";
import stripeIcon from "assets/images/stripe_icon.png";
// import { imageOnError } from "helpers/common";
import "./style.css"

const CheckoutForm = (props) => {
  const { data } = props;
  const [getData, SetGetData] = useState(false);
  const [load, setLoad] = useState(false);
  const [total, SetTotal] = useState(0);
  const [from, setFrom] = useState('');
  const formData = useSelector((state) => state.Auth);
  // const [templateImg, settemplateImg] = useState(null);
  const { t, i18n } = useTranslation();
  const orderSrv = new OrderService();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    let path = history?.state?.state?.from === 'content' ? 'content' : "subscriptions"
    setFrom(path)

    let lan = data?.planData?.has_second_lang ? data.planData?.second_lang_price?.find(res=> res.currency === data.planData?.currency)?.amount : 0
    let content = data?.planData?.has_content ? data.planData?.content_price?.find(res=> res.currency === data.planData?.currency)?.amount : 0
    let tot 

    if(path==='content'){
      tot = data.planData?.content_price.length > 0 ? data.planData?.content_price?.find(res=> res?.currency === data.planData?.currency)?.amount : data.planData?.content_price?.amount
    } else {
      tot = data.planData?.price?.find(res=> res.currency === data.planData?.currency)?.amount + lan + content
    }
    if(!load) SetGetData(true)
    SetTotal(tot)
  }, [data]);

  // const handleSubmit = async event => {
  //   event.preventDefault();
  //   const { stripe, elements } = props;
  //   if (!stripe || !elements) {
  //     return;
  //   }
  //   const card = elements.getElement(CardNumberElement);
  //   const result = await stripe.createToken(card);
  //   if (result.error) {
  //     toast.error(`${result.error.message}`);
  //   } else {
  //     handleValidSubmit(result.token.id);
  //     SetGetData(true);
  //   }
  // };

  const payment = async (res) =>{
    let {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: 'if_required'
    })

    if(paymentIntent && paymentIntent?.status === 'succeeded') {
      const resData = res.data[0];
      orderSrv.updatePayment({order_id: resData.id, payment_status: 1}).then(res=>{
        if (res?.status === 200) {
          props.history.push(`/success`,{project_id: resData.id});
        } else {
          props.history.push(`/fail`);
        }
      })
    } else if(error?.type === "card_error" || error?.type === "validation_error" ){
      SetGetData(false)
      toast.error(error?.type)
      return
    }
  }
  const handleValidSubmit = async (e) => {
    e.preventDefault();
    let requestData = {
      plan: formData.stripe_id,
      business_name: history?.state?.state?.from === 'content' ? formData?.selectedTemplate?.business_name : formData?.selectedTemplate?.name?.en,
      has_domain: formData.has_domain === 1,
      subscription_plan_id: +formData.planData?.id,
      client: formData.client_id,
      size_id: formData.size?.value,
      content_included: formData.planData.has_content,
      lang_count: +formData.langNumber
    };
    if(formData.template) requestData['template_id'] = formData.template
    if(formData.custom_template) requestData['custom_template'] = formData.custom_template

    if(from === 'content'){
      let data = {
        business_name: formData?.selectedTemplate?.business_name,
        order_id: formData?.selectedTemplate?.id,
        subscription_plan_id: +formData.planData?.id,
        content_included: 1
      }
      SetGetData(true)
      let {error, paymentIntent} = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: 'if_required'
      })

      if(paymentIntent && paymentIntent?.status === 'succeeded') {
        orderSrv.updateOrder(formData?.selectedTemplate?.id, data)?.then(res=>{
          if(res?.status === 200){
            toast.success(res.data.success)
            props.history.push(`/client/project/${formData?.selectedTemplate?.id}`)
            return
          }
          SetGetData(false)
        }).catch(()=> SetGetData(false))
      } else if(error?.type === "card_error" || error?.type === "validation_error" ){
        toast.error(error?.type)
        SetGetData(false)
        return
      }
    } else{
      SetGetData(true)
      orderSrv.create(requestData).then((res) => {
        if (res?.status === 201) {
          payment(res) 
          return
        }
        SetGetData(false)
      }).catch(()=> SetGetData(false))
    }
  };

  const paymentElementOptions = {
    clientSecret: props?.clientSecret,
    // payment_method_types: ['card']
  }

  return (<div className="container-fluid pt-2">
    <form onSubmit={handleValidSubmit} id="payment-form">
      <div className="row">
        {/* <CardSection/> */}
        <Col md={8}>
          <div className="d-flex align-items-start gap-3">
            <img src={visa} className="img-fluid mt-1" />
            <div>
              <h6 className="fw-bold">{t("Payment Information")}</h6>
              <p className="text-muted font-14">{t("Complete your purchase today to get your website up and running.")}</p>
            </div>
          </div>
          <div>
            <LinkAuthenticationElement />
            <PaymentElement
             options={paymentElementOptions} 
             className='paymentElement'
             onReady={()=> {
              setLoad(true)
              SetGetData(false)
             }}
             />
          </div>
          <div className="mt-4 d-flex justify-content-between">
          <span>
            <img src={stripeIcon} width='20' className='me-1' alt='stripeIcon' />
            Powered by Stripe
          </span>
          <div >
            <a href="https://stripe.com/legal/consumer" target="blank" className="mx-3">Terms</a>
            <a href="https://stripe.com/privacy" target="blank">Privacy</a>
          </div>
        </div>
        </Col>
        <Col md={4}>
          <div className="card boxShadow mb-4 bmt-3">
            <div className="card-body px-3">
              <div className="d-flex align-items-center justify-content-between">
                  <h3 className="fw-bold m-0">{data?.planData?.name[`${i18n.language}`]}</h3>
                  <p className="px-3 m-0" style={{background: '#20D577', borderRadius:'18px'}}>{data?.planData?.pages_num?.name[`${i18n.language}`]}</p>
              </div>
              <p>Make websites great again</p>
              <hr/>

              
              {from!=='content' && <div className="d-flex justify-content-between mb-4">
                <div >
                  <p className="my-0 text-muted font-size-14">{t("Item")}</p>
                  <p className="my-0 fw-bold-600 font-size-13">{data.planData?.name[`${i18n.language}`]} (1 website)</p>
                  
                </div>
                <div>
                  <p className="my-0 text-muted font-size-14">{t("Price")}</p>
                  <p className="my-0 fw-bold-600 font-size-13">{data?.planData?.price?.find(res=> res.currency === data.planData?.currency)?.amount} {data?.planData?.currency}</p>
                </div>
              </div>}

              {(data?.planData?.has_second_lang || data?.planData?.has_content || from==='content') && <div className="d-flex justify-content-between mb-4">
                <div >
                  <p className="my-0 text-muted font-size-14">{t("Extras")}</p>
                  {data?.planData?.has_second_lang && <p className="my-0 fw-bold-600 font-size-13">{t("Language")}</p>}
                  {(data?.planData?.has_content || from==='content') && <p className="my-0 fw-bold-600 font-size-13">{t("Content")} ({t("up to ")} {data?.planData?.words_num})</p>}
                </div>
                <div>
                  <p className="my-0 text-muted font-size-14">{t("Price")}</p>
                  {data?.planData?.has_second_lang && <p className="my-0 fw-bold-600 font-size-13">{data?.planData?.second_lang_price?.find(res=> res.currency === data.planData?.currency)?.amount} {data?.planData?.currency}</p>}
                  {(data?.planData?.has_content || from==='content') && <p className="my-0 fw-bold-600 font-size-13">{from==='content' ? total : data?.planData?.content_price?.find(res=> res.currency === data.planData?.currency)?.amount} {data?.planData?.currency}</p>}
                </div>
              </div>}

              {/* <div className="d-flex justify-content-between mt-2">
                <div>
                  <p className="my-0 text-muted font-size-14">{t("Number Of languages")}</p>
                  <p className="my-0 fw-bold-600 font-size-13">{data?.langNumber}</p>
                </div>
                <div>
                  <p className="my-0 text-muted font-size-14">{t("Price")}</p>
                  <p
                    className="my-0 fw-bold-600 font-size-13">{data?.langNumber == "2" ? data.planData?.second_lang_price?.amount : data?.langNumber == "1" ? data?.planData?.first_lang_price.amount : data?.planData?.price?.amount}</p>
                </div>
              </div> */}
              {/* <div className="d-flex justify-content-between mt-2">
                <div>
                  <p className="my-0 text-muted font-size-14">Extra</p>
                  <p className="my-0 fw-bold-600 font-size-13">taxes</p>
                </div>
                <div>
                  <p className="my-0 text-muted font-size-14">price</p>
                  <p className="my-0 fw-bold-600 font-size-13">120</p>
                </div>
              </div> */}
              {/* <div className="d-flex justify-content-between mt-2 me-1">
                <div>
                  <p className="my-0 fw-bold-600 font-size-13">Total</p>
                </div>
                <div>
                <p className="my-0 fw-bold-600 font-size-13">{data?.langNumber=="2" ?  data.planData?.second_lang_price?.amount :data?.langNumber=="1" ? data?.planData?.first_lang_price.amount :"0" }</p>
                </div>
              </div> */}
              <hr/>
              <div className="d-flex justify-content-between my-2">
                <div>
                  <p className="my-0 text-muted fw-bold-600 font-size-18">{t("Total")}</p>
                </div>
                <div>
                  <p
                    className="my-0 fw-bold-600 font-size-13">{total} {data.planData?.currency}</p>
                </div>
              </div>
              <button type="submit" form="payment-form" disabled={getData}
                      className="btn btn-primary w-100 text-start px-3 btnHeightLg mt-3">
                {t("Complete purchase")}
                {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i> :
                  <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
              </button>
              {getData && <div style={{zIndex:'9999'}} className="loaderStyle">
                <Circles color="#00BFFF"/>
              </div>}
            </div>
          </div>
          <img src={paymentImage} className="mx-auto d-block img-fluid"/>
        </Col>

      </div>
    </form>
  </div>);
};

CheckoutForm.propTypes = {
  stripe: PropTypes.any, 
  elements: PropTypes.any, 
  data: PropTypes.any, 
  history: PropTypes.any,
  clientSecret: PropTypes.any,
};

export default CheckoutForm;

