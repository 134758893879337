import React, { useState } from "react";
import PropTypes from "prop-types";
import docImage from "assets/images/load.gif";
import globe from "assets/images/globe.png";
import { SuccessCreated } from "../../../../configs/statusCode";
import { toast } from "react-toastify";
import OrderService from "../../../../services/Admin/OrderService";

const FourthStepData = ({ projectData, fourthStepData, updateProjectData }) => {
  const [repositories, setRepositories] = useState({
    frontend: '',
    backend: ''
  })
  const [fourthData, setFourthData] = useState({...fourthStepData})
  const [formState, setFormState] = useState({
    domain: '',
    user_name: '',
    link:'',
    password: ''
  })

  const orderService = new OrderService();
  // const publish = async () => {
  //   const res = await orderService.update(projectData.id, { step_confirmed: "fourth_step_status" });
  //   if (res.status === SuccessOk) {
  //     updateProjectData({ fourth_step_data_status: 1 });
  //     toast.success("Project published successfully");
  //   } else {
  //     toast.error("Whoops something went wrong");
  //   }
  // };

  const sendProject = async (e) => {
    e.preventDefault()
    let data = {
      order_id: projectData.id,
      repositories: repositories,
      fourth_step_status: 1
    }
    const res = await orderService.update(data);
    if (res.status === SuccessCreated) {
      updateProjectData({ fourth_step_data_status: 1});
      setFourthData({...fourthData, repositories: repositories})
      toast.success("Project Send Successfully");
    } else {
      toast.error("Whoops something went wrong");
    }
  };

  const sendDomain = async () => {
    let data = {
      order_id: projectData.id,
      server_info: {
        link: formState.link,
        user_name: formState.user_name,
        password: formState.password
      },
      fourth_step_status: 1
    }
    const res = await orderService.update(data);
    if (res.status === SuccessCreated) {
      updateProjectData({ fourth_step_data_status: 1});
      setFourthData({...fourthData, ...data})
      toast.success("Project Published Successfully");
    } else {
      toast.error("Whoops something went wrong");
    }
  };

  const sendDomain2 = async (e) => {
    e.preventDefault()
    let data = {
      order_id: projectData.id,
      has_server: 1,
      domain: formState.domain,
      fourth_step_status: 1
    }
    const res = await orderService.update(data);
    if (res.status === SuccessCreated) {
      updateProjectData({ fourth_step_data_status: 1});
      setFourthData({...fourthData, ...data})
      toast.success("Project Published Successfully");
    } else {
      toast.error("Whoops something went wrong");
    }
  };

  return (
    <div>
      {(projectData.fourth_step_data_status === 4 && projectData?.fourth_step_data?.need_repositories === 1 ) ?
        !fourthData?.repositories ? (
        <div>
          <div className="row justify-content-between">
            <div className="col-lg-12">
              <h6 className="fw-bold pb-2">Client Project</h6>
              <form
                onSubmit={sendProject} className="formFeedback"
              >
                <div className="row">
                  {Object.keys(repositories)?.map((item, index)=>{
                    return <div className="col-md-8 mb-3" key={index}>
                      <label className="text-muted">{item}</label>
                      <input
                        type="text"
                        required='true'
                        value={repositories[item]}
                        className="form-control height-42"
                        placeholder={`Enter ${item} Link`}
                        onChange={e=> setRepositories({ ...repositories, [item]: e.target.value })}
                      />
                  </div>
                  })}
                </div>
              
              <button
                type="submit"
                className="btn btn-primary height-45 px-4 mt-3"
              >
                Send
              </button>
            </form>
            </div>
            {/* <div className="col-lg-12">
              <button
                className="btn btn-primary px-5 height-45"
                onClick={() => publish()}
              >
                Sendsss
              </button>
            </div> */}
          </div>
        </div>
      ) : (
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <div>
              <h6 className="pb-3 fw-bold">Your website Links</h6>
              <div className="row">
              {Object.keys(fourthData.repositories)?.map((item, index)=>{
                    return <div className="col-lg-12 col-md-12" key={index}>
                      <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <span className="me-2">{item}: </span>
                        <a
                        href={fourthData.repositories[item]}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthData.repositories[item]}
                        </a>
                        <a
                        href={fourthData.repositories[item]}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                        </a>
                    </div>
                </div>
              })}
              </div>
          </div>
        </div>
      ): ""}

      {(projectData.fourth_step_data_status === 4 && !!projectData?.fourth_step_data?.need_server) && (<>
        {!fourthData?.server_info ? <div className="row pt-2">
        <div className="col-md-8 col-lg-8 mb-3">
          <label className="text-muted pb-2 font-size-14">Domain</label>
          <input
            required
            type="text"
            value={fourthData?.domain}
            disabled
            className="form-control height-50"
            placeholder="Domain"
            // onChange={e=> setFormState({...formState, domain: e.target.value})}
          />
        </div>
        <div className="col-md-8 col-lg-8 mb-2">
          <span className="fw-bold font-size=14">Server Information</span>
        </div>
        <div className="col-md-8 col-lg-8 mb-2">
          <label className="text-muted pb-2 font-size-14">Link</label>
          <input
            value={formState?.link}
            required
            type="text"
            className="form-control height-50"
            placeholder="Link"
            onChange={e=> setFormState({...formState, link: e.target.value})}
          />
        </div>
        <div className="col-md-8 col-lg-8 mb-2">
          <label className="text-muted pb-2 font-size-14">User Name</label>
          <input
            value={formState?.user_name}
            required
            type="text"
            className="form-control height-50"
            placeholder="User Name"
            onChange={e=> setFormState({...formState, user_name: e.target.value})}
          />
        </div>
        <div className="col-md-8 col-lg-8">
          <label className="text-muted pb-2 font-size-14">Password</label>
          <input
            value={formState?.password}
            required
            type="text"
            className="form-control height-50"
            placeholder="Password"
            onChange={e=> setFormState({...formState, password: e.target.value})}
          />
        </div>
        <div className="col-md-12 col-lg-12 pt-lg-4 mt-2">
          <button
            type="button"
            onClick={sendDomain}
            className="btn btn-primary me-2 height-50"
          >
            Save
          </button>
        </div>
      </div> : (
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <div>
              <h6 className="pb-3 fw-bold">Website Domain</h6>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                      <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <a
                        href={fourthData.domain}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthData.domain}
                        </a>
                        <a
                        href={fourthData.domain}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                        </a>
                    </div>
                </div>
              </div>
          </div>
          <div>
              <h6 className="pb-3 fw-bold">Server Information</h6>
              <div className="row">
              <div className="col-md-8 col-lg-8 mb-2">
                <label className="text-muted pb-2 font-size-14">Link</label>
                <input
                  value={fourthData?.server_info?.link}
                  type="text"
                  disabled
                  className="form-control height-50"
                />
              </div>
              <div className="col-md-8 col-lg-8 mb-2">
                <label className="text-muted pb-2 font-size-14">User Name</label>
                <input
                  value={fourthData?.server_info?.user_name}
                  type="text"
                  disabled
                  className="form-control height-50"
                />
              </div>
                <div className="col-md-8 col-lg-8">
                  <label className="text-muted pb-2 font-size-14">Password</label>
                  <input
                    value={fourthData?.server_info?.password}
                    disabled
                    type="text"
                    className="form-control height-50"
                  />
                </div>
              </div>
          </div>
        </div>
      )}
      </>)}

      {(projectData.fourth_step_data_status === 4 && !!projectData?.fourth_step_data?.server_info) && 
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <div>
              <h6 className="pb-3 fw-bold">Server Information</h6>
              <div className="row">
              <div className="col-md-8 col-lg-8 mb-2">
                <label className="text-muted pb-2 font-size-14">Link</label>
                <input
                  value={fourthData?.server_info?.link}
                  type="text"
                  disabled
                  className="form-control height-50"
                />
              </div>
              <div className="col-md-8 col-lg-8 mb-2">
                <label className="text-muted pb-2 font-size-14">User Name</label>
                <input
                  value={fourthData?.server_info?.user_name}
                  type="text"
                  disabled
                  className="form-control height-50"
                />
              </div>
                <div className="col-md-8 col-lg-8">
                  <label className="text-muted pb-2 font-size-14">Password</label>
                  <input
                    value={fourthData?.server_info?.password}
                    disabled
                    type="text"
                    className="form-control height-50"
                  />
                </div>
              </div>
          </div>
          <h6 className="pt-3 fw-bold">Website Domain</h6>
          {!fourthData.domain ? <form onSubmit={sendDomain2}>
              <div className="row">
                <div className="col-md-8 col-lg-8 mb-2">
                  <label className="text-muted pb-2 font-size-14">Domain</label>
                  <input
                    value={formState?.domain}
                    type="text"
                    required

                    placeholder="Domain"
                    className="form-control height-50"
                    onChange={e=> setFormState({...formState, domain: e.target.value})}
                  />
                </div>
                <div className="col-md-12 col-lg-12 pt-lg-4 mt-2">
                  <button
                    type="submit"
                    className="btn btn-primary me-2 height-50"
                  >
                    Save
                  </button>
                </div>
              </div>
          </form> : <div className="col-lg-10 mt-2 col-md-9">
                    <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <a
                        href={fourthData.domain}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthData.domain}
                        </a>
                        <a
                        href={fourthData.domain}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                        </a>
                    </div>
                </div>}
        </div>
      }

      {projectData.fourth_step_data_status === 0 && (
        <div>
          <div className="boxShadow pt-4 px-3 mb-2 pb-3">
            <div className="row justify-content-between">
              <div className="col-lg-7 ">
                <h6 className="fw-bold mb-0">
                  <img
                    src={docImage}
                    alt=""
                    width={50}
                    height={40}
                  />
                  <span className="fw-bold ps-1 text-primary font-size=14">
                    Waiting for client domain{" "}
                  </span>
                </h6>
                <p className="text-muted font-14 mb-2 ps-5 ms-2">
                  Fill information about your business and goals for this
                  website, this data can vary and time can be from 30 mins to 4
                  hours
                </p>
                <p className="fw-bold font-size-14 ps-5 ms-2">Check back soon</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {projectData.fourth_step_data_status === 1 && (
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
            <div>
              <h6 className="pb-3 fw-bold">Your website is published and live on this domain</h6>
              <div className="row">
                  <div className="col-lg-10 col-md-9">
                      <div className="bg-light p-3 rounded-3 mb-md-3">
                          <img src={globe} className="me-2" />
                          <a
                          href={fourthStepData.domain}
                          target="_blank"
                          className="text-black fw-bold text-decoration-none"
                          rel="noreferrer"
                          >
                          {fourthStepData.domain}
                          </a>
                          <a
                          href={fourthStepData.domain}
                          target="_blank"
                          className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                          rel="noreferrer"
                          >
                          <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                          </a>
                      </div>
                  </div>
                  <div className="col-lg-2 col-md-3">
                      <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>Published</p>
                  </div>
              </div>
            </div>
        </div>
      )}


      {/* {projectData.fourth_step_data_status === 44 ? (
        <div>
          <div className="row justify-content-between">
            <div className="col-lg-12">
              <h6 className="fw-bold pb-2">Client Domains</h6>
              {fourthStepData.is_purchased === 0 ? (
                <div>
                  <div className="bg-light p-3 rounded-3 mb-3">
                    <img src={globe} className="me-2" />
                    <a
                      href={fourthStepData.ns1}
                      target="_blank"
                      className="text-black fw-bold text-decoration-none"
                      rel="noreferrer"
                    >
                      {fourthStepData.ns1}
                    </a>
                  </div>
                  <div className="bg-light p-3 rounded-3 mb-3">
                    <img src={globe} className="me-2" />
                    <a
                      href={fourthStepData.ns2}
                      target="_blank"
                      className="text-black fw-bold text-decoration-none"
                      rel="noreferrer"
                    >
                      {fourthStepData.ns2}
                    </a>
                  </div>
                </div>
              ) : (
                <div className="bg-light p-3 rounded-3 mb-3">
                  <img src={globe} className="me-2" />
                  <a
                    href={fourthStepData.domain}
                    target="_blank"
                    className="text-black fw-bold text-decoration-none"
                    rel="noreferrer"
                  >
                    {fourthStepData.domain}
                  </a>
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <button
                className="btn btn-primary px-5 height-45"
                onClick={() => publish()}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      ) : projectData.fourth_step_data_status === 1 ? (
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          {fourthStepData?.is_purchased === 0 ? (
            <div>
              <h6 className="pb-3 fw-bold">Your website is published and live on this domain</h6>
              <div className="row">
                <div className="col-lg-10 col-md-9">
                    <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <a
                        href={fourthStepData.ns1}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthStepData.ns1}
                        </a>
                        <a
                        href={fourthStepData.ns1}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                        </a>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3">
                    <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>Published</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 col-md-9">
                    <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <a
                        href={fourthStepData.ns2}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthStepData.ns2}
                        </a>
                        <a
                        href={fourthStepData.ns2}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                        </a>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3">
                    <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>Published</p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h6 className="pb-3 fw-bold">Your website is published and live on this domain</h6>
              <div className="row">
                  <div className="col-lg-10 col-md-9">
                      <div className="bg-light p-3 rounded-3 mb-md-3">
                          <img src={globe} className="me-2" />
                          <a
                          href={fourthStepData.domain}
                          target="_blank"
                          className="text-black fw-bold text-decoration-none"
                          rel="noreferrer"
                          >
                          {fourthStepData.domain}
                          </a>
                          <a
                          href={fourthStepData.domain}
                          target="_blank"
                          className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                          rel="noreferrer"
                          >
                          <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                          </a>
                      </div>
                  </div>
                  <div className="col-lg-2 col-md-3">
                      <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>Published</p>
                  </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="boxShadow pt-4 px-3 mb-2 pb-3">
            <div className="row justify-content-between">
              <div className="col-lg-7 ">
                <h6 className="fw-bold mb-0">
                  <img
                    src={docImage}
                    className="pe-2"
                    alt=""
                    width={30}
                    height={30}
                  />
                  <span className="fw-bold ps-3 text-primary font-size=14">
                    Waiting for client domain{" "}
                  </span>
                </h6>
                <p className="text-muted font-14 mb-2 ps-5 ">
                  Fill information about your business and goals for this
                  website, this data can vary and time can be from 30 mins to 4
                  hours
                </p>
                <p className="fw-bold font-size-14 ps-5">Check back soon</p>
              </div>
            </div>
          </div>
        </div>
      )
      } */}
    </div>
  );
};
FourthStepData.propTypes = {
  projectData: PropTypes.object, fourthStepData: PropTypes.object, updateProjectData: PropTypes.func
};
export default FourthStepData;