import React, { useEffect, useState, useRef } from "react";
import {
  FileMetaData,
  FilePreviewContainer,
  FileUploadContainer,
  FormField,
  ImagePreview,
  InputLabel,
  PreviewContainer,
  PreviewList,
  RemoveFileIcon,
  ShowContent,
  UploadFileBtn,
} from "./file-upload.styles";
import BaseService from "services/BaseService";
import pdf from "assets/images/pdf.png";
import { withTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from 'prop-types';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;

const FileUpload = ({
  label,
  attachmentType,
  attachmentData,
  updatedAttachment,
  deleteAttachment,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  setUploadState = () => { },
  ...otherProps
}) => {
  const cancelTokenSource = useRef();
   const fileInputField = useRef(null);
  const [percent, setPercent] = useState(0)
  const [files, setFiles] = useState([]);
  const [lastAddedFiles, setLastAddedFiles] = useState([]);
  const acceptFileTypes = ["pdf", "doc", "docx"];
  const acceptImageTypes = ["jpg", "jpeg", "png", "mp4", "PNG", "JPG", "JPEG"];

  useEffect(() => {
    let uploadState = percent === 0 ? false : percent === 100 ? false : true
    setUploadState(uploadState)
  }, [percent])
  useEffect(() => {
    //if(attachmentData){
    setFiles(attachmentData);
    //}
    // return () => {
    //   cancelTokenSource ?.current ?.cancel()
    // }
  }, [attachmentData]);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    let files = [];
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return [file];
        }
        files.push(file);
      }
    }
    return files; // [...files]
  };

  const checkType = (src) => {
     const dotIndex = src.lastIndexOf(".")
    const fileType = src.substring(dotIndex + 1, src.length)
    const isFile = acceptFileTypes.findIndex((type) => type === fileType) > -1;
    return isFile

  }
  const handleNewFileUpload = async (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      //TODO enhance if 50 file will send 50 ajax
      let createdFiles = [];
      cancelTokenSource.current = axios.CancelToken.source();
      const options = {
        cancelToken: cancelTokenSource.current.token,
        ignoreInterceptors: true,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percentValue = (loaded * 100) / total
          setPercent(percent => {
            const value = (percentValue - percent) / updatedFiles.length
            const updatedPercentage = value + percent
            if (percent > updatedPercentage) {
              return percent
            } else {
              return updatedPercentage
            }
          })
        }
      }
      Promise.all(
        updatedFiles.map(async (file) => {
          const dotIndex = file.name.lastIndexOf(".")
          const fileType = file.name.substring(dotIndex + 1, file.name.length)
          const isFile = acceptFileTypes.findIndex((type) => type === fileType) > -1;
          const isImage = acceptImageTypes.findIndex((type) => type === fileType) > -1;
          let res;
          if (isImage) {
            res = await new BaseService().postUpload(file, options);
          }
          if (isFile) {
            res = await new BaseService().postUploadOther(file, options);
          }
          const createdFile = res ?.data ?.data;
          createdFiles.push(createdFile);
        }),
      ).then(() => {
        if (!otherProps.multiple) {
          setFiles(createdFiles);
        } else {
          setFiles([...files, ...createdFiles]);
        }
        setPercent(0)
        setLastAddedFiles(createdFiles);
      }).catch(() => {
        setPercent(0)
      })
    }
  };

  useEffect(() => {
    if (lastAddedFiles.length) {
      // updatedAttachment([...files], attachmentType)
      updatedAttachment(lastAddedFiles, attachmentType);
    }
  }, [lastAddedFiles]);

  const removeFile = async (id) => {
    await setFiles(() => []);
    const updatedFiles = files.filter((f) => f.id !== id);
    setFiles(() => [...updatedFiles]);
    // updatedAttachment([...updatedFiles], attachmentType)
    deleteAttachment(
      files.filter((f) => f.id === id),
      attachmentType,
    );
  };

  return (
    <>
      <FileUploadContainer style={{borderColor: otherProps?.className?.includes('border-danger') ? '#DC3545' : '' }}>
        <InputLabel>{label}</InputLabel>
        <UploadFileBtn type='button' onClick={handleUploadBtnClick}>
          <i className='fa fa-file' />
          <span>{`Upload ${otherProps.multiple ? "files" : "a file"}`}</span>
        </UploadFileBtn>
        <FormField
          type='file'
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=''
          value=''
          {...otherProps}
        />
      </FileUploadContainer>
      {percent > 0 &&
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped"
            role="progressbar"
            style={{ width: percent + '%' }}
            aria-valuenow={`${percent}`}
            aria-valuemin="0"
            aria-valuemax="100"
          >
          </div>
        </div>
      }
      <FilePreviewContainer>
        <PreviewList>
          {
            files ?.map((file, index) => {
              if (file ?.path) {
                return (
                  <PreviewContainer key={index}>
                    <div>
                      {file.path.substring(file.path.length - 3, file.path.length) === "mp4" ?
                        (
                          <video
                            style={{
                              display: "block",
                              width: "100%",
                              marginBottom: "5px",
                              height: "100px",
                            }}
                            controls>
                            <source src={file.path} type='video/mp4' />
                          </video>
                        ) : checkType(file ?.path) ?
                          <ImagePreview
                            src={pdf}
                            alt={`file preview ${index}`}
                          />
                          : <ImagePreview
                            src={file.path}
                            alt={`file preview ${index}`}
                          />
                      }

                      <FileMetaData>
                        {/* <span>{file.name}</span> */}
                        <aside>
                          {/* <span>{convertBytesToKB(file.size)} kb</span> */}
                          <RemoveFileIcon
                            className='fa fa-trash-o'
                            onClick={() => removeFile(file.id)}
                          />
                          <ShowContent
                            href={file.path}
                            target='_blank'
                            rel='noopener noreferrer'>
                            <RemoveFileIcon className='fa fa-eye' />
                          </ShowContent>
                        </aside>
                      </FileMetaData>
                    </div>
                  </PreviewContainer>
                );
              }
            })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

FileUpload.propTypes = {
  label:PropTypes.string,
  attachmentType:PropTypes.string,
  attachmentData: PropTypes.object,
  updatedAttachment: PropTypes.func,
  deleteAttachment:PropTypes.func,
  maxFileSizeInBytes: PropTypes.number,
  setUploadState: PropTypes.func,
 };

export default withTranslation()(FileUpload);
