import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appendFilters } from "store/Auth/actions";
import CategoryService from "services/Admin/CategoryService";
import { SuccessOk } from "configs/statusCode";

const filtersArray = ["industry", "size", "type"];

const Authmiddleware = ({
    component: Component,
    layout: Layout,
    routePrefix: routePrefix,
    isAuthProtected,
    panel,
    ...rest
}) => {
     return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthProtected && !localStorage.getItem("token")) {
                    if (panel === "user")
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                }}
                            />
                        );
                    else if (panel === "admin")
                        return (
                            <Redirect
                                to={{ pathname: "/admin/login", state: { from: props.location } }}
                            />
                        );
                    else if (panel === "client")
                        return (
                            <Redirect
                                to={{ pathname: "/login", state: { from: props.location } }}
                            />
                        );
                } else if (isAuthProtected && localStorage.getItem("token")) {

                    if (panel === "user" && !localStorage.getItem("user_type") === "user")
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                }}
                            />
                        );
                    else if (panel === "admin" && !localStorage.getItem("user_type") === "admin")
                        return (
                            <Redirect
                                to={{ pathname: "/admin/login", state: { from: props.location } }}
                            />
                        );
                    else {
                        return (
                            <Layout routePrefix={routePrefix}>
                                <Wrapper  {...props} ><Component {...props} /></Wrapper>
                            </Layout>
                        );
                    }
                } else {
                    return (
                        <Layout routePrefix={routePrefix}>
                            <Wrapper  {...props} ><Component {...props} /></Wrapper>
                        </Layout>
                    );
                }
            }}
        />
    )
}
const Wrapper = (props) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const state = useSelector(state => state.Auth)
    const categoryService = new CategoryService();

    useEffect(() => {
        let industryOptions, sizeOptions, typeOptions;
        if (state.industryOptions.length === 0 &&
            (props.location.pathname === "/create-website" ||
            props.location.pathname === "/templates"||
            props.location.pathname === "/plans" ||
            props.location.pathname === "/checkout")) {
            filtersArray.map(type => {
                categoryService
                    .getDropDown("categoryValues", { type })
                    .then((res) => {
                        if (res.status === SuccessOk) {
                            const options = res.data.data.map((item) => ({
                                label: i18n.language === "en" ? item.name.en : item.name.ar, value: item.id
                            }));
                            if (type === "industry") {
                                industryOptions = [...options];
                            } else if (type === "size") {
                                sizeOptions = [...options];
                            } else {
                                typeOptions = [...options];
                            }
                            dispatch(appendFilters({
                                industryOptions, sizeOptions, typeOptions
                            }));
                        }
                    });
            });
        }
    }, [i18n.language]);
     return <>{props.children}</>
}

Wrapper.propTypes = {
    children: PropTypes.any,
    location: PropTypes.any
};
Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
    routePrefix: PropTypes.string,
    panel: PropTypes.any
};

export default Authmiddleware;
