import React from "react";
import "./styles.scss";
import Navbar from "components/Website/Layouts/Header";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import GeneralFooter from "components/Website/Layouts/Footer/GeneralFooter";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="websitPages mt-5 pt-5">
        <div className="pt-4">
          <div className="container">
            <h3 className="fw-bold headersColor pb-2">
              {t("Privacy Policy")}
            </h3>
            <p className="font-18 subtitles fw-medium">
              {t(
                "We at Webkicks understand how important privacy is to our customers. Your trust is important to us, and we are committed to handling your information responsibly and transparently. This Privacy Policy explains what data we collect from visitors to our website and customers who purchase our services, how we use and protect this information, and your rights and choices with respect to your personal data. Our aim is to provide full transparency around our privacy and data handling practices, so you can feel confident sharing your information with us."
              )}{" "}
              <br />
            </p>

            <p className="muteSubtitle font-18">
              {t(
                "Prior to using any Webkicks services, please read this Privacy Policy carefully to understand how we collect, use, and protect the personal information of our users.If after reviewing our policy you do not feel comfortable with our privacy and data protection practices, you should not create an account or make purchases on our site. We want all our users to have trust and confidence in how we manage their personal data. If you have any other questions after reviewing this policy, please don't hesitate to contact us at support@webkicks.co."
              )}
            </p>
            <h4 className="fw-bold headersColor pt-4 pb-2">{t("Information Collection")}</h4>
            
            <p className="font-18 muteSubtitle">
              We collect information you provide when setting up an account or interacting with our services, like:
            </p>
            <ul>
              <li className="muteSubtitle font-18">Device data (IP address, operating system) and browser type</li>
              <li className="muteSubtitle font-18">Website/page views and items added to cart</li>
              <li className="muteSubtitle font-18">Location data used to customize your experience (e.g. showing local stores)</li>
            </ul>
            <p className="muteSubtitle font-18">We use analytics tools like Google Analytics which also collects data like:</p>
            <ul>
              <li className="muteSubtitle font-18">Browser and device characteristics</li>
              <li className="muteSubtitle font-18">Pages visited on our site</li>
              <li className="muteSubtitle font-18">Referral sites and advertisements clicked</li>
            </ul>
            <p className="muteSubtitle font-18">{('We collect this "Usage Data" to administer our services, analyze site and app usage, and provide a personalized experience. It helps us improve technical performance and prevent fraud.')}</p>
            <p className="muteSubtitle font-18">{("We do not collect any sensitive personal information (e.g. SSN, driver's license, biometric data) without your consent. Our primary goals are to safely fulfill orders and provide excellent customer support.")}</p>
            <p className="muteSubtitle font-18">{('We obtain limited information about your customers when they purchase products or services from Webkicks on your behalf. We call this information "Customer Data." Customer Data is collected when customers transact with you through Webkicks and may include:')}</p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">{("Device and Usage Data. Technical information about a customer's device and how they interact with our services, such as IP address, browser type, and referrer URL")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Identification and Transaction Data. A customer's name, email, phone number, mailing address, payment information, order details, and purchase history associated with transactions made on Webkicks.")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Location Data. A customer's zip code or country when used for location-based services like customized shipping rates or tax calculations.")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Customer Communications. Notes of a customer's comments, questions, or product reviews provided directly to Webkicks customer support or your business.")}</p>
              </li>
            </ul>
            <p className="muteSubtitle font-18">{("This information helps us complete transactions and provide related customer service. It also helps us prevent fraud and fulfill legal/compliance requirements. Webkicks does not access or store any other personal details (e.g. SSN or driver's license) without your customer's consent.")}</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Use of Information")}</h4>
            <p className="muteSubtitle font-18">We use the information we collect primarily to provide our website building, content writing and hosting/publishing services to you. This includes but not limited to:</p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">{("Setting up and managing your website and hosting account")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Providing website design, build, and maintenance services")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Drafting and publishing website content as requested")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Processing and collecting payment for our services")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Fulfilling orders, providing customer support, and communicating with you and responding to your questions and requests")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("We may also use your information to improve our services, fix problems, and display customized advertising and content on our site or on third party sites.")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Operating and improving our site/apps/services")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Conducting analytics and research to enhance the user experience")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Sending marketing communications about Webkicks and partner offers via email (with an opt-out)")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Preventing fraud and enforcing our policies")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Displaying personalized ads and content across devices")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Detecting and preventing security incidents and policy violations")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Complying with applicable laws and responding to valid legal requests")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Enforcing our Terms of Service")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Using aggregated data to display targeted ads")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Sharing data with third parties like Google Analytics to evaluate our marketing (see our Cookies and Tracking Technologies Policy)")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Researching new products and services")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Improving current features and functionality")}</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">{("Contacting you regarding your account or orders")}</p>
              </li>
            </ul>
            <p className="muteSubtitle font-18">We aim to be transparent in how we collect and use customer information. Please contact our privacy team if you have any other questions.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Data Sharing and Disclosure")}</h4>
            <p className="muteSubtitle font-18">We do not sell or rent your personal information to third parties for marketing purposes without your consent. We may share your information we stated in (Information Collection section) with third parties as needed to provide our services to you, such as hosting providers. </p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">Other Users: With other users of our services that you interact with through your use of our services. For example, we may share info with customers when you make payments.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Our Affiliates: With our affiliates like Cash App and Afterpay to understand how users engage with our products and tailor services to preferences.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Service Providers: With service providers who help provide, maintain and improve our services.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Advertising Partners: With partners for interest-based advertising, analytics, contests and promotions. For choices on ads and how we share info, see our privacy policy.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Business Transfers: To a new owner of our services in cases like mergers, acquisitions or sales of all or part of our business.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Safety and Compliance: If needed to comply with law, legal processes, establish or defend legal rights, protect users, our company or the public from harm like fraud, or in an emergency to protect your safety or the safety of others.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">With Consent: With your consent, such as when you allow a third party access or as described when you agree to share.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Anonymized Data: We may share aggregated and anonymized data that does not identify individual users with affiliates, providers or other third parties.</p>
              </li>
            </ul>
            <p className="muteSubtitle font-18">We will not share or sell your personal information to third parties for their own marketing purposes without your consent. Aggregated and anonymized data may be shared with third parties.</p>
            <p className="muteSubtitle font-18">International Transfers: Your personal information may be transferred to, stored, and processed outside of your country of residence where our servers and suppliers operate. It may be accessed by staff operating outside your country in compliance with this privacy policy and applicable laws and regulations.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Data Security")}</h4>
            <p className="muteSubtitle font-18">We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. </p>
            <p className="muteSubtitle font-18">The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Site, you are responsible for keeping this password confidential.</p>
            <p className="muteSubtitle font-18">{('All of our users information, both financial and personal, is protected by an SSH key and is placed behind a front-end firewall. When our customers purchase products or services on our Site, the transaction is encrypted using Secure Sockets Layer (SSL) technology.')}</p>
            <p className="muteSubtitle font-18">We regularly monitor our system for possible vulnerabilities and attacks, and we regularly review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems.</p>
            <p className="muteSubtitle font-18">{('Strict access controls exist on a "need to know" basis only. Only employees, contractors and vendors who need the information to perform a specific job are granted access to personally identifiable information.')}</p>
            <p className="muteSubtitle font-18">Unfortunately, no data transmission over the internet or electronic storage is 100% secure. As a result, while we strive to protect your personal information, we cannot guarantee its absolute security. If a data breach occurs that compromises your personal information, we will notify you promptly so you can take appropriate protective steps.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Data Retention")}</h4>
            <p className="muteSubtitle font-18">We retain personal information we collect from you where we have an ongoing legitimate need to do so, for example:</p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">To provide you with a service you have requested</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">To comply with applicable legal, tax or accounting requirements</p>
              </li>
            </ul>
            <p className="muteSubtitle font-18">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible, then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            <p className="muteSubtitle font-18">We retain personal information collected or received from customers for as long as needed to provide services to the customer and to comply with our legal obligations, resolve disputes and enforce agreements.</p>
            <p className="muteSubtitle font-18">For tax purposes and to comply with generally applicable accounting rules and similar legal requirements, we retain personal information during the periods required by applicable laws. After the conclusion of the applicable retention period, the corresponding data is routinely deleted.</p>
            <p className="muteSubtitle font-18">The criteria used to determine our retention periods include:</p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">The length of time we have an ongoing relationship with our customer and provide the requested services</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Whether there is a legal obligation to which we are subject (e.g. taxation law requiring us to keep records of transactions for a number of years)</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">Whether retention is advisable in light of our legal position (such as in regard of the statute of limitations, litigation or investigations)</p>
              </li>
            </ul>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Cookies and Tracking Technologies")}</h4>
            <p className="muteSubtitle font-18">We and our partners use various technologies to collect and store information when you visit our website, and this may include using cookies or similar tracking technologies.</p>
            <p className="muteSubtitle font-18">Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</p>
            <p className="muteSubtitle font-18">We use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the site.</p>
            <p className="muteSubtitle font-18">We use cookies for a number of purposes, including:</p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">To recognize your computer or device to provide a seamless experience when you return to our website.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">To compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future.</p>
              </li>
              <li>
                <p className="muteSubtitle font-18">To customize content or recommendations based on your interests.</p>
              </li>
            </ul>
            <p className="muteSubtitle font-18">You can refuse cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you select this setting you may be unable to access certain parts of our Site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to our Site.</p>
            <p className="muteSubtitle font-18">You can control cookies through your browser settings. Learn more about managing cookies for </p>
            <ul>
              <li>
                <p className="muteSubtitle font-18">
                  Chrome <a href="https://support.google.com/chrome/answer/95647">Support</a>
                </p>
              </li>
              <li>
                <p className="muteSubtitle font-18">
                  <span>Firefox </span> <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences">Support</a>
                </p>
              </li>
              <li>
                <p className="muteSubtitle font-18">
                  <span> Safari  </span> <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Support</a> 
                  <a href="https://support.apple.com/en-gb/HT201265">Support Apple</a>
                </p>
              </li>
              <li>
                <p className="muteSubtitle font-18">
                  <span> Edge </span> <a href="https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879">Support Edge</a>
                </p>
              </li>
              <li>
                <p className="muteSubtitle font-18">
                  <span> Edge </span> <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Support Edge</a>
                </p>
              </li>
              <li>
                <p className="muteSubtitle font-18">
                  <span> Internet Explorer </span> <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a>
                </p>
              </li>
            </ul>
            <p className="muteSubtitle font-18">{("We also use Google Analytics and other similar third-party analytics services that use cookies and tracking technologies to help us analyze how users use the site. The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to Google's privacy policies.")}</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">{t("Children's Privacy")}</h4>
            <p className="muteSubtitle font-18">Our services are not directed to children under 18. We do not knowingly collect personally identifiable information from children under 18 without parental consent.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">User Rights</h4>
            <p className="muteSubtitle font-18">You can update or correct your information and change your communications preferences by accessing your account or by contacting us via chatting with your account manager or sending email to support@webkicks.co. You can also unsubscribe from our marketing communications by following the unsubscribe instructions in those emails.</p>
            <p className="muteSubtitle font-18">You have the right to access, correct, or delete your personal information. You can request to receive an electronic copy of the personal data we hold about you. To exercise these rights, please contact us at support@webkicks.co.</p>
            <p className="muteSubtitle font-18">If you wish to deactivate your account, email us at support@webkicks.co.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">Third-Party Websites</h4>
            <p className="muteSubtitle font-18">Our website may contain links to other websites. We are not responsible for the privacy practices or content of websites that we do not own or control. We recommend that you check the privacy policies of any third-party websites or services you visit or use before you provide them with personal information.</p>
            <p className="muteSubtitle font-18">Some of our pages utilize framing techniques to serve content from third party websites. These sites may also collect IP addresses and set cookies and those cookies may be used to track your browsing on those sites and on non-affiliated sites.</p>
            <p className="muteSubtitle font-18">We have no access to or control over these cookies or how the third-party sites that set them use your information. This Privacy Policy covers only information collected by our website and does not cover the information practices of third parties that advertise on our site, sponsor promotions, provide content or links, or participate in co-branded services or programs.</p>
            <p className="muteSubtitle font-18">We encourage you to be aware when you leave our website and to read the privacy policies of other websites that may collect your personal information.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">Privacy Policy Updates</h4>
            <p className="muteSubtitle font-18">We may update this Privacy Policy from time to time to reflect changes in our data practices. If we make any material changes, we will notify you by email or through a prominent notice on our website prior to the change becoming effective.</p>
            <p className="muteSubtitle font-18">We encourage you to periodically review this page for the latest information on our privacy practices. If you have an account with us, continued use of our services following notice of any changes to the Privacy Policy will indicate your acknowledgment of such changes.</p>
            <p className="muteSubtitle font-18">If you do not agree to the updated Privacy Policy, you must cancel your account by contacting our customer support team (support@webkicks.co) prior to the changes taking effect. Your continued use of Webkicks services following the effective date means that you accept and agree to the updated Privacy Policy.</p>
            <h4 className="fw-bold headersColor pt-2 pb-2">Contact Information</h4>
            <p className="muteSubtitle font-18">Email: support@webkicks.co</p>
            <p className="muteSubtitle font-18">Webkicks<br/> Sharjah Media City (Shams) </p>
            <p className="muteSubtitle font-18">Business Hours: <br/>Monday - Friday: 11:00 AM - 7:00 PM GST <br/> Saturday - Sunday: Closed</p>
            <p className="muteSubtitle font-18">If you have any concerns about our privacy practices, please contact our team at support@webkicks.co.</p>
            <p className="muteSubtitle font-18">A member of our team will respond to address your questions or resolve any issues you are experiencing. We take privacy seriously and want to ensure we respect your rights and comply with all applicable laws. For security inquiries, please contact us during business hours via email at support@webkicks.co.</p>
            <p className="muteSubtitle font-18">For emergency issues outside of business hours, please leave a detailed message including your name, account information, description of the issue, and contact details. We will respond as soon as possible.</p>
            <p className="muteSubtitle font-18 pb-5">We will use commercially reasonable efforts to respond to all inquiries promptly. However, response times may vary depending on the nature and complexity of the issue.</p>
          </div>
        </div>
      </div>
      <GeneralFooter/>
    </>
  );
};

Privacy.propTypes = {
  direction: PropTypes.any,
  t: PropTypes.any,

};
export default Privacy;
