const SocialMediaOptions = [
    {label: "Facebook", value: "Facebook"},
    {label: "Twitter", value: "Twitter"},
    {label: "Instagram", value: "Instagram"},
    {label: "LinkedIn", value: "LinkedIn"},
    {label: "YouTube", value: "YouTube"},
    {label: "TikTok", value: "TikTok"},
    {label: "Pinterest", value: "Pinterest"},
    {label: "Snapchat", value: "Snapchat"},
    {label: "Reddit", value: "Reddit"},
    {label: "Discord", value: "Discord"},
    {label: "Telegram", value: "Telegram"}
]

export default SocialMediaOptions