import React from "react";
import { Col,Row,Container,Card,CardBody } from "reactstrap";
import "./styles.scss";
import Navbar from "components/Website/Layouts/Header";
import { useTranslation } from 'react-i18next';
import GeneralFooter from "components/Website/Layouts/Footer/GeneralFooter";
import imgstep1 from "assets/images/step1.svg"
import imgstep2 from "assets/images/step2.svg"
import imgstep3 from "assets/images/step3.svg"
import imgstep4 from "assets/images/step4.svg"
import exampleImg from "assets/images/example.svg"
import arrowDown from "assets/images/arrow-down.svg"
import line from "assets/images/connector-line.svg"

const SiteMap = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className={t("websitPages container-fluid ms-0 p-0")}>
        <div className="py-5 mt-4">
          <Container fluid className="pb-4">
            <Row className="justify-content-center">
              <Col xxl="6" lg="8" className="text-center">
                <h3 className="pt-5 fw-bold pb-3 px-lg-4">
                  {t("Welcome to the Webkicks Site Map Guide!")}
                </h3>
                <p className="text-muted pb-2">{t("A site map is your website's roadmap, laying out how all the pages connect together.")}</p>
                <p className="text-muted">{t("A well-organized site map leads to a better visitor experience. This guide will walk you through how to create one.")}</p>
                <h4 className="text-primary pt-4 fw-bold">{t("Steps to Creating a Site Map")}</h4>
              </Col>
            </Row>
            <div className="px-lg-5 pt-4 card-step">
              <Card className="card-identity pb-5">
                <CardBody className="py-3 px-0">
                  <div className="row">
                    <div className="col-lg-1 pt-4">
                      <img src={imgstep1} alt="" />
                    </div>
                    <div className="col-lg-7 pt-4">
                      <div className="px-3 px-lg-0">
                        <h5 className="font-22 fw-medium">Identify Main Pages and Categories</h5>
                        <h6 className="pt-3 font-14 fw-medium">Start by outlining the key sections of your site. Common main pages include:</h6>
                        <div className="row pb-2 pt-4">
                          <div className="col-lg-2 col-md-3 col-4 p-0">
                            <ul className="ps-3">
                              <li className="fw-medium">{t("Homepage")}</li>
                            </ul>
                          </div>
                          <div className="col-lg-2 col-md-3 col-4 p-0">
                            <ul>
                              <li className="fw-medium">{t("About Us")}</li>
                            </ul>
                          </div>
                          <div className="col-lg-2 col-md-3 col-4 p-0">
                            <ul>
                              <li className="fw-medium">{t("Services")}</li>
                            </ul>
                          </div>
                          <div className="col-lg-2 col-md-3 col-4 p-0">
                            <ul>
                              <li className="fw-medium">{t("Products")}</li>
                            </ul>
                          </div>
                          <div className="col-lg-2 col-md-3 col-4 p-0">
                            <ul>
                              <li className="fw-medium">{t("Contact")}</li>
                            </ul>
                          </div>
                          <div className="col-lg-2 col-md-3 col-4 p-0">
                            <ul>
                              <li className="fw-medium">{t("Blog")}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="bg-group p-3 rounded-3 my-4">
                          <p className="font-14">{t("Group related content into categories or sections.")}</p>
                          <p className="mb-1 font-14">{t('For example, separate out all services into a "Services" section.')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="card-example">
                <CardBody>
                  <img src={exampleImg} className="exampleImg mx-lg-3" alt="" />
                  <div className="row justify-content-center pt-5 w-100">
                    <div className="col-lg-10 text-center pt-4">
                      <div className="d-flex justify-content-center">
                        <p className="bgLightSuccess px-4 py-3 text-white rounded-1">Home page</p>
                      </div>
                      <div className="row justify-content-lg-center py-5 mb-5">
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">             
                          <p className="bgLightPrimary mx-2 py-3 text-white rounded-1">About Us</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 text-white rounded-1">Services</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 text-white rounded-1">Products</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 text-white rounded-1">Contact</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 text-white rounded-1">Blog</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="px-lg-5 pt-5 card-step">
              <Card className="card-identity pb-5">
                <CardBody className="py-3 px-0">
                  <div className="row">
                    <div className="col-lg-1 pt-4">
                      <img src={imgstep2} alt="" />
                    </div>
                    <div className="col-lg-9 pt-4">
                      <div className="px-3 px-lg-0">
                        <h5 className="font-22 fw-medium">Determine Important Subpages</h5>
                        <h6 className="pt-3 font-14 fw-medium">Identify supporting pages such as:</h6>
                        <div className="row pb-5 pt-4">
                          <div className="col-md-6">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Individual service pages under "Services"')}</li>
                              <li className="fw-medium pb-4">{t('Contact form, company address pages under "Contact"')}</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Category or product pages under "Products"')}</li>
                              <li className="fw-medium pb-4">{t('FAQ, pricing, resources pages as needed')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="card-example">
                <CardBody>
                  <img src={exampleImg} className="exampleImg mx-lg-3" alt="" />
                  <div className="row justify-content-center w-100">
                    <div className="col-lg-10 text-center pt-4">
                      <div className="row justify-content-lg-center py-5">
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">             
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">About Us</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">About Us</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Our Team</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">FAQ</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Services</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">Service One</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Service Two</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Service Three</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Products</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">Category One</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Category Two</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Category Three</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Contact</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">Contact Form</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Company Info.</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Blog</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="px-lg-5 pt-5 card-step">
              <Card className="card-identity pb-5">
                <CardBody className="py-3 px-0">
                  <div className="row">
                    <div className="col-lg-1 pt-4">
                      <img src={imgstep3} alt="" />
                    </div>
                    <div className="col-lg-9 pt-4">
                      <div className="px-3 px-lg-0">
                        <h5 className="font-22 fw-medium">Organize into a Hierarchy</h5>
                        <h6 className="pt-3 font-14 fw-medium">Structure your site map with a clear hierarchy:</h6>
                        <div className="row pb-5 pt-4">
                          <div className="col-md-6">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Homepage at the top')}</li>
                              <li className="fw-medium pb-4">{t('Main pages/categories branch off homepage')}</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Subpages nest under the relevant section')}</li>
                              <li className="fw-medium pb-4">{t('Maintain a logical flow.')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="card-example">
                <CardBody>
                  <img src={exampleImg} className="exampleImg mx-lg-3" alt="" />
                  <div className="row justify-content-center w-100">
                    <div className="col-lg-10 text-center pt-4">
                      <div className="d-flex justify-content-center">
                        <p className="bgLightSuccess px-4 py-3 text-white rounded-1">Home page</p>
                      </div>
                      <div className="row justify-content-lg-center py-5">
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">             
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">About Us</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">About Us</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Our Team</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">FAQ</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Services</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">Service One</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Service Two</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Service Three</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Products</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">Category One</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Category Two</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Category Three</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Contact</p>
                          <img src={arrowDown} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 mb-1 fw-medium rounded-1">Contact Form</p>
                          <img src={line} className="mx-auto d-block" alt="" />
                          <p className="bgLightSecondary mx-2 py-3 my-1 fw-medium rounded-1">Company Info.</p>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 p-0 mb-4">
                          <p className="bgLightPrimary mx-2 py-3 mb-0 text-white rounded-1">Blog</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="px-lg-5 pt-5 card-step">
              <Card className="card-identity pb-5">
                <CardBody className="py-3 px-0">
                  <div className="row">
                    <div className="col-lg-1 pt-4">
                      <img src={imgstep4} alt="" />
                    </div>
                    <div className="col-lg-10 pt-4">
                      <div className="px-3 px-lg-0">
                        <h5 className="font-22 fw-medium">Organize into a Hierarchy</h5>
                        <h6 className="pt-3 font-14 fw-medium">Structure your site map with a clear hierarchy:</h6>
                        <div className="row pt-4">
                          <div className="col-md-4">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Adding any missing pages')}</li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Removing unnecessary pages')}</li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <ul className="ps-3">
                              <li className="fw-medium pb-4">{t('Adjusting hierarchy as needed')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
        <GeneralFooter/>
      </div>
    </>
  );
};

export default SiteMap;
