import React, { useEffect, useState } from "react";
import TemplateService from "services/Admin/TemplateService";
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Row, Col, CardText
} from "reactstrap";
import DeleteModal from "components/Common/DeleteModal";
import PropTypes from "prop-types";
import "./style.scss";
import { imageOnError } from "../../../../helpers/common";
import { Circles } from "react-loader-spinner";
import { SuccessOk } from "configs/statusCode";
import { toast } from "react-toastify";
import ModalChangeStatus from "components/Common/ModalChangeStatus";

const CardItem = ({ template, index, setModalAdd, setEditItem, setGetData }) => {
  const templateService = new TemplateService();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(()=>{
    setStatus(template.status)
  },[])

  const handleCloseModal = (action) => {
    setDeleteModal(false);
    if (action === "delete") {
      setGetData(true);
    }
  };

  const changeStatus = ()=>{
    setLoader(true)
    setLoading(true)
    templateService.toggleStatus(template.id).then(res=>{
      if (res?.status == SuccessOk) {
        setStatus(!status);
        setLoader(false);
        setLoading(false)
        toast.success("Template Status Updated Successfully");
        setStatusModal(false)
    }
    })
  }

  return (<div className="styleCard">
    <div className="card template-item">
      <Container fluid>
        <Row key={index} className="template-border justify-content-between">
          <Col md={9} lg={8}>
            <div className="d-md-flex py-3">
              <div className="p-3 bg-template me-md-3 rounded-3 overflow-hidden" style={{maxHeight: '190px', minWidth: '200px'}}>
                <img src={template?.media?.path}
                     onError={(e) => imageOnError(e, "imageTemplate")}
                     className="imageTemplate" alt=""/>
              </div>
              <div className="overflow-auto">
                <CardText tag="h6" className="text-capitalize pt-md-2 pt-3 fw-bold">{template.name.en}</CardText>
                <CardText tag="div" className="overflow-auto">
                  <CardText tag="div" className="text-primary keywordsDesign">
                    <div className="pe-2 mb-2 ">
                      <span className="text-muted">Industry: </span>
                      {template?.industry?.map((item, index) => {
                      return (<span className="border-bottom border-primary text-primary me-2"
                                    key={index}>{item.name.en}</span>);
                      })}
                    </div>
                    <div className="pe-2 mb-2">
                    <span className="text-muted">Type: </span>
                      {template?.type?.map((item, index) => {
                      return (<span className="border-bottom border-primary text-primary me-2"
                                    key={index}>{item.name.en}</span>);
                      })}
                    </div>
                  </CardText>
                </CardText>
                <CardText tag="p" className="text-muted mb-2">{template.description.en}</CardText>
                <CardText tag="p" className="text-muted mb-2">
                  Number of sold: {template?.sold_times}
                  {/* {template.description.en} */}
                </CardText>
                {loader ? <div className="">
                  <Circles width={35} height={35} color="#00BFFF"/>
                </div> : <p 
                  style={{width: 'fit-content'}}
                  className={`${status ? 'textSuccess' : 'text-danger'} fw-bold mb-0 cursor-pointer`}
                  onClick={()=> setStatusModal(true)}
                >
                  {status ? 'Active' : 'Deactive'}
                </p>}
              </div>
            </div>
          </Col>
          <Col md={3} lg={2} className="pt-md-4 pt-2 px-2 mb-3">
            <button onClick={(e) => {
              e.stopPropagation();
              window.open(template.url);
            }}
                    className="btn btn-primary d-block m-auto fw-bold font-14 btnWidth text-center height-42 pt-2">View
              Template
            </button>
            <div className="removebtnhover position-relative">
              <Dropdown className="dropdownWidth w-100" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="border-0">
                  <button className="btn btnSearch fw-bold font-14 height-42">More Options</button>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="text-primary" onClick={() => {
                    setEditItem(template);
                    setModalAdd((s) => !s);
                  }}><i className="fa fa-link me-1"></i> Edit/Update theme details</DropdownItem>
                  <DropdownItem className="text-danger" onClick={() => {
                    setDeleteModal(!deleteModal);
                    setDeletedItem(template);
                  }}><i className="fas fa-trash-can me-1"></i> Delete Theme from System</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <DeleteModal
      open={deleteModal}
      titleMsg={deletedItem?.name.en}
      deletedItem={deletedItem}
      modelService={templateService}
      onCloseModal={(action) => handleCloseModal(action)}
    />
    {statusModal && 
      <ModalChangeStatus
        modal={statusModal}
        toggle={()=> setStatusModal(false)}
        loading={loading}
        changeStatus={changeStatus} 
      />}
  </div>);
};
CardItem.propTypes = {
  template: PropTypes.object,
  index: PropTypes.number,
  setModalAdd: PropTypes.func,
  setEditItem: PropTypes.func,
  setGetData: PropTypes.func
};

export default CardItem;
