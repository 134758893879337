import * as yup from "yup";
import {
    ARABIC_PATTERN_VALIDATOR,
    TITLE_PATTERN_VALIDATOR
} from "helpers/validators";
export const AddPlanValidator = yup.object().shape({
    name: yup.object().shape({
        ar: yup.string().matches(
                ARABIC_PATTERN_VALIDATOR.value,
                ARABIC_PATTERN_VALIDATOR.errorMessage
            )
            .required(),
        en: yup
            .string()
            .matches(
                TITLE_PATTERN_VALIDATOR.value,
                TITLE_PATTERN_VALIDATOR.errorMessage
            )
            .required(),
    }),
    words: yup.number().required('Num of Words is required')
        .typeError("Must be a number")
        .positive("Num of Words must be positive")
        .integer("Num of Words must be integer"),
    pages_num: yup.object().required("Number of Pages is required" ),
    description: yup.object().shape({
        ar: yup.string().matches(
            ARABIC_PATTERN_VALIDATOR.value,
            ARABIC_PATTERN_VALIDATOR.errorMessage
        ).required(),
        en: yup.string().required(),
    }),
    // expected_delivery_duration: yup.number()
    //     .required('expected delivery duration is required')
    //     .positive("expected delivery duration must be positive")
    //     .integer("expected delivery duration must be integer"),
    // tag_description: yup.string().required(),
    // content: yup.string().required(),
    features: yup.array().of(
        yup.object().shape({
            feature: yup.string()
                .required('Feature is required'),
        })
    ),
    price: yup.object().shape({
        USD: yup.number().typeError("Must be a number").required("USD is required").positive("USD must be positive"),
        SAR: yup.number().typeError("Must be a number").required("SAR is required").positive("SAR must be positive"),
        AED: yup.number().typeError("Must be a number").required("AED is required").positive("AED must be positive")

    }),
    content_price: yup.object().shape({
        USD: yup.number().typeError("Must be a number").required("USD is required").positive("USD must be positive"),
        SAR: yup.number().typeError("Must be a number").required("SAR is required").positive("SAR must be positive"),
        AED: yup.number().typeError("Must be a number").required("AED is required").positive("AED must be positive")

    }),
    second_lang_price: yup.object().shape({
        USD: yup.number().typeError("Must be a number").required("USD is required").positive("USD must be positive"),
        SAR: yup.number().typeError("Must be a number").required("SAR is required").positive("SAR must be positive"),
        AED: yup.number().typeError("Must be a number").required("AED is required").positive("AED must be positive")

    }),
    deal_with_account_manager: yup.object().shape({
        USD: yup.number().typeError("Must be a number").required("USD is required").positive("USD must be positive"),
        SAR: yup.number().typeError("Must be a number").required("SAR is required").positive("SAR must be positive"),
        AED: yup.number().typeError("Must be a number").required("AED is required").positive("AED must be positive")
    }),
});