import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Label, Row, Col } from "reactstrap";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import Select from "react-select";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PlanService from "services/Admin/PlanService";
import { AddPlanValidator } from "FormValidations/Admin/Plan/Add";
import PropTypes from "prop-types";
import { SuccessCreated, SuccessOk } from "configs/statusCode";
import "./style.scss";
import CategoryService from "services/Admin/CategoryService";
import SuccessModal from "components/Common/SuccessModal";
import arrowImg from "assets/images/arrow.png";
import { Circles } from "react-loader-spinner";

const prices = ["USD", "SAR", "AED"];
const colors = ["#637DFC", "#B2BFFF", "#D1D7F7","#20D577","#A1E3C1","#CAEFDC","#F8CC47","#F6E5AF","#F6EDD2"]
const ModalAdd = ({ modalAdd, toggleAdd, setGetData, editItem, view , viewItem }) => {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    checkbox: true,
    mode: 'onBlur',
    reValidateMode: 'onBlur' ,
    resolver: yupResolver(AddPlanValidator),
    defaultValues: {
      words: "",
      active: true,
      // ecommerce: true ,
      // highlighted: true,
      features: editItem
        ? []
        : [
            {
              feature: "",
            },
          ],
    },
  });

  const planService = new PlanService();
  const [selectedColor, setSelectedColor] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [modalSuccess, setModalSuccess] = useState(false);
  const { fields, append, remove } = useFieldArray({
    name: "features",
    control,
  });

  const categoryService = new CategoryService();
  useEffect(() => {
    categoryService
      .getDropDown("categoryValues", { type: "size" })
      .then((res) => {
        if (res.status === SuccessOk) {
          const options = res.data.data.map((item) => ({
            label: item.name.en,
            value: item.id,
          }));
          setSizeOptions([...options]);
        }
      });
      setSelectedColor(colors[0])
  }, []);
  useEffect(() => {
    if (editItem || viewItem) {
      let item = editItem || viewItem;
      planService.find(item.id).then((res) => {
        setValue("name", res.data.name);
        setValue("description", res.data.description);
        // setValue("expected_delivery_duration", res.data.expected_delivery_duration);
        // setValue("tag_description", res.data.highlighted_tag_description);
        res.data.price.map((item) => setValue(`price.${item.currency}`, item.amount));
        res.data.content_price.map((item) => setValue(`content_price.${item.currency}`, item.amount));
        res.data.second_lang_price.map((item) => setValue(`second_lang_price.${item.currency}`, item.amount));
        setValue("words", Number(res.data.words_num));
        setValue("pages_num", {value:res.data.pages_num.id, label: res.data.pages_num.name.en});
        setValue( "features",
          res.data.features//.map((item) => ({ feature: item }))
        );
        setValue("active", res.data?.activated);
        setSelectedColor(res.data?.color || colors[0])
        res.data?.deal_with_account_manager?.map((item) => setValue(`deal_with_account_manager.${item.currency}`, item.amount));
        // setValue("ecommerce", res.data.e_commerce);
        // setValue("highlighted", res.data.highlighted);
        // setValue("content", res.data.content_feature_description);
      });
    }
  }, []);

  const handleValidSubmit = async (values) => {
    setIsSubmitting(true);
    let requestData = {
      name: values.name,
      description: values.description,
      // highlighted_tag_description: values.tag_description,
      // content_feature_description: values.content,
      // expected_delivery_duration: values.expected_delivery_duration,
      pages_num_id: values.pages_num.value,
      words_num: values.words,
      activated: values.active,
      // e_commerce: values.ecommerce,
      // highlighted: values.highlighted,
      features: values.features,//.map((item) => item.feature),
      price: values.price,
      content_price: values.content_price,
      // first_lang_price: values.first_lang_price,
      second_lang_price: values.second_lang_price,
      deal_with_account_manager: values.deal_with_account_manager,
      color: selectedColor,
      basic_content: {
        USD: (values.content_price.USD + values.price.USD).toFixed(3),
        SAR: (values.content_price.SAR + values.price.SAR).toFixed(3),
        AED: (values.content_price.AED + values.price.AED).toFixed(3),
      },
      basic_content_second: {
        USD: (values.content_price.USD + values.price.USD + values.second_lang_price.USD).toFixed(3),
        SAR: (values.content_price.SAR + values.price.SAR + values.second_lang_price.SAR).toFixed(3),
        AED: (values.content_price.AED + values.price.AED + values.second_lang_price.AED).toFixed(3),
      },
      basic_content_account: {
        USD: (values.content_price.USD + values.price.USD + values.deal_with_account_manager.USD).toFixed(3),
        SAR: (values.content_price.SAR + values.price.SAR + values.deal_with_account_manager.SAR).toFixed(3),
        AED: (values.content_price.AED + values.price.AED + values.deal_with_account_manager.AED).toFixed(3),
      },
      basic_content_second_account: {
        USD: (values.content_price.USD + values.price.USD + values.second_lang_price.USD + values.deal_with_account_manager.USD).toFixed(3),
        SAR: (values.content_price.SAR + values.price.SAR + values.second_lang_price.SAR + values.deal_with_account_manager.SAR).toFixed(3),
        AED: (values.content_price.AED + values.price.AED + values.second_lang_price.AED + values.deal_with_account_manager.AED).toFixed(3),
      },
      basic_second: {
        USD: (values.price.USD + values.second_lang_price.USD).toFixed(3),
        SAR: (values.price.SAR + values.second_lang_price.SAR).toFixed(3),
        AED: (values.price.AED + values.second_lang_price.AED).toFixed(3),
      },
      basic_second_account: {
        USD: (values.price.USD + values.second_lang_price.USD + values.deal_with_account_manager.USD).toFixed(3),
        SAR: (values.price.SAR + values.second_lang_price.SAR + values.deal_with_account_manager.SAR).toFixed(3),
        AED: (values.price.AED + values.second_lang_price.AED + values.deal_with_account_manager.AED).toFixed(3),
      },
      basic_account: {
        USD: (values.price.USD + values.deal_with_account_manager.USD).toFixed(3),
        SAR: (values.price.SAR + values.deal_with_account_manager.SAR).toFixed(3),
        AED: (values.price.AED + values.deal_with_account_manager.AED).toFixed(3),
      },
    };
    try {
      if (editItem?.type === 'edit') {
        const userData = await planService.update(editItem.id, requestData);
        
        if (userData.status == SuccessOk) {
          setModalSuccess(true);
        }
      } else {
        const userData = await planService.create(requestData);
        if (userData.status == SuccessCreated) {
          // toggleAdd()
          // toast.success("Template Created Successfully")
          setModalSuccess(true);
        }
      }
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };
  
  const toggleSuccess = () => {
    setModalSuccess(!modalSuccess);
    setGetData(true);
  };

  return (
    <Modal
      isOpen={modalAdd}
      className="modal-dialog-scrollable container modal-lg"
      toggle={() => toggleAdd()}
    >
      <ModalHeader className="modalHead" toggle={()=> toggleAdd()}>
        <div>
          <h4 className="font-18 fw-bold">
            {/* <i className="fa fa-plus-square-o"></i> */}
            {editItem?.type === 'edit' ? "Update Plan" : "  Add Subscription Plan"}
          </h4>
          <p className="text-muted mb-0 font-14">
            Fill information about your business ang goals for this website
          </p>
        </div>
      </ModalHeader>
      <ModalBody className="border-0 py-4">
        <form
          className="g-3 needs-validation"
          onSubmit={handleSubmit(handleValidSubmit)}
        >
          <Row>
            <Col md="6">
              <div className="pb-3">
                <Label>Subscription Plan Name (en)</Label>
                <input
                  {...register("name.en")}
                  placeholder="Plan Name"
                  className={`form-control height-47 ${
                    errors?.name?.en && "border-danger"
                  }`}
                  type="text"
                  disabled={view}
                />
                {errors?.name?.en && (
                  <ErrorMessage message={errors?.name?.en?.message} />
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="pb-3">
                <Label>Subscription Plan Name (ar)</Label>
                <input
                  {...register("name.ar")}
                  placeholder="Plan Name"
                  className={`form-control height-47 ${
                    errors?.name?.ar && "border-danger"
                  }`}
                  type="text"
                  disabled={view}
                />
                {errors?.name?.ar && (
                  <ErrorMessage message={errors?.name.ar.message} />
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="pb-2">
                <Label>Description (en)</Label>
                <textarea
                  {...register("description.en")}
                  placeholder="Type Description"
                  className={`form-control ${
                    errors?.description?.en && "border-danger"
                  }`}
                  disabled={view}
                  rows="6"
                ></textarea>
                {errors?.description?.en && (
                  <ErrorMessage message={errors?.description?.en?.message} />
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="pb-2">
                <Label>Description (ar)</Label>
                <textarea
                  {...register("description.ar")}
                  placeholder="Type Description"
                  className={`form-control ${
                    errors?.description?.ar && "border-danger"
                  }`}
                  disabled={view}
                  rows="6"
                ></textarea>
                {errors?.description?.ar && (
                  <ErrorMessage message={errors?.description?.ar?.message} />
                )}
              </div>
            </Col>
            {/* <Col md="6">
              <div className="pb-3">
                <Label>Expected Delivery Duration</Label>
                <input
                  {...register("expected_delivery_duration")}
                  placeholder="Delivery duration with days"
                  className={`form-control height-47 ${
                    errors?.expected_delivery_duration && "border-danger"
                  }`}
                  type="text"
                  disabled={view}
                />
                {errors?.expected_delivery_duration && (
                  <ErrorMessage message={errors?.expected_delivery_duration.message} />
                )}
              </div>
            </Col> */}
          </Row>
          <Row>
            {/* <Col md="6">
              <div className="pb-2">
                <Label>Highlighted Tag Description</Label>
                <input
                  {...register("tag_description")}
                  placeholder="Type Description"
                  className={`form-control height-47 ${
                    errors?.tag_description && "border-danger"
                  }`}
                  disabled={view}
                />
                {errors?.tag_description && (
                  <ErrorMessage message={errors?.tag_description?.message} />
                )}
              </div>
            </Col> */}
            <Col md="6">
              <div md={12} className="pb-2">
                <Label>Number of Pages</Label>
                <Controller
                  name="pages_num"
    
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={`heightSelect ${
                        errors?.pages_num && "select-border"
                      }`}
                      options={sizeOptions}
                      isDisabled={view}
                    />
                  )}
                />
                {errors?.pages_num && <ErrorMessage message={errors.pages_num.message} />}
              </div>
            </Col>

            <Col md="6">
              <Label>Color of Card</Label>
              <div className="d-flex mt-1 color-select-div" style={{gap: '8px'}}>
                {colors?.map((color, index)=>{
                  return <p 
                    key={index}
                    style={{background: color}}
                    onClick={()=> setSelectedColor(color)}
                    className={`${selectedColor === color ? 'active' : ''} color-select cursor-pointer mb-0`}
                  >
                  </p>
                })}
              </div>
              
              {errors?.color && <ErrorMessage message={errors.color.message} />}
            </Col>

            <Col>
              <Row>
                <Col md={3} className='m-auto'>
                  <Label>Basic Price</Label>
                </Col>
                {prices.map((item, index) => {
                  return (
                    <Col md={3} key={index} className="mt-2">
                      <div className="pb-2">
                        <Label className="m-0">{`${item}`}</Label>
                        <input
                          {...register(`price.${item}`)}
                          placeholder="50"
                          className={`form-control height-47 ${
                            errors?.price?.[`${item}`] && "border-danger"
                          }`}
                          type="text"
                          disabled={view}
                        />
                      </div>
                      {errors?.price?.[`${item}`] && (
                        <ErrorMessage
                          message={errors?.price?.[`${item}`].message}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col md={3} className='m-auto'>
                  <Label>Content Price</Label>
                </Col>
                {prices.map((item, index) => {
                  return (
                    <Col md={3} key={index} className="mt-2">
                      <div className="pb-2">
                        <Label className="m-0">{`${item}`}</Label>
                        <input
                          {...register(`content_price.${item}`)}
                          placeholder="50"
                          className={`form-control height-47 ${
                            errors?.content_price?.[`${item}`] && "border-danger"
                          }`}
                          type="text"
                          disabled={view}
                        />
                      </div>
                      {errors?.content_price?.[`${item}`] && (
                        <ErrorMessage
                          message={errors?.content_price?.[`${item}`].message}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
              <Row>
                  <Col md={3} className='m-auto'>
                    <Label className='m-0'>Price for 2nd Language</Label>
                  </Col>
                    {prices.map((item, index) => {
                      return (
                        <Col md={3} key={index} className="mt-2">
                          <div className="pb-2">
                            <Label>{item}</Label>
                            <input
                              {...register(`second_lang_price.${item}`)}
                              placeholder="50"
                              className={`form-control height-47 ${
                                errors?.second_lang_price?.[`${item}`] &&
                                "border-danger"
                              }`}
                              type="text"
                              disabled={view}
                            />
                          </div>
                          {errors.second_lang_price?.[`${item}`] && (
                            <ErrorMessage
                              message={
                                errors?.second_lang_price?.[`${item}`].message
                              }
                            />
                          )}
                        </Col>
                      );
                    })}
                  </Row>
              <Row>
                  <Col md={3} className='m-auto'>
                    <Label className='m-0'>Price for Deal with AccountManager</Label>
                  </Col>
                    {prices.map((item, index) => {
                      return (
                        <Col md={3} key={index} className="mt-2">
                          <div className="pb-2">
                            <Label>{item}</Label>
                            <input
                              {...register(`deal_with_account_manager.${item}`)}
                              placeholder="50"
                              className={`form-control height-47 ${
                                errors?.deal_with_account_manager?.[`${item}`] &&
                                "border-danger"
                              }`}
                              type="text"
                              disabled={view}
                            />
                          </div>
                          {errors.deal_with_account_manager?.[`${item}`] && (
                            <ErrorMessage
                              message={
                                errors?.deal_with_account_manager?.[`${item}`].message
                              }
                            />
                          )}
                        </Col>
                      );
                    })}
                  </Row>
              <div className="py-2">
                <div className="row">
                  <div className="col-sm-12">
                    <Label>Features</Label>
                    {fields.map((item, i) => {
                      return (
                        <div className="row mb-3" key={i}>
                          <div className="col-9">
                            <div className="form-group">
                              <input
                                {...register(`features.${i}.feature`)}
                                type="text"
                                name={`features[${i}].feature`}
                                className={`form-control height-47 ${
                                  errors.features?.[i]?.feature && "is-invalid"
                                }`}
                                placeholder="Feature"
                                disabled={view}
                              />
                            </div>
                            {errors.features?.[i]?.feature?.message && (
                              <ErrorMessage
                                message={errors.features?.[i]?.feature?.message}
                              />
                            )}
                          </div>
                          <Col md="3" className="d-flex" style={{gap: '18px'}}>
                            <div className="form-check form-switch py-2">
                              <input
                                {...register(`features.${i}.active`)}
                                className={`form-check-input`}
                                type="checkbox"
                                id={`features[${i}].active`}
                              />
                            </div>
                            {(!view && i !== 0 ) && 
                                <button
                                  type="button"
                                  className="btn height-47 btn-outline-danger"
                                  onClick={() => remove(i)}
                                >
                                  x
                                </button>
                            }
                          </Col>
                        </div>
                      );
                    })}
                    {!view ? (
                      <div className="row">
                        <div className="col-sm-12">
                          <button
                            className="btn btn-outline-primary "
                            onClick={() => append({ feature: "", active: true })}
                            type="button"
                          >
                            + Add More Features
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-4"></div>
              </div>
              <div className="py-2">
                <Label>Number of Words</Label>
                <input
                  {...register("words")}
                  placeholder="ex: up to 500 words"
                  className={`form-control height-47 ${
                    errors?.words && "border-danger"
                  }`}
                  disabled={view}
                />
                {errors?.words && (
                  <ErrorMessage message={errors?.words?.message} />
                )}
              </div>
              <div>
                <Row>
                  {/* <Row>
                    <Label>Price for 1st Language</Label>
                    {prices.map((item, index) => {
                      return (
                        <Col key={index} className="mt-2">
                          <div className="pb-2">
                            <Label>{item}</Label>
                            <input
                              {...register(`first_lang_price.${item}`)}
                              placeholder="50 $"
                              className={`form-control height-47 ${
                                errors?.first_lang_price?.[`${item}`] &&
                                "border-danger"
                              }`}
                              type="text"
                              disabled={view}
                            />
                          </div>
                          {errors.first_lang_price?.[`${item}`] && (
                            <ErrorMessage
                              message={
                                errors?.first_lang_price?.[`${item}`].message
                              }
                            />
                          )}
                        </Col>
                      );
                    })}
                  </Row> */}
                  
                  {/* <Col md={12}>
                    <div className="pb-2">
                      <Label>Content</Label>
                      <textarea
                        {...register("content")}
                        placeholder="Type Content"
                        className={`form-control ${
                          errors?.content && "border-danger"
                        }`}
                        disabled={view}
                      ></textarea>
                      {errors?.content && (
                        <ErrorMessage message={errors?.content?.message} />
                      )}
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col md="12">
              <Row className="border-top mt-3">
                {/* <Col
                  md="4"
                  className="my-2 d-flex justify-content-between align-items-center"
                >
                  <label className="form-check-label d-inline">
                    Highlighted
                  </label>
                  <div className="form-check form-switch py-2">
                    <input
                      {...register("highlighted")}
                      className={`form-check-input`}
                      type="checkbox"
                      id="highlightd"
                      disabled={view}
                    />
                  </div>
                </Col>

                <Col
                  md="4"
                  className="my-2 d-flex justify-content-between align-items-center"
                >
                  <label className="form-check-label d-inline">
                    E-commerce
                  </label>
                  <div className="form-check form-switch py-2">
                    <input
                      {...register("ecommerce")}
                      className={`form-check-input`}
                      type="checkbox"
                      id="highlightd"
                      disabled={view}
                    />
                  </div>
                </Col> */}

                <Col
                  md="4"
                  className="my-2 d-flex justify-content-between align-items-center"
                >
                  <label className="form-check-label d-inline">Active</label>
                  <div className="form-check form-switch py-2">
                    <input
                      {...register("active")}
                      className={`form-check-input`}
                      type="checkbox"
                      id="highlightd"
                      disabled={view}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {!view ? (
            <div className="modal-footer pb-0 ps-lg-0 text-end">
              <div className="row w-100 justify-content-end">
                <div className="col-lg-8 d-none d-lg-grid">
                  <p className="text-muted mb-1 font-14 text-start">
                    <i className="fa fa-info-circle fa-lg"></i> Fill information
                    about your business and goals for this website and this data
                    can vary and time can be from.
                  </p>
                </div>
                <div className="col-md-6 col-lg-4">
                  {isSubmitting ? <div className="loaderStyle">
                    <Circles color="#00BFFF"/>
                </div> : <button
                    className="btn btn-primary d-block ms-auto text-start height-42 w-100"
                    type="submit"
                    disabled={isSubmitting ? true : false}
                  >
                    {editItem ? "Update Plan" : "Add Plan"}
                    <img className="float-end pt-2" src={arrowImg} alt="" />
                  </button>}
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-footer pb-0 ps-lg-0 text-end mt-3">
              <div className="row w-100 justify-content-end">
                <div className="col-lg-8 d-none d-lg-grid">
                  <p className="text-muted mb-1 font-14 text-start">
                    <i className="fa fa-info-circle fa-lg"></i> Fill information
                    about your business and goals for this website and this data
                    can vary and time can be from.
                  </p>
                </div>
                <div className="col-md-6 col-lg-4">
                  <button
                    className="btn btn-primary d-block ms-auto text-start height-42 w-100"
                    type="button"
                    onClick={toggleAdd}
                  >
                    Close
                    <img className="float-end pt-2" src={arrowImg} alt="" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
        <SuccessModal
          modalSuccess={modalSuccess}
          entiityName="Plan"
          edit={editItem ? true : false}
          toggleSuccess={() => {
            toggleAdd()
            toggleSuccess();
          }}
        />
      </ModalBody>
    </Modal>
  );
};

ModalAdd.propTypes = {
  modalAdd: PropTypes.bool,
  view: PropTypes.bool,
  toggleAdd: PropTypes.func,
  setGetData: PropTypes.func,
  editItem: PropTypes.object,
  viewItem : PropTypes.object
};

export default ModalAdd;
