import React from "react";
import Login from "../pages/Admin/Authentication/Login";
import Register from "../pages/Admin/Authentication/Register";
import Resetpassword from "../pages/Admin/Authentication/Resetpassword";
import ForgetPassword from "../pages/Admin/Authentication/ForgetPassword";
import Logout from "../pages/Admin/Authentication/Logout";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import AdminDashBoard from "../pages/Admin/Dashboard"
import { Redirect } from "react-router-dom"
import Category from './../pages/Admin/Category/index';
import Templates from "pages/Admin/Templates";
import Plans from "pages/Admin/Plans";
import BlogsAdmin from 'pages/Admin/Blogs/index';
import Managers from "pages/Admin/Managers";
import Projects from "pages/Admin/Projects";
import OrderView from "pages/Admin/OrderView";
import Clients from "pages/Admin/Clients";
import Chat from "pages/Admin/Chat";
import Profile from "pages/Admin/Profile";
import FirstStepForm from "components/Admin/Steps/First-Step/FirstStepForm";
// import TemplatesForm from "pages/Admin/Templates/templates";

const adminRoutes = [
  { path: "/logout", component: Logout },

  { path: "/pages-404", component: Pages404 },
  { path: "/dashboard", component: AdminDashBoard },
  { path: "/managers", component: Managers },
  { path: "/categories", component: Category },
  { path: "/blogs", component: BlogsAdmin },
  { path: "/templates", component: Templates },
  // { path: "/templates/add-templates", component: TemplatesForm },
  { path: "/projects", component: Projects },
  { path: "/project/:id/view", component: OrderView },
  { path: "/project/:id/first-step", component: FirstStepForm },
  { path: "/plans", component: Plans },
  { path: "/clients", component: Clients },
  { path: "/chat", component: Chat },
  { path: "/profile", component: Profile },


  { path: "*", component: () => <Redirect to='/admin/pages-404' /> },
  // this route should be at the end of all other routes
];

const adminAuthRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Register },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/reset-password", component: Resetpassword },
  { path: "/pages-500", component: Pages500 },

];

export { adminRoutes, adminAuthRoutes };
