export default class CategoryTypeEnum {
  static get DESIGN() {
    return { label: "Design", value: "design" };
  }

  static get TYPE() {
    return { label: "Type", value: "type" };
  }

  static get INDUSTRY() {
    return { label: "Industry", value: "industry" };
  }
  static get SIZE() {
    return { label: "Size", value: "size" };
  }

  static get BLOG() {
    return { label: "Blog", value: "blog_category" };
  }
  static get ALL() {
    return [
      CategoryTypeEnum.DESIGN,
      CategoryTypeEnum.TYPE,
      CategoryTypeEnum.INDUSTRY,
      CategoryTypeEnum.SIZE,
      CategoryTypeEnum.BLOG
    ];
  }
}
