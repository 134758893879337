import React from 'react'
import {
    Modal,
    ModalBody,CardText
} from "reactstrap"
import PropTypes from 'prop-types';
import imgUser from 'assets/images/add-person.png'

const ModalInvite = ({ modalInvite, toggleInvite}) => {

    return (
        <Modal isOpen={modalInvite} className="modal-dialog-scrollable container" toggle={() => toggleInvite()}>
            
            <ModalBody className='border-0 py-4 text-center pt-5'>
                <img src={imgUser} />
                <CardText tag="h5" className='fw-bold text-dark pt-3'>Invite Sent to moneer@pencil-designs.com</CardText>
                <CardText tag="p" className='text-muted pt-2'>Fill information about your business and goals for this website, this data can vary and time.</CardText>
                <button className='btn btn-outline-primary my-3 px-4 height-42' onClick={()=> toggleInvite()}>Go to account Managers</button>
            </ModalBody>
        </Modal>
    );
}
ModalInvite.propTypes = {
    modalInvite: PropTypes.bool,
    toggleInvite: PropTypes.func
};
export default ModalInvite;
