import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import classnames from "classnames";
import { useSelector } from "react-redux";

const CardPlan = ({ plan, onSelect ,index}) => { //height
  const { t } = useTranslation();
  const [planData, setPlanData] = useState(null);
  
  const [content, setContent] = useState(false);
  const [secondLang, setSecondNumber] = useState(false);
  const [total, setTotal] = useState(0);
  const data = useSelector((state) => state.Auth);

  useEffect(() => {
    const words_num = data.has_content === 1 ? 1 : 0;
    if (words_num) setContent(true);
    setPlanData({...plan});
    setTotal(plan.price?.find(res => res.currency === plan.currency)?.amount)
  }, [plan]);

  const OnSelectHandler = (e) => {
    e.preventDefault();

    onSelect({...planData, has_content: content, has_second_lang: secondLang});
  };

  useEffect(()=>{
    if(content && secondLang){
      setTotal(plan.price?.find(res => res.currency === plan.currency)?.amount+plan?.content_price?.find(res => res.currency === plan.currency)?.amount+plan?.second_lang_price?.find(res => res.currency === plan.currency)?.amount)
    } else if(secondLang){
      setTotal(plan.price?.find(res => res.currency === plan.currency)?.amount+plan?.second_lang_price?.find(res => res.currency === plan.currency)?.amount)
    }else if(content){
      setTotal(plan.price?.find(res => res.currency === plan.currency)?.amount+plan?.content_price?.find(res => res.currency === plan.currency)?.amount)
    } else{
      setTotal(plan.price?.find(res => res.currency === plan.currency)?.amount)
    }
  }, [content, secondLang])

  return (
    <div className="card h-100 border-0 rounded-3 position-relative">
                <div className="card-body">
                  <div className={`p-3 absoluteEnterprise`} 
                    style={{
                      backgroundColor: plan?.color,
                      boxShadow: '0px 3px 20px 0px #0000001F',
                      borderRadius: '8px'
                    }}>
                    <h1 className="fw-bold textBlueDark">${total} <span className="badge bg-primary font-14 fw-light rounded-pill px-4 ms-auto float-end mt-3">{plan?.pages_num?.name?.en !== "One Page" && 'From'} {plan?.pages_num?.name?.en}</span></h1>
                  {/* <div className={`p-3 rounded-3 absoluteEnterprise ${colorsClass[index].bg} `}> */}
                    <h5 className="fw-bold textBlueDark">{plan?.name?.en}</h5>
                    <p className="textBlueDark opacity-75 mb-0 minHeight">{plan?.description?.en}</p>
                  </div>
                  <form className="pt-3" onSubmit={e=> OnSelectHandler(e)}>
                  <div className="mb-2 pt-5 mt-5">
                      <input
                        className="form-check-input me-2"
                        id={`content${index+1}`}
                        // {...register("checkbox")}
                        onChange={(e)=> setContent(e.target.checked)}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor={`content${index+1}`}>
                        Include Content (copywriting)
                      </label>
                    </div>
                    {/* <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id="firstLang"
                        {...register("checkbox")}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor="firstLang">
                        With One Language (English)
                      </label>
                    </div> */}
                    <div className="mb-2">
                      <input
                        className="form-check-input me-2"
                        id={`secondLang${index+1}`}
                        // {...register("checkbox")}
                        onChange={(e)=> setSecondNumber(e.target.checked)}
                        type="checkbox"
                        value="A"
                      />
                      <label className="text-black" htmlFor={`secondLang${index+1}`}>
                        Make my Website Bilingual(English/Arabic)
                      </label>
                    </div>
                    <hr />
                     <div style={{paddingBottom: '100px'}}> {/*style={{height: `${height}px`}}> */}
                      {plan?.features?.map((feature, index)=>{
                        return <p className="font-size-14 fw-bold" key={index}>
                          {feature?.active ? <i className="fa fa-check text-primary me-2"></i> : <i className="fa fa-close text-danger me-2"></i>} {feature.feature}
                        </p>
                      })}
                    </div>
                    <button 
                      style={{
                          position: 'absolute', 
                          bottom: '16px', 
                          width: '93%',
                          fontWeight: '800'
                      }}
                      className={`btn font-14 height-62 mb-2 text-start bg-dark text-white`}>
                      {t("Continue with this plan")}{" "}
                      <i 
                        className="fa fa-long-arrow-right float-end pt-1"
                        style={{marginRight: '24px'}}
                      ></i>
                    </button>
                  </form>
                </div>
    </div>
    // </div>
  );
};

CardPlan.propTypes = {
  plan: PropTypes.object,
  onSelect: PropTypes.func,
  price: PropTypes.string,
  type: PropTypes.number,
  index: PropTypes.number,
  height: PropTypes.any
};
export default CardPlan;