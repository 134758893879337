import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardText,
  // Dropdown, DropdownToggle, DropdownMenu, DropdownItem 
} from "reactstrap";
import { useTranslation } from "react-i18next";
import avtImage from "assets/images/avatar.png";
import OrderService from "services/Client/OrderServices";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Filters from "components/Admin/Projects/Filters";
import { Circles } from "react-loader-spinner"
import { imageOnError } from "helpers/common";
import Paginator from "components/Common/PaginatorWithHasData/Paginator";

const Projects = ({history , location}) => {
  const { t } = useTranslation();
  const orderService = new OrderService();
  const [projects, setProjects] = useState([]);
  const [getData, setGetData] = useState(false);
  const [hasData, setHasData] = useState(0);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen(prevState => !prevState);
  const [filtersParams, setFilterParams] = useState(null)
  const [formState, setFormState] = useState({
    // selectedDesign: [],
    selectedType: [],
    // selectedSize: [],
    selectedindustry: [],
    keyword: "",
    date: ""
})

useEffect(() => {
  let params = {}
  let category_value_ids = []
  for (const key in formState) {
      if (key === "date" || key === "keyword"){
          if (formState[`${key}`].length) params[`${key}`] = formState[`${key}`].trim()
  }else {
      if (formState[`${key}`].length) category_value_ids.push(formState[`${key}`].map(item => item.value).join())

  }
}

  if (category_value_ids.length) {
      setFilterParams({ ...params, category_value_ids: category_value_ids.join() })

  } else {
      setFilterParams({ ...params })

  }
}, [formState])

useEffect(()=>{
  if(hasData === 1 && location?.state?.from === 'login') history.push("/templates")
}, [hasData])

const getStatus = (step, status) =>{
  if(step === 'first' && status === 0){
    return 'In Progress'
  } else if(step === 'third'){
    if(status === 0) return 'Waiting'
    if(status === 2) return 'In Progress'
    if(status === 3) return 'Ready to Review'
  } else {
    if(status === 0) return 'Pending' // Client Action
    if(status === 2) return 'In Progress'
    if(status === 3) return 'Pending Account Manager Action'
  }
}

const getStatusBack = (step, status) => {
  if(step === 'first' && status === 0) return false
  if(status === 2){
    return false
  } else {
    return true
  }
}

  return (<div className="websitPages pt-5 mt-3">
    <Container fluid className="px-md-5 pt-4">
      <h4 className="pt-3 fw-bold textDarkPrimary">{t("projects list")}</h4>
      <p className="font-size-14 text-muted pb-2">
        {t("select template to preview")}
      </p>
      <Filters formState={formState} setFormState={setFormState} />
      {hasData === 0 && <div className='loaderStyle'>
        <Circles color='#00BFFF' />
      </div>}
      {hasData === 2 && <div>
        {projects.map(project => {
          return (
            <div className="styleCard bgGray" key={project.id}>
              <div className="card template-item">
                <Container fluid>
                  <Row className="template-border justify-content-between">
                    <Col md={8} lg={9}>
                      <div className="d-md-flex py-md-3 pt-3 pb-0">
                        <div className="p-3 bg-template me-md-3 rounded-3">
                          <img
                            className="imageTemplate mobileImage"
                            src={
                              project?.template?.media?.path
                                ? project?.template?.media?.path
                                : avtImage
                            }
                            onError={(e) => imageOnError(e, "imageTemplate")}
                          />
                        </div>
                        <div className="overflow-auto">
                            {project?.first_step_business_name ? <CardText tag="h6" className="text-capitalize pt-md-2 pt-3 fw-bold">{project?.first_step_business_name}</CardText> : 
                            <CardText tag="h6" className="text-danger pt-md-2 pt-3 fw-bold">Not yet determined</CardText>}
                            {/* <CardText tag="p" className="text-muted mb-0">Business Name: <h6 className={`text-capitalize d-inline pt-md-2 pt-3 ${project?.first_step_business_name ? 'text-black' : ''} fw-bold`}>{project?.first_step_business_name || 'Not yet determined'}</h6></CardText> */}
                              {(project?.type || project?.business_location) &&<CardText tag="div" className="overflow-auto mb-2">
                              {project?.type && <CardText tag="p" className="text-muted mb-0">Kind: <span>{project.type}</span></CardText>}
                              {project?.business_location && <CardText tag="p" className="text-muted mb-0">Location: <span>{project.business_location}</span></CardText>}
                            </CardText>}

                              {!!project.business_description && <CardText tag="p" className="text-muted">{project.business_description}</CardText>}
                              <CardText tag="p" className="text-muted mb-2">
                                  <span>Account Manager: 
                                    {project?.assignee?.role !== 'Admin' ? <span className="mx-2">{project?.assignee?.name}</span> : <span className="text-danger mx-2">{`Isn't selected`}</span>}
                                  </span>
                              </CardText>
                              <CardText tag="p" className="text-muted mb-2">Payment: 
                                <span className="mx-2">
                                  {project.subscription_plan?.name?.en}
                                  {(project?.content_included && project?.lang_count === 2) ? ' with Content and Two Language' : project?.content_included ? ' with Content' : project?.lang_count === 2 ? ' with Two Language' : ''}
                                </span>
                              </CardText>
                              <CardText tag="p" className="text-muted mb-2">Step:
                                <span className="mx-2">
                                  {project?.first_step_data_status !== 1 ? 'Business Info' : 
                                  (project?.second_step_data_status  !== 1 && project.content_included) ? 'Content Brief' : 
                                  (project?.second_step_data_status  !== 1 && !project.content_included) ? 'DIY Content' : 
                                  project?.third_step_data_status !== 1  ? 'Feedback' : 'Publishing'}
                                </span> <br className="d-block d-md-none pt-3"/>
                                <span className={`${(
                                  project?.first_step_data_status !== 1 ? getStatusBack('first',project?.first_step_data_status) :
                                  project?.second_step_data_status !== 1 ? getStatusBack('second',project?.second_step_data_status) :
                                  project?.third_step_data_status !== 1 ? getStatusBack('third',project?.third_step_data_status) : getStatusBack('forth', project?.fourth_step_data_status)
                                  )? 'status-badge d-inline-flex d-md-inline mt-2 mt-md-0 align-items-center' : 'badgeCompleted d-inline-flex d-md-inline mt-2 mt-md-0 align-items-center'}`}>
                                  {project?.first_step_data_status !== 1 ? getStatus('first', project?.first_step_data_status) :
                                  project?.second_step_data_status  !== 1 ? getStatus('second', project?.second_step_data_status) :
                                  project?.third_step_data_status !== 1 ? getStatus('third', project?.third_step_data_status) : getStatus('forth', project?.fourth_step_data_status) }
                                </span>
                              </CardText>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} lg={2} className="pt-md-4 pt-2 px-2 mb-3">
                      <Link
                        to={`/client/project/${project.id}`}
                        className="btn btn-primary d-block m-auto fw-bold font-14 btnWidth height-42 pt-2"
                      >
                        {t("View Project")}
                      </Link>
                      <div className="removebtnhover position-relative">
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          );
        })}
      </div>}
      {hasData === 1 && <div>
          <h3 className="text-center pt-3 text-muted">There is No Projects</h3>
        </div>}

      <div className="bg-white mt-3">
        <div className="d-flex justify-content-end" md={8} sm={12}>
          <Paginator
            service={orderService}
            pageOptions={{ per_page: 10 }}
            setData={(data) => {
              setProjects(data);
              setGetData(false);
            }}
            setPageOptions={() => {
            }}
            filtersParams={filtersParams}
            shouldGetData={getData}
            setShouldGetData={() => {
            }}
            setHasData={setHasData}
          />
        </div>
      </div>
    </Container>
  </div>);
};
Projects.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
}
export default Projects;