import React, { useState, useRef, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Modal,
    ModalHeader, ModalBody, Label, Row, Col
} from "reactstrap"
import { toast } from "react-toastify";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import BlogService from 'services/Admin/BlogService';
import { SuccessCreated, SuccessOk } from "configs/statusCode";
import { AddBlogValidator } from 'FormValidations/Admin/Blog/Add';
import PropTypes from 'prop-types';
import FileUpload from "components/Common/file-upload/file-upload.component";
import CategoryService from "services/Admin/CategoryService"
import ReactTags from 'react-tag-autocomplete'
import "./style.scss";
import TagService from 'services/Admin/TagService';
import arrowImg from "assets/images/arrow.png"
import SuccessModal from "components/Common/SuccessModal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "../TextEditor";
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const ModalAdd = ({ modalAdd, toggleAdd, setGetData, editItem }) => {
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors },
        watch
    } = useForm({
        resolver: yupResolver(AddBlogValidator)
    });
    const reactTags = useRef()
    const blogService = new BlogService();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState({});
    const [uploadState, setUploadState] = useState(false)
    const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [modalSuccess, setModalSuccess] = useState(false);
    const tagServices = new TagService();
    const [formData, setFormData] = useState({
        media_id: "",
        media_id_path: "",
    })

    const categoryService = new CategoryService()

    const toggleSuccess = () => {
        setModalSuccess(!modalSuccess);
        setGetData(true);
    };

    const getTags = async () => {
        const { data: itemList } = await tagServices.getList();
        setSuggestions(itemList);
    }
    useEffect(() => {
        getTags();
    }, []);

    useEffect(() => {
        categoryService.getDropDown('categoryValues', { type: "blog_category" }).then((res) => {
            if (res.status === SuccessOk) {
                const options = res.data.data.map(item => ({ label: item.name.en, value: item.id }))
                setCategoryOptions(options)
            }
        })
    }, []);

    const onDelete = (i) => {
        const tagsFilter = tags.filter((tag, index) => index !== i)
        setTags(tagsFilter);
    }

    const onValidate = (tag) => {
        return tag.name.length >= 3;
    }

    const onAddition = (tag) => {
        const isExistSuggestion = suggestions.length > 0 ? suggestions.find(tagData => tagData.id === tag.id) : false
        const isExistTags = tags.length > 0 ? tags.find(tagData => tagData.id === tag.id) : false
        if (!isExistSuggestion && !isExistTags) {
            tagServices.create({ name: tag.name }).then(res => {
                const newTag = {
                    id: res.data.id,
                    name: tag.name
                }
                setTags([...tags, newTag]);
                setSuggestions([...suggestions, newTag])
            })
        } else if (!isExistTags) {
            setTags([...tags, tag]);
        }
    }

    useEffect(() => {
        if (editItem) {
            blogService.find(editItem.id).then(res => {
                setValue("name", res.data.title)
                setValue("description", {
                    ar:htmlToDraft(res.data.description.ar),
                    en:htmlToDraft(res.data.description.en),
                })
                setTags([...res.data.tags])
                setValue("Category", {
                    value: res.data.category.id,
                    label: res.data.category.name.en

                })
                setFormData(data => ({
                    ...data,
                    media_id: res.data.media.id,
                    media_id_path: res.data.media.path
                }))
            })
        }
    }, [])
    const handleValidSubmit = async (values) => {
        if (tags.length === 0) {
            toast.error("tags field is required");
            return
        } else {
            setIsSubmitting(true);
            let requestData = {
                title: values.name,
                description:{
                    ar:draftToHtml(convertToRaw(values.description.ar.getCurrentContent())),
                    en:draftToHtml(convertToRaw(values.description.en.getCurrentContent())),
                },
                tag_ids: tags.map(tag => tag.id),
                category_id: values.Category.value,
                media_id: formData.media_id ?? null

            };
            try {
                if (editItem) {
                    const userData = await blogService.update(editItem.id, requestData);
                    if (userData.status == SuccessOk) {
                        toggleAdd();
                        setIsSubmitting(false);
                        setGetData(true);
                        toast.success("Blog Updated Successfully");
                    }
                } else {
                    const userData = await blogService.create(requestData);
                    if (userData.status == SuccessCreated) {
                        setModalSuccess(true);
                    }
                }

            } catch (err) {
                setIsSubmitting(false);
            }
        }
    };


    const updatedAttachment = (attachments, type) => {
        const mediaObj = {
            [`${type}`]: attachments[0] ?.id,
            [`${type}_path`]: attachments[0] ?.path,
        }
        setFormData({ ...formData, ...mediaObj })
    }

    const deleteAttachment = (_, type) => {
        const mediaObj = {
            [type]: null,
            [`${type}_path`]: null,
        }
        setFormData({ ...formData, ...mediaObj })
    }


    return (
        <Modal isOpen={modalAdd} className="modal-dialog-scrollable modal-lg container" toggle={() => toggleAdd()}>
            <ModalHeader className='modalHead me-2' toggle={() => toggleAdd()}>
                <div>
                    <h6 className='fw-bold'>{editItem ? "Update Blog" : "Add New Blog"}</h6>
                    <p className='font-14 text-muted mb-0'>Fill information about your business and goals for this website</p>
                </div>
            </ModalHeader>
            <ModalBody className='border-0 py-4'>
                <form className="g-3 needs-validation" onSubmit={handleSubmit(handleValidSubmit)}>
                    <Row>
                        <Col lg={7}>
                            <div className="col-md-12">
                                <div className='pb-2'>
                                    <Label>Title (en)</Label>
                                    <input
                                        {...register("name.en")}
                                        placeholder="Type Name"
                                        className={`form-control height-47 ${errors ?.name ?.en && "border-danger" }`}
                                        type="text"
                                    />
                                    {errors ?.name ?.en && <ErrorMessage message={errors ?.name ?.en ?.message} />}
                                </div>
                                <div className='pb-2'>
                                    <Label>Title (ar)</Label>
                                    <input
                                        {...register("name.ar")}
                                        placeholder="Type Name"
                                        className={`form-control height-47 ${errors ?.name ?.ar && "border-danger" }`}
                                        type="text"
                                    />
                                    {errors ?.name ?.ar && <ErrorMessage message={errors ?.name ?.ar ?.message} />}
                                </div>
                                <div className='pb-2'>
                                    <Label>Category</Label>
                                    <Controller
                                        name="Category"
                                        control={control}
                                        render={({ field }) => <Select
                                            {...field}
                                            options={categoryOptions}
                                            className={`heightSelect ${errors ?.Category && "select-border" }`}
                                        />}
                                    />
                                    {errors ?.Category && <ErrorMessage message={errors.Category.message} />}
                                </div>
                                <div>
                                    <Label className="mt-2">Keywords</Label>
                                    <div className="tag-container ps-0">
                                        <ReactTags
                                            allowNew={true}
                                            ref={reactTags}
                                            tags={tags}
                                            // tagComponent={TagComponent}
                                            suggestions={suggestions.length > 0 ? suggestions.filter(suggest => !tags.some(tag => tag.id === suggest.id)) : []}
                                            onDelete={onDelete}
                                            onValidate={onValidate}
                                            onAddition={onAddition} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="sectionUpload">
                                <FileUpload
                                    accept='.jpg,.png,.jpeg,.JPG,.PNG,.JPEG'
                                    label='Photo'
                                    attachmentType='media_id'
                                    attachmentData={[{
                                        id: formData.media_id,
                                        path: formData.media_id_path
                                    }]}
                                    updatedAttachment={updatedAttachment}
                                    deleteAttachment={deleteAttachment}
                                    setUploadState={setUploadState}

                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <Label> Description (en)</Label>
                            <TextEditor control={control} name="description.en" error={errors.description?.en?.message} watch={watch} setValue={setValue} editItem={editItem} />
                            <Label> Description (ar)</Label>
                            <TextEditor control={control} name="description.ar" error={errors.description?.ar?.message} watch={watch} setValue={setValue} editItem={editItem} />
                        </Col>
                    </Row>
                    <div className='modal-footer pb-0 mt-3'>
                        <div className='row w-100 justify-content-end'>
                            <div className='col-lg-8 d-none d-lg-grid'>
                                <p className='text-muted mb-1 font-14'><i className='fa fa-info-circle fa-lg'></i> Fill information about your business and goals for this website and this data can vary and time can be from.</p>
                            </div>
                            <div className='col-md-6 col-lg-4'>
                                <button
                                    className="btn btn-primary d-block ms-auto text-start height-42 w-100"
                                    type="submit"
                                    onClick={() => console.log(errors)}
                                    disabled={uploadState ? true : isSubmitting ? true : false}
                                > {editItem ? "Update Blog" : "Add Blog"}
                                    <img className='float-end pt-2' src={arrowImg} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <SuccessModal
                        modalSuccess={modalSuccess}
                        entiityName="Blog"
                        toggleSuccess={() => {
                            toggleSuccess(), toggleAdd();
                        }}
                    />
                </form>
            </ModalBody>
        </Modal>
    );
}
ModalAdd.propTypes = {
    modalAdd: PropTypes.bool,
    toggleAdd: PropTypes.func,
    setGetData: PropTypes.func,
    editItem: PropTypes.object,
};
export default ModalAdd;
