import BaseService from "../BaseService";
import { API_BASE_URL_ENV } from "../../helpers/common";
import http from "../HttpService";

const apiEndpoint = API_BASE_URL_ENV() + "/admin/order";

export default class extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  assign(id, data) {
    const body = { ...data };
    return http.put(`${apiEndpoint}/${id}/assign`, body);
  }

  rooms(params) {
    return http.get(`${apiEndpoint}/rooms`, {params});
  }

  firstStep(id) {
    return http.get(`${apiEndpoint}/${id}/first-step`);
  }
  
  projectBrief(data) {
    return http.post(`${apiEndpoint}/first-step`, data);
  }

  secondStep(id) {
    return http.get(`${apiEndpoint}/${id}/second-step`);
  }

  fourthStep(id) {
    return http.get(`${apiEndpoint}/${id}/fourth-step`);
  }

  secondStepRequestToEdit(id) {
    return http.put(`${apiEndpoint}/second-step/${id}/request-to-edit`);
  }

  changeStepStatus(id, data) {
    const body = { ...data };
    return http.put(`${apiEndpoint}/${id}/change-step-status`, body);
  }

  sendFeedBackUrl(id, data) {
    const body = { ...data };
    return http.put(`${apiEndpoint}/${id}/second-step-file`, body);
  }

  sendContentUrl(data) {
    const body = { ...data };
    return http.post(`${apiEndpoint}/second-step`, body);
  }

  sendDesignUrl(id, data) {
    const body = { ...data };
    return http.put(`${apiEndpoint}/${id}/third-step-feedback-url`, body);
  }

  update(data) {
    return http.put(`${apiEndpoint}/fourth-step/update`, data);
  }

  updateOrder(id,data) {
    return http.put(`${apiEndpoint}/${id}`, data);
  }

}
