import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/website/twilio";

export default class extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getData(type, params) {
    return http.get(`${apiEndpoint}/${type}`, { params });
  }

  getToken(data) {
    return http.post(`${apiEndpoint}/token`,data);
  }


}
