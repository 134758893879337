import React, { useEffect, useState } from "react";
import { Card, CardBody, CardText, Col, Container, Form, Progress, Row } from "reactstrap";
import PropTypes from "prop-types";
import OrderService from "services/Client/OrderServices";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import FileUpload from "components/Common/file-upload/file-upload.component";
import StepsService from "services/Client/StepsService";
import { toast } from "react-toastify";
import { SuccessOk } from "configs/statusCode";
import { useDispatch } from "react-redux";
import { appendFilters } from "store/Auth/actions";
import OrderServices from "services/Client/OrderServices";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ContentPage = (props) => {
  const { id } = props.match.params;
  const [formData, setFormData] = useState({
    documents: [],
    links: '',
    reference_url: ''
  })
  const [completed, setCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [uploadState, setUploadState] = useState(false);
  const [urlPattern, setUrlPattern] = useState(false);
  const { t } = useTranslation();
  const orderService = new OrderService();
  const stepsService = new StepsService();
  const dispatch = useDispatch()

  const getProjectData = async () => {
    const res = await orderService.find(id);
    setProjectData(res.data);
  };
  
  useEffect(() => {
    if (projectData?.first_step_status == 1) {
      setCompleted((prave) => prave + 25);
    }
    if (projectData?.second_step_status == 1) {
      setCompleted((prave) => prave + 25);
    }
    if (projectData?.third_step_status == 1) {
      setCompleted((prave) => prave + 25);
    }
    if (projectData?.fourth_step_status == 1) {
      setCompleted((prave) => prave + 25);
    }
  }, [projectData]);

  const callAPIS = async () => {
    return Promise.allSettled(getProjectData())
  };
  useEffect(() => {
    if (id) {
      callAPIS().catch((e) => console.log(e));
    }
  }, [id]);

  const updatedAttachment = (attachments, type) => {
    if (type === "documents") {
      setFormData((data) => ({
        ...data, documents: [...formData.documents, ...attachments]
      }));
    } else {
      const mediaObj = {
        [`${type}`]: attachments[0]?.id, [`${type}_path`]: attachments[0]?.path
      };
      setFormData({ ...formData, ...mediaObj });
    }
  };

  const deleteAttachment = (Items, type) => {
      if (type === "documents") {
        let updatedDocs = formData.documents.filter(item => item.id !== Items[0].id);
        setFormData((data) => ({ ...data, documents: updatedDocs }));
      } else {
        const mediaObj = {
          [type]: null, [`${type}_path`]: null
        };
        setFormData({ ...formData, ...mediaObj });
      }
  };

  const handleValidSubmit = (e)=>{
    e.preventDefault();
    if(!formData.documents.length && !formData.reference_url){
      toast.error("Please Upload Document or Enter the Reference Url Field")
      return
    }

    // const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]$/;
    if(formData.links && !formData.links.includes("http")){ //!urlRegex.test(formData.links)
      setError(true)
      return
    }

    let data = {
      order_id: projectData.id,
      reference_url: formData.reference_url,
      media_id: formData.documents?.length > 0 ? formData?.documents[0]?.id : '',
      links: formData.links
    }
    setIsLoading(true);
    const handleContent = stepsService.update("second-step", data)
    const updateOrder = new OrderServices().updateOrder(projectData?.id, { step_confirmed: "second_step_status", status: 2 })
    
    Promise.all([handleContent.catch(()=> toast.error("Please Enter the Required Fields")), updateOrder]).then(res=>{
      if (res[0].status === SuccessOk && res[1].status === SuccessOk) {
        toast.success("Project Content Successfully Submitted");
        props.history.push(`/client/project/${projectData.id}`)
      }
    })
  }

  return (
      <div className="pt-3">
        {isLoading ? (
          <div className="loaderStyle">
            <Circles color="#00BFFF" />
          </div>
        ) : (
          <Container fluid className="px-md-5 pt-5 contentPage">
            <Row>
                <Col lg={9}>
                    <Row className="pt-2">
                    <Col className="pt-1">
                        <div className="row pt-2 justify-content-between">
                        <div className="col-lg-6">
                            <div className="text-dark font-16 fw-bold mb-0 pt-2">
                            {projectData?.second_step_status === 1 ? (
                                <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                                <i className="fa fa-check text-white"></i>
                                </p>
                            ) : (
                                <p className="badge badgeNumber mb-2 rounded-circle me-2">
                                {" "}
                                2
                                </p>
                            )}
                            <span className="h5 fw-bold">{t("Content")}</span>
                            <p className="textSecondary font-14">
                                Help us build your website by sharing some details
                            </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="d-flex justify-content-lg-end justify-content-between pt-2 gap-4 align-items-baseline">
                            <div className="editProgress">
                                <h6 className="fw-bold textSuccess font-18">
                                {completed}%
                                <span className="font-16">{t("Completed")}</span>
                                </h6>
                                <Progress className="progressBar" value={completed} />
                            </div>
                            <div>
                                <a
                                className="btn btn-outline-primary px-4"
                                target="_blank"
                                rel="noreferrer"
                                href={projectData?.template?.url}
                                >
                                <i className="fa fa-eye"></i> {t("Preview")}
                                </a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </Col>
                    </Row>
                    <Card className="mt-3">
                        <CardBody>
                            <CardText className="fw-bold font-18" tag="h6">Insert your content</CardText>
                            <CardText className="font-14 textLight" tag="p">Provide content details for your websites pages.</CardText>
                            <hr/>
                            <Form onSubmit={e=> handleValidSubmit(e)}>
                                <Row>
                                    <Col lg={8}>
                                        <label className="pb-2">Upload Document</label>
                                        <CardText className="font-14 textLight" tag="p">Gather all your content into a single document (e.g., MS Word, PDF) and upload it here.</CardText>
                                        <div className="sectionUpload pt-3">
                                            {/* <FileUploadComponent
                                              accept=".pdf,.doc,.docx"
                                              label="Attach a file content"
                                              attachmentType="documents"
                                            /> */}
                                            <FileUpload
                                              accept=".pdf,.doc,.docx"
                                              attachmentType="documents"
                                              attachmentData={[...formData.documents]}
                                              updatedAttachment={updatedAttachment}
                                              deleteAttachment={deleteAttachment}
                                              setUploadState={setUploadState}
                                            />
                                        </div>
                                        {/* <div className='pb-2 pt-3'>
                                            <input
                                            {...register("document")}
                                                type="file"
                                                ref={inputRef}
                                                // accept="image/*"
                                                style={{ display: 'none' }}
                                                id="contained-button-file"
                                            />
                                            <label htmlFor="contained-button-file">
                                                <button 
                                                onClick={() => inputRef.current.click()} 
                                                variant="contained"
                                                className="btn btn-outline-primary font-14 height-42 mb-2 px-xl-3 px-lg-2"
                                                  component="span"
                                                  type={"button"}
                                                  >
                                                  <i className='fa fa-link'></i> Attach a file 
                                                </button>
                                            </label>
                                        </div> */}
                                        <div className="d-flex justify-content-center align-items-center gap-1">
                                            <hr className="w-100"/>
                                            <span>OR</span>
                                            <hr className="w-100"/>
                                        </div>
                                        <p className="text-center "></p>
                                        <div className="pb-3 mt-2">
                                            <div className="form-group w-100">
                                            <label className="pb-2">Paste Google Doc Link</label>
                                            <CardText className="font-14 textLight" tag="p">
                                              Before sharing, set your Google Doc permissions to &quot;Anyone with the link can view&quot; and paste the link below.
                                              </CardText>
                                            <input
                                                placeholder={"e.g. https://docs.google.com/document/d/5sQWPy8AXMESnq_1bqFmUmPcN_GYq8ykZV3eMh8C0nGE/edit?usp=drive_link"}
                                                name={`reference_url`}
                                                type="text"
                                                onfocusout={()=>{
                                                  const urlPattern = /^https?:\/\/.+/;
                                                  if(!formData?.reference_url && urlPattern?.test(formData?.reference_url)) setUrlPattern(false)
                                                }}
                                                onChange={e=> {
                                                  const urlPattern = /^https?:\/\/.+/;

                                                  if (!urlPattern.test(e.target.value)) {
                                                    setUrlPattern(true)
                                                  } else{
                                                    setUrlPattern(false)
                                                  }
                                                  
                                                  setFormData({...formData, reference_url: e.target.value})
                                                }}
                                                value={formData?.reference_url}
                                                className={`form-control height-50`}
                                            />
                                            {urlPattern && (<ErrorMessage message='Please enter a valid URL' />)}
                                            </div>
                                        </div>
                                        <p className="mb-3 pt-2 text-primary"><span className="fw-bold">Notes:</span></p>
                                        <CardText className="text-primary d-flex">
                                            <i className="fas fa-check"></i> 
                                            <span className="font-14 ps-2">
                                             For the best results, label each section or page in your document according to your site map. This ensures we capture every detail of your vision when designing your site.Check out our <Link target="_blank" to={`/site-map`}>Site Map Guide.</Link>
                                            </span>
                                        </CardText>
                                        {/* <hr className="my-4"/>
                                        <label className={`pb-2 ${error && "text-danger"}`}>Links (Optional)</label>
                                        <CardText className="font-14 textLight" tag="p">Add any links from your own website or external websites that you want hyperlinked on this page.</CardText>
                                        <input
                                            placeholder={"Links separated by commas, e.g. https://www.l1.com, https://www.l2.com"}
                                            name={`links`}
                                            value={formData.links}
                                            onChange={e=> {
                                              setError(false)
                                              setFormData({...formData, links: e.target.value})
                                            }}
                                            type="text"
                                            className={`form-control height-50 ${error && "border-danger"}`}
                                        /> */}
                                        {error && (<ErrorMessage message={'Invalid Url Value'} />)}
                                        <CardText className="text-primary d-flex">
                                            <i className="fas fa-check"></i> 
                                            <span className="font-14 ps-2">Feeling stuck? No worries. Upgrade to our copywriting plan and let us take care of the content creation for you.</span>
                                        </CardText>
                                        <button 
                                          type="button" 
                                          className="btn fw-bold align-items-center"
                                          onClick={()=>{
                                            dispatch(appendFilters({
                                              from: 'content',
                                              stripe_id: projectData?.subscription_plan?.content_price?.filter(res=> res?.currency.toLowerCase() === projectData?.subscription_plan?.currency.toLowerCase())[0]?.stripe_id,
                                              selectedTemplate: projectData,
                                              planData: {...projectData?.subscription_plan}
                                            }));
                                            props.history.push("/checkout", {plan:{...projectData.subscription_plan},from: 'content', selectedTemplate: projectData})
                                          }}
                                        >
                                            <span className="textGreen text-decoration-underline pe-2">
                                              Upgrade content
                                            </span>  
                                            <i className="fa fa-long-arrow-right textGreen"></i>
                                        </button>
                                    </Col>
                                </Row>
                                <hr/>
                                <div className="d-flex">
                                  <p className='textRed w-75 mt-3 mb-0 font-14'><i className='fa fa-info-circle fa-lg mx-2'></i> 
                                          {t("Before uploading, did you check your content details? Remember, our design is based on what you provide.")}
                                      </p>
                                  <button type="submit"
                                    disabled={uploadState ? true : false}
                                      className="btn btn-primary btnHeightLg p-2 mb-2 px-3 me-lg-3 font-14 text-start ms-auto d-block"
                                  >
                                      Continue <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i>
                                  </button>
                                </div>
                                
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                {/* <Col lg="3">
                    <div className="fixedChat">
                        {projectData.room ? <ClientChatTwilio client= {projectData.client} room={projectData}/> : null}
                    </div>
                </Col> */}
            </Row>
          </Container>
        )}
      </div>
  );
};

ContentPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ContentPage;
