import React, { useState, useEffect, useMemo } from "react";
import Navbar from "components/Website/Layouts/Header";
import "./styles.scss";
import Select from "react-select";
import OrderService from "services/Website/OrderTemplateService";
import { CardImg } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { appendFilters } from "store/Auth/actions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Circles } from "react-loader-spinner";
import { imageOnError } from "../../../helpers/common";
import customDesignImg from "assets/images/customer-support.gif"
import GeneralFooter from "components/Website/Layouts/Footer/GeneralFooter";
// import Business from "components/Website/Steps/Business";
// import Briefly from "components/Website/Steps/Briefly";
// import Pages from "components/Website/Steps/Pages";
// import CopyRight from "components/Website/Steps/CopyRight";
// import Domain from "components/Website/Steps/Domain";
import CustomModal from "./Modal";

const quryParams = ["industry", "website_type", "name", "description", "content", "domain"]; // "size"
const Templates = ({ history }) => {
  const [paramsObj, setParamsObj] = useState(null);
  const [step, setStep] = useState("Template")
  const { industry, website_type, name, description, content, domain } = paramsObj || {}; //size
  let query = useQuery();
  const { t, i18n } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [hasData, setHasData] = useState(null);
  // const [customTemplate, setCustomTemplate] = useState(null);
  const [customModal, setCustomModal] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const orderService = new OrderService();
  const [page, setPage] = useState(1);
  const [filtersParams, setFilterParams] = useState(null);
  // const [pageOptions, setPageOptions] = useState({
  //   sizePerPage: 10, totalSize: 0
  // });
  const data = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setStep('Template')
    const updatedParamsObj = {};
    for (let index = 0; index < quryParams.length; index++) {
      updatedParamsObj[`${quryParams[index]}`] = query.get(`${quryParams[index]}`);
    }
    setParamsObj(updatedParamsObj);
  }, []);

  useEffect(() => {
    let { industryOptions, sizeOptions, typeOptions } = data;
    if (industryOptions?.length && sizeOptions?.length && typeOptions?.length && paramsObj) {
      let selectedIndustry, selectedType; //selectedSize
      let typeCondition = data?.website_type !== 0 && !data?.type;
      if (!data.industry || !data.size || typeCondition) {
        selectedIndustry = industryOptions?.find(option => option.value == industry);
        // selectedSize = sizeOptions?.find(option => option.value == size);
        selectedType = typeOptions?.find(option => option.value == website_type);
        let filter = {
          industry: selectedIndustry,
          // size: selectedSize,
          type: selectedType,
          website_type: selectedType?.value ?  selectedType?.value : 0,
          business_name: name,
          business_description: description,
          has_domain: parseInt(domain),
          has_content: parseInt(content),
          // custom_template: customTemplate
        }
        if(history?.location?.state) filter['plan'] = history?.location?.state
        dispatch(appendFilters(filter));
      }
    }
  }, [data.industryOptions, data.sizeOptions, data.typeOptions, paramsObj]);

  useEffect(() => {
    const filtersObject = {};

    if (data.industry) filtersObject["industry_ids"] = data.industry?.map(res=> res.value).join(',');
    // if (data.size) filtersObject["size_id"] = data.size.value;
    if (data.type) filtersObject["type_id"] = data.type.value;
    setPage(1)
    setTemplates([])
    setFilterParams(filtersObject);
  }, [data.industry, data.size, data.type]);

  const navigateToCheckOut = (template) => {
      // if (selectedTemplate) {
        // let params = {};
        // if (data.industry) {
        //   params["industry"] = data.industry?.value;
        // }
        // if (data.size) {
        //   params["size"] = data.size?.value;
        // }
        // if (type) {
        //   params["website_type"] = type;
        // }
        // if (data.business_name) {
        //   params["name"] = data.business_name;
        // }
        // if (data.business_description) {
        //   params["description"] = data.business_description;
        // }
        // if (data.has_domain) {
        //   params["domain"] = data.has_domain;
        // }
        // if (data.has_content) {
        //   params["content"] = data.has_content;
        // }
        // if (selectedTemplate) {
        //   params["template"] = selectedTemplate.id;
        // }
        // history.push(`plans?${generateQueryString(params)}`);
      // } else if(customTemplate){
      //   let type;
      //   if (data?.website_type === 0) {
      //     type = 0;
      //   } else {
      //     type = data?.type?.value;
      //   }
      //   history.push(`plans?industry=${data.industry?.value}&website_type=${type}&custom_template=${customTemplate}`); //&size=${data.size?.value} //&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}
      // } else {
      //   toast.error("Please select template");
      // }
      dispatch(appendFilters({ template: template.id, selectedTemplate: template , custom_template: ''}));
      // let type;
      // if (data?.website_type === 0) {
      //   // type = 0;
      // } else {
      //   // type = data?.type?.value;
      // }
        
      if(history.location.state){
        dispatch(appendFilters({
          navigateTo: "checkout"
        }))
        history.push(`checkout?template=${template.id}&plan=${history?.location?.state?.id}`, {plan: history?.location?.state,selectedTemplate: template}); //industry=${data.industry?.value}&&website_type=${type}
        return
      }
      history.push(`plans?template=${template.id}`,template); //industry=${data.industry?.value}&&website_type=${type}&size=${data.size?.value} //&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}
  };

  const fetchMoreData = () =>{
    setPage(prev=> prev+1)
  }

  useEffect(()=>{
    setTimeout(()=>{
      if(totalPages > 0 && page > totalPages){
        setHasMore(false)
        return
      }
      let params={
        ...filtersParams,
        per_page: 12,
        page: page
      }
      setLoading(true)
      if(page===1) setLoadingFirst(true)
      orderService.getList(params).then(res=>{
        if(res.status === 200){
          setTemplates([...templates, ...res.data.data])
          if(page === 1){
            if(res.data.meta.total == 0){
              setHasData(1)
            } else {
              setHasData(2)
            }
            if(res.data.meta.total <= 12){
              setHasMore(false)
            } else{
              setHasMore(true)
            }
            let total = Math.ceil(res.data.meta.total/12)
            setTotalPages(total)
          }
        }
        setLoadingFirst(false)
        setLoading(false)
        window.scrollTo({
          top: 2200 * (page-1),
          behavior: "smooth",
        });
      })
    },300)
  },[page, filtersParams])

  return (<>
    <Navbar/>
    <Helmet>
      <title>Choose your website template</title>
      <meta name="description" content="Explore wesite template , browse design template"/>
    </Helmet>
    {step === 'Template' && <div className="websitPages pt-5 mt-3">
      <div className="container-fluid px-md-5 pt-5">
        <h4 className="pt-3 fw-bold textDarkPrimary">{t("Elevate Your Projects with Our Premium Templates")}</h4>
        <p className="font-size-14 text-muted pb-2">
          {t("We've got templates for every occasion and project, so you can let your creativity run wild.")}
        </p>
        <p className="opacity-75 font-size-14 fw-bold">**Our filters may provide industry-specific suggestions, but our templates can be adapted for any industry.</p>
        <div className="card mb-4 mt-3 cardShadow">
          <div className="card-body px-lg-4 px-0">
            <div className="row justify-content-between w-100 m-0">
              <div className="row col-lg-9">
              <div className="col-lg-4 col-md-6 col-sm-6 pb-3">
                <label className="my-0 py-0">{t("Industry")}</label>
                <Select
                  placeholder={t("Industry")}
                  options={data.industryOptions}
                  className="borderSelect"
                  isMulti
                  onChange={(data) => dispatch(appendFilters({ industry: data }))}
                  value={data.industry}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 pb-3">
                <label className="my-0 py-0">{t("Type")}</label>
                <Select
                  placeholder={t("Type")}
                  options={data.typeOptions}
                  className="borderSelect"
                  onChange={(data) => dispatch(appendFilters({ type: data, website_type: data?.value }))}
                  value={data.type}
                />
              </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 pb-3 ps-3">
                <button onClick={()=> {
                    dispatch(appendFilters({ type: '', industry: '', website_type:'' })) //, size: ''
                  }}
                className="btn border-0 fw-bold btn-outline-primary mt-sm-4 float-md-end px-5 height-42 w-100">
                  View all Templates
                </button>
              </div>
            </div>
            <hr className="border-top border-secondary"/>
            <div className="row w-100 pt-3 m-0">
                {loadingFirst && <div className='loaderStyle'>
                  <Circles color='#00BFFF' />
                </div>}
                {hasData === 2 && <div className="row w-100 pt-3 m-0" id='sc'>
                  {templates.map((item, index) => {
                    return <div key={index}
                    className="col-lg-4 col-sm-6 p-0  mb-4  px-3 btn-hover position-relative" >
                      <div className={selectedTemplate?.id === item.id ? "selected_template selected_border" : ""} >
                        <div className="card position-relative cardPreview border-0">
                          <div className="pic">
                            <CardImg
                              className="img-preview"
                              src={item.media?.path}
                              onError={(e) => imageOnError(e, "img-preview")}
                            />
                          </div>
                          <div className="overlaySection">
                            <div className="preview-btn">
                              <h6 className="mb-0 fw-bold text-center text-white pb-3 font-18">{item.name[i18n.language]}</h6>
                              <p className="descriptionTemplate font-14 text-center text-white pb-3">{item.description[i18n.language]}</p>
                              <button
                                onClick={() => {
                                  navigateToCheckOut(item)
                                }}
                                className="btn btn-primary btnHeightLg btnWidthBuy mb-3 px-3 py-2 text-center">
                                {t("Select This Template")}
                              </button>
                              <a href={item.url} target="_blank" rel="noreferrer"
                                className="btn btn-outline-light btnWidthBuy pt-2 height-47 font-16">Preview Template</a>
                                
                            </div>
                          </div>
                          <div className="card-body p-3 headerTemplate">
                            <h6 className="mb-0 fw-bold text-center">{item.name[i18n.language]}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  })}
                  {templates.length > 0 && <div className="col-lg-4 col-sm-6 p-0  mb-4  px-3 btn-hover" >
                    <div className="card cardShadow custom-card position-relative">
                      <div className="mx-4 pt-3 heightSection">
                        <h6 className="mb-0 text-muted">{t("Can’t find the right design yet? Stumbled upon a design you love elsewhere?")}</h6>
                        <p
                          className="mb-3 me-3 mt-2 font-size-16 fw-bolder">{t("Our team is here to work with you, delivering a website that perfectly showcases your ideas.")}</p>
                      </div>
                      <img
                        className="mx-3 img-fluid customImage"
                        src={customDesignImg}
                      />
                      <div className="card-body p-3">
                        <div className="">
                          <div className="position-absolute btnSchedule">
                            <button className="btn btn-dark mt-1 w-100 height-50 text-start" onClick={() => {
                              setCustomModal(true)
                              // history.push("create-website")
                            }}>
                              {t("Schedule a Consultation")} {i18n.language !== "ar" ?
                              <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i> :
                              <i className="fa fa-long-arrow-left pe-3 float-start pt-1 me-5"></i>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>}

              {(hasMore && page<totalPages && hasData === 2 && !loadingFirst) && <div className="text-center py-3">
                {loading ? <div className='loaderStyle'>
                <Circles color='#00BFFF' />
              </div> : <button className="btn btn-primary px-5 py-2" onClick={fetchMoreData}>See More</button>}
              </div>}
              
              {hasData === 1 && <p>There isn`t a templates option that fits your choices</p>}
            </div> 
            {customModal && 
              <CustomModal 
                modalAdd={customModal} 
                // history={history} 
                // setStep={setStep} 
                setSelectedTemplate={setSelectedTemplate} 
                toggleAdd={()=>setCustomModal(false)} 
                // setCustomTemplate={setCustomTemplate}
                // data={data}
              />}
            {/* <hr className="border-top border-secondary"/> */}
          </div>
        </div>
        <div className="bg-white mt-3">
          <div className="d-flex justify-content-end" md={8} sm={12}>
          </div>
        </div>
      </div>
      <GeneralFooter/>
    </div>}
    {/* <div className='websitPages'>
      <div className="container">
        {step === "Business" &&
          <div className="pt-5 mt-5">
            <Business setStep={setStep} next={'Briefly'} back={'Template'} />
          </div> 
        }
        {step === "Briefly" &&
          <div className="pt-5 mt-5">
            <Briefly setStep={setStep} next={'Pages'} back={'Business'} />
          </div> 
        }
        {step === "Pages" &&
          <div className="pt-5 mt-5">
            <Pages setStep={setStep} next={'CopyRight'} back={'Briefly'} />
          </div> 
        }
        {step === "CopyRight" &&
          <div className="pt-5 mt-5">
            <CopyRight setStep={setStep} next={'Domain'} back={'Pages'} />
          </div> 
        }
        {step === "Domain" &&
          <div className="pt-5 mt-5">
            <Domain submitHandler={navigateToCheckOut} setLoading={setLoading} setStep={setStep} back={'CopyRight'} />
          </div> 
        }
      </div>
    </div> */}
  </>);
};


Templates.propTypes = {
  history: PropTypes.object

};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default Templates;
