import React, { useEffect, useState } from "react"
import "./style.scss"
import noProject from "assets/images/noProject.png"
import Filterswrapper from "components/Common/filterswrapper"
import ModalAdd from "components/Admin/Plans/ModalAdd"
import PlanService from "services/Admin/PlanService"
import Paginator from 'components/Common/Paginator/Paginator'
import { Container, Col, Row, InputGroup,InputGroupText } from 'reactstrap' // Card, CardBody, CardImg, CardText
import CardItem from "components/Admin/Plans/CardItem"
import { Circles } from "react-loader-spinner";
//import moneyImg from "assets/images/money.png"
//import folderImg from "assets/images/folder.png"
import { MetaTags } from "react-meta-tags"

const Plans = () => {
    const [modalAdd, setModalAdd] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [plans, setPlans] = useState([])
    const [getData, setGetData] = useState(true)
    const [editItem, setEditItem] = useState(null)
    const [filtersParams, setFilterParams] = useState(null)
    const [viewItem, setViewItem] = useState(null)
    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
    })
    const planService = new PlanService()
    const toggleAdd = () => {
        setEditItem(null)
        setViewItem(null)
        setModalAdd(!modalAdd)
    }
    useEffect(()=>{
        if(keyword ===''){
            setFilterParams({keyword: null})
        }
    },[keyword])
    
    return (
        <div className="admin-container pt-2">
            <MetaTags>
                <title> Plans & Payments </title>
            </MetaTags>
            <Container fluid className="bgGray pb-5 pt-3 px-lg-5 needs-validation">
                <Filterswrapper
                    AddHandler={() => toggleAdd()}
                    title={`Subscriptions & Payments`}
                    subTitle={"List of the plans and payments on webkicks"}
                    addbtnName={"Add a new Plan"}
                >
                    <Row>
                        <Col lg={10} md={9} sm={8} xs={12}>
                            <InputGroup>
                                <InputGroupText addonType="prepend" className="bg-white iconSearch">
                                    <i className="fa fa-search fa-lg "></i>
                                </InputGroupText>
                                <input
                                    value={keyword}
                                    onChange={(e) => {
                                        setKeyword(e.target.value )
                                    }}
                                    className={`form-control btnHeightLg border-start-0 mb-2`}
                                    placeholder="Search by Plan Name"
                                    type="text"
                                />
                            </InputGroup>
                        </Col>
                        <Col lg={2} md={3} sm={4} xs={12}>
                            <button type="button" disabled={!keyword} onClick={() => setFilterParams({keyword})} className="btn btnHeightLg btn-outline-primary px-3 w-100">
                                <i className="fa fa-search"></i> 
                                Search
                            </button>
                        </Col>
                    </Row>

                </Filterswrapper>

                {getData ? <div className="loaderStyle">
                    <Circles color="#00BFFF"/>
                </div> : plans.length ? (
                    <div className="table-responsive mt-3">
                        <table className="table table-borderless bg-white">
                            <thead className="border-0 rounded-3" style={{background:"#edf0f3", height:"62px",verticalAlign:"middle"}}>
                                <tr>
                                    <th className="rounded-start opacity-75 ps-4">ID</th>
                                    <th className="opacity-75">Name</th>
                                    {/* <th className="opacity-75">Delivery Duration</th> */}
                                    <th className="opacity-75">Amount</th>
                                    <th className="opacity-75">Status</th>
                                    <th className="rounded-end opacity-75"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {plans.map((plan, index) =>
                                    <CardItem
                                        key={index}
                                        index={index}
                                        plan={plan}
                                        setEditItem={setEditItem}
                                        setViewItem={setViewItem}
                                        setModalAdd={setModalAdd}
                                        setGetData={setGetData}
                                    />
                                )}
                            </tbody>
                        </table>
                    </div>
                    ) 
                        : 
                    (
                        <div className="py-5 text-center">
                            <img src={noProject} alt="" className="mx-auto d-block" width="80px" />
                            <h4 className="py-2">You don’t have any subscription plans</h4>
                            <p>You currently dont have a brief , please one to help the <br />
                                designer uderstand more of what you need</p>
                        </div>
                    )}

                {modalAdd && (
                    <ModalAdd
                        modalAdd={modalAdd}
                        toggleAdd={toggleAdd}
                        editItem={editItem}
                        setGetData={setGetData}
                        view={viewItem ? true:false}
                        viewItem = {viewItem}
                    />
                )}
                <div className="bg-white mt-3">
                    <div className="d-flex justify-content-end" md={8} sm={12}>
                        <Paginator
                            service={planService}
                            pageOptions={pageOptions}
                            setData={(data) => {
                                setPlans([...data])
                                setGetData(false)
                            }}
                            setPageOptions={setPageOptions}
                            filtersParams={filtersParams}
                            shouldGetData={getData}
                            setShouldGetData={() => { }}
                        />
                    </div>
                </div>
            </Container >
        </div>
    );
};

export default Plans;
