import BaseService from "../BaseService";
import { API_BASE_URL_ENV } from "../../helpers/common";
import http from "../HttpService";

const apiEndpoint = API_BASE_URL_ENV() + "/client/steps";


export default class extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  update(type, data) {
    return http.post(`${apiEndpoint}/${type}`, data);
  }

  updateContent(type, data) {
    return http.put(`${apiEndpoint}/${type}/update-page-content`, data);
  }

  confirmPageContent(type, data) {
    return http.put(`${apiEndpoint}/${type}/confirm-page-content`, data);
  }

  addMorePageContent(type, data) {
    return http.put(`${apiEndpoint}/${type}/add-more-page-content`, data);
  }

  uploadDocumentContent(type, data) {
    return http.put(`${apiEndpoint}/${type}/content-file`, data);
  }

  buyHosting(type, id, data) {
    return http.put(`${apiEndpoint}/${type}/${id}/buy-hosting`, data);
  }

}
