import React, { useState } from "react";
import glope from "assets/images/globe.png";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import StepsService from "../../../../services/Client/StepsService";
import { SuccessCreated } from "../../../../configs/statusCode";
import { toast } from "react-toastify";
import globe from "assets/images/globe.png";
import docImage from "assets/images/load.gif";
// import { DomainValidator } from "FormValidations/Client/FourthStep/domain";
// import { yupResolver } from "@hookform/resolvers/yup";
// import avatarImage from 'assets/images/avatar.png';
import { useTranslation } from "react-i18next";

const FourthStepData = ({ projectData, updateProjectData, toggleAccordion, fourthStepData, }) => {
  // const [showBuyHosting, setShowBuyHosting] = useState(fourthStepData?.buy_hosting !== 0);
  const [withUs, setWithUs] = useState(0)
  const stepsService = new StepsService();
  const [fourthData, setFourthData] = useState({...fourthStepData})
  const {
    handleSubmit, register
  } = useForm({ 
    // resolver: yupResolver(DomainValidator)
  });

  // const sendDomain = async (formState) => {
  //   const data = {
  //     order_id: projectData.id, 
  //     ns1: formState.ns1, 
  //     ns2: formState.ns2, 
  //     is_purchased: 0
  //   }
  //   stepsService.update("fourth-step", data).then((res) => {
  //     if (res.status === SuccessCreated) {
  //       toast.success("Your NS submitted Successfully");
  //       updateProjectData({ fourth_step_status: 4 });
  //       toggleAccordion("4");
  //     } else {
  //       toast.error("Please enter the required fields");
  //     }
  //   });
  // };

  const purchaseDomain = async (formState) => {
    const data = {
      order_id: projectData.id, 
      server_info: {
        link: formState.link, 
        user_name: formState.user_name, 
        password: formState.password, 
      }
    };
    stepsService.update("fourth-step", data).then((res) => {
      if (res.status === SuccessCreated) {
        toast.success("Your domain send successfully and we will purchase it");
        updateProjectData({ fourth_step_status: 4 });
        setFourthData({...fourthData, repositories: data})
        toggleAccordion("4");
        setFourthData({...fourthData, server_info: data})
      } else {
        toast.error("Please enter the required fields");
      }
    });
  };

  const requestProject = async () => {
    const data = {
      order_id: projectData.id, 
      need_repositories: 1, 
    };
    stepsService.update("fourth-step", data).then((res) => {
      if (res.status === SuccessCreated) {
        toast.success("Your Request send successfully and Admin will reply");
        updateProjectData({ fourth_step_status: 4 });
        setFourthData({...fourthData, ...data})
      } else {
        toast.error("Please enter the required fields");
      }
    });
  };

  const hostWithUs = async (values) => {
    const data = {
      order_id: projectData.id,
      domain: values.domain,
      need_server: 1, 
    };
    stepsService.update("fourth-step", data).then((res) => {
      if (res.status === SuccessCreated) {
        toast.success("Your Request send successfully and Admin will reply");
        updateProjectData({ fourth_step_status: 4 });
        setFourthData({...fourthData, ...data,domain: '', server_info: null})
      } else {
        toast.error("Please enter the required fields");
      }
    });
  };

  // const buyHosting = async () => {
  //   const data = {
  //     buy_hosting: 1
  //   };
  //   stepsService.buyHosting("fourth-step", fourthStepData.id, data).then((res) => {
  //     if (res.status === SuccessOk) {
  //       toast.success("Your Request send successfully and we will buy a hosting for you ");

  //       setShowBuyHosting(1);
  //     } else {
  //       toast.error("Please enter the required fields");
  //     }
  //   });
  // };

  const { t } = useTranslation();

  // const doHostiong = ()=>{}

  return (<div>
    {projectData.fourth_step_status === 0 && <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <h6 className="fw-bold mb-0">
          <img src={glope} className="pe-2" alt=""/>
          <span className="fw-bold font-size=14">Purchase domain</span>
        </h6>
        {withUs === 0 && <div className="row mt-4 mb-3">
          <div className="col-md-4 mb-2">
            <button className="btn btn-primary w-100" onClick={()=> setWithUs(1)}>
            Host with us
            </button>
          </div>
          <div className="col-md-4 mb-2">
            <button className="btn btn-primary w-100" onClick={()=> setWithUs(2)}>
            Connect to Existing Host
            </button>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary w-100" onClick={requestProject}>
            Don’t Host, Get Code
            </button>
          </div>
        </div>}
        {withUs === 2 && <form
          onSubmit={handleSubmit(purchaseDomain)}
          className="formFeedback ps-lg-4"
        >
          <h6 className="fw-bold mb-0 mt-3"> 
              <span className="fw-bold font-size=14">Instructions</span>
          </h6>
          
          <div className="row pt-2">
            <div className="col-md-8 col-lg-8">
              <label className="text-muted pb-2 font-size-14">Link</label>
              <input
                {...register("link", {
                  required: true
                })}
                type="text"
                className="form-control height-50"
                placeholder="Link"
              />
            </div>
            <div className="col-md-8 col-lg-8">
              <label className="text-muted pb-2 font-size-14">User Name</label>
              <input
                {...register("user_name", {
                  required: true
                })}
                type="text"
                className="form-control height-50"
                placeholder="User Name"
              />
            </div>
            <div className="col-md-8 col-lg-8">
              <label className="text-muted pb-2 font-size-14">Password</label>
              <input
                {...register("password", {
                  required: true
                })}
                type="text"
                className="form-control height-50"
                placeholder="Password"
              />
            </div>
            <div className="col-md-12 col-lg-12 pt-lg-4 mt-2">
              <button
                type="submit"
                className="btn btn-primary me-2 height-50"
              >
                Save
              </button>
              <button
                type="button"
                onClick={()=>setWithUs(0)}
                className="btn btn-secondary me-2 height-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>}
        {withUs === 1 && <form
          onSubmit={handleSubmit(hostWithUs)}
          className="formFeedback ps-lg-4"
        >
          <div className="row">
            <h6 className="fw-bold mb-0 mt-3"> 
              <span className="fw-bold font-size-14">Instructions</span>
            </h6>
            <span className="text-muted">
              Don`t have Domain?
              <a href="#" className="ms-2">Go Daddy</a>
            </span>
          </div>
          
          <div className="row pt-2">
            <div className="col-md-8 col-lg-8">
              <label className="text-muted pb-2 font-size-14">Domain</label>
              <input
                {...register("domain", {
                  required: true
                })}
                type="text"
                className="form-control height-50"
                placeholder="Link"
              />
            </div>
            <div className="col-md-12 col-lg-12 pt-lg-4 mt-2">
              <button
                type="submit"
                className="btn btn-primary me-2 height-50"
              >
                Save
              </button>
              <button
                type="button"
                onClick={()=>setWithUs(0)}
                className="btn btn-secondary me-2 height-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>}
    </div>}

    {!!fourthData?.need_repositories && <>
      {(projectData.fourth_step_status > 0 && !!fourthData?.repositories) ?
        <div className="boxShadow pt-4 px-3 mb-2 pb-3">
            <div>
                <h6 className="pb-3 fw-bold">{t("Your Website Links")}</h6>
                <div className="row">
                {Object.keys(fourthStepData.repositories)?.map((item, index)=>{
                      return <div className="col-lg-12 col-md-12" key={index}>
                        <div className="bg-light p-3 rounded-3 mb-md-3">
                          <img src={globe} className="me-2" />
                          <span className="me-2">{item}: </span>
                          <a
                          href={fourthStepData.repositories[item]}
                          target="_blank"
                          className="text-black fw-bold text-decoration-none"
                          rel="noreferrer"
                          >
                          {fourthStepData.repositories[item]}
                          </a>
                          <a
                          href={fourthStepData.repositories[item]}
                          target="_blank"
                          className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                          rel="noreferrer"
                          >
                          <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                          </a>
                      </div>
                  </div>
                })}
                </div>
            </div>
        </div> : <div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row justify-content-between">
        <div className="col-lg-7 ">
          <h6 className="fw-bold mb-0">
            <img src={docImage} alt="" width={30} height={30}/>
            <span className="fw-bold ps-3 text-primary font-size=14">{t("Waiting for Publishing")}</span>
          </h6>
          <p className="text-muted font-14 mb-2 ps-5 ">
            {t("Choose the hosting option that suits you best. Your plan includes 1-year free hosting.")}
          </p>
          <p className="fw-bold font-size-14 ps-5">{t("Check back soon")}</p>
        </div>
      </div>
      </div> }
    </>}

    {(!!fourthData?.need_server || !!fourthData?.server_info) && projectData.fourth_step_status === 4 && <>
    {(projectData.fourth_step_status === 4 && !!fourthData?.domain && !!fourthData.server_info ) ? (
      <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <div>
              <h6 className="pb-3 fw-bold">{t("Your Website Link")}</h6>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                      <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <a
                        href={fourthData.domain}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthData.domain}
                        </a>
                        <a
                        href={fourthData.domain}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> Go to live site
                        </a>
                    </div>
                </div>
              </div>
          </div>
      </div>) : <div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row justify-content-between">
        <div className="col-lg-7 ">
          <h6 className="fw-bold mb-0">
            <img src={docImage} className="pe-2" alt="" width={30} height={30}/>
            <span className="fw-bold ps-3 text-primary font-size=14">{t("Waiting for Publishing")}</span>
          </h6>
          <p className="text-muted font-14 mb-2 ps-5 ">
            {t("Fill information about your business and goals for this website, this data can vary and time can be from 30 mins to 4 hours")}
          </p>
          <p className="fw-bold font-size-14 ps-5">{t("Check back soon")}</p>
        </div>
      </div>
    </div>}
    </>}

    {(projectData?.fourth_step_status === 1 && !!fourthData?.domain)&& <div className="boxShadow pt-4 px-3 mb-2 pb-3">
            <h6 className="pb-3 fw-bold">{t("Your website is published and live on this domain")}</h6>
            <div className="row">
              <div className="col-lg-10 col-md-9">
                  <div className="bg-light p-3 rounded-3 mb-md-3">
                      <img src={globe} className="me-2" />
                      <a
                      href={fourthData?.domain}
                      target="_blank"
                      className="text-black fw-bold text-decoration-none"
                      rel="noreferrer"
                      >
                      {fourthData?.domain}
                      </a>
                      <a
                      href={fourthData?.domain}
                      target="_blank"
                      className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                      rel="noreferrer"
                      >
                      <i className="fa fa-sign-in pt-1 pe-2"></i> {t("Go to live site")}</a>
                  </div>
              </div>
              <div className="col-lg-2 col-md-3">
                  <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>{t("Published")}</p>
              </div>
            </div>
          </div>}

{/* ********************************************************************************************************************* */}
    {/* { projectData.fourth_step_status === 1 ? (
    <div>  
      <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        {fourthStepData?.is_purchased === 0 ? (
          <div>
            <h6 className="pb-3 fw-bold">{t("Your website is published and live on this domain")}</h6>
            <div className="row">
              <div className="col-lg-10 col-md-9">
                  <div className="bg-light p-3 rounded-3 mb-md-3">
                      <img src={globe} className="me-2" />
                      <a
                      href={fourthStepData?.ns1}
                      target="_blank"
                      className="text-black fw-bold text-decoration-none"
                      rel="noreferrer"
                      >
                      {fourthStepData?.ns1}
                      </a>
                      <a
                      href={fourthStepData?.ns1}
                      target="_blank"
                      className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                      rel="noreferrer"
                      >
                      <i className="fa fa-sign-in pt-1 pe-2"></i> {t("Go to live site")}</a>
                  </div>
              </div>
              <div className="col-lg-2 col-md-3">
                  <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>{t("Published")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 col-md-9">
                  <div className="bg-light p-3 rounded-3 mb-md-3">
                      <img src={globe} className="me-2" />
                      <a
                      href={fourthStepData?.ns2}
                      target="_blank"
                      className="text-black fw-bold text-decoration-none"
                      rel="noreferrer"
                      >
                      {fourthStepData?.ns2}
                      </a>
                      <a
                      href={fourthStepData?.ns2}
                      target="_blank"
                      className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                      rel="noreferrer"
                      >
                      <i className="fa fa-sign-in pt-1 pe-2"></i> {t("Go to live site")}
                      </a>
                  </div>
              </div>
              <div className="col-lg-2 col-md-3">
                  <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>{t("Published")}</p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h6 className="pb-3 fw-bold">{t("Your website is published and live on this domain")}</h6>
            <div className="row">
                <div className="col-lg-10 col-md-9">
                    <div className="bg-light p-3 rounded-3 mb-md-3">
                        <img src={globe} className="me-2" />
                        <a
                        href={fourthStepData?.domain}
                        target="_blank"
                        className="text-black fw-bold text-decoration-none"
                        rel="noreferrer"
                        >
                        {fourthStepData?.domain}
                        </a>
                        <a
                        href={fourthStepData.domain}
                        target="_blank"
                        className="text-primary fw-bold text-decoration-none float-end d-none d-md-flex"
                        rel="noreferrer"
                        >
                        <i className="fa fa-sign-in pt-1 pe-2"></i> {t("Go to live site")}
                        </a>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3">
                    <p className="textGreen pt-3"><i className="fa fa-check-circle me-2"></i>{t("Published")}</p>
                </div>
            </div>
          </div>
        )}
      </div>
      <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <div className="row">
          <div className="col-lg-4">
            <img src={avatarImage} className="imageFinish" />
          </div>
          <div className="col-lg-8">
            <h5 className="fw-bold">{t("Your website is securely hosted on your servers")}</h5>
            <p className="text-muted">{t("Check out this video to understand how to edit and change things on your website without the need to talk to a designer or developer.")}</p>
            {!showBuyHosting ? <button
              type="button"
              onClick={() => buyHosting()}
              className="btn btn-primary height-45 px-4 mt-3">
              Buy Hosting
            </button> : (
              <button
                type="button"
                className="btn btn-primary height-45 px-4 mt-3">
                {t("Go to Cpanel")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>) : (<div>
      {projectData.has_domain === 1 ? <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <div className="row justify-content-between">
          <div className="col-lg-8">
            <h6 className="fw-bold mb-0">
              <img src={glope} className="pe-2" alt=""/>
              <span className="fw-bold font-size=14">{t("Connect a domain")}</span>
            </h6>
            <form
              onSubmit={handleSubmit(sendDomain)}
              className="formFeedback ps-lg-4"
            >
              <div className="pt-3">
                <label className="text-muted">Record 1</label>
                <input
                  {...register("ns1", {
                    required: true
                  })}
                  type="text"
                  className="form-control height-42"
                  placeholder="ns1.212.mx.com"
                />
              </div>
              <div className="pt-2">
                <label className="text-muted">Record 2</label>
                <input
                  {...register("ns2", {
                    required: true
                  })}
                  type="text"
                  className="form-control height-42"
                  placeholder="ns1.212.mx.com"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary height-45 px-4 mt-3"
              >
                {t("Save")}
              </button>
            </form>
            <div className="ps-lg-4 pt-4">
              <p className="text-muted"><span className="badge badgeNumber mb-2 rounded-circle me-2">1</span>
                {t("Go to your domain provider")}
              </p>
              <p className="text-muted"><span className="badge badgeNumber mb-2 rounded-circle me-2">2</span>
                {t("Open domain settings, then MX records")}
              </p>
              <p className="text-muted"><span className="badge badgeNumber mb-2 rounded-circle me-2">3</span>
                {t("Paste MX records in the above fields")}
              </p>
            </div>
          </div>
        </div>
      </div> : ""}
    </div>)} */}

  </div>);
};
FourthStepData.propTypes = {
  projectData: PropTypes.object,
  fourthStepData: PropTypes.any,
  updateProjectData: PropTypes.func,
  toggleAccordion: PropTypes.func
};
export default FourthStepData;