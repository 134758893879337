import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";
import axios from "axios";

const apiEndpoint = API_BASE_URL_ENV() + "/client/user/profile";

export default class extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getProfileData() {
    return http.get(this.apiEndpoint);
  }

  getGoogleUserData(user) {
    return axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: "application/json"
        }
      });
  }

  profileUpdate(data) {
    return http.post(this.apiEndpoint, data);
  }
}
