import * as yup from "yup";
import {
    TITLE_PATTERN_VALIDATOR,
    EMAIL_PATTERN_VALIDATOR
} from "helpers/validators";

export const loginValidator = yup.object().shape({
  email: yup
      .string()
      .matches(
          EMAIL_PATTERN_VALIDATOR.value,
          EMAIL_PATTERN_VALIDATOR.errorMessage
      )
      .required(),
  password: yup.string()
      .required(),
 
});

export const registerValidator = yup.object().shape({
  name: yup
  .string()
  .matches(
    TITLE_PATTERN_VALIDATOR.value,
    TITLE_PATTERN_VALIDATOR.errorMessage
  )
  .required(),
  email: yup
  .string()
  .matches(
    EMAIL_PATTERN_VALIDATOR.value,
    EMAIL_PATTERN_VALIDATOR.errorMessage
  )
  .required(),
  password: yup.string().required(),
   confirm_password: yup.string()
      .required()
      .oneOf([yup.ref('password')], 'Passwords does not match'),
});
