import React from "react";
import Footer from "./Layouts/Footer";
import PropTypes from "prop-types";
import Header from './../Website/Layouts/Header/index';


const NonAuthClientLayout = (props) => {
    return (
        <>
            <Header/>
            <>
                {props.children}
            </>
            <Footer/>
        </>
    );

}

NonAuthClientLayout.propTypes = {
    children: PropTypes.any,
};
export default NonAuthClientLayout
