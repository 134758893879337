import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { toast } from "react-toastify";
import { Col, FormGroup, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useState } from "react";
import ProfileService from "services/Client/ProfileService";

const Password = ({profileData, handleFieldChange, setChangePassword, getProfileDetails}) =>{
    const profileService = new ProfileService();
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
    const [isCurrentPasswordShown, setIsCurrentPasswordShown] = useState(false);
    const togglePassword = () => setIsPasswordShown(!isPasswordShown);
    const toggleConfirmedPassword = () => setIsConfirmPasswordShown(!isConfirmPasswordShown);
    const toggleCurrentPassword = () => setIsCurrentPasswordShown(!isCurrentPasswordShown);
    const [errors, setErrors] = useState({})

    const handleChangePasswordSubmit = (e) => {
      e.preventDefault()
      let errs = {}
      if(!profileData?.current_password) errs['current_password'] = true
      if(!profileData?.password) errs['password'] = true
      if(!profileData?.password_confirmation) errs['password_confirmation'] = true
      if(!profileData?.current_password || !profileData?.password || !profileData?.password_confirmation){
        setErrors(errs)
        return
      }

      const data = {
        name: profileData.name,
        email: profileData.email,
        current_password: profileData.current_password,
        password: profileData.password,
        password_confirmation: profileData.password_confirmation
      };
  
      profileService.profileUpdate(data).then((res) => {
        if (res?.status === 200) {
          toast.success("Password Successfully Updated");
          getProfileDetails();
          setChangePassword(false)
        }
        else {
          toast.error("Please enter the required fields");
        }
      }).catch(error=>{
        toast.error(error?.response?.data?.error)
    })
    };

    return <form className="g-3 needs-validation">
    <Row>
    <Col md="6" className="pt-1">
        <div className="position-relative">
          <FormGroup>
            <label className={`${errors?.current_password && "text-danger"}`}>Current Password</label>
            <input
              value={profileData.current_password}
              name='current_password'
              className={`form-control height-47 ${
                errors?.current_password && "border-danger"
              }`}
              placeholder="********"
              label="Current Password"
              required
              type={isCurrentPasswordShown ? "text" : "password"}
              onChange={(e) => {
                handleFieldChange(e, "current_password");
                setErrors({...errors, current_password: false})
              }}
            />
            {errors?.current_password && <ErrorMessage message='this field is required' />}

            <i onClick={toggleCurrentPassword} 
              style={{position: 'absolute', top: '47px'}}
              className={`fa ${isCurrentPasswordShown ? 'fa-eye-slash':'fa-eye'}  fa-lg input-addon pt-2`}
            ></i>
          </FormGroup>
        </div>
      </Col>
      <Col md="6" className="pt-1"></Col>
      <Col md="6" className="pt-1">
        <div className="position-relative">
          <FormGroup>
            <label className={`${errors?.password && "text-danger"}`}>Password</label>
            <input
              value={profileData.password}
              name='password'
              className={`form-control height-47 ${
                errors?.password && "border-danger"
              }`}
              placeholder="********"
              label="Password"
              required
              type={isPasswordShown ? "text" : "password"}
              onChange={(e) => {
                handleFieldChange(e, "password");
                setErrors({...errors, password: false})
              }}
            />
            {errors?.password && <ErrorMessage message='this field is required' />}

            <i onClick={togglePassword} 
              style={{position: 'absolute', top: '47px'}}
              className={`fa ${isPasswordShown ? 'fa-eye-slash':'fa-eye'}  fa-lg input-addon pt-2`}
            ></i>
          </FormGroup>
        </div>
      </Col>
      <Col md="6" className="pt-1">
        <div className="position-relative">
          <FormGroup>
            <label className={`${errors?.password_confirmation && "text-danger"}`}>Confirm Password</label>
            <input
              value={profileData.password_confirmation}
              className={`form-control height-47 ${
                errors?.password_confirmation && "border-danger"
              }`}
              name="password_confirmation"
              required
              placeholder="********"
              type={isConfirmPasswordShown ? "text" : "password"}
              onChange={(e) => {
                handleFieldChange(e, "password_confirmation");
                setErrors({...errors, password_confirmation: false})
              }}
            />
            {errors?.password_confirmation && <ErrorMessage message='this field is required' />}

            <i onClick={toggleConfirmedPassword} 
              style={{position: 'absolute', top: '47px'}}
              className={`fa ${isConfirmPasswordShown ? 'fa-eye-slash':'fa-eye'}  fa-lg input-addon pt-2`}
            ></i>
          </FormGroup>
        </div>
      </Col>
    </Row>
    <button className="btn btn-primary px-4 height-42" 
    // type="submit"
    onClick={handleChangePasswordSubmit}
    >Change</button>
  </form>
}

Password.propTypes = {
    profileData: PropTypes.object,
    handleFieldChange: PropTypes.any,
    getProfileDetails: PropTypes.any,
    setChangePassword: PropTypes.any,
};

export default Password;