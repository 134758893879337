import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/website/order/order-subscription-plans";
const locationApi=API_BASE_URL_ENV() + "/website/order/location-data"

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getLocation=()=>{
        return http.get(locationApi);
     }
    
}
