import * as yup from "yup";
// import {
//     TITLE_PATTERN_VALIDATOR,
// } from "helpers/validators";
export const AddTemplateValidator = yup.object().shape({
    name:  yup.object().shape({
        // ar: yup.string()
        //     .required(),
        en:  yup
        .string()
        // .matches(
        //   TITLE_PATTERN_VALIDATOR.value,
        //   TITLE_PATTERN_VALIDATOR.errorMessage
        // )
        .required(),
    }),
    // description:  yup.object().shape({
    //     ar: yup.string()
    //         .required(),
    //     en: yup.string()
    //         .required()
    // }),
    url: yup.string().required(),
    image: yup.string().required(),
    type:  yup.array().required(),
    // design:  yup.array().required(),
    industry:  yup.array().required(),
    status:  yup.boolean()


});