import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Progress, Row, Col } from "reactstrap";
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { appendFilters } from "store/Auth/actions"
import websiteImage from "assets/images/website.png"
// import ecommerceImg from "assets/images/ecommerce.png"
import fileImg from "assets/images/ecommerce.png"
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const TypeWebsite = ({ setStep, next, back }) => {
  const { t, i18n } = useTranslation();
  const [completed, setCompleted] = useState(70);
  const [itemSelected, setItemSelected] = useState()
  const data = useSelector(state => state.Auth)
  const dispatch = useDispatch()

  useEffect(() => {
    setItemSelected(data.website_type);
    setInterval(() => setCompleted(85), 1000);
  }, []);

  const onRadioBtnClick = (itemSelected) => {
    setItemSelected(itemSelected);
  };

  const handleSubmit = () => {
      if (itemSelected || itemSelected ===0) {
      dispatch(appendFilters({ website_type: itemSelected }))
      setStep(next)
    } else {
      toast.error("Choose Website Type")
    }
  }

  return (
    <div className="card mb-4 mt-3">
      <div className="card-header border-0 p-0">
        <Progress value={completed} />
      </div>
      <div className="card-body text-center pt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={websiteImage} className="img-fluid" alt="website" />
            <h3 className="pb-2 pt-3 fw-bold">
              {t("Select the type of website you wish to create")}
            </h3>
            <p className="text-muted pb-3">
              {t("Fill information about your business and goals for this website, this data")}
              <br /> {t("can vary and time can be from 30 mins to 4 hours ")}
            </p>
            <form>
              <ButtonGroup className="btnGroup btnType d-grid d-md-flex">
                <Row className="justify-content-around">
                  {/* <Col lg={4} md={6} xs={6}>
                    <Button
                      color="outline-secondary"
                      onClick={() => onRadioBtnClick(0)}
                      active={itemSelected === 0}
                    >
                      <div className="imgType mb-2">
                        <img src={ecommerceImg} />
                      </div>
                      <span>{t("E-commerce / Store")}</span>
                    </Button>
                  </Col> */}
                  {data.typeOptions.length > 0 && data.typeOptions.map((item, index) => {
                    return (
                      <Col key={index} lg={4} md={6} xs={6}>
                        <Button
                          color="outline-secondary"
                          active={itemSelected === index + 1}
                          onClick={() => {
                            onRadioBtnClick(index + 1)
                            dispatch(appendFilters({ type: item }))
                          }}
                        >
                          <div className="imgType mb-2">
                            <img src={fileImg} />
                          </div>
                          <span>{item.label}</span>
                        </Button>
                      </Col>
                    )
                  })}
                </Row>
              </ButtonGroup>
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button
                  className="btn btnHeightLg px-3 py-2"
                  onClick={() => setStep(back)}
                  type={"button"}
                >
                   {t("Back")} {i18n.language !== "ar" ? <i className="fa fa-sign-out-alt px-2 rotateDegree float-start pt-1 pe-3"></i>
                    : <i className="fa fa-sign-out-alt px-2 float-end pt-1 ps-3"></i>}
                </button>
                <button
                  className="btn btn-primary btnHeightLg px-3 py-2"
                  type="button"
                  onClick={handleSubmit}
                >
                  {t("Continue")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i>
                    : <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};


TypeWebsite.propTypes = {
  setStep: PropTypes.func,
  next: PropTypes.string, 
  back: PropTypes.string

};
export default TypeWebsite;
