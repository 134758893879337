import React ,{useState} from 'react'
import { Modal } from "reactstrap";
import PropTypes from "prop-types"
import { toast } from "react-toastify";
import deleteImg from "assets/images/delete.png"
import closeImg from "assets/images/close.png"
function DeleteModal(props) {
    const [loading, setLoading] = useState(false)
    const handleDeletedItem = () => {
        setLoading(true)
        props.modelService.remove(props.deletedItem.id).then(response=>{
            toast.success(response.success);
            setLoading(false)
            return props.onCloseModal('delete', response)
        // eslint-disable-next-line no-unused-vars
        }).catch((e) => {
            props.onCloseModal('close')
            props.setcantDeleteModal(true)
            setLoading(false)
        })
    }
    return (
        <div>
            <Modal isOpen={props.open} className="modal-md">
                <div className='modal-header border-0'>
                    <button
                        onClick={() => props.onCloseModal('close')}
                        className="btn ms-2"
                        data-dismiss="modal"
                    >
                        <img src={closeImg} height="25" />
                    </button>
                </div>
                <div className="modal-body border-0 text-center">
                    <img src={deleteImg} className="img-fluid pb-3" />
                    <h5 className='py-3 fw-bold'>Are you sure you want to delete {props.titleMsg}?</h5>
                    <p className='pb-2'>
                        {`Kindly note that this action can't be undone and the ${props.titleMsg} details will get deleted.`}
                    </p>
                    <div className='pb-3 btnFooter'>
                        <button
                            type="button"
                            onClick={() => props.onCloseModal('close')}
                            className="btn me-2 btn-outline-danger px-4"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleDeletedItem}
                            type="button"
                            className="btn btnRed text-white px-4"
                            disabled={loading ? true : false}

                        >
                            Yes, Delete
                        </button>
                        
                    </div>
                </div>
            </Modal>
        </div>
    )
}

DeleteModal.propTypes = {
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    handleTableChange: PropTypes.func,
    setcantDeleteModal: PropTypes.func,
    deletedItem: PropTypes.object,
    modelService: PropTypes.object,
    titleMsg: PropTypes.string
};

export default DeleteModal
