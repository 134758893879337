import avtImage from "assets/images/avatars.png";

export const API_BASE_URL_ENV = () => {
  return "https://webkicks.co/.website_8e6ae6d3/public/api"
  // return "https://pencilprod.com/webkiks/backend/public/api";
  // return 'http://localhost:8000/api';
};

export const API_CALENDLY_URL_ENV = [
  {assignee: {id: 50, name: "Ahmed Mohamed Essa"}, url: 'https://calendly.com/sarakilany13'},
  {assignee: {id: 48, name: "Assem Sholkamy"}, url: 'https://calendly.com/pencil-development-test'},
]

export const GET_AUTH_USER = () => JSON.parse(localStorage.getItem("auth"));

export const generateQueryString = (params) => Object.keys(params).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])).join("&");

export const imageOnError = (event, className = "img-broken") => {
  event.currentTarget.src = avtImage;
  event.currentTarget.className = className;
};

// export const handleFieldChange = (e, setFormData, formData) => {
//     setFormData({...formData, ...{[e.target.name]: e.target.value}})
// }

// export const handleSelectChange = (option, fieldName, setFormData, formData, cbFunc = {}) => {
//     const optionValues = Array.isArray(option) ? option.map(({value}) => value) : option ? option.value : null
//     setFormData({...formData, ...{[fieldName]: optionValues}})
//     if (!_.isEmpty({cbFunc}))
//         cbFunc[fieldName]
// }

// export const getOrderColor = (status) => {
//     switch (status) {
//         case OrderStatusEnum.PENDING.value:
//             return OrderStatusEnum.PENDING.className;
//         case OrderStatusEnum.APPROVED.value:
//             return OrderStatusEnum.APPROVED.className;
//         case OrderStatusEnum.CANCELED.value:
//             return OrderStatusEnum.CANCELED.className;
//         case OrderStatusEnum.DELIVERED.value:
//             return OrderStatusEnum.DELIVERED.className;
//         case OrderStatusEnum.REJECTED.value:
//             return OrderStatusEnum.REJECTED.className;
//         default:
//             return 'btnDone';
//     }
// }

// export const getBackgroundOrderColor = (status) => {
//     switch (status) {
//         case OrderStatusEnum.PENDING.value:
//             return 'bg-info';
//         case OrderStatusEnum.APPROVED.value:
//             return 'bg-success';
//         case OrderStatusEnum.CANCELED.value:
//             return 'bg-danger';
//         case OrderStatusEnum.DELIVERED.value:
//             return 'bg-warning';
//         case OrderStatusEnum.REJECTED.value:
//             return 'bg-danger';
//         default:
//             return 'bg-info';
//     }
// }


export const getLang = () => {
  return localStorage.getItem("web-kicks_lang");
};


export const getCurrency = (country) => {
  if (country === "Saudi Arabia") {
    return "SAR";
  } else if (country === "Egypt") {
    return "EGP";
  } else {
    return "USD";
  }

};


