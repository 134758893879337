import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, InputGroup, InputGroupText } from "reactstrap";
import Select from "react-select";
import CategoryService from "services/Admin/CategoryService";
import { SuccessOk } from "configs/statusCode";
import { useTranslation } from 'react-i18next';
import OrderService from "services/Admin/OrderService";
import { GET_AUTH_USER } from "helpers/common";

const types = ["type", "industry"] // "design","size"
const Filters = ({ formState, setFormState }) => {
  // const [designOptions, setDesignOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [industryOptions, seIndustryOptions] = useState([]);
  const [accountManagerOptions, setAccountManagerOptions] = useState([]);
  // const [sizeOptions, setSizeOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const { t } = useTranslation();
  const orderService = new OrderService();
  const userRole = GET_AUTH_USER();
  
  useEffect(() => {
    types.forEach((type) => {
      new CategoryService()
        .getDropDown("categoryValues", { type })
        .then((res) => {
          if (res.status === SuccessOk) {
            const options = res.data.data.map((item) => ({
              label: item.name.en,
              value: item.id,
            }));
            if (type === "type") {
              setTypeOptions([...options]);
            } else {
              seIndustryOptions([...options]);
            }
            // else if (type === "design") {
            //   setDesignOptions([...options]);
            // } else if (type === "size") {
            //   setSizeOptions([...options]);
            // } 
          }
        });
    });

    orderService.getDropDown("assignee", {status :1}).then(res=>{
      if(res?.status === 200){
        let data = res.data.data?.map(acc=>{
          return{
            label: acc.name,
            value: acc.id
          }
        })
        setAccountManagerOptions([{label: 'Not Assigned',value: 'null'},...data]);
      }
    })
  }, []);

  return (
    <>
      <Row>
        <Col lg={10} md={9} sm={8} xs={12}>
          <InputGroup className="inputGroup mb-2 px-2 px-md-0">
            <InputGroupText
              addonType="prepend"
              className="bg-white iconSearch"
            >
              <i className="fa fa-search fa-lg"></i>
            </InputGroupText>
            <input
              valuee={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className={`form-control border-start-0 btnHeightLg mb-2`}
              placeholder={t('Search Projects by name , description or Template name')}
              type="text"
            />
          </InputGroup>
        </Col>
        <Col lg={2} md={3} sm={4} xs={12}>
            <div className="px-2 px-md-0">
                <button
                    type="button"
                    onClick={() => {
                    setFormState((state) => ({ ...state, keyword }));
                    }}
                    className="btn btn-outline-primary btnHeightLg px-3 w-100"
                >
                    <i className="fa fa-search me-2"></i>
                    {t("Search")}
                </button>
            </div>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col lg={9}>
          <div className="d-lg-flex pt-2">
            <div>
              <label className="pe-2 py-2 text-muted" style={{ width: "100px" }}>
                {t("Filter by")}
              </label>
            </div>
            <div className="row w-100">
              {/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Design"
                  options={designOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedDesign: data })
                  }
                  value={formState.selectedDesign}
                  setOptions={(options) => setDesignOptions(options)}
                  isMulti={true}
                />
              </div> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Industry"
                  options={industryOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedindustry: data })
                  }
                  value={formState.selectedindustry}
                  setOptions={(options) => seIndustryOptions(options)}
                  isMulti={true}
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Type"
                  options={typeOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedType: data })
                  }
                  value={formState.selectedType}
                  setOptions={(options) => setTypeOptions(options)}
                  isMulti={true}
                />
              </div>
              {(userRole.role === "Admin" || userRole.role === "Account Manager") && <div className="col-lg-4 col-md-4 col-sm-6 pb-3 ps-3">
                <button 
                  onClick={()=> {
                    setFormState({ ...formState, selectedType: '',selectedindustry: '', accountManager: ''})
                  }}
                  className="btn border-0 fw-bold btn-outline-secondary height-42">
                        Reset
                </button>
              </div>}
            </div>
          </div>
        </Col>
        {/* {userRole.user_type === 'user' && <div className="col-lg-3 col-md-6 col-sm-6 pb-3 ps-3">
          <button 
            onClick={()=> {
              setFormState({ ...formState, selectedType: '',selectedindustry: ''})
            }}
            className="btn border-0 fw-bold btn-outline-primary mt-sm-2 float-md-end px-5 height-42">
                  View all Projects
          </button>
        </div>} */}
        {(userRole.role === "Admin" || userRole.role === "Account Manager") && <Col lg={3} className="py-2 pb-3">
          <div className="d-lg-flex justify-content-between">
            <div>
              <label
                className="text-muted py-2 font-size-14"
                style={{ width: "70px" }}
              >
                {t("Filter by")}
              </label>
            </div>
            <div className="w-100  pe-lg-0 ">
              <div className="row">
                <div className="col-lg-12 col-md-4 col-sm-6 ps-lg-4">
                  <div className="pe-3 pe-md-0 accountManagerSelect">
                    {/* <input
                      type="date"
                      value={formState.date}
                      className="form-control"
                      onChange={(e) =>
                        setFormState((state) => ({
                          ...state,
                          date: e.target.value,
                        }))
                      }
                    /> */}
                    <Select
                      placeholder="Account Manager"
                      options={accountManagerOptions}
                      className="borderSelect"
                      onChange={(data) =>
                        setFormState({ ...formState, accountManager: data })
                      }
                      value={formState.accountManager}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>}
      </Row>
    </>
  );
};

Filters.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
};

export default Filters;
