import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, Label, Form } from "reactstrap";
import PropTypes from "prop-types";
import arrowImg from "assets/images/arrow.png";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { EditContentValidator } from "../../../../FormValidations/Client/SecondStep/edit";
import { SuccessOk } from "../../../../configs/statusCode";
import { toast, ToastContainer } from "react-toastify";
import StepsService from "../../../../services/Client/StepsService";
import FileUpload from "components/Common/file-upload/file-upload.component";

const EditPageContentModal = ({ modalContent, toggleModalContent, item, projectData }) => {
  const stepsService = new StepsService();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(EditContentValidator),
    defaultValues: {
      title: item.title,
      description: item.description,
      reference_url: item.reference_url
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadState, setUploadState] = useState(false)
  const [media, setMedia] = useState({
    id: "",
    path: "",
  })

  useEffect(() => {
    if (item) {
      setMedia({...item.media})
    }
  }, [item])

  const handleValidSubmit = (formState) => {
    setIsSubmitting(true);
    const data = {
      order_id: projectData.id,
      title: formState.title,
      description: formState.description,
      reference_url: formState.reference_url,
      media_id:media.path?.length  ? media.id : null,
      id: item.id
    };
    stepsService.updateContent("second-step", data).then((res) => {
      if (res.status === SuccessOk) {
        toast.success("Project content successfully submitted");
        toggleModalContent();
      } else {
        toast.error("Whoops something went wrong");
        setIsSubmitting(false);
      }
    });
  };

  const updatedAttachment = (attachments) => {
    setMedia({
      id: attachments[0] ?.id,
      path: attachments[0] ?.path,
    })
  }

  const deleteAttachment = () => {
    setMedia({ id: "", path: "" })
  }
  return (
    <>
      <ToastContainer />
      <Modal isOpen={modalContent} className="modal-dialog-scrollable modal-lg container"
        toggle={() => toggleModalContent()}>
        <ModalHeader className="modalHead me-2" toggle={() => toggleModalContent()}>
          <div>
            <h6 className="fw-bold">Edit Page Content</h6>
            <p className="font-14 text-muted mb-0">Fill information about your business and goals for this website</p>
          </div>
        </ModalHeader>
        <ModalBody className="border-0 py-4">
          <div>
            <Form onSubmit={handleSubmit(handleValidSubmit)} className="g-3 needs-validation">
              <div className="list-group list-group-flush">
                <div className="list-group-item bgGray pb-3">
                  <div className="d-flex pb-3">
                    <div className="form-group w-100">
                      <label className="pb-2">Title</label>
                      <input
                        placeholder={"title"}
                        name={`title`}
                        {...register(`title`)}
                        type="text"
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                          }`}
                      />
                      {errors.title ?.message && (
                        <ErrorMessage
                          message={errors.title ?.message}
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <div>
                      <Label> Description</Label>
                      <textarea
                        rows="4"
                        name={`description`}
                        {...register(`description`)}
                        placeholder="3 Fill information about your business and goals for this website and this data can vary and time can be from."
                        className={`form-control ${
                          errors ?.description ? "is-invalid" : ""
                        }`}
                      ></textarea>
                    </div>
                    {errors ?.description ?.message && (
                      <ErrorMessage
                        message={errors ?.description ?.message}
                      />
                    )}
                  </div>
                  <div className="sectionUpload pt-3">
                    <FileUpload
                      accept='.pdf,.doc,.docx'
                      label='Attach a file content'
                      attachmentType='media'
                      attachmentData={[{
                        id: media.id,
                        path: media.path
                      }]}
                      updatedAttachment={updatedAttachment}
                      deleteAttachment={deleteAttachment}
                      setUploadState={setUploadState}

                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <hr className="w-100"/>
                    <span>OR</span>
                    <hr className="w-100"/>
                  </div>
                  <div className="d-flex pb-3">
                    <div className="form-group w-100">
                      <label className="pb-2">Google Document URL</label>
                      <input
                        placeholder={"reference_url"}
                        name={`reference_url`}
                        {...register(`reference_url`)}
                        type="text"
                        className={`form-control ${
                          errors.reference_url ? "is-invalid" : ""
                        }`}
                      />
                      {errors.reference_url ?.message && (
                        <ErrorMessage
                          message={errors.reference_url ?.message}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="border-top border-secondary mt-5" />
              <div className="text-end">
                <button
                  className="btn btn-primary d-block ms-auto text-start height-42"
                  type="submit"
                  disabled={uploadState ? true : isSubmitting ? true : false}
                >Continue
                  <img className="float-end pt-2 ps-5" src={arrowImg} alt="" />
                </button>
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
EditPageContentModal.propTypes = {
  toggleModalContent: PropTypes.func,
  modalContent: PropTypes.bool,
  item: PropTypes.object,
  projectData: PropTypes.object
};

export default EditPageContentModal;
