import React, { useEffect, useState, useRef } from "react";
import ChatItem from "./ChatItem";
import ChatService from "services/Website/ChatService";
import { Row, Col, Container } from "reactstrap";
import "./styles.scss";
import { getUser } from "../../Utility/localStorageUtil";

const Chat = require("twilio-chat");

const ChatTwilio = () => {
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState("");
  const [client, setClient] = useState("");
  const [channel, setChannel] = useState("");
  const scrollDiv = useRef();
  const messagesRef = useRef();
  const { email } = getUser();

  useEffect(() => {
    startChat();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }

    setChannel(channel);
    channel.on("messageAdded", handleMessageAdded);
    // scrollToBottom();
  };

  const handleMessageAdded = (message) => {
    if (messagesRef.current) {
      messagesRef.current = [...messagesRef.current, message];
      setMessages(messagesRef.current);

    } else {
      messagesRef.current = [message];
      setMessages(messagesRef.current);

    }


  };


  const scrollToBottom = () => {
    const scrollHeight = scrollDiv.current?.scrollHeight;
    const height = scrollDiv.current?.clientHeight;
    const maxScrollTop = scrollHeight - height;
    scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const startChat = async () => {
    let token = "";
    token = await getToken(email);
    const client = await Chat.Client.create(token);
    setClient(client);
    client.on("tokenAboutToExpire", async () => {
      const token = await getToken(email);
      client.updateToken(token);
    });

    client.on("tokenExpired", async () => {
      const token = await getToken(email);
      client.updateToken(token);
    });

    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      if (channel.channelState.uniqueName === "24-24") {
        const messages = await channel.getMessages();
        messagesRef.current = messages.items;
        setMessages(messagesRef.current);
        //  await channel.setNoMessagesConsumed();
        channel.updateLastConsumedMessageIndex(messages.items.length - 1)
          .then(function() {
          });
        var members = channel.getMembers();
        members.then(function(currentMembers) {
          currentMembers.forEach(function(member) {

            let updatedChannel = { ...channel };
            for (let key in updatedChannel) {
              if (key === "members") {
                for (let id in key) {
                  if (id === member.identity) {
                    //  id.value.channelState.lastConsumedMessageIndex = member.lastConsumedMessageIndex

                  }

                }
              }
            }
            // setChannel(updatedChannel)
            // updateMemberMessageReadStatus(
            //     member.identity,
            //     member.lastConsumedMessageIndex,
            //     member.lastConsumptionTimestamp
            // );


          });
        });


        // channel.on('memberUpdated', function (event) {

        //     let updatedChannel = { ...channel }

        //     for (let key in updatedChannel) {
        //         if (key === "members") {
        //             for (let id in key) {
        //                 if (id === event.member.identity) {
        //                     id.value.channelState.lastConsumedMessageIndex = event.member.lastConsumedMessageIndex

        //                 }

        //             }
        //         }
        //     }

        // });
      }
      // scrollToBottom();
    });

    try {
      const channel = await client.getChannelByUniqueName("24-24");

      joinChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: "24-24", friendlyName: "24-24"
        });
        joinChannel(channel);
      } catch (e) {
        console.log(e);
        throw new Error("Unable to create channel, please reload this page");
      }
    }
  };

  const getToken = async (email) => {
    const response = await new ChatService().getToken(email);
    return response.data.token;

  };

  const sendMessage = () => {
    if (messageText) {
      channel.sendMessage(String(messageText).trim());
      setMessageText("");
    }
  };

  const addImagesHandler = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    client.getChannelBySid(channel.sid).then(function(channel) {
      channel.sendMessage(formData);
    });
  };

  return (<div className="bg-white chat rounded-0 pt-5 mt-4">
    <Container>
      <Row>
        <Col lg={8}></Col>
        <Col lg={4}>
          <p className="fw-bold font-14 mt-2">Chat with your account manager </p>
          <div ref={scrollDiv} style={{ overflow: "auto", height: "66vh" }}>
            {messages && messages.map((message) => {
              return (<ChatItem
                  key={message.index}
                  message={message}
                  email={email}
                />);
            })}
          </div>
          <Row className="justify-content-between align-items-center mt-3 mb-1 px-4">
        <Col md={9} className="px-0">
          <input
            className="w-100 py-1"
            style={{ borderColor: "rgba(0,0,0,.1)", borderRadius: "5px" }}
            value={messageText}
            onChange={(event) => setMessageText(event.target.value)}
          />
        </Col>
        <Col md={1} className="px-0">

          <input type="file" id="upload" hidden onChange={addImagesHandler}/>
          <label htmlFor="upload" className="upload-label py-2 px-2 mb-3">
            <i className="fa-solid fa-file-alt"></i>
          </label>
        </Col>
        <Col md={1} className="px-1">
          <button className="btn btn-primary p-1" onClick={sendMessage}><i className="fa-solid fa-paper-plane "></i>
          </button>
        </Col>

      </Row>
        </Col>
      </Row>
      
    </Container>
    </div>);
};

export default ChatTwilio;
