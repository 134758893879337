import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "reactstrap";
import PropTypes from 'prop-types';
import {   useSelector, useDispatch } from "react-redux"
import {validatorBusinessDescription} from "FormValidations/Website/steps"
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import {appendFilters } from "store/Auth/actions"
import brieflyImage from "assets/images/briefly.png"
import { useTranslation } from 'react-i18next';

const Briefly = ({setStep, next, back}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } =useForm({ resolver: yupResolver(validatorBusinessDescription) });
  const { t ,i18n } = useTranslation();
  const data = useSelector(state => state.Auth)
  const dispatch = useDispatch()
  const [completed, setCompleted] = useState(30);

  useEffect(() => {
    setValue("description",data.business_description);
    setInterval(() => setCompleted(45), 1000);
 }, []);

 const handleValidSubmit = (data) => {
  dispatch(appendFilters({ business_description: data.description }))
  setStep(next)
}

  return (
    <div className="card mb-4 mt-3">
      <div className="card-header border-0 p-0">
        <Progress value={completed} />
      </div>
      <div className="card-body text-center pt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={brieflyImage} className="img-fluid" alt="briefly" />
            <h3 className="pb-2 pt-3 fw-bold">{t("Briefly describe your business or the services you provide")}</h3>
            <p className="text-muted pb-3">
            {t("Information of websites you visit. Use tools for lead generation,market")} <br /> {t("analysis and competitor research.")}
            </p>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
              <textarea
                {...register("description")}
                placeholder={t("Zero Gravity, LLC is a huge company started in 1995 when all clients are big whales in the industry its easy competiton")}
                className={`form-control ${
                  errors ?.description && "border-danger"
                }`}
                rows="4"
              >
              </textarea>
              {errors ?.description && <ErrorMessage message={errors?.description?.message} />}
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button
                  className="btn btnHeightLg px-3 py-2"
                  onClick={()=>setStep(back)}
                  type={"button"}
                >
                   {t("Back")} {i18n.language !== "ar" ? <i className="fa fa-sign-out-alt px-2 rotateDegree float-start pt-1 pe-3"></i>
                    : <i className="fa fa-sign-out-alt px-2 float-end pt-1 ps-3"></i>}
                </button>
                <button
                  className="btn btn-primary btnHeightLg px-3 py-2"
                  type={"submit"}
                >
                  {t("Continue")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i>
                    : <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Briefly.propTypes = {
  setStep:PropTypes.func,
  next: PropTypes.string, 
  back: PropTypes.string

 };
export default Briefly;
