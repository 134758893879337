import React, { useState } from "react";

import { CardBody, Collapse } from "reactstrap";
import { Link } from "react-router-dom";

const FaqPayment = () => {
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);

  function t_col1() {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  }

  function t_col2() {
    setcol1(false);
    setcol2(!col2);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  }

  function t_col3() {
    setcol1(false);
    setcol2(false);
    setcol3(!col3);
    setcol4(false);
    setcol5(false);
  }

  function t_col4() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(!col4);
    setcol5(false);
  }
  function t_col5() {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(!col5);
  }

  return (
    <React.Fragment>
      <div>
        <div id="gen-ques-accordion" className="accordion custom-accordion">
          <div className="mb-3 pt-4">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col1();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">How long does it take to create a website using your platform?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col1 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>

            <Collapse isOpen={col1}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">Most basic websites can be completed within 1-2 weeks. More complex sites may take 3-6 weeks. Timelines depend on the number of pages, number of sections in each page, amount of content, and customization needs. </p>
                <p className="text-muted">For more accurate estimated delivery timeframes expected for delivery, check our plans <Link to={'/plans'}>here.</Link></p>
                <p className="text-muted">Our dedicated account managers can also provide a customized project timeline estimate once we have discussed your business goals and technical requirements in more detail. Overall, we strive for efficient and timely website launches while ensuring quality.</p>
                <p className="text-muted">Please note that the speed of website completion relies on your prompt actions and feedback. To expedite the process, we urge you to actively monitor your dashboard and registered email.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col2();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">What payment options do you accept?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col2 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col2}>
              <CardBody className="px-0 pt-2">
                <p className="mb-0 text-muted">We accept payments made via major credit cards, and debit cards: Visa, Mastercard, American Express, Discover, JCB, UnionPay, and Elo.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col3();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">What if I want a refund?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col3 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col3}>
              <CardBody className="px-0 pt-2">
                <p className="pt-3 pb-3 text-muted">{("We understand that sometimes plans change, and we're happy to provide a refund within certain limits. Here's our refund policy:")}</p>
                <p className="pb-3 fw-bold">{("For packages that include our content creation services:")}</p>
                <ul className="pt-2 text-muted">
                  <li className="pb-3">A full refund is available if requested before the initial content briefing meeting between the client and our content strategists.</li>
                  <li className="pb-3">A 50% refund is available if requested after the initial content briefing meeting is held.</li>
                </ul>
                <p className="pb-3 fw-bold">For packages that do not include our content creation services:</p>
                <ul className="text-muted">
                  <li className="pb-3">A full refund is available if requested before the client uploads any of their own content assets to the website builder dashboard to begin the building process.</li>
                </ul>
                <p className="pb-3 fw-bold">For all packages:</p>
                <ul className="text-muted">
                  <li className="pb-3">No refund will be provided once the website building process has begun by our development team(step 3 is open in the project’s dashboard).</li>
                </ul>
                <p className="text-dark">We must receive refund requests by email to support@webkicks.co. Please allow up to 21 business days for processing. No refunds will be provided for requests made after website launch or go-live dates. To speed up the process, it’s preferable to add “Refund Request for (project id)” in the subject line.</p>
              </CardBody>
            </Collapse>
          </div>

          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col4();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">Do you offer ongoing website support?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col4 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col4}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">At present, all of our website packages include support throughout the initial build process to help with setup, content input, and launching the site. Additionally, technical support for any hosting or website issues is provided for the entire duration of the included 1 year of hosting.</p>
                <p className="text-muted">This means if any technical problems arise with your hosted website within that first year - such as server errors, broken links, plugin conflicts etc. - our team will troubleshoot and resolve the issues at no extra cost.</p>
                <p className="text-muted">We are currently developing new website support plans that will allow clients to purchase ongoing website support or one-time support cases after launch.</p>
                <p className="text-muted">The forthcoming plans will provide ongoing minor updates like content changes, as well as assistance troubleshooting any technical problems through phone, email or chat with an account manager.</p>
                <p className="text-muted">Our goal is to introduce ongoing affordable support options for customers who want peace of mind knowing help is available down the road. For now, our dedicated account managers are available to resolve issues that arise on a case by case basis.</p>
                <p className="text-muted">As we continue improving our services, onboarding new tools, and iterating based on feedback, a suite of ongoing and one-time support packages will be released soon to complement our existing website builds.</p>
              </CardBody>
            </Collapse>
          </div>
          
          <hr />
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list text-decoration-none text-dark"
              onClick={() => {
                t_col5();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="textHeaders">How long is the hosting included for?</h5>
                </div>
                <div className="hideArrow">
                  <i
                    className={
                      col5 ? "fa fa-long-arrow-right angle-45 fa-lg text-primary" : "fa fa-long-arrow-right fa-lg text-primary"
                    }
                  />
                </div>
              </div>
            </Link>
            <Collapse isOpen={col5}>
              <CardBody className="px-0 pt-2">
                <p className="text-muted">Our packages include 1 year of free shared hosting with our preferred provider. Hosting can be renewed after the initial period for an annual fee.</p>
              </CardBody>
            </Collapse>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default FaqPayment;
