import Pages404 from "pages/Utility/pages-404";
import Login from "pages/Client/Authentication/Login";
import Register from "pages/Client/Authentication/Register";
import Logout from "pages/Client/Authentication/Logout";
import Resetpassword from "../pages/Client/Authentication/Resetpassword";
import ForgetPassword from "../pages/Client/Authentication/ForgetPassword";
import Projects from "pages/Client/Projects";
import OrderView from "pages/Client/Order-View";
import Dashboard from "../pages/Client/Dashboard";
import Purchase from "../pages/Client/Purchase";
import PaymentSuccess from "../pages/Client/Purchase/PaymentSuccess";
import PaymentFail from "../pages/Client/Purchase/PaymentFail";
import Profile from "pages/Client/Profile";
import FirstStepForm from "components/Client/Steps/First-Step/FirstStepForm";
import ContentPage from "components/Client/Steps/Second-Step/ContentPage";

const clientRoutes = [
  { path: "/client/dashboard", component: Dashboard },
  { path: "/client/projects", component: Projects },
  { path: "/client/project/:id", component: OrderView },
  { path: "/client/project/:id/first-step", component: FirstStepForm },
  { path: "/client/:id/content", component: ContentPage },
  { path: "/checkout", component: Purchase },
  { path: "/success", component: PaymentSuccess },
  { path: "/fail", component: PaymentFail },
  { path: "/logout", component: Logout },
  { path: "/profile", component: Profile }

];
const clientAuthRoutes = [

  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/pages-404", component: Pages404 },

  { path: "/forget-password", component: ForgetPassword },
  { path: "/client/reset-password", component: Resetpassword }

];

export { clientAuthRoutes, clientRoutes };
