import React, { useState, useRef, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Modal,
    ModalHeader, ModalBody, Label, Row, Col,
} from "reactstrap"
import { toast } from "react-toastify";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import TemplateService from 'services/Admin/TemplateService';
import { SuccessCreated, SuccessOk } from "configs/statusCode";
import { AddTemplateValidator } from 'FormValidations/Admin/Template/Add';
import PropTypes from 'prop-types';
// import ErrorMessageList from 'components/Common/ErrorMessage/ErrorMessageList';
import _ from 'lodash';
import FileUpload from "components/Common/file-upload/file-upload.component";
import CategoryService from "services/Admin/CategoryService"
import ReactTags from 'react-tag-autocomplete'
import "./style.scss";
import TagService from 'services/Admin/TagService';
import arrowImg from "assets/images/arrow.png"
import SuccessModal from "components/Common/SuccessModal";

const types = ["design", "type", "industry"]
const ModalAdd = ({ modalAdd, toggleAdd, setGetData, editItem }) => {
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur' ,
        resolver: yupResolver(AddTemplateValidator), defaultValues:{
            status: true
        }
    });
    const reactTags = useRef()
    const templateService = new TemplateService();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [serverErrors, setServerErrors] = useState({});
    const [uploadState, setUploadState] = useState(false)
    const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    // const [designOptions, setDesignOptions] = useState([]);
    const [typeOptions, setTypeOption] = useState([]);
    const [industryOptions, seIndustryOptions] = useState([]);
    const [modalSuccess, setModalSuccess] = useState(false);
    const toggleSuccess = () => {
        setModalSuccess(!modalSuccess);
        setGetData(true);
    };

    const tagServices = new TagService();
    const [formData, setFormData] = useState({
        media_id: "",
        media_id_path: "",
    })

    const getTags = async () => {
        const { data: itemList } = await tagServices.getList();
        setSuggestions(itemList);
    }
    useEffect(() => {
        getTags();
    }, []);
    const categoryService = new CategoryService()
    useEffect(() => {
        types.forEach(type => {
            categoryService.getDropDown('categoryValues', { type }).then((res) => {
                if (res.status === SuccessOk) {
                    const options = res.data.data.map(item => ({ label: item.name.en, value: item.id }))
                    if (type === "type") {
                        setTypeOption([...options])
                    } 
                    // else if (type === "design") {
                    //     setDesignOptions([...options])
                    // } 
                    else if (type === "industry"){
                        seIndustryOptions([...options])
                    }
                }
            })
        })
    }, []);
    
    const onDelete = (i) => {
        const tagsFilter = tags.filter((tag, index) => index !== i)
        setTags(tagsFilter);
    }

    const onValidate = (tag) => {
        return tag.name.length >= 3;
    }

    const onAddition = (tag) => {
        const isExistSuggestion = suggestions.length > 0 ? suggestions.find(tagData => tagData.id === tag.id) :false
        const isExistTags = tags.length > 0 ? tags.find(tagData => tagData.id === tag.id) :false
        if (!isExistSuggestion && !isExistTags) {
            tagServices.create({ name: tag.name }).then(res => {
                const newTag = {
                    id: res.data.id,
                    name: tag.name
                }
                setTags([...tags, newTag]);
                setSuggestions([...suggestions, newTag])
            })
        } else if (!isExistTags) {
            setTags([...tags, tag]);
        }
    }

    useEffect(() => {
        if (editItem) {
            templateService.find(editItem.id).then(res => {
                setValue("url", res.data.url)
                setValue("name", res.data.name)
                setValue("description", res.data.description)
                setValue("image", res.data.media.path)
                setValue("spa", !!Number(res?.data?.spa))
                setValue("status", !!res?.data?.status)
                 setTags([...res.data.tags])
                //  setValue("design",res.data.design.map(item=>({
                //          value:item.id,
                //         label:item.name.en
                        
                //   })))
                 setValue("type", res.data.type.map(item=>({
                    value:item.id,
                   label:item.name.en
                   
             })))
                  setValue("industry",res.data.industry.map(item=>({
                    value:item.id,
                   label:item.name.en
                   
             })))
                setFormData(data=>({
                    ...data,
                    media_id: res.data.media.id,
                    media_id_path:res.data.media.path
                }))
            })
        }
    }, [])
    const handleValidSubmit = async (values) => {
        //  if (tags.length === 0) {
        //     toast.error("tags field is required");
        //     return
        // }
        if (formData.media_id_path.length===0){
            toast.error("Photo is required");
            return
        } 
        else {
            setIsSubmitting(true);
            let requestData = {
                name: values.name,
                description: values.description,
                url: values.url,
                tag_ids: tags.map(tag => tag.id),
                // design_ids: values.design.map(item => item.value),
                type_ids: values.type.map(item => item.value),
                industry_ids: values.industry.map(item => item.value),
                media_id:formData.media_id,
                spa: values?.spa ? 1 : 0,
                status: values?.status,
            };
            try {
                if (editItem) {
                    const userData = await templateService.update(editItem.id, requestData);
                    if (userData.status == SuccessOk) {
                        toggleAdd();
                        setIsSubmitting(false);
                        setGetData(true);
                        toast.success("Template Updated Successfully");
                    }
                } else {
                    const userData = await templateService.create(requestData);
                    if (userData.status == SuccessCreated) {
                        // toggleAdd();
                        // setIsSubmitting(false);
                        // toast.success("Template Created Successfully");
                        setModalSuccess(true);
                    }
                }

            } catch (err) {
                setIsSubmitting(false);
                setServerErrors(err.response.data);
                toast.error(err.response?.data?.error)
            }
        }
    };


    const updatedAttachment = (attachments, type) => {
        const mediaObj = {
            [`${type}`]: attachments[0] ?.id,
            [`${type}_path`]: attachments[0] ?.path,
        }
        setFormData({ ...formData, ...mediaObj })
        setValue("image", attachments[0] ?.path)
    }

    const deleteAttachment = (_, type) => {
        const mediaObj = {
            [type]: null,
            [`${type}_path`]: null,
        }
        setFormData({ ...formData, ...mediaObj })
    }
    return (
        <Modal isOpen={modalAdd} className="modal-dialog-scrollable modal-lg container" toggle={() => toggleAdd()}>
            <ModalHeader className='modalHead me-2' toggle={() => toggleAdd()}>
                <div>
                    <h6 className='fw-bold'>{editItem ? "Update Template" : "Add New Template"}</h6>
                    <p className='font-14 text-muted mb-0'>Fill information about your business and goals for this website</p>
                </div>
                
            </ModalHeader>
            <ModalBody className='border-0 py-4'>
                <form className="g-3 needs-validation" onSubmit={handleSubmit(handleValidSubmit)}>
                    <Row>
                        <Col lg={7}>
                            <div className="col-md-12">
                                <div className='pb-2'>
                                    <Label>Please insert templae link</Label>
                                    <input
                                        {...register("url")}
                                        placeholder="Type Link"
                                        className={`form-control height-47 ${(errors ?.url || !_.isEmpty(serverErrors))&& "border-danger"}`}
                                    />
                                    {errors ?.url && <ErrorMessage message={errors ?.url ?.message} />}
                                    {!_.isEmpty(serverErrors) && <ErrorMessage message={serverErrors.error} />}
                                </div>
                                <div className='pb-2'>
                                    <Label>Theme Name (en)</Label>
                                    <input
                                        {...register("name.en")}
                                        placeholder="Type Name"
                                        className={`form-control height-47 ${errors ?.name?.en && "border-danger" }`}
                                        type="text"
                                    />
                                    {errors ?.name?.en && <ErrorMessage message={errors ?.name?.en ?.message} />}
                                </div>
                                <div className='pb-2'>
                                    <Label>Theme Name (ar)</Label>
                                    <input
                                        {...register("name.ar")}
                                        placeholder="Type Name"
                                        // pattern='^[\u0621-\u064A0-9 ]+$'
                                        title="Text Should be in Arabic"
                                        className={`form-control height-47 ${errors ?.name?.ar && "border-danger" }`}
                                        type="text"
                                    />
                                    {errors ?.name?.ar && <ErrorMessage message={errors ?.name ?.ar?.message} />}
                                </div>
                                <Label className="mt-2">Tags</Label>
                                <div className="tag-container ps-0">
                                    <ReactTags
                                        allowNew={true}
                                        ref={reactTags}
                                        tags={tags}
                                        // tagComponent={TagComponent}
                                        suggestions={suggestions.length > 0 ? suggestions.filter(suggest => !tags.some(tag => tag.id === suggest.id)):[]}
                                        onDelete={onDelete}
                                        onValidate={onValidate}
                                        onAddition={onAddition} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="sectionUpload">
                                <FileUpload
                                    accept='.jpg,.png,.jpeg,.JPG,.PNG,.JPEG'
                                    label='Photo'
                                    attachmentType='media_id'
                                    attachmentData={[{
                                        id:formData.media_id,
                                       path:formData. media_id_path
                                    }]}
                                    className={errors ?.image ? 'border-danger' : ''}
                                    updatedAttachment={updatedAttachment}
                                    deleteAttachment={deleteAttachment}
                                    setUploadState={setUploadState}
                                />
                                {errors ?.image && <ErrorMessage message={errors ?.image ?.message} />}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <div className='pt-2'>
                                <Label>Description (en)</Label>
                                <textarea
                                    {...register("description.en")}
                                    placeholder="Type Description"
                                    rows="4"
                                    className={`form-control ${errors ?.description?.en && "border-danger"}`}
                                >
                                </textarea>
                                {/* {errors ?.description?.en && <ErrorMessage message={errors ?.description ?.en?.message} />} */}
                            </div>
                            <div className='pt-2'>
                                <Label>Description (ar)</Label>
                                <textarea
                                    {...register("description.ar")}
                                    placeholder="Type Description"
                                    rows="4"
                                    className={`form-control ${errors ?.description?.ar && "border-danger"}`}
                                >
                                </textarea>
                                {/* {errors ?.description?.ar && <ErrorMessage message={errors ?.description ?.ar?.message} />} */}
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className='my-2 d-flex align-items-center justify-content-between'>
                                <label className="form-check-label d-inline">Status</label>
                                <div className="form-check form-switch py-2">
                                    <input
                                    {...register("status")}
                                    className={`form-check-input`}
                                    type="checkbox"
                                    id="status"
                                    />
                                </div>
                            </div>
                            <div className='my-2 d-flex align-items-center justify-content-between'>
                                <label className="form-check-label d-inline">Single Page</label>
                                <div className="form-check form-switch py-2">
                                    <input
                                    {...register("spa")}
                                    className={`form-check-input`}
                                    type="checkbox"
                                    id="spa"
                                    />
                                </div>
                            </div>
                        {/* <div className='my-2'>
                                <Label>Design</Label>
                                <Controller
                                    name="design"
                                    control={control}
                                    render={({ field }) => <Select
                                        {...field}
                                        className={`heightSelect ${errors ?.design && "select-border" }`}
                                        options={designOptions}
                                        isMulti
                                    />}
                                />
                                {errors ?.design && <ErrorMessage message={errors.design.message} />}
                            </div> */}
                            <div className="mt-2">
                                <Label>Industry</Label>
                                <Controller
                                    name="industry"
                                    control={control}
                                    render={({ field }) => <Select
                                        {...field}
                                        className={`heightSelect ${errors ?.industry && "select-border" }`}
                                        options={industryOptions}
                                        isMulti
                                    />}
                                />
                                {errors ?.industry && <ErrorMessage message={errors.industry.message} />}
                            </div>
                            <div className="mt-2">
                                <Label>Type</Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => <Select
                                        {...field}
                                        className={`heightSelect ${errors ?.type && "select-border" }`}
                                        options={typeOptions}
                                        isMulti
                                    />}
                                />
                                {errors ?.type && <ErrorMessage message={errors.type.message} />}
                            </div>
                        </Col>
                    </Row>
                    <div className='modal-footer pb-0 mt-3'>
                        <div className='row w-100 justify-content-end'>
                            <div className='col-lg-8 d-none d-lg-grid'>
                                <p className='text-muted mb-1 font-14'><i className='fa fa-info-circle fa-lg'></i> Fill information about your business and goals for this website and this data can vary and time can be from.</p>
                            </div>
                            <div className='col-md-6 col-lg-4'>
                                <button
                                    className="btn btn-primary d-block ms-auto text-start height-42 w-100"
                                    type="submit"
                                    disabled={uploadState ? true : isSubmitting ? true : false}
                                > {editItem ? "Update Template" : "Add Template"}
                                    <img className='float-end pt-2' src={arrowImg} alt="" /> 
                                </button>
                            </div>
                        </div>
                        {/* <button type='button' className='btn btn-light' onClick={toggleAdd}>Cancel</button> */}
                    </div>
                    <SuccessModal
                        modalSuccess={modalSuccess}
                        entiityName="Template"
                        toggleSuccess={() => {
                        toggleSuccess(), toggleAdd();
                        }}
                    />
                </form>
            </ModalBody>
        </Modal>
    );
}
ModalAdd.propTypes = {
    modalAdd: PropTypes.bool,
    toggleAdd: PropTypes.func,
    setGetData: PropTypes.func,
    editItem: PropTypes.object,
};
// function TagComponent({ tag, removeButtonText, onDelete }) {
//     return (
//         <button type='button' title={`${removeButtonText}: ${tag.name}`} onClick={onDelete}>
//             {tag.name}
//         </button>
//     )
// }

// TagComponent.propTypes = {
//     tag: PropTypes.object,
//     removeButtonText: PropTypes.string,
//     onDelete: PropTypes.func,
// };
export default ModalAdd;
