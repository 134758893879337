import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import FileUpload from "components/Common/file-upload/file-upload.component";
import { Label, Row, Col } from "reactstrap";
import arrowImg from "assets/images/arrow.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { BriefValidator } from "FormValidations/Client/Dashboard/Brief";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import StepsService from "services/Client/StepsService";
import { SuccessCreated } from "configs/statusCode";
import { toast } from "react-toastify";
import { IMAGE } from "Enums/FileTypeEnum";
import reactCSS from 'reactcss' 
import Select from "react-select";
import SocialMediaOptions from "Enums/SocialOptions";

const Form = ({ updateProjectData, data, toggle, orderId, callFirstStep,setCompleted }) => {
  const { t } = useTranslation();
  const stepsService = new StepsService();
  const [isSubmited, setIsSubmited] = useState(false);
  const [socialOptions, setSocialOptions] = useState([]);
  const [uploadState, setUploadState] = useState(false);
  const [displayColorPickerPrimary, setDisplayColorPickerPrimary] = useState(false);
  const [displayColorPickerSecondary, setDisplayColorPickerSecondary] = useState(false);

  const [formData, setFormData] = useState({
    media_id: "", 
    media_id_path: "", 
    primary_color: "", 
    secondary_color: "", 
    documents: [],
    social: [{
      name: "", link: ""
    }]
  });

  const styles = reactCSS({
      'default': {
        colorPrimary: {
          width: '40px',
          height: '20px',
          borderRadius: '2px',
          background: `${formData.primary_color}`,
        },
        colorSecondary: {
          width: '40px',
          height: '20px',
          borderRadius: '2px',
          background: `${formData.secondary_color}`,
        },
        swatch: {
          padding: '1px',
          background: '#417505',
          border: '1px solid black',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.3)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        swatch2: {
          padding: '1px',
          border: '1px solid black',
          background: '#4A90E2',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.6)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

  const {
    handleSubmit, register, formState: { errors }, reset
  } = useForm({
    resolver: yupResolver(BriefValidator), defaultValues: {
      pages: [{
        name: "", description: ""
      }]
      // ,social: [{
      //   name: "", link: ""
      // }]
    }
  });
  // const {
  //   fields: socialFields, append: appendSocial, remove: removeSocial
  // } = useFieldArray({
  //   name: "social", control
  // });

  useEffect(()=>{
    setSocialOptions(SocialMediaOptions)
  },[])

  useEffect(() => {
    if (data) {
      const socialLinks = [];
      for (let index = 0; index < data.social_media_links.length; index++) {
        const item = data.social_media_links[index];
        for (let key in item) {
          socialLinks.push({
            name: key, link: item[key]
          });
        }
      }
      reset({
        name: data.business_name,
        description: data.business_description,
        primary_color: data.primary_color,
        secondary_color: data.secondary_color,
        email: data.contact_email,
        social: socialLinks
      });

      setFormData({
        ...data,
        media_id: data.media.id,
        media_id_path: data.media.path,
        primary_color: data.primary_color,
        secondary_color: data.secondary_color,
        documents: data.documents
      });
    }
  }, [data]);
 
  const updatedAttachment = (attachments, type) => {
    if (type === "documents") {
      setFormData((data) => ({
        ...data, documents: [...formData.documents, ...attachments]
      }));
    } else {
      const mediaObj = {
        [`${type}`]: attachments[0]?.id, [`${type}_path`]: attachments[0]?.path
      };
      setFormData({ ...formData, ...mediaObj });
    }
  };

  const deleteAttachment = (Items, type) => {
    if (type === "documents") {
      let updatedDocs = formData.documents.filter(item => item.id !== Items[0].id);
      setFormData((data) => ({ ...data, documents: updatedDocs }));
    } else {
      const mediaObj = {
        [type]: null, [`${type}_path`]: null
      };
      setFormData({ ...formData, ...mediaObj });
    }
  };
  const handleValidSubmit = (formState) => {
    if (!isSubmited) setIsSubmited(true);
    const data = {
      order_id: orderId,
      business_name: formState.name,
      primary_color: formData.primary_color,
      secondary_color: formData.secondary_color,
      contact_email: formState.email,
      business_description: formState.description,
      social_media_links: formData.social.map((item) => {
        return {
          [item.name.label]: item.link
        };
      }), // status: formData.status,
      media_id: formData.media_id, // category_value_id: formState.type.value,
      documents: formData.documents.map((item) => item.id)
    };

    stepsService.update("first-step", data).then((res) => {
      if (res.status === SuccessCreated) {
        toast.success("Project brief successfully submitted");
        updateProjectData({ first_step_status: 1 });
        toggle();
        callFirstStep();
        setCompleted(prev => prev+25);
      } else {
        toast.error("Please enter the required fields");
      }
    });
  };

  return (<div>
    <div>
      <form
        className="g-3 needs-validation"
        onSubmit={handleSubmit(handleValidSubmit)}
      >
        <Row>
          <Col lg={7}>
            <div className="pb-3">
              <div className="pb-3">
                <Label>Business name*</Label>
                <input
                  {...register("name", { required: true })}
                  placeholder="Zero Gravity Ent"
                  className={`form-control height-47 ${errors?.name && "border-danger"}`}
                />
                {errors?.name && (<ErrorMessage message={errors?.name?.message}/>)}
              </div>
              <div className="pb-2">
                <Label>Describe your business*</Label>
                <textarea
                  rows="4"
                  {...register("description")}
                  placeholder="Fill information about your business and goals for this website"
                  className={`form-control ${errors?.description && "border-danger"}`}
                ></textarea>
                {errors?.description && (<ErrorMessage message={errors?.description?.message}/>)}
              </div>
              <div className="mb-3 pt-xl-2">
                <Label>{t("Business Email Address")}</Label>
                <input
                  {...register("email")}
                  type="email"
                  className="form-control btnHeightLg"
                  placeholder="Your email"
                />
              </div>
              <div>
                <Label className="d-block pt-3">Primary Color</Label>
                <div style={ styles.swatch } onClick={() => { setDisplayColorPickerPrimary(true);}}>
                  <div style={ styles.colorPrimary } />
                </div>
                { displayColorPickerPrimary ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={() => { setDisplayColorPickerPrimary(false);}}/>
                  <SketchPicker
                      color={{ hex: formData.primary_color }}
                      onChange={(color) => setFormData((data) => ({
                        ...data, primary_color: color.hex
                      }))}
                    />
                </div> : null }
              </div>
              <div>
                <Label className="d-block pt-3">Secondary Color</Label>
                <div style={ styles.swatch2 } onClick={() => { setDisplayColorPickerSecondary(true);}}>
                  <div style={ styles.colorSecondary } />
                </div>
                { displayColorPickerSecondary ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={() => { setDisplayColorPickerSecondary(false);}}/>
                  <SketchPicker
                      color={{ hex: formData.secondary_color }}
                      onChange={(color) => setFormData((data) => ({
                        ...data, secondary_color: color.hex
                      }))}
                    />
                </div> : null }
              </div>
              {/* <Label className="pt-3">Secondary Color</Label>
              <TwitterPicker
                color={{ hex: formData.secondary_color }}
                onChangeComplete={(color) => setFormData((data) => ({
                  ...data, secondary_color: color.hex
                }))}
              /> */}
            </div>
          </Col>
          <Col lg={5}>
            <div className="sectionUpload">
              <FileUpload
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                label="Logo*"
                attachmentType="media_id"
                attachmentData={[{
                  id: formData.media_id, path: formData.media_id_path
                }]}
                updatedAttachment={updatedAttachment}
                deleteAttachment={deleteAttachment}
                setUploadState={setUploadState}
                hasError={isSubmited && !formData.media_id}
                file_type={IMAGE}
                className={`form-control height-47 ${isSubmited && !formData.media_id ? "border-danger" : ""}`}
              />
              {isSubmited && !formData.media_id && (<ErrorMessage message="logo is required"/>)}
            </div>
            <div className="sectionUpload pt-3">
              <FileUpload
                accept=".pdf,.doc,.docx"
                label="Add reference files"
                attachmentType="documents"
                // multiple
                attachmentData={[...formData.documents]}
                updatedAttachment={updatedAttachment}
                deleteAttachment={deleteAttachment}
                setUploadState={setUploadState}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Label className="pb-2 pt-3">Social Media</Label>
          <Row>
            {formData.social?.map((item, i) => (<div key={i} className="list-group list-group-flush">
              <div className="list-group-item bgGray pb-3">
                <Row>
                  <Col md={5} className="form-group col-sm-12">
                    <label className="pb-2">Name</label>
                    <Select
                      placeholder="Name"
                      name={`social`}
                      options={socialOptions}
                      className="borderSelect"
                      setOptions={(options) => setSocialOptions(options)}
                      onChange={(data) =>{
                        let update = formData.social?.map((res,index)=>{
                          if(i === index){
                            return {...res, name: data}
                          } else{
                            return res
                          }
                        })
                        setFormData({...formData,social: update})
                      }}
                      value={item.name}
                    />
                    {/* <input
                      placeholder={"name"}
                      name={`social[${i}].name`}
                      {...register(`social.${i}.name`)}
                      type="text"
                      className={`form-control mb-2`}
                    /> */}
                    {/* {errors.social?.[i]?.name?.message && (
                          <ErrorMessage
                            message={errors.social?.[i]?.name?.message}
                          />
                        )} */}
                  </Col>

                  <Col md={5} className="form-group col-9">
                    <label className="pb-2">Link</label>
                    <input
                      placeholder={"link"}
                      type="text"
                      className={`form-control`}
                      required
                      onChange={(e) =>{
                        let update = formData.social?.map((res,index)=>{
                          if(i === index){
                            return {...res, link: e.target.value}
                          } else{
                            return res
                          }
                        })
                        setFormData({...formData,social: update})
                      }}
                      value={item.link}
                    />
                    {/* {errors.social?.[i]?.link?.message && (
                          <ErrorMessage
                            message={errors.social?.[i]?.link?.message}
                          />
                        )} */}
                  </Col>
                  <Col md={2} className="pt-4 pt-md-4 col-3">
                    <button
                      onClick={() => {
                        let update = formData.social.filter((_,index)=> index !== i)
                        setFormData({...formData, social: update})
                      }}
                      className="bg-danger btn px-2 mt-2"
                      type="button"
                    >
                      <i className="fa fa-trash-o text-white"></i>
                    </button>
                  </Col>
                </Row>
              </div>
            </div>))}
          </Row>
          <Col lg={7}>
            <Row className="mx-2">
              <Col md={7}>
                <button
                  type="button"
                  onClick={() => {
                    setFormData({...formData, social: [...formData.social, {name: '', link: ''}]})
                  }}
                  className="btn btn-outline-primary w-100 fw-bold btnHeightLg my-3"
                >
                  + Add New Social Link
                </button>
              </Col>
            </Row>
          </Col>
          <div className="modal-footer pb-0 mt-3 ps-lg-0">
            <div className="row w-100 justify-content-between">
              <div className="col-lg-8 d-none d-lg-grid">
                <p className="text-muted mb-1 font-14">
                  <i className="fa fa-info-circle fa-lg"></i> Fill information
                  about your business and goals for this website and this data
                  can vary and time can be from.
                </p>
              </div>
              <div className="col-md-6 col-lg-3">
                <button
                  className="btn btn-primary d-block ms-auto text-start height-42 w-100"
                  type="submit"
                  disabled={uploadState ? true : false}
                >
                  {" "}
                  Continue
                  <img className="float-end pt-2" src={arrowImg} alt=""/>
                </button>
              </div>
            </div>
            {/* <button type='button' className='btn btn-light' onClick={toggleAdd}>Cancel</button> */}
          </div>
        </Row>
      </form>
    </div>
  </div>);
};

Form.propTypes = {
  data: PropTypes.object,
  callFirstStep: PropTypes.func,
  toggle: PropTypes.func,
  updateProjectData: PropTypes.func,
  setCompleted: PropTypes.any,
  orderId: PropTypes.string
};

export default Form;
