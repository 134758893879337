import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import CardPlan from "components/Website/Plans/CardPlan";
import PlanService from "services/Website/PlanService";
import { useSelector, useDispatch } from "react-redux";
import { appendFilters, authenticate } from "store/Auth/actions";
import PropTypes from "prop-types";
import { getCurrency } from "helpers/common";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet";
import Header from "components/Website/Layouts/Header/index";
import OrderPlanServices from "services/Website/OrderPlanServices";
import { Link } from "react-router-dom/cjs/react-router-dom";
import FaqPayment from "components/Website/Plans/FaqPayment";
// import StaticCardPlan from "components/Website/Plans/StaticCardPlan";

const quryParams = [
  "industry",
  // "size",
  "website_type",
  "name",
  "description",
  "content",
  "domain",
  "template",
];

const Plans = ({ history }) => {
  const [paramsObj, setParamsObj] = useState(null);
  const {
    industry,
    // size,
    website_type,
    name,
    description,
    content,
    domain,
    template,
  } = paramsObj || {};
  let query = useQuery();
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);
  const planService = new PlanService();
  const orderPlanServices = new OrderPlanServices();
  const data = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const [price, setPrice] = useState();
  const [height, setHeight] = useState('')

  useEffect(() => {
    const updatedParamsObj = {};
    for (let index = 0; index < quryParams.length; index++) {
      updatedParamsObj[`${quryParams[index]}`] = query.get(
        `${quryParams[index]}`
      );
    }
    setParamsObj(updatedParamsObj);
  }, []);

  useEffect(() => {
    let selectedIndustry, selectedType; //selectedSize
    let { industryOptions, typeOptions } = data; //sizeOptions
    if (
      industryOptions?.length &&
      // sizeOptions?.length &&
      typeOptions?.length &&
      paramsObj
    ) {
      let typeCondition = data?.website_type !== 0 && !data?.type;
      if (!data.industry || typeCondition) { //|| !data.size
        selectedIndustry = industryOptions?.find(
          (option) => option.value == industry
        );
        // selectedSize = sizeOptions?.find((option) => option.value == size);
        selectedType = typeOptions?.find(
          (option) => option.value == website_type
        );
        dispatch(
          appendFilters({
            industry: selectedIndustry,
            // size: selectedSize,
            type: parseInt(website_type) === 0 ? null : selectedType,
            website_type: parseInt(website_type) === 0 ? 0 : "",
            business_name: name,
            business_description: description,
            has_domain: parseInt(domain),
            has_content: parseInt(content),
            template: parseInt(template),
          })
        );
      }
    }
  }, [data.industryOptions, data.typeOptions, paramsObj]); //, data.sizeOptions

  const calcHeight = (data) => {
    let filterArray = data?.map(res=> res?.features?.length)
    let maxHeight = Math.max(...filterArray)
    setHeight(maxHeight*40)
  }

  useEffect(() => {
    orderPlanServices.getLocation().then((res) => {
      if (res.status === 200) {
        const resData = res.data[0];
        setPrice(getCurrency(resData?.country));
      }
    });
  }, []);

  useEffect(() => {
    // const e_commerce = data.website_type === 0 ? 0 : 1;
    // const e_commerce = data.website_type === 0 ? 1 : 0;
    // const words_num = data.has_content === 1 ? 1 : 0;
    // const size_id = data.size?.value ? data.size?.value : null;
    // if (size_id) {
      planService.getList() //{ e_commerce,words_num, size_id })
        .then((res) => {
          if(res && res?.status === 200){
            if(history.location?.state?.spa === '1' || history.location?.state?.spa === 1){
              let data = res.data?.filter(res=> res.pages_num?.name?.en === "One Page")
              setPlans(data)
              calcHeight(data)
              return
            }
            setPlans(() => {
              return [...res.data];
            });
            calcHeight([...res.data])
          }
        });
    // }
  }, [data.template]);

  const OnSelectHandler = (plan) => {
    // let type;
    // if (data?.website_type === 0) {
    //   type = 0;
    // } else {
    //   type = data?.type?.value;
    // }

    const planString = JSON.stringify(plan);
    let langNumber = plan.has_second_lang ? "2" : "1" //content ? "1" : "0";
    let stripe_id = plan.has_second_lang ? plan.second_lang_price.stripe_id : plan.has_content ? plan.content_price.stripe_id : plan.price.stripe_id;
    
    localStorage.setItem("stripe_id", stripe_id);
    localStorage.setItem("langNumber", langNumber);
    localStorage.setItem("plan", planString);

    dispatch(appendFilters({plan: plan.id, planData: {...plan, basic: true}, stripe_id, langNumber }));

    if (data.token && data.userType === "user") {
      history.push(
        `checkout?template=${data.template}&plan=${plan.id}`, {plan, selectedTemplate: history.location?.state} //&size=${data.size?.value} //industry=${data.industry?.value}&website_type=${type}&&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}
      );
    } else {
      dispatch(authenticate({ navigateTo: "checkout" }));
      history.push(
        `login?template=${data.template}&plan=${plan.id}`, {plan,selectedTemplate: history.location?.state} //industry=${data.industry?.value}&website_type=${type}&&size=${data.size?.value} //&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}
      );
    }
  };

  return (
    <div className="websitPages">
      <Header />
      <div className="pt-5 mt-3">
        <div className="container-fluid px-md-5 pt-5">
          <div className="d-md-flex d-block justify-content-between align-items-center pb-md-2 pb-4 px-4 px-md-0">
            <div>
              <h4 className="pt-3 fw-bold textDarkPrimary">
                {t("Tailored pricing to fit your needs")}
              </h4>
              <p className="font-size-14 text-muted pb-2">
                {t(
                  "Select from our range of plans to build a package that's right for you."
                )}
              </p>
            </div>
            <Link
              to={"/templates"}
              className="btn btn-primary font-14 ms-2 d-flex height-47 align-items-center mb-2 px-xl-3 px-lg-2 justify-content-between"
            >
              {t("Back To Templets")}{" "}
              <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i>
            </Link>
          </div>
        
          <div className="row pt-5 mt-lg-4">
            {plans.length ?
              plans.map((plan, index) => {
                return (
                  <div className="col-lg-4 col-md-6 pt-2 pb-5 mb-5" key={index}>
                    <div className="px-2 h-100 cardStyle">
                      <CardPlan
                        plan={plan}
                        onSelect={(planData) => OnSelectHandler(planData)}
                        price={price}
                        originPrice={plan.price.amount}
                        type={index % 3}
                        history={history}
                        index={index}
                        height={height}
                      />
                    </div>
                  </div>
                );
              }) : <p className="mx-3">There isn`t a payment plans option that fits your choices.</p>}
          </div>
          
          <div className="card">
            <div className="card-body p-lg-5">
              <div className='text-center pb-lg-3'>
                <h3 className='fw-bold textBlueDark'>Frequently Asked Questions</h3>
                <p className='text-muted smallText'>Have a question? We have got you covered. </p>
              </div>
              <FaqPayment />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

Plans.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
export default Plans;
