import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/admin/settings/templates";
const apiUploadEndpoint = API_BASE_URL_ENV() + "/admin/settings/templates-import";
const apiToggleStatusEndpoint = API_BASE_URL_ENV() + "/admin/settings/toggle-activation/template";

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    import(selectedFile) {
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        return http.post(apiUploadEndpoint, formData);
    }

    toggleStatus(id) {
        return http.post(`${apiToggleStatusEndpoint}/${id}`);
    }
}
