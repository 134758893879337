import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Progress, Row } from "reactstrap";
import "./style.scss";
import PropTypes from "prop-types";
import OrderService from "services/Client/OrderServices";
import StepDetails from "components/Client/Steps";
import { Circles } from "react-loader-spinner";
import ClientChatTwilio from "../../../components/Client/ChatTwillio";
import { useTranslation } from 'react-i18next';

const OrderView = (props) => {
  const { id } = props.match.params;
  const [completed, setCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [firstStepData, setFirstStepData] = useState(null);
  const [secondStepData, setSecondStepData] = useState(null);
  const [fourthStepData, setFourthStepData] = useState(null);
  
  const { t } = useTranslation();
  const orderService = new OrderService();
 
  
  const callFourthStep = async () => {
    const fourthStepRes = await orderService.getFourthStepData(id);
    if (fourthStepRes.status === 200) setFourthStepData(fourthStepRes.data);
  };
  const getProjectData = async () => {
    const res = await orderService.find(id);
    setProjectData(res.data);
  //   if (projectData?.second_step_status == 0 ) {
  //     setInterval(() => setCompleted(completed+25), 1000);
  //  }
  //   if (projectData?.third_step_status == 0 ) {
  //     setInterval(() => setCompleted(completed+25), 1000);
  //  }
  //   if (projectData?.fourth_step_status == 0 ) {
  //     setInterval(() => setCompleted(completed+25), 1000);
  //  }
  };
  useEffect(() => {
    if (projectData?.first_step_status == 1 ) {
       setCompleted(prave => prave+25);
   }
    if (projectData?.second_step_status == 1 ) {
       setCompleted(prave => prave+25);
   }
    if (projectData?.third_step_status == 1 ) {
       setCompleted(prave => prave+25);
   }
    if (projectData?.fourth_step_status == 1 ) {
       setCompleted(prave => prave+25);
   }
  }, [projectData]);
  const callAPIS = async () => {
    setIsLoading(true);
    return Promise.allSettled([getProjectData(), callFirstStep(), callSecondStep(),callFourthStep()]).then(() => {
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (id) {
      callAPIS().catch(e => console.log(e));
    }
  }, [id]);
  
  const callFirstStep = async () => {
    const firstStepRes = await orderService.getFirstStepData(id);
    if (firstStepRes.status === 200) setFirstStepData(firstStepRes.data);
  };
  // if (firstStepData?.first_step_status === 1){
  //   setInterval(() => setCompleted(completed+25));
  // }
  const callSecondStep = async () => {
    const secondStepRes = await orderService.getSecondStepData(id);
    if (secondStepRes.status === 200) setSecondStepData(secondStepRes.data);
  };
  
  
  return (<>
    {/* <Header /> */}
    <div>
      {isLoading ? (<div className="loaderStyle">
        <Circles color="#00BFFF"/>
      </div>) : (<Container fluid className="px-lg-4 pt-5 mt-4">
        <Row>
          <Col lg="9" className="pt-1">
            <div className="row pt-2 justify-content-around">
              <div className="col-lg-6">
                <h6 className={`fw-bold pt-3 text-capitalize ${!firstStepData ? 'text-danger' : ''}`}>{firstStepData ? firstStepData?.business_name : 'Not yet determined'}</h6>
                <h5 className={`fw-bold pt-1`} style={{fontSize: '1.1rem'}}>Template name: {projectData?.business_name}</h5>
                <Badge color="info" style={{fontSize: '1rem'}}>
                  {projectData?.subscription_plan?.name?.en}
                  {(projectData?.content_included && projectData?.lang_count === 2) ? ' with Content and Two Language' : projectData?.content_included ? ' with Content' : projectData?.lang_count === 2 ? ' with Two Language' : ''}
                </Badge>
                {/* <p className='text-muted font-14'>This should help the customer understand what to expect next</p> */}
              </div>
              <div className="col-lg-5">
                <div className="d-flex justify-content-lg-end justify-content-between pt-2 gap-4 align-items-baseline">
                  <div className="editProgress">
                    <h6 className="fw-bold textSuccess font-18">{completed}%<span className="font-16">{t("Completed")}</span></h6>
                    <Progress className="progressBar" value={completed}/>
                  </div>
                  <div>
                  {/* <a className="btn btn-outline-primary px-4" target="_blank" rel="noreferrer"
                   href={projectData?.template?.url}><i className="fa fa-eye"></i> {t("Preview")}
                  </a> */}
                  </div>
                </div>
              </div>
            </div>
            <StepDetails orderId={id}
                         projectDataProps={projectData}
                         firstStepData={firstStepData}
                         callFirstStep={callFirstStep}
                         callSecondStep={callSecondStep}
                         setCompleted={setCompleted}
                         fourthStepData={fourthStepData}
                         secondStepData={secondStepData}
                         otherProps={props}
            />
          </Col>
          <Col lg="3" className="px-lg-0">
            <div className="sticky-top newChat">
              {projectData.room ? <ClientChatTwilio client= {projectData.client} room={projectData.room}/> : null}
            </div>
          </Col>
        </Row>
      </Container>)}
    </div>
  </>);
};


OrderView.propTypes = {
  location: PropTypes.object, history: PropTypes.object, match: PropTypes.object

};

export default OrderView;