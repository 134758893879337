import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import closeImg from "assets/images/close.png";

function CanNotDeleteModal(props) {
  return (
    <div>
      <Modal isOpen={props.open} className="modal-md">
        <div className="modal-header border-0">
          <button
            onClick={() => props.onCloseModal("close")}
            className="btn ms-2"
            data-dismiss="modal"
          >
            <img src={closeImg} height="25" />
          </button>
        </div>
        <div className="modal-body border-0 text-center">   
          <h5 className="py-3 fw-bold">{`Payment system can't be deleted because it belongs to an active project!!`}</h5>

          <div className="pb-3 btnFooter">
            <button
              onClick={() => props.onCloseModal("close")}
              type="button"
              className="btn btnRed text-white px-4"
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

CanNotDeleteModal.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

export default CanNotDeleteModal;
