import React, { useState, useEffect } from "react";
import OrderService from "services/Admin/OrderService";
import PropTypes from "prop-types";
import { Row, Col, Container,Progress, Badge } from "reactstrap";
import StepDetails from "components/Admin/Steps";
import { Circles } from "react-loader-spinner";
import AdminChatTwilio from "../../../components/Admin/ChatTwillio";
import { useSelector } from "react-redux";

const OrderView = (props) => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [firstStepData, setFirstStepData] = useState(null);
  const [secondStepData, setSecondStepData] = useState(null);
  const [fourthStepData, setFourthStepData] = useState(null);
  const [completed, setCompleted] = useState(0);
  const orderService = new OrderService();
  const Auth = useSelector(state=> state?.Auth)

  // Allow EDITS Button
  
  const getProjectData = async () => {
    const res = await orderService.find(id);
    setProjectData(res.data);
  };

  const callFirstStep = async () => {
    const firstStepRes = await orderService.firstStep(id);
    if (firstStepRes.status === 200) setFirstStepData(firstStepRes.data);

  };

  const callSecondStep = async () => {
    const secondStepRes = await orderService.secondStep(id);
    if (secondStepRes.status === 200) setSecondStepData(secondStepRes.data);
  };

  const callFourthStep = async () => {
    const fourthStepRes = await orderService.fourthStep(id);
    if (fourthStepRes.status === 200) setFourthStepData(fourthStepRes.data);
  };
  useEffect(() => {
    if (projectData?.first_step_data_status == 1 ) {
       setCompleted(prave => prave+25);
   }
    if (projectData?.second_step_data_status == 1 ) {
       setCompleted(prave => prave+25);
   }
    if (projectData?.third_step_data_status == 1 ) {
       setCompleted(prave => prave+25);
   }
    if (projectData?.fourth_step_data_status == 1 ) {
       setCompleted(prave => prave+25);
   }
  }, [projectData]);
  
  const callAPIS = async () => {
    setIsLoading(true);
    return Promise.allSettled([getProjectData(), callFirstStep(), callSecondStep(), callFourthStep()])
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      callAPIS().catch(e => console.log(e));
    }
  }, [id]);
//   if (projectData?.first_step_data_status == 1 && projectData?.second_step_data_status == 1 && projectData?.third_step_data_status == 1 && projectData?.fourth_step_data_status == 1 ) {
//     setInterval(() => setCompleted(100), 1000);
//  }
  return (<div>
    {isLoading ? (<div className="loaderStyle">
      <Circles color="#00BFFF"/>
    </div>) : (<Container fluid className="px-lg-4 pt-5 mt-4">
      <Row>
        <Col lg="9" className="pt-1">
          <div className="row pt-2 justify-content-between px-lg-3">
            <div className="col-lg-6">
            <h6 className={`fw-bold pt-3 text-capitalize ${!firstStepData ? 'text-danger' : ''}`}>{firstStepData ? firstStepData?.business_name : 'Not yet determined'}</h6>
                <h5 style={{fontSize: '1.1rem'}} className={`fw-bold pt-1`}>Template name: {projectData?.business_name}</h5>
                <Badge color="info" style={{fontSize: '1rem'}}>
                  {projectData?.subscription_plan?.name?.en}
                  {(projectData?.content_included && projectData?.lang_count === 2) ? ' with Content and Two Language' : projectData?.content_included ? ' with Content' : projectData?.lang_count === 2 ? ' with Two Language' : ''}
                </Badge>
              {/* <p className='text-muted font-14'>This should help the customer understand what to expect next</p> */}
            </div>
            <div className="col-lg-5">
              <div className="d-flex justify-content-lg-end justify-content-between pt-2 gap-4 align-items-baseline">
                <div className="editProgress">
                  <h3 className="fw-bold textSuccess font-18">{completed}%<span className="font-16">Completed</span></h3>
                  <Progress className="progressBar" value={completed}/>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
          <StepDetails orderId={id} 
                       projectDataProps={projectData}
                       firstStepData={firstStepData}
                       fourthStepData={fourthStepData}
                       callFirstStep={callFirstStep} 
                       callSecondStep={callSecondStep}
                       secondStepData={secondStepData}/>
        </Col>
        <Col lg="3" className="px-lg-0">
          <div className="sticky-top newChat">
            <AdminChatTwilio admin={Auth} room={projectData}/>
          </div>
        </Col>
      </Row>
    </Container>)}
  </div>);
};

OrderView.propTypes = {
  location: PropTypes.object, history: PropTypes.object, match: PropTypes.object

};

export default OrderView;
