import React, { useEffect, useState, useRef } from "react";
import ChatItem from "./ChatItem";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  addDoc,
  serverTimestamp
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import "./styles.scss";
import PropTypes from "prop-types";
import { InputGroup , Input } from 'reactstrap';
import { db } from "../../../firebase";
import { v4 } from "uuid";
import { storage } from "../../../firebase";

const AdminChatTwilio = ({admin, setSelectedRoom, room}) => {
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const scrollDiv = useRef();
  let id = admin?.id || Number(localStorage.getItem('adminWebkiks'));

  // const scrollToBottom = () => {
  //   const scrollHeight = scrollDiv.current?.scrollHeight;
  //   const height = scrollDiv.current?.clientHeight;
  //   const maxScrollTop = scrollHeight - height;
  //   scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  // };

  useEffect(() => {
    const q = query(
      collection(db, room?.room),
      orderBy("createdAt", "desc"),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      setMessages(sortedMessages);
    });
    return () => unsubscribe;
  }, [room]);

  useEffect(()=>{
    if(messages?.length > 0) scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight
  },[messages])

  useEffect(()=>{
    uploadFile()
  },[imageUpload])

  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        sendMessage(url, 'image')
      });
    });
  };

  const sendMessage = async (msgText, type) => {
    if (!msgText && type === 'text') {
      alert("Enter valid message");
      return;
    }
    let uid = id;
    const displayName  = admin.user_name;
    await addDoc(collection(db, room?.room), {
      text: msgText,
      type: type,
      name: displayName,
      createdAt: serverTimestamp(),
      uid,
    });
    
    setMessageText("");
    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };


  return (
  <div className="bg-white chat boxShadow rounded-0 position-relative shadow border-1 rounded-3 mt-1">
    <div className="py-2 mx-2 text-center">
      <p className="fw-bold font-16 pt-2 mb-2">Chat of {room?.business_name || 'Account Manager'} </p>
    </div>
    <span 
      onClick={()=>setSelectedRoom(null)} 
      className='position-absolute cursor-pointer'
      style={{top: '16px', right: '16px'}}
    >
      <i className="fa fa-close"></i>
    </span>
    <hr className="m-0 p-0"/>
    <div ref={scrollDiv} className="fixedHeightChat">
      {messages && messages.map((message) => {
        return (<ChatItem
            key={message.id}
            message={message}
            uid={id}
        />);
      })}
    </div>
    <div className="d-flex flex-column px-2 pb-2 bg-white pt-2">
      <div className="w-100 inputGroupChat pb-2">
        <InputGroup className="pe-3">
          <Input
            className="form-control height-42 rounded-pill"
            value={messageText}
            placeholder="Enter Message..."
            onChange={(event) => setMessageText(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendMessage(messageText, 'text')
              }
            }}
          />
          <label className="position-relative">
                <input type='file'
                accept='image/*'
                style={{
                  width: '30px',
                  opacity: '0',
                  zIndex: '99',
                  position: 'absolute',
                  right: '3px',
                  top: '5px'
                }} onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}/>
                <i className='fa fa-image' style={{
                  fontSize: '20px',
                  position: 'absolute',
                  right: '8px',
                  top: '9px',
                  zIndex: '99999'
                }}></i>
              </label>
          {/* <InputGroupText className="height-42 bg-white pt-1">
            <input
              type="file"
              id="upload"
              hidden
              onChange={addImagesHandler}
            />
            <label htmlFor="upload" className="mt-1 mx-2">
              <i className="fa-solid fa-file-alt text-primary fa-lg"></i>
            </label>
          </InputGroupText> */}
        </InputGroup>
      </div>
      <button
        onClick={()=> sendMessage(messageText, 'type')}
        className="btn btn-primary widthBtn height-42 p-1"
      >
        Send <i className="fa fa-paper-plane ps-3"></i>
      </button>
    </div>
  </div>);
};
AdminChatTwilio.propTypes = {
  room: PropTypes.string,
  admin: PropTypes.any,
  setSelectedRoom: PropTypes.any
};
export default AdminChatTwilio;
