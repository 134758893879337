import * as yup from "yup";
import {
    EMAIL_PATTERN_VALIDATOR
} from "helpers/validators";

export const forgetValidator = yup.object().shape({
    email: yup
        .string()
        .matches(
            EMAIL_PATTERN_VALIDATOR.value,
            EMAIL_PATTERN_VALIDATOR.errorMessage
        )
        .required(),

   
})