import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Progress } from "reactstrap";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"
import { Col } from "reactstrap"
import { validatorIndustry } from "FormValidations/Website/steps"
import Select from "react-select";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { appendFilters } from "store/Auth/actions"
import industryImage from "assets/images/industry.png"
import { useTranslation } from 'react-i18next';

const Industry = ({ setStep ,history, next }) => {
  const { handleSubmit, setValue, control, formState: { errors } } = useForm({ resolver: yupResolver(validatorIndustry) });
  const data = useSelector(state => state.Auth)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    setInterval(() => setCompleted(15), 1000);
    setValue("industry", data.industry)
  }, []);

  const handleValidSubmit = (data) => {
    dispatch(appendFilters({ industry: data.industry }))
    setStep(next)
  }

  return (
    <div className="card mb-4 mt-3">
      <div className="card-header border-0 p-0">
        <Progress value={completed} />
      </div>
      <div className="card-body text-center pt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={industryImage} className="img-fluid" alt="industry" />
            <h3 className="pb-2 pt-4 fw-bold">
              {t("Please select the industry of your business")}
            </h3>
            <p className="text-muted pb-2">
              {t("Information of websites you visit. Use tools for lead generation,market")}
              <br /> {t("analysis and competitor research.")}
            </p>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
              <div className="row justify-content-center">
                <Col md={6} className="mt-2 text-start">
                  <Controller
                    name="industry"
                    control={control}
                    render={({ field }) => <Select
                      {...field}
                      className={`heightSelect ${errors ?.industry && "select-border"}`}
                      placeholder={t("Select")}
                      options={data.industryOptions}
                    />}
                  />
                  {errors ?.industry && <ErrorMessage message={errors.industry.message} />}
                </Col>
              </div>
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button
                  className="btn btnHeightLg px-3 py-2"
                  onClick={()=>history.push("/")}
                  type={"button"}
                >
                   {t("Back")} {i18n.language !== "ar" ? <i className="fa fa-sign-out-alt px-2 rotateDegree float-start pt-1 pe-3"></i>
                    : <i className="fa fa-sign-out-alt px-2 float-end pt-1 ps-3"></i>}
                </button>
                <button
                  className="btn btn-primary btnHeightLg px-3 py-2"
                  type={"submit"}
                >
                  {t("Continue")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i>
                    : <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Industry.propTypes = {
  setStep: PropTypes.func,
  history:PropTypes.func,
  next: PropTypes.string

};
export default Industry;
