import React, { useState, useEffect, useMemo } from "react";
import { Container, Label } from "reactstrap";
import Navbar from "components/Website/Layouts/Header";
import "./style.scss";
import lockImg from "assets/images/lock.png";
import AuthService from "services/Website/AuthService";
import chromeImg from "assets/images/chrome.png";
import registerImg from "assets/images/register.png";
// import faceImg from "assets/images/face.png";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidator } from "FormValidations/Website/Auth/LoginValidator";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { authenticate, appendFilters } from "store/Auth/actions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProfileService from "../../../services/Client/ProfileService";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const quryParams = ["industry", "size", "website_type", "name", "description", "content", "domain", "template", "plan"];

const Register = ({ history }) => {
  const [paramsObj, setParamsObj] = useState(null);
  let query = useQuery();
  const { industry, size, website_type, name, description, content, domain, template, plan } = paramsObj || {};
  const { register, formState: { errors }, handleSubmit } = useForm({ resolver: yupResolver(registerValidator) });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector(state => state.Auth);
  const UserService = new ProfileService();

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const { data: res, status: statusCode } = await UserService.getGoogleUserData(codeResponse);
      if (statusCode === 200) {
        AuthService.registerSocial({
          social_id: res.id, name: res.name, email: res.email, social_type: "google"
        }).then(res=>{
          doLogin(res);
        }).catch((err)=> toast.error(err?.response?.data?.error))
      } else {
        toast.error("Google Lgoin Failed");
      }
    }, onError: (error) => toast.error("Login Failed:", error)
  });

  useEffect(() => {
    const updatedParamsObj = {};
    for (let index = 0; index < quryParams.length; index++) {
      updatedParamsObj[`${quryParams[index]}`] = query.get(`${quryParams[index]}`);
    }
    setParamsObj(updatedParamsObj);
  }, []);

  useEffect(() => {
    let selectedIndustry, selectedSize, selectedType;
    let { industryOptions, sizeOptions, typeOptions } = data;
    if (industryOptions?.length && sizeOptions?.length && typeOptions?.length && paramsObj) {
      let typeCondition = data?.website_type !== 0 && !data?.type;
      if (!data.industry || !data.size || typeCondition) {
        selectedIndustry = industryOptions?.find(option => option.value == industry);
        selectedSize = sizeOptions?.find(option => option.value == size);
        selectedType = typeOptions?.find(option => option.value == website_type);
        dispatch(appendFilters({
          industry: selectedIndustry,
          size: selectedSize,
          type: parseInt(website_type) === 0 ? null : selectedType,
          website_type: parseInt(website_type) === 0 ? 0 : "",
          business_name: name,
          business_description: description,
          has_domain: parseInt(domain),
          has_content: parseInt(content),
          template,
          plan
        }));
      }
    }
  }, [data.industryOptions, data.sizeOptions, data.typeOptions, paramsObj]);

  const handleValidSubmit = async (values) => {
    setIsSubmitting(true);
    let requestData = {
      name: values.name, email: values.email, password: values.password, password_confirmation: values.confirm_password
    };
    try {
      const res = await AuthService.register(requestData);
      doLogin(res);
    } catch (error) {
      setIsSubmitting(false);
      toast.error(error?.response?.data?.error);
    }
  };
  // const responseFacebook = (response) => {
  //   console.log(response);
  // };
  const doLogin = (res) => {
    if (res && res.status) {
      dispatch(authenticate({
        token: res.access_token, userType: "user", user_name: res.name, email: res.email
      }));
      toast.success("Register Successfully");
      localStorage.removeItem('plan')
      setIsSubmitting(false);
      if (data.navigateTo === "checkout") {
        // let type;
        // if (data?.website_type === 0) {
        //   type = 0;
        // } else {
        //   type = data?.type?.value;
        // }
        history.push(`checkout?template=${data.template}&plan=${data.plan}`); //industry=${data.industry.value}&size=${data.size.value}&website_type=${type}&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}&
      } else {
        history.push(`/client/projects`, {
          email: res.email, room: "12-12", from: 'login'
        });
      }
    }
  };
  return (<>
    <Helmet>
      <title>Webkicks Register</title>
      <meta name="description" content="sign up webkick app"/>
    </Helmet>
    <div className="websitPages">
      <Navbar/>
      <Container className="pt-3">
        <div className="d-lg-flex pt-5 cardSign mt-5 pb-4">
          <div className="card w-100 p-2">
            <div className="card-body px-lg-4 px-2">
              <h5 className="fw-bold">
                <img src={lockImg} alt="lock" className="pe-2"/> {t("New to Webkicks? Create an account")}
              </h5>
              <p
                className="text-muted ps-4 ms-1 font-14 pb-2">{t("Inside your account, you can pick your website template, chat with your account manager, pick your content style, and more.")}</p>
              <hr className="opacity-50 border-bottom"/>
              <div className="py-2 px-2">
                <button className="btn btnSign me-md-3 mb-1" onClick={() => googleLogin()}>
                  <img src={chromeImg} className="pe-3" alt="chrome"/> {t("Sign up using Google")}
                </button>
                {/* <FacebookLogin
                  appId="943267207026926"
                  fields="name,email,picture"
                  scope="public_profile"
                  cssClass="btnSign"
                  textButton="Sign up using Facebook"
                  icon={<img src={faceImg} className="pe-3" />}
                  callback={responseFacebook}
                  /> */}
                {/* <button className="btn btnSign mb-1"><img src={faceImg} className="pe-3"
                                                          alt="face"/> {t("Sign up using Facebook")}</button> */}
              </div>
              <hr className="opacity-50 border-bottom"/>
              <form onSubmit={handleSubmit(handleValidSubmit)} className="px-2">
                <div className="mb-3 pt-xl-2">
                  <Label>{t("Your Name")}</Label>
                  <input
                    {...register("name")}
                    type="text"
                    className="form-control btnHeightLg"
                    placeholder="ex. Alex"
                  />
                  {errors?.name && <ErrorMessage message={errors?.name?.message}/>}
                </div>
                <div className="mb-3">
                  <Label>{t("Email Address")}</Label>
                  <input
                    {...register("email")}
                    type="email"
                    className="form-control btnHeightLg"
                    placeholder="Your email"
                  />
                  {errors?.email && <ErrorMessage message={errors?.email?.message}/>}
                </div>
                <div className="mb-3">
                  <Label>{t("Password")}</Label>
                  <input
                    {...register("password")}
                    type="password"
                    className="form-control btnHeightLg"
                    placeholder="************"
                  />
                  {errors?.password && <ErrorMessage message={errors?.password?.message}/>}
                </div>
                <div className="mb-3">
                  <Label>{t("Confirm Password")}</Label>
                  <input
                    {...register("confirm_password")}
                    type="password"
                    className="form-control btnHeightLg"
                    placeholder="************"
                  />
                  {errors?.confirm_password && <ErrorMessage message={errors?.confirm_password?.message}/>}
                </div>
                <p>By clicking Create account, I agree to the <Link to={"/terms"}>Terms of Service</Link>  and
                  acknowledge the <Link to={'/privacy-policy'}>Privacy Policy</Link> </p>
                <div className="d-xl-flex">
                  <button
                    className="btn btn-primary mb-0 mt-2 px-4 btnSignClick btnHeightLg"
                    disabled={isSubmitting}
                    type="submit">
                    {t("Create an account")}
                  </button>
                  <div className="pt-3 mt-1 ps-md-4 linkSign">
                    <p onClick={() => {
                      if (data.navigateTo === "checkout") {
                        let type;
                        if (data?.website_type === 0) {
                          type = 0;
                        } else {
                          type = data?.type?.value;
                        }
                        history.push(`login?industry=${data.industry.value}&size=${data.size.value}&website_type=${type}&name=${data.business_name}&description=${data.business_description}&domain=${data.has_domain}&content=${data.has_content}&template=${data.template}&plan=${plan.id}`);
                      } else {
                        history.push(`/login`);
                      }
                    }} className="text-primary btn-hover"> {t("Already have an acount? Login now")}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="position-relative">
            <img src={registerImg} alt="register" className="imgRegister d-none d-md-block"/>
            <p className="absoluteTextTop text-white d-none d-md-block">“By far one of the best choices I made to use
              Webkicks to launch a website for my business”</p>
            {/* <div className="absoluteTextBottom text-white d-none d-md-block">
              <h5>Sara Dijambwe</h5>
              <p className="opacity-75">Owner at Sara’s Boutique</p>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  </>);
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

Register.propTypes = {
  history: PropTypes.object
};

export default Register;
