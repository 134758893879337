import React, {useState} from "react";
import CategoryService from "services/Admin/CategoryService";
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import DeleteModal from 'components/Common/DeleteModal';
import framedot from 'assets/images/framedot.png';
import PropTypes from 'prop-types';

const CardItem = ({category, index, setModalAdd, setEditItem, setGetData}) => {
    const categoryService = new CategoryService();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [deletedItem, setDeletedItem] = useState(null);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [deleteModal, setDeleteModal] = useState(false);

    const handleCloseModal = (action) => {
        setDeleteModal(false);
        if (action === "delete") {
            setGetData(true);
        }
    };
    return (
        <tr key={index} className="py-2">
            <td>{category.id}</td>
            <td>{category.name}</td>
            {/* <td>{ category? CategoryTypeEnum.value : CategoryTypeEnum.value}</td> */}
            <td>{category.type}</td>
            <td><Dropdown className="dropdownWidth" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color="white" className="border-0">
                    <img src={framedot} alt=""/>
                </DropdownToggle>
                <DropdownMenu>

                    <DropdownItem className="text-primary" onClick={() => {
                        setEditItem(category);
                        setModalAdd((s) => !s)
                    }}>Edit Category</DropdownItem>
                    <DropdownItem className="text-danger" onClick={() => {
                        setDeleteModal(!deleteModal);
                        setDeletedItem(category);
                    }}>Delete Category</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            </td>
            <DeleteModal
                open={deleteModal}
                titleMsg={deletedItem?.name}
                deletedItem={deletedItem}
                modelService={categoryService}
                onCloseModal={(action) => handleCloseModal(action)}
            />
        </tr>
    );
};
CardItem.propTypes = {
    category: PropTypes.object,
    index: PropTypes.number,
    setModalAdd: PropTypes.func,
    setEditItem: PropTypes.func,
    setGetData: PropTypes.func
};

export default CardItem;
