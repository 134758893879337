import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import PropTypes from "prop-types";
import "../style.scss";
import Form from "./Form";

const FirstStepModal = ({projectData,updateProjectData, modalAdd, toggleAdd, data, orderId, callFirstStep,setCompleted }) => {
  return (<Modal isOpen={modalAdd} className="modal-dialog-scrollable modal-lg container">
    <ModalHeader className="modalHead me-2" toggle={() => {
      toggleAdd();
    }}>
      <div>
        <h6 className="fw-bold">
          <button className="p-0 m-0 border-0 bg-light"
                  onClick={() => {
                  }}>
          </button>
          Basic Information
        </h6>
        <p className="font-14 text-muted mb-0">Fill information about your business and goals for this website</p>
      </div>
    </ModalHeader>
    <ModalBody className="border-0 py-4">
      <div>
        <Form data={data}
              callFirstStep={callFirstStep}
              orderId={orderId}
              setCompleted={setCompleted}
              projectData={projectData}
              updateProjectData={(updateElements) => updateProjectData(updateElements)}
              toggle={() => {
                toggleAdd();
              }}/>
      </div>
    </ModalBody>
  </Modal>);
};
FirstStepModal.propTypes = {
  modalAdd: PropTypes.bool,
  toggleAdd: PropTypes.func,
  callFirstStep: PropTypes.func,
  updateProjectData: PropTypes.func,
  projectData: PropTypes.object,
  data: PropTypes.object,
  setCompleted: PropTypes.any,
  orderId: PropTypes.string
};

export default FirstStepModal;
