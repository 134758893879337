import React, { useState, useEffect } from "react"
import TemplateService from "services/Admin/TemplateService"
import { Container } from 'reactstrap'
import ModalAdd from "components/Admin/Template/ModalAdd"
import noProject from "assets/images/noProject.png"
import CardItem from "components/Admin/Template/CardItem"
import { Circles } from "react-loader-spinner"
import Filterswrapper from "components/Common/filterswrapper"
import './style.scss'
import Filters from "components/Admin/Template/Filters"
import { MetaTags } from "react-meta-tags"
import Paginator from "components/Common/PaginatorWithHasData/Paginator"

const Templates = () => {
    const templateService = new TemplateService()
    const [getData, setGetData] = useState(false)
    const [templates, setTemplates] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [editItem, setEditItem] = useState(null)
    const [hsaData, setHasData] = useState(0)
    const [filtersParams, setFilterParams] = useState(null)
    const [formState, setFormState] = useState({
        selectedDesign: [],
        selectedType: [],
        selectedSize: [],
        selectedindustry: [],
        keyword: "",
        date: ""

    })

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
    })

    const toggleAdd = () => {
        setEditItem(null)
        setModalAdd(!modalAdd)
    }

    useEffect(() => {
        let params = {}
        let category_value_ids = []
        for (const key in formState) {
            if (key === "date" || key === "keyword"){
                if (formState[`${key}`].length) params[`${key}`] = formState[`${key}`].trim()
        }else {
            if (formState[`${key}`].length) category_value_ids.push(formState[`${key}`].map(item => item.value).join())

        }
    }

        if (category_value_ids.length) {
            setFilterParams({ ...params, category_value_ids: category_value_ids.join() })

        } else {
            setFilterParams({ ...params })

        }
    }, [formState])


    return (
        <div className="admin-container pt-2">
            <MetaTags>
                <title> Templates </title>
            </MetaTags>
            <Container fluid className="bgGray pb-5 pt-3 px-lg-5">
                <Filterswrapper
                    AddHandler={() => toggleAdd()}
                    title={`Templates`}
                    subTitle={"This should help the customer understand what to expect next"}
                    addbtnName={"Add a new template"}
                >
                    <Filters formState={formState} setFormState={setFormState} />
                </Filterswrapper>
                <div>
                    {hsaData === 0 && <div className='loaderStyle'>
                        <Circles color='#00BFFF' />
                    </div>}

                    {hsaData === 2 && <div className="bg-white boxShadow">
                            {templates.map((template, index) =>
                                <CardItem
                                    key={index}
                                    index={index}
                                    template={template}
                                    setEditItem={setEditItem}
                                    setModalAdd={setModalAdd}
                                    setGetData={setGetData}
                                />
                            )}
                    </div>}

                    {hsaData === 1 && <div className="text-center">
                            <img src={noProject} alt="" />
                            <p className="pt-2 mb-2"> 
                                {filtersParams?.keyword ? 'There are no templates with the name or tags entered "The entered data"' : 'Thers is No Templates'}
                            </p>
                            <button className="btn btn-primary" onClick={() => {
                                toggleAdd()
                            }}> Create Template
                                        </button>
                    </div>}

                </div>
                {modalAdd && (
                    <ModalAdd
                        editItem={editItem}
                        modalAdd={modalAdd}
                        toggleAdd={toggleAdd}
                        setGetData={setGetData}
                    />
                )}
                <div className="bg-white mt-3">
                    <div className="d-flex justify-content-end" md={8} sm={12}>
                        <Paginator
                            service={templateService}
                            pageOptions={pageOptions}
                            setData={(data) => {
                                setTemplates([...data])
                                setGetData(false)
                            }}
                            setPageOptions={setPageOptions}
                            filtersParams={filtersParams}
                            shouldGetData={getData}
                            setShouldGetData={() => { }}
                            setHasData={setHasData}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}


export default Templates
