import React from "react";
import docImage from "assets/images/load.gif";
import PropTypes from "prop-types";
import { SuccessOk } from "../../../../configs/statusCode";
import { toast } from "react-toastify";
import OrderServices from "../../../../services/Client/OrderServices";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FeedBack = ({ projectData, toggleAccordion, updateProjectData, callSecondStep,secondStepData,setCompleted }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const orderService = new OrderServices();

  const confirmSecondStep = async () => {
    const res = await orderService.updateContent(id, { step_confirmed: "second_step_status" });
    if (res.status === SuccessOk) {
      toggleAccordion("3");
      updateProjectData({ second_step_status: 1 });
      toast.success("Project content Confirmed successfully");
      setCompleted(prev => prev +25);
      callSecondStep();
    } else {
      toast.error("Whoops something went wrong");
    }
  };

  return (<div>
    <div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row justify-content-between">
        <div className="col-lg-9">
          <h6 className="fw-bold mb-1 d-flex align-items-center">
            {projectData.second_step_status !== 1 && <img src={docImage} alt="" width={50} height={40}/>}
            {projectData.second_step_status == 1 && <i className="fa fa-check-circle text-primary" style={{fontSize: '30px'}}></i>}
            <span className="fw-bold ps-1 text-primary font-size=14">
                {projectData.second_step_status === 1 ? <span>{t("Completed")}</span> : 
                (projectData.second_step_status === 2 ? `Your account manager is currently reviewing your content.` : 
                <span>{t("Your account manager is currently reviewing your content.")}</span>)}
              </span>
            </h6>
            {projectData.second_step_status === 3 ? 
            <p className='text-muted mb-1 pt-2 font-14'> {t("Fill information about your business and goals for this website and this data can vary and time can be from.")}</p>
              : ""}
          {projectData.second_step_status === 2 ?
            <p className="fw-bold font-size-14 ps-5 ms-2">{t("Check back soon")}</p> : null}
        </div>
        <div className="col-lg-3 text-end">
          {projectData.second_step_status === 1 ?
              <label htmlFor="contained-button-file">
                <a href={secondStepData[0]?.reference_url} target="_blank" rel="noreferrer" className="btn btn-primary font-16  height-42 px-2 " >
                  {t("Preview Content")}
                </a>
              </label> : ""}
            {projectData.second_step_status === 3  ?
                <button className="btn btn-success height-42 mt-3" onClick={() => confirmSecondStep()}>
                  {t("I am Happy with this content")} <i
                    className="fas fa-smile"></i></button> : null}
        </div>
      </div>
    </div>
  </div>);
};
FeedBack.propTypes = {
  projectData: PropTypes.object,
  secondStepData: PropTypes.object,
  toggleAccordion: PropTypes.func,
  updateProjectData: PropTypes.func,
  callSecondStep: PropTypes.func,
  setCompleted: PropTypes.any,
};

export default FeedBack;