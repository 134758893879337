import "./styles.scss"
import React from "react"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import activitiesImage from "assets/images/activities.gif"
import useCaseImage from "assets/images/use-case.gif"
import customerSupportImage from "assets/images/customer-support.gif"
import accessImage from "assets/images/home5.png"
import professionalImage from "assets/images/home6.png"
import insightImage from "assets/images/home7.png"
// import starImage from "assets/images/stars.png"
import sslImage from "assets/images/protection.gif"
import supportImage from "assets/images/faq.gif"
import widgetImage from "assets/images/web-browser.gif"
import paymentImage from "assets/images/payment-app.gif"
import hostImage from "assets/images/data-cloud.gif"
import responsiveImage from "assets/images/responsive-design.gif"
import exportImage from "assets/images/database-table.gif"
import imgCircle from "assets/images/oval.png"
import imgLogoMark from "assets/images/logomark.png"
import imgFirstSection from "assets/images/firstSection.png"
import imgPen from "assets/images/pen.png"
import imgLine from "assets/images/lineCurve.png"
import imgTimeline from "assets/images/timeline.png"
import imgTimelineMob from "assets/images/timelineMob.png"
import imgtime1 from "assets/images/time1.png"
import imgtime2 from "assets/images/time2.png"
import imgtime3 from "assets/images/time3.png"
import imgtime4 from "assets/images/time4.png"
import { Helmet } from "react-helmet";
import HeaderHome from "components/Website/Layouts/HeaderHome";
import GeneralFooter from "components/Website/Layouts/Footer/GeneralFooter";
import AskedQuestions from "components/Website/Plans/AskedQuestions";
import Slider from "./Slider";

const Home = ({ history }) => {
    const { t, i18n } = useTranslation();
    
    return (
      <div className="homepage">
        <div>
          <div
            className={
              i18n.language !== "ar" ? "firstSection1" : "firstSection2"
            }
          >
            <HeaderHome />
            <Helmet>
              <title>Webkicks</title>
              <meta
                name="description"
                content="hire egyptian software house to build your website"
              />
              <meta
                name="keywords"
                content="web development ,web design , coding in egypt "
              />
            </Helmet>
            <div className="container pt-5 pb-4">
              <div className="row py-4 justify-content-center">
                <div
                  className="col-lg-7 col-md-12"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="2000"
                >
                  <h1 className="fw-bold lineHeight text-white text-center pt-3 pt-lg-5 mt-md-5">
                    {" "}
                    {t(
                      "Expertly designed websites, made easy for busy entrepreneurs"
                    )}{" "}
                  </h1>
                  <p className="description text-white text-center pt-4 px-lg-5">
                    {t(
                      "From concept all the way to launch, every step is handled by experts, from design to content creation, backed by a dedicated account manager for personalized guidance."
                    )}{" "}
                  </p>
                  <div className="d-block d-sm-flex justify-content-center text-center pt-4">
                    <button
                      className="btn btn-primary btnHeightLg p-2 mb-2 px-5 me-lg-3 font-14 text-start rounded-3"
                      onClick={() => history.push("/templates")}
                    >
                      {t("Get Your New Website Now")}
                    </button>
                  </div>
                </div>
              </div>
              <img src={imgFirstSection} className="img-fluid m-auto d-block" />
              <div className="row pt-3 justify-content-center pb-md-5 about-section">
                <div className="col-lg-3 col-md-4 col-6 pt-lg-3 pb-lg-5 p-lg-0">
                  <div className="d-flex align-items-center gap-2">
                    <p>
                      <img
                        src={customerSupportImage}
                        className="imgActivities"
                        alt=""
                      />
                    </p>
                    <p className="font-16 text-white fw-bold">
                      {t("Personalized Human Support")}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-6 pt-lg-3 pb-lg-5">
                  <div className="d-flex align-items-center gap-2 justify-content-lg-end">
                    <p>
                      <img
                        src={useCaseImage}
                        className="imgActivities"
                        alt=""
                      />
                    </p>
                    <p className="font-16 text-white fw-bold">
                      {t("Daily website backups")}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-6 pt-lg-3 pb-lg-5">
                  <div className="d-flex justify-content-center align-items-center gap-2 justify-content-lg-end">
                    <p>
                      <img
                        src={activitiesImage}
                        className="imgActivities"
                        alt=""
                      />
                    </p>
                    <p className="font-16 text-white fw-bold">
                      {t("FREE hosting")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container pt-4">
            {/* <div className="row justify-content-around">
              <div className="col-lg-10">
                <div className="p-4">
                  <div className="d-none d-md-block">
                    <div className="row justify-content-between">
                      <div className="col-lg-2 col-md-4">
                        <img src={starImage} className="img-fluid" />
                      </div>
                      <div className="col-lg-6 col-md-8">
                        <p className="fw-medium">
                          “webkicks has helped me transform from a local shop to
                          an online up and running business in less than 36
                          hours”
                        </p>
                      </div>
                      <div className="col-lg-3">
                        <h1 className="fw-bold text-primary">
                          4.8 <span className="font-18">out of 5</span>
                        </h1>
                        <p className="text-primary font-14">
                          Based on 1,278 reviews
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-none">
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <img src={starImage} className="img-fluid" />
                      </div>
                      <div className="col-5">
                        <h1 className="fw-bold text-primary">
                          4.8 <span className="font-18">out of 5</span>
                        </h1>
                        <p className="text-primary font-14">
                          Based on 1,278 reviews
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-8">
                        <p className="fw-medium">
                          “webkicks has helped me transform from a local shop to
                          an online up and running business in less than 36
                          hours”
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row pt-md-5 pt-3 justify-content-center section-website">
              <div className="col-md-6">
                <img
                  src={accessImage}
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                  className="mx-auto d-block img-fluid"
                  alt="attachments"
                />
              </div>
              <div className="col-md-6 newBadge pt-md-5 pt-4">
                <div
                  className="pt-md-2"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                >
                  <div className="d-flex justify-content-md-center">
                    <div className="row justify-content-lg-center">
                      <div className="col-lg-7">
                        <p className="textLightSuccess mb-0">
                        {t("Streamlined website building")}
                        </p>
                        <h3 className="fw-bold py-3 textDarkPrimary">
                          {t("Effortless website building, focused on you")}
                        </h3>
                        <p className="textMed fw-medium pb-4">
                          {t("Everything in one place – chat, email, briefing, content writing, website design, and more. Stay informed on your website’s progress with your dedicated account manager at webkicks.")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-2 justify-content-center section-website" dir="rtl">
              <div className="col-md-6">
                <img
                  src={professionalImage}
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                  className="mx-auto d-block img-fluid"
                  alt="attachments"
                />
              </div>
              <div className="col-md-6 newBadge pt-lg-4 pt-3" dir="ltr">
                <div
                  className="pt-md-4"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                >
                  <div className="d-flex justify-content-md-center">
                    <div className="row justify-content-lg-center">
                      <div className="col-lg-7">
                        <p className="text-primary mb-0">{t("Support")}</p>
                        <h3 className="fw-bold py-3 textDarkPrimary">
                          {t("Unmatched support at")} <br/>
                          {t("every step")}
                        </h3>
                        <p className="textMed fw-medium pb-4">
                          {t("Say goodbye to website-building hassles with webkicks. Our full support system includes dedicated account managers, skilled web developers, and creative content writers so that you get a smooth process from start to finish.")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-2 justify-content-center section-website">
              <div className="col-md-6">
                <img
                  src={insightImage}
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                  className="mx-auto d-block img-fluid"
                  alt="attachments"
                />
              </div>
              <div className="col-md-6 newBadge pt-lg-4">
                <div
                  className="pt-md-4"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                >
                  <div className="d-flex justify-content-md-center pt-3">
                    <div className="row justify-content-lg-center">
                      <div className="col-lg-7">
                        <p className="textWarning mb-0">Incredible designs</p>
                        <h3 className="fw-bold py-3 textDarkPrimary">
                          Over <span className="h1 fw-bold"> 100+</span> <br />{" "}
                          customizable templates
                        </h3>
                        <p className="textMed fw-medium">
                          {t("Choose from over 100 stunning templates. Whether it’s SaaS or education, we have designs that resonate with your audience.")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-lg-5 py-3 cards">
          <div className="container pb-lg-5">
            <div className="text-center newBadge py-5">
              <p className="text-primary mb-0">Expert website designs</p>
              <h4 className="fw-bold textDarkPrimary py-3">
                One-time fee, Professional support
              </h4>
              <p className="textMed fw-medium d-none d-md-block">
                Are you tired of dealing with recurring fees for your website{" "}
                <br /> builder service? We understand the frustration.
              </p>
              <p className="textMed fw-medium d-md-none">
                Are you tired of dealing with recurring fees for your website{" "}
                builder service? We understand the frustration.
              </p>
              <p className="font-size-14 fw-bold pt-2">
                Now you can enjoy hassle-free website management with these
                amazing benefits:
              </p>
            </div>
            <div className="row text-center pt-5 pb-lg-3 justify-content-between">
              <div className="col-lg-3 col-md-6 pb-5 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={sslImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">Free SSL & DDN</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pb-5 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={supportImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">
                      Dedicated account manager
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={widgetImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">Widgets & embeds</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-lg-3 justify-content-around text-center">
              <div className="col-lg-3 col-md-6 pb-5 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={hostImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">Hosting</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pb-5 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={responsiveImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">
                      Desktop & mobile responsive
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={exportImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">HTML export</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 text-center justify-content-center">
              <div className="col-lg-3 col-md-6 pb-5 mb-4 px-lg-0">
                <div className="card cardSupport border-0">
                  <div className="card-body border-0">
                    <img
                      src={paymentImage}
                      className="img-fluid imgSupport"
                      alt=""
                    />
                    <h6 className="fw-bold textPrimary pt-2 pb-3">
                      Accept online payments
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="how">
        <div className="container px-4 pb-5">
            <div className="lunchWebsite text-center py-5 position-relative px-3">
              <img
                src={imgCircle}
                className="img-fluid absoluteCircle"
                alt=""
              />
              <h3 className="text-white fw-bold pt-4">
                You don’t need to be a tech expert to launch a great website
              </h3>
              <p className="text-white py-3">
                Now you too can create an online presenceby turning your idea
                into a website
              </p>
              <button
                className="btn btn-primary btnHeightLg p-2 px-5 font-14 text-start mt-4 mb-md-4 mb-5"
                onClick={() => history.push("/templates")}
              >
                {t("Try it for yourself")}
              </button>
              <img
                src={imgLogoMark}
                className="img-fluid position-absolute imgMark"
                alt=""
              />
            </div>
          </div>
          <div className="pt-lg-5 container">
            <h3 className="text-center textDarkPrimary fw-bold">Built with Webkicks</h3>
            <p className="text-center font-size-20 textMed">Explore our portfolio of stunning website designs for our satisfied clients</p>
            <Slider/>
          </div>
          <div className="container pt-md-5 pb-5">
            <div data-aos="fade-up">
              <h3 className="text-center fw-bold mb-0">
                Your Website, Made Easy
                {/* <span className="text-primary">4 easy steps</span> */}
                <img src={imgPen} className="img-fluid" />
              </h3>
              <img src={imgLine} className="img-fluid m-auto d-block" />
              {/* <p className="text-center text-muted pt-3 mb-0 pb-3">Fill information about your business and goals for this website, this data <br /> can vary and time can be from 30 mins to 4 hours </p> */}
            </div>
          </div>
          <div className="container-fluid ps-0">
            <div className="bgTimeline overflow-hidden">
              <img src={imgTimeline} className="time-image position-absolute d-none d-lg-block" />
              <img src={imgTimelineMob} className="time-image position-absolute d-lg-none" />
              <div className="row justify-content-around pt-xl-3">
                <div className="col-lg-5 col-md-8 col-11 pb-5 col-xxl-6">
                  <div>
                    <div className="pt-md-2 pb-md-3 pb-xl-1 pt-4 mt-2 position-relative">
                      <div className="position-time"
                      >
                        <div className="pt-1 pt-md-5 pt-xxl-5 mt-md-5 d-flex ms-3 ms-md-0 mb-md-5">
                          <img className="imgTime" src={imgtime1} alt="" />
                          <div>
                            <h6 className="headerTimeline fw-bold pt-md-2">
                              Pick a Look
                            </h6>
                            <p className="textMed fw-medium descTimeline pb-md-3">
                            Browse our templates and select <br className="d-md-none"/> your favorite.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="position-time">
                      <div
                        className="pt-md-3 pt-3 mt-3"
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-duration="1000"
                      >
                        <div className="pt-md-5 pt-3 mt-md-3 d-flex ms-3 ms-md-0">
                          <img className="imgTime" src={imgtime2} alt="" />
                          <div>
                            <h6 className="headerTimeline fw-bold pt-md-2">
                              Tell Us About Your Business
                            </h6>
                            <p className="textMed fw-medium descTimeline pb-md-5 pb-3">
                            Fill out a quick form so we understand  <br className="d-md-none" /> your vision.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-md-5 mt-md-3 mt-md-5 position-time pb-2 pb-md-0">
                      <div className="pt-md-4 pb-md-3 pb-xl-2" data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-duration="1000">
                        <div className="mt-md-5 pt-2 mt-4 d-flex ms-3 ms-md-0">
                          <img className="imgTime" src={imgtime3} alt="" />
                          <div>
                            <h6 className="headerTimeline fw-bold pt-md-2">
                              Brief Us
                            </h6>
                            <p className="textMed fw-medium descTimeline minHeightDesc pb-2 pb-md-0">
                              Provide any brand information or content. Prefer us to <br className="d-none d-md-block"/> write? Just say so. Then relax while we build.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="pt-md-5 mt-md-5"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="2000"
                    >
                      <div className="pt-md-5 mb-2 pb-md-5 mb-5 mb-xxl-1 mt-lg-5 position-time">
                        <div className="pt-lg-0 pt-md-5 pt-3 d-flex ms-3 ms-md-0 mb-xxl-3">
                          <img className="imgTime" src={imgtime4} alt="" />
                          <div>
                            <h6 className="headerTimeline fw-bold">
                              Give us your feedback and Go Live
                            </h6>
                            <p className="textMed fw-medium descTimeline">
                              Launch your website and let it work <br className="d-md-none d-block"/> its magic for <br className="d-none d-md-block" /> your business. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-md-5">
          <div className="container pb-md-5">
            <AskedQuestions />
          </div>
        </div>

        {/* What are you waiting for? */}
        <div>
          <div className="bgLunch p-4 position-relative">
            <div className="text-center pt-5">
              <div>
                <h2 className="fw-bold pb-3 pt-2 pt-lg-5 textBlueDark textLarge">
                  Ready to launch your website?
                </h2>
                <h4 className="fw-bold pb-3 textBlueDark textEqual">
                  Get started now and see your website come to life
                </h4>
                <p className="text-white pt-2">
                  Say goodbye to complicated website building. With our
                  platform,
                  <br />
                  you can get your site up and running in no time.
                </p>
                <button
                  className="btn btn-primary btnHeightLg p-2 mb-5 mt-4 px-5 font-14 text-start"
                  onClick={() => history.push("/templates")}
                >
                  {t("Get your website now")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <GeneralFooter />
      </div>
    );
}

Home.propTypes = {
    history: PropTypes.object,

};

export default Home
