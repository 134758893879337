import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/website/order/order-template";
const apiCustomTemplateEndpoint = API_BASE_URL_ENV() + "/client/custom-design-email";

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    customTemplate(data){
        return http.post(apiCustomTemplateEndpoint, data)
    }
}
