import React, { useState } from "react";
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import DeleteModal from "components/Common/DeleteModal";
import PropTypes from "prop-types";
import ClientService from "services/Admin/ClientService";
import { GET_AUTH_USER } from "../../../../helpers/common";
import ModalChangeStatus from "components/Common/ModalChangeStatus";
import { SuccessOk } from "configs/statusCode";
import { toast } from "react-toastify";

const CardItem = ({ client, index, toggleAdd, setEditItem, setGetData }) => {
  const clientService = new ClientService();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(client?.status);
  const [deletedItem, setDeletedItem] = useState(null);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [deleteModal, setDeleteModal] = useState(false);
  const userRole = GET_AUTH_USER();
  const handleCloseModal = (action) => {
    setDeleteModal(false);
    if (action === "delete") {
      setGetData(true);
    }
  };

  const changeStatus = ()=>{
    setLoading(true)
    clientService.toggleStatus(client.id).then(res=>{
      if (res?.status == SuccessOk) {
        setStatus(!status);
        toast.success(`User Status Updated Successfully`);
        setModalStatus(false)
      }
      setLoading(false)
    })
  }

  return (
    <tr ke={index}>
      <td className="ps-4">{client.name}</td>
      <td>{client.email}</td>
      <td>
        <span 
          onClick={()=> setModalStatus(true)}
          className={`${status ? "textSuccess" : 'text-danger'} fw-bold cursor-pointer`}>
          {status ? 'Active' : 'Deactive'}
        </span>
      </td>
      {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
        <td>
          <div>
            <div className="removebtnhover">
              <Dropdown className="dropdownWidth w-100" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="border-0 pt-0">
                  <button className="btn btn-outline-primary fw-bold font-14 px-5 height-42 ms-3">More</button>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="text-primary" onClick={() => {
                    setEditItem(client);
                    toggleAdd();
                  }}><i className="fa fa-link"></i> Edit User</DropdownItem>
                  <DropdownItem className="text-danger" onClick={() => {
                    setDeleteModal(!deleteModal);
                    setDeletedItem(client);
                  }}><i className="fas fa-trash-can"></i> Delete User</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <DeleteModal
              open={deleteModal}
              titleMsg={deletedItem?.name}
              deletedItem={deletedItem}
              modelService={clientService}
              onCloseModal={(action) => handleCloseModal(action)}
            />
            {modalStatus && 
                <ModalChangeStatus 
                    modal={modalStatus} 
                    toggle={()=> setModalStatus(false)}
                    loading={loading}
                    changeStatus={changeStatus}
                />
            }
          </div>
        </td> : null}
    </tr>
  );
};
CardItem.propTypes = {
  client: PropTypes.object,
  index: PropTypes.number,
  toggleAdd: PropTypes.func,
  setEditItem: PropTypes.func,
  setGetData: PropTypes.func
};

export default CardItem;
