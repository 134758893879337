import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import "./style.scss";
import AdminChatTwilio from "../../../components/Admin/ChatTwillio";
import OrderService from "../../../services/Admin/OrderService";
import { imageOnError } from "../../../helpers/common";
import { Circles } from "react-loader-spinner";
import Paginator from "./Paginator/Paginator";
import { useSelector } from "react-redux";

const Chat = () => {
  const Auth = useSelector(state=> state?.Auth)
  const [getData, setGetData] = useState(true);
  const [rooms, setRooms] = useState([]);
  // const [client, setClient] = useState({});
  const [selectedRoom, setSelectedRoom] = useState(null);
  const orderService = new OrderService();
  // const getRooms = async () => {
  //   setGetData(false);
  //   const res = await orderService.rooms();
  //   setRooms(res.data.data);
  //   setGetData(false);
  // };
  // useEffect(() => {
  //   getRooms().catch(e => console.log(e));
  //   setGetData(true);
  // }, []);
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
  })
  return (<>
    <div className="admin-container">
      <MetaTags>
        <title> Clients </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col md="7">
            <div className="bg-white shadow border-1 rounded-3">
              <div className="py-3 ps-lg-4 mt-2">
                <h4 className="pb-3 fw-bold border-bottom">Projects chats</h4>
                {getData ?
                (
                  <div className="loaderStyle">
                    <Circles color="#00BFFF"/>
                  </div>)
                :
                (
                  <div className="listUsers">
                    {!!rooms.length && rooms.map((room) => {
                      return (
                      <a key={room?.room}
                        onClick={() => {
                          // setClient(room?.client)
                          setSelectedRoom(room);
                          // setGetData(false)
                        }}
                        className="text-decoration-none cursor-pointer">
                        <div className="d-flex pt-2 ps-2 rounded-2 align-items-center">
                          <i className="fa fa-circle text-success pt-2 pe-2"></i>
                          <div>
                          <img src={room?.first_step_media?.path || room?.template_media?.path} style={{objectFit: 'cover'}}
                              onError={(e) => imageOnError(e, "rounded-circle")}
                              className="rounded-circle" width= "50px" height="50px" alt="chat"/>
                            </div>
                          <div className="ps-2">
                            <p className="mb-0 fw-bold text-black">{room?.business_name}</p>
                            <p className="text-black-50 mb-0" style={{fontSize: '13px'}}>{room?.client?.name}</p>
                          </div>
                        </div>
                      </a>);
                    })}
                  </div>
                )}
              </div>
              <Paginator
                service={orderService }
                pageOptions={pageOptions}
                setData={(data) => {
                  setRooms([...data])
                  setGetData(false)
                }}
                setPageOptions={setPageOptions}
                filtersParams={null}
                shouldGetData={getData}
                setShouldGetData={setGetData}
              />
              </div>
          </Col>
          <Col md="5">
            {selectedRoom ? <AdminChatTwilio room={selectedRoom} setSelectedRoom={setSelectedRoom} admin={Auth} /> : ""}
          </Col>
        </Row>
      </Container>
    </div>
  </>);
};

export default Chat;
