import React from "react";
import {
  Container, Row, Col, CardText
} from "reactstrap";
import PropTypes from "prop-types";
import "./style.scss";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { GET_AUTH_USER } from "helpers/common";

const CardItem = ({ order, assignProject }) => {
  const userRole = GET_AUTH_USER();

  const getStatus = (step, status) =>{
    if(step === 'first' && status === 0){
      return 'In Progress'
    } else if(step === 'third'){
      if(status === 0) return 'Waiting'
      if(status === 2) return 'In Progress'
      if(status === 3) return 'Ready to Review'
    } else {
      if(status === 0) return 'Pending Client Action'
      if(status === 2) return 'In Progress'
      if(status === 3) return 'Pending Account Manager Action'
    }
  }

  const getStatusBack = (step, status) => {
    if(step === 'first' && status === 0) return false
    if(status === 2){
      return false
    } else {
      return true
    }
  }

  return (
    <div className="styleCard">
      <div className="card template-item">
        <Container fluid>
          <Row key={order.id} className="template-border justify-content-around">
            <Col md={10} lg={10}>
              <div className="p-3">
                <div>
                  <CardText tag="h6" className="text-capitalize pt-md-2 pt-3 fw-bold">
                    {order?.first_step_business_name ? order?.first_step_business_name : <span className="text-danger">Not yet determined</span>}
                    <span
                      className={classnames("badge badgeDanger ms-2 mt-2", { "bg-success": order?.assignee?.role !== 'Admin' })}>{order?.assignee?.role === 'Admin' ? "Not Assigned" : "Assigned"}</span>
                  </CardText>
                  {(order?.type || order?.business_location) &&<CardText tag="div" className="overflow-auto mb-2">
                    {order?.type && <CardText tag="p" className="text-muted mb-0">Type: <span>{order.type}</span></CardText>}
                    {order?.business_location && <CardText tag="p" className="text-muted mb-0">Location: <span>{order.business_location}</span></CardText>}
                  </CardText>}
                  {/* <CardText tag="div" className="text-primary keywordsDesign">
                      <div className="pe-2 mb-2 d-flex">{order?.template?.design?.map((item, index) => {
                        return (<span className="border-bottom border-primary text-primary me-2"
                                      key={index}>{item.name.en}  </span>);
                      })}</div>
                    </CardText> */}
                  {!!order.business_description && <CardText tag="p" className="text-muted">{order.business_description}</CardText>}
                  <CardText tag="p" className="text-muted mb-0">Owner: <span>{order.client_name}</span></CardText>
                  <CardText tag="p" className="text-muted mb-2">Payment: 
                    <span className="mx-2">
                      {order.subscription_plan?.name?.en}
                      {(order?.content_included && order?.lang_count === 2) ? ' with Content and Two Language' : order?.content_included ? ' with Content' : order?.lang_count === 2 ? ' with Two Language' : ''}
                    </span>
                  </CardText>
                  <CardText tag="p" className="text-muted mb-2">Step:
                    <span className="mx-2">
                      {order?.first_step_data_status !== 1 ? 'Business Info' : 
                       (order?.second_step_data_status  !== 1 && order?.content_included)? 'Content Brief' : 
                       (order?.second_step_data_status  !== 1 && !order?.content_included) ? 'DIY Content' : 
                       order?.third_step_data_status !== 1  ? 'Feedback' : 'Publishing'}
                    </span>
                    <span className={`${(
                      order?.first_step_data_status !== 1 ? getStatusBack('first',order?.first_step_data_status) :
                      order?.second_step_data_status !== 1 ? getStatusBack('second',order?.second_step_data_status) :
                      order?.third_step_data_status !== 1 ? getStatusBack('third',order?.third_step_data_status) : getStatusBack('forth', order?.fourth_step_data_status)
                      )? 'status-badge' : 'badgeCompleted'}`}>
                      {order?.first_step_data_status !== 1 ? getStatus('first', order?.first_step_data_status) :
                       order?.second_step_data_status  !== 1 ? getStatus('second', order?.second_step_data_status) :
                       order?.third_step_data_status !== 1 ? getStatus('third', order?.third_step_data_status) : getStatus('forth', order?.fourth_step_data_status) }
                    </span>
                  </CardText>
                  {order?.assignee?.name?.length ?
                    <CardText tag="p" className="text-muted">
                      <i className="fas fa-user me-2"></i>
                      <span>Assigned to 
                        {order?.assignee?.role === 'Admin' ? 
                        <span className="text-danger mx-1">{`Isn't selected`}</span> : 
                        <span className="mx-1">{order?.assignee?.name}</span> }
                      </span>
                    </CardText> :
                    <CardText tag="p" className="text-muted">
                      <i className="fas fa-user me-2"></i><span>Not Assigned</span>
                    </CardText>
                  }
                </div>
              </div>
            </Col>
            <Col md={2} lg={2} className="pt-md-4 pt-2 px-2 mb-3">
              <Link className="btn btn-outline-primary d-block m-auto text-center fw-bold font-14 btnWidth height-42 pt-2"
                    to={`/admin/project/${order.id}/view`}> View Project</Link>
             {(userRole.role === "Admin") ?
              <button onClick={() => assignProject(order)}
                      className="btn btn-primary d-block m-auto fw-bold font-14 btnWidth height-42 mt-3"> {order?.assignee?.role !== 'Admin' ? 'Re-Assign Project' : 'Assign Project'}
              </button>:null}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
CardItem.propTypes = {
  order: PropTypes.object,
  assignProject: PropTypes.func
};

export default CardItem;
