import { AUTHENTICATE, LOGOUT ,APPENDFILTERS } from "./actionTypes";

const INITIAL_STATE = {
 token:"",
 userType:"",
 user_name:"",
 client_id:"",
 navigateTo:"", 
 permissions:[],
 industry:null,
 business_name:"",
 business_description:"",
 size:null,
 website_type:"",
 type:null,
 has_domain:"",
 template:"",
 plan:null,
 has_content:"",
 industryOptions:[], 
 sizeOptions:[], 
 typeOptions:[], 
 planData:null,
 stripe_id:"",
 langNumber:""


}

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATE:
             return { ...state, ...action.payload };
        case APPENDFILTERS:
             return { ...state, ...action.payload };
        case LOGOUT:
            return {
                ...INITIAL_STATE
            };
          default:
            return state
    }
}

export default AuthReducer
