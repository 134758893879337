import * as yup from "yup";
import {
    TITLE_PATTERN_VALIDATOR,
} from "helpers/validators";
export const AddCategoryValidator = yup.object().shape({
    display_name: yup
        .string()
        .matches(
            TITLE_PATTERN_VALIDATOR.value,
            TITLE_PATTERN_VALIDATOR.errorMessage
        )
        .required("Display name is required"),
    type: yup.string().required(),
    subCategory: yup.array().of(
        yup.object().shape({
            name: yup.object().shape({
                ar: yup.string()
                    .required(),
                en: yup.string()
                    .required()
            })

        })
    )
});

