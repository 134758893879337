import "./styles.scss";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import rocketImage from "assets/images/rocket.png";
import logoWhite from "assets/images/logowhite.png";
import detailsImage from "assets/images/details.png";
// import detailsImage2 from "assets/images/details2.png";
import imgBlog1 from "assets/images/imgBlog1.png";
import imgBlog2 from "assets/images/imgBlog2.png";
 import "./styles.scss";
import { Link } from "react-router-dom";
 import BlogService from "services/Website/BlogService"
import { SuccessOk } from "configs/statusCode";
import parse from 'html-react-parser'
import NavbarHeader from "components/Website/Layouts/Header"
import { Helmet } from "react-helmet";

const BlogDetails = ({ history, match }) => {
  const { t, i18n } = useTranslation();
  const [blog, setBlog] = useState(null);
  const blogService = new BlogService()
  const id = match.params.id

  useEffect(() => {
    blogService.find(id).then((res) => {
      if (res.status === SuccessOk) {
        const description = res.data.description[`${i18n.language}`]
        setBlog({ ...res.data, description: description })
      }
    })
  }, [i18n.language])

  return (
    <div className="homepage">
      {/* Header */}
      <div className="bg-white pb-3">
        <div className="container-fluid">
          <NavbarHeader />
          {blog  && <Helmet>
                <title>{blog.title[`${i18n.language}`]}</title>
                <meta
                    name="description"
                    content={blog ?.category ?.name[`${i18n.language}`]}
                  />
            </Helmet>}
        </div>
      </div>

      {/* Content Blog  */}
      <div className="contentBlog">
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-5">
              <div className="margin_section">
                <img src={blog ?.media ?.path.length ? blog ?.media ?.path : detailsImage}
                  className="mb-4 marginImage" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000" />
              </div>
            </div>
            <div className="col-lg-5 ps-lg-4">
              <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <p className="text-muted pt-4">{blog ?.title[`${i18n.language}`]}</p>
                <h2 className="text-white fw-bold">
                  {blog ?.category ?.name[`${i18n.language}`]}
                </h2>
                {/* <p className="text-muted">6 min read</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pt-5 backgroundSquare">
        <div className="container pt-3 pt-lg-5">
          <div className="row border-bottom pb-5">
            {/* <div className="col-lg-3">
              <h6 className="text-muted mb-0">Author</h6>
              <p className="textGeneral fw-bold">Regina Stewart</p>
              <h6 className="text-muted mb-0">Date</h6>
              <p className="textGeneral fw-bold">March 26, 2020</p>
              <h6 className="text-muted mb-0">Category</h6>
              <p className="textGeneral fw-bold">Learning Resources</p>
              <h6 className="text-muted pt-md-5">Share</h6>
              <p className="pb-3">
                <Link to="/">
                    <i className="fa fa-facebook-square fa-lg textGeneral"></i>
                </Link>
                <Link to="/">
                    <i className="fa fa-twitter-square fa-lg textGeneral ms-2"></i>
                </Link>
              </p>
              
            </div> */}
            <div className="col-lg-9">
              <h4 className="textGeneral font-24" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                {parse(`${blog ?.description}`)}
                {/* {`
                    Business processes in organizations and how they could benefit from automation we've all been 
                    there. You have an idea for a life-altering, earth-shattering, ground-breaking mobile app, web app,
                    or game but you don't know how to code and you can't afford a developer.
                `} */}
              </h4>
              {/* <h4 className="textGeneral opacity-75 pt-4 font-22">
                {`
                    Business processes in organizations and how they could benefit from automation 
                    we've all been there. You have an idea for a life-altering, earth-shattering, 
                    ground-breaking mobile app, web app, or game but you don't know how to code and you 
                    can't afford a developer. 
                `} 
              </h4>*/}
              {/* <h4 className="textGeneral opacity-75 pt-4 font-22">The key to a sustainable business is strategy</h4>
              <div className="row">
                <div className="col-lg-11">
                  <h4 className="textGeneral opacity-75 pt-4 font-22">
                    {`
                            irithm application builder powered by next-generation AI and machine learning 
                            algorithms will generate robust, error-free and well-architected code in a stack of 
                            popular, open-source technologies for maximim maintainability and continuity.

                        `}
                  </h4>
                  <h4 className="textGeneral opacity-75 pt-4 font-22">
                    {`
                                            
                            Code is generated with Node.js and Vue.js as well as Dart/Flutter for mobile applications.
                        `}
                  </h4>
                  <h4 className="textGeneral opacity-75 pt-4 font-22">
                    {`
                                                
                            Google's world-leading cloud computing infrastructure will gaurantee the quality of 
                            your applications' deployment, hosting, security and scalability is second to none. 
                        `}
                  </h4>
                </div> 
              </div>*/}
              {/* <img src={detailsImage2} className="img-fluid rounded-3 mt-4" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" />
              <h4 className="pt-3 textGeneral font-24 fw-bold">
                Deploy, Host and Scale with zero DevOps
              </h4>
              <h4 className="textGeneral font-22 opacity-75 pt-3">
                {`
                    Finished your app? Sitting on top of Google’s cloud infrastructure, your app can be 
                    securely deployed with just one click. irithm handles your deployment, hosting, 
                    scalability and devOps on your behalf so you can focus on managing & growing your 
                    business.
                `}
              </h4> */}
            </div>
          </div>
        </div>
      </div>

      {/* Related Articles */}
      <div className="bg-white py-4">
        <div className="container">
          <h6 className="fw-bold pb-2">Related Articles</h6>
          <div className="row">
            <div className="col-lg-5 col-sm-6 pb-4" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
              <div className="row">
                <div className="col-md-4">
                  <img src={imgBlog2} className="imgLearning mb-2" />
                </div>
                <div className="col-md-8 ps-3">
                  <p className="mb-2 text-success font-12">
                    {" "}
                    Learning Resources
                  </p>
                  <h6 className="fw-bold font-14">
                    Build your own UI kit for apps using React components on
                    iRithm’s platform
                  </h6>
                  <p className="mb-1 text-muted font-12">
                    Business processes in any organization could always benefit
                    from automation and optimization
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-sm-6 pb-4" data-aos="fade-up" data-aos-delay="150" data-aos-duration="2000">
              <div className="row">
                <div className="col-md-4">
                  <img src={imgBlog1} className="imgLearning mb-2" />
                </div>
                <div className="col-md-8 ps-3">
                  <p className="mb-2 text-primary font-12">
                    Artificial Intelligence
                  </p>
                  <h6 className="fw-bold font-14">
                    Build your own UI kit for apps using React components on
                    iRithm’s platform
                  </h6>
                  <p className="mb-1 text-muted font-12">
                    Business processes in any organization could always benefit
                    from automation and optimization
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* What are you waiting for? */}
      <div className="rocket p-4 position-relative">
        <div className="text-center pb-5">
          {/* <img
            src={rocketImage}
            className="rocketImageAbsolute d-none d-lg-block"
          /> */}
          <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
            <h2 className="fw-bold pb-3 pt-2 mt-lg-5">
              What are you waiting for? <br />
              Get started now and have your <br /> website up and running in no
              time
            </h2>
            <p className="text-muted pt-2">
              Refersion helps merchants connect with customers at scale through{" "}
              <br /> affiliate and influencer marketing program custom
              commission structures <br /> for e-commerce brands.
            </p>
            <button
              className="btn btn-primary btnHeightLg p-2 mb-5 mt-4 px-4 font-14 text-start"
              onClick={() => history.push("create-website")}
            >
              {t("Create your website now")}{" "}
              {i18n.language !== "ar" ? (
                <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i>
              ) : (
                  <i className="fa fa-long-arrow-left pe-3 me-5"></i>
                )}
            </button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="footerHome p-4">
        <div className="container pt-4">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <img src={logoWhite} alt="" height="30px" />
              <p className="text-muted pt-4">
                From design to content, our all-in-one website builder helps business owners create professional websites with ease.
              </p>
            </div>
            <div className="col-lg-3">
              <p className="text-muted">Connect with us</p>
              <p>
                <Link
                  className="text-white text-decoration-none px-2"
                  to={""}
                  alt=""
                >
                  <i className="fa fa-facebook-f fa-lg"></i>
                </Link>
                <Link
                  className="text-white text-decoration-none px-2"
                  to={""}
                  alt=""
                >
                  <i className="fa fa-instagram fa-lg"></i>
                </Link>
                <Link
                  className="text-white text-decoration-none px-2"
                  to={""}
                  alt=""
                >
                  <i className="fa fa-twitter fa-lg"></i>
                </Link>
              </p>
            </div>
          </div>
          <div
            className="border-top mt-4 border-secondary d-sm-flex justify-content-between text-start"
            dir="rtl"
          >
            <div className="pt-3">
              <Link
                className="text-white opacity-75 text-decoration-none px-2"
                to={"/contact-us"}
                alt=""
              >
                How it works
              </Link>
              <Link
                className="text-white opacity-75 text-decoration-none px-2"
                to={""}
                alt=""
              >
                Contact
              </Link>
              <Link
                className="text-white opacity-75 text-decoration-none px-2"
                to={"/privacy-policy"}
                alt=""
              >
                Privacy
              </Link>
            </div>
            <p className="text-white pt-3 opacity-75">
              Webkicks {(new Date().getFullYear())}, All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogDetails.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default BlogDetails;
