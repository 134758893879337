import React, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, InputGroupText } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Filterswrapper from "components/Common/filterswrapper";
import ModalAdd from "components/Admin/Clients/ModalAdd";
import Paginator from "components/Common/Paginator/Paginator";
import { Circles } from "react-loader-spinner";
import ClientService from "services/Admin/ClientService";
import CardItem from "components/Admin/Clients/CardItem";
import "./style.scss";
import { GET_AUTH_USER } from "../../../helpers/common";

const Clients = () => {
  const clientService = new ClientService();
  const [getData, setGetData] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [filtersParams, setFilterParams] = useState(null);
  const userRole = GET_AUTH_USER();
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0
  });
  const [keyword, setKeyword] = useState("");
  const [formState, setFormState] = useState({
    keyword: ""

  });


  const toggleAdd = () => {
    setEditItem(null);
    setModalAdd(modalAdd => !modalAdd);
  };

  const toggleEdit = (item) => {
    setEditItem(item);
    setModalAdd(!modalAdd);

  };

  useEffect(() => {
    let params = {};
    // const debounceHandler = setTimeout(() => {
    if (formState.keyword.length) {
      params["keyword"] = formState.keyword;
    }
    setFilterParams({ ...params });
    // }, 1500);

    // return () => clearTimeout(debounceHandler)

  }, [formState]);

  return (
    <>
      <div className="admin-container pt-2">
        <MetaTags>
          <title> Clients </title>
        </MetaTags>
        <Container fluid className="bgGray pb-5 pt-3 px-lg-5">
          {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
            <Filterswrapper
              AddHandler={() => toggleAdd()}
              title={`Registered Users`}
              subTitle={
                "List of Registered Useres on webkicks"
              }
              addbtnName={"Add a User"}
            /> : null}
          <Row>
            <Col lg={10} md={9} sm={8} xs={12}>
              <InputGroup className="inputGroup mb-2 px-2 px-md-0">
                <InputGroupText
                  addonType="prepend"
                  className="bg-white iconSearch"
                >
                  <i className="fa fa-search fa-lg"></i>
                </InputGroupText>
                <input
                  className={`form-control border-start-0 btnHeightLg mb-2`}
                  placeholder="Search by user name"
                  type="text"
                  // value={formState.keyword}
                  // onChange={(e) => setFormState({ keyword: e.target.value })}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col lg={2} md={3} sm={4} xs={12}>
              <div className="px-2 px-md-0">
                <button
                  type="button"
                  className="btn btn-outline-primary btnHeightLg px-3 w-100"
                  onClick={() => {
                    setFormState((state) => ({ ...state, keyword }));
                  }}
                >
                  <i className="fa fa-search me-2"></i>
                  Search
                </button>
              </div>
            </Col>
          </Row>
          <div className="table-responsive mt-3">
            <table className="table table-borderless bg-white">
              <thead className="border-0 rounded-3"
                     style={{ background: "#edf0f3", height: "62px", verticalAlign: "middle" }}>
              <tr>
                <th className="opacity-75 rounded-start ps-4">Name</th>
                <th className="opacity-75">Email</th>
                <th className="opacity-75">Status</th>
                {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
                  <th className="rounded-end opacity-75"></th> : null}
              </tr>
              </thead>
              <tbody>
              {clientList.length ?
                <>
                  {!getData && clientList.map((client, index) =>
                    <CardItem
                      key={index}
                      index={index}
                      client={client}
                      setEditItem={toggleEdit}
                      setModalAdd={setModalAdd}
                      setGetData={setGetData}
                    />
                  )}
                </> :
                <>
                  {!getData &&
                    <div className="loaderStyle">
                      <Circles color="#00BFFF"/>
                    </div>
                  }
                </>
              }
              </tbody>
            </table>
          </div>

          {modalAdd  && (userRole.role === "Admin" || userRole.role === "Account Manager") && (
            <ModalAdd
              editItem={editItem}
              modalAdd={modalAdd}
              toggleAdd={toggleAdd}
              setGetData={setGetData}
            />
          )}
          <div className="bg-white mt-3">
            <div className="d-flex justify-content-end" md={8} sm={12}>
              <Paginator
                service={clientService}
                pageOptions={pageOptions}
                setData={(data) => {
                  setClientList([...data]);
                  setGetData(false);
                }}
                setPageOptions={setPageOptions}
                filtersParams={filtersParams}
                shouldGetData={getData}
                setShouldGetData={() => {
                }}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Clients;
