 import React, { useState } from "react"
 import { Container } from 'reactstrap'
import ModalAdd from "components/Admin/Blog/ModalAdd"
import Paginator from 'components/Common/Paginator/Paginator'
import noProject from "assets/images/noProject.png"
import CardItem from 'components/Admin/Blog/CardItem'
import { Circles } from "react-loader-spinner"
import Filterswrapper from "components/Common/filterswrapper"
import { MetaTags } from "react-meta-tags"
import BlogService from "services/Admin/BlogService";

const BlogsAdmin = () => {
    const blogService = new BlogService()
    const [getData, setGetData] = useState(true)
    const [blogs, setBlogs] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [editItem, setEditItem] = useState(null)
    const [filtersParams, setFilterParams] = useState(null)
    // const [formState, setFormState] = useState({
    //     keyword: "",
    // })
console.log(setFilterParams)
    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
    })

    const toggleAdd = () => {
        setEditItem(null)
        setModalAdd(!modalAdd)
    }

    // useEffect(() => {
    //     let params = {}
    //     let category_value_ids = []
    //     for (const key in formState) {
    //         if (key === "date" || key === "keyword"){
    //             if (formState[`${key}`].length) params[`${key}`] = formState[`${key}`].trim()
    //     }else {
    //         if (formState[`${key}`].length) category_value_ids.push(formState[`${key}`].map(item => item.value).join())

    //     }
    // }

    //     if (category_value_ids.length) {
    //         setFilterParams({ ...params, category_value_ids: category_value_ids.join() })

    //     } else {
    //         setFilterParams({ ...params })

    //     }
    // }, [formState])


    return (
        <div className="admin-container pt-2">
            <MetaTags>
                <title> Blogs </title>
            </MetaTags>
            <Container fluid className="bgGray pb-5 pt-3 px-lg-5">
                <Filterswrapper
                    AddHandler={() => toggleAdd()}
                    title={`Blogs`}
                    subTitle={""}
                    addbtnName={"Add a new blog"}
                />
                <div>
                    {getData &&
                        <div className='loaderStyle'>
                            <Circles color='#00BFFF' />
                        </div>
                    }
                    {blogs.length ? (
                        <div className="bg-white boxShadow">
                            {!getData && blogs.map((blog, index) =>
                                <CardItem
                                    key={index}
                                    index={index}
                                    blog={blog}
                                    setEditItem={setEditItem}
                                    setModalAdd={setModalAdd}
                                    setGetData={setGetData}
                                />
                            )}
                        </div>
                    ) : !getData && (
                        <div className="text-center">
                            <img src={noProject} alt="" />
                            <p className="pt-2 mb-2"> Thers is no blogs </p>
                            <button className="btn btn-primary" onClick={() => {
                                toggleAdd()
                            }}> Create Blog
                                        </button>
                        </div>
                    )}
                </div>
                {modalAdd && (
                    <ModalAdd
                        editItem={editItem}
                        modalAdd={modalAdd}
                        toggleAdd={toggleAdd}
                        setGetData={setGetData}
                    />
                )}
                <div className="bg-white mt-3">
                    <div className="d-flex justify-content-end" md={8} sm={12}>
                        <Paginator
                            service={blogService}
                            pageOptions={pageOptions}
                            setData={(data) => {
                                setBlogs([...data])
                                setGetData(false)
                            }}
                            setPageOptions={setPageOptions}
                            filtersParams={filtersParams}
                            shouldGetData={getData}
                            setShouldGetData={() => { }}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}


export default BlogsAdmin
