import React, { useEffect, useState, useRef } from "react";
import ChatItem from "./ChatItem";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  addDoc,
  serverTimestamp
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";
import "./styles.scss";
import PropTypes from "prop-types";
import { InputGroup , Input } from 'reactstrap';
import { db } from "../../../firebase";
import { v4 } from "uuid";
import { storage } from "../../../firebase";

const ClientChatTwilio = ({client, room }) => {
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const scrollDiv = useRef();

  useEffect(() => {
    const q = query(
      collection(db, room),
      orderBy("createdAt", "desc"),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      setMessages(sortedMessages);
    });
    return () => unsubscribe;
  }, []);

  useEffect(()=>{
    if(messages?.length > 0) scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight
  },[messages])

  useEffect(()=>{
    uploadFile()
  },[imageUpload])

  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        sendMessage(url, 'image')
      });
    });
  };

  const sendMessage = async (msgText, type) => {
    if (!msgText && type === 'text') {
      alert("Enter valid message");
      return;
    }
    let uid = client.id;
    const displayName  = client.name;
    await addDoc(collection(db, room), {
      text: msgText,
      type: type,
      name: displayName,
      createdAt: serverTimestamp(),
      uid,
    });
    setMessageText("");
    // scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    // scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight + 150;
  };

  return (
    <div className="bg-white chat boxShadow rounded-0 shadow border-1 rounded-3 mt-1">
      <div className="py-2 mx-2 text-center">
        <p className="fw-bold font-16 pt-2 mb-2">Chat with Account Manager </p>
      </div>
      <hr className="m-0 p-0"/>
      <div ref={scrollDiv} className="fixedHeightChat">
        {messages && messages.map((message) => {
          if(!message?.createdAt) return
          return (<ChatItem
            key={message.id}
            message={message}
            uid={client.id}
          />);
        })}
      </div>
      <div>
        <div className="d-flex flex-column mx-2 pb-2 bg-white pt-2">
          <div className="w-100 inputGroupChat pb-2">
            <InputGroup className="pe-3">
              <Input
                className="form-control height-42 rounded-pill"
                value={messageText}
                placeholder="Enter Message..."
                onChange={(event) => setMessageText(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    sendMessage(messageText, 'text')
                  }
                }}
              />
              <label className="position-relative">
                <input type='file'
                accept='image/*'
                style={{
                  width: '30px',
                  opacity: '0',
                  zIndex: '99',
                  position: 'absolute',
                  right: '3px',
                  top: '5px'
                }} onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}/>
                <i className='fa fa-image' style={{
                  fontSize: '20px',
                  position: 'absolute',
                  right: '8px',
                  top: '9px',
                  zIndex: '99999'
                }}></i>
              </label>
            </InputGroup>
          </div>
          <button
            onClick={()=> sendMessage(messageText, 'type') }
            className="btn btn-primary widthBtn height-42 p-1"
          >
            Send <i className="fa fa-paper-plane ps-3"></i>
          </button>
        </div>
      </div>
  </div>);
};
ClientChatTwilio.propTypes = {
  room: PropTypes.string,
  client: PropTypes.any
};
export default ClientChatTwilio;
