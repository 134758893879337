import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { appendFilters } from "store/Auth/actions";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import OrderService from "services/Website/OrderService";
import InjectedCheckoutForm from "components/Website/Stripe/InjectedCheckoutForm";
import imgLogoMark from "assets/images/logomark.png"

const quryParams = ["industry", "size", "website_type", "name", "description", "content", "domain", "template", "plan"];
const Purchase = (props) => {
  const stripePromise = loadStripe("pk_test_51OAUfEJtD74K52FwOVJPJYiRaPOXSMlkptC3YDg4QXkoZw5ZUlsvL2K33ozZCiIuR1NgdWtsJvrXapnBAOdP7DfA00P4eOT6JD");
  // const stripePromise = loadStripe("pk_test_51KBFffC71jeR6DgNS4KGfY6ISW0u5zkqvJX81zRuCcjrrCJOZ0GEQXUgqCqN5eVvpQcuUWRnUDtoACAgVa618tNt00eSUdwWPO");
  const [paramsObj, setParamsObj] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { t } = useTranslation();
  let query = useQuery();
  const {
    industry, size, website_type, name, description, content, domain, template, plan
  } = paramsObj || {};

  const data = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const orderServices = new OrderService()

  useEffect(() => {
    const updatedParamsObj = {};
    for (let index = 0; index < quryParams.length; index++) {
      updatedParamsObj[`${quryParams[index]}`] = query.get(`${quryParams[index]}`);
    }
    setParamsObj(updatedParamsObj);
  }, []);


  useEffect(() => {
    const planData = localStorage.getItem("plan");
    const stripe_id = localStorage.getItem("stripe_id");
    const langNumber = localStorage.getItem("langNumber");

    const parsedPlan = JSON.parse(planData);
    let selectedIndustry, selectedSize, selectedType;
    let { industryOptions, sizeOptions, typeOptions } = data;
    if (industryOptions?.length && sizeOptions?.length && typeOptions?.length && paramsObj) {
      let typeCondition = data ?.website_type !== 0 && !data ?.type
      if (!data.industry || !data.size || typeCondition) {
        selectedIndustry = industryOptions?.find(option => option.value == industry);
        selectedSize = sizeOptions?.find(option => option.value == size);
        selectedType = typeOptions?.find(option => option.value == website_type);
        let filter = {
          industry: selectedIndustry,
          size: selectedSize,
          type: parseInt(website_type) === 0 ? null : selectedType,
          website_type: parseInt(website_type) === 0 ? 0 : "",
          business_name: name,
          business_description: description,
          has_domain: parseInt(domain),
          has_content: parseInt(content),
          template:parseInt(template),
          plan,
          planData: parsedPlan,
          selectedTemplate: props?.history?.location?.state?.selectedTemplate,
          stripe_id,
          langNumber
        }
        dispatch(appendFilters(filter));
      } 
    }
  }, [data.industryOptions, data.sizeOptions, data.typeOptions ,paramsObj]);
  
  const totalPrice = (plan) =>{
    if(plan?.has_content && plan?.has_second_lang){
      return parseFloat(plan?.price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount) + parseFloat(plan?.content_price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount) + parseFloat(plan?.second_lang_price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount)
    } else if(!plan?.has_content && !plan?.has_second_lang){
      return parseFloat(plan?.price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount)
    } else{
      if(plan?.has_content){
        return parseFloat(plan?.price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount) + parseFloat(plan?.content_price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount)
      }
      if(plan?.has_second_lang){
        return parseFloat(plan?.price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount) + parseFloat(plan?.has_second_lang?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount)
      }
    }
  }

  useEffect(() => {
    let plan = props?.history?.location?.state?.plan
    let data = {
      amount: props?.history?.location?.state?.from === "content" ? parseFloat(plan?.content_price?.find(res=> res.currency.toLowerCase() === plan?.currency.toLowerCase())?.amount)*100 : totalPrice(plan) *100,
      currency: props?.history?.location?.state?.plan?.currency,
      automatic_payment_methods: {
        enabled: true,
      },
    }
    orderServices?.customPayment(data).then(res=>{
      if(res?.status === 200){
        setClientSecret(res.data.client_secret)
      }
    })
  }, []);

  const options = {
    clientSecret,
  };
  return (
  <>
    <div className="websitPages pt-5 mt-3">
      <div className="container-fluid px-md-5 pt-5">
        <div>
          <h4 className="pt-3 fw-bold">{t("You’re almost there!")}</h4>
          <p className="font-14 text-muted">
            {t("Your website is within reach, but there's one more step to take.")}
          </p>
          {props.history.location?.state?.from !== "content" && <div className="lunchWebsite p-5 position-relative mb-4">
            {props.history.location?.state?.plan.has_content ? <>
              {/* <h3 className="text-white fw-bold font-size-20">
               We offer exceptional value for your investment:
              </h3> */}
              <p className="text-white">
                Get your website ready in  [{props.history.location?.state?.plan?.features?.feature?.match(/(\d+)-(\d+)/).length && props.history.location?.state?.plan?.features[0].feature?.match(/(\d+)-(\d+)/)[0]} days], 1-year free hosting, dedicated account manager support, premium template design, daily backup and recovery, and [ Up to {props.history.location?.state?.plan?.words_num} words of content]. We offer limited refund options, visit FAQ Section at Home page to learn about our refund policy.
              </p>
            </> : <>
            <p className="text-white">
            Get your website ready in  [{props.history.location?.state?.plan?.features.feature?.match(/(\d+)-(\d+)/)?.length && props.history.location?.state?.plan?.features[0].feature?.match(/(\d+)-(\d+)/)[0]} days], 1-year free hosting, dedicated account manager support, premium template design, and daily backup and recovery. We offer limited refund options, visit FAQ Section at Home page to learn about our refund policy.
            </p>
            </>}
  
            <img
              src={imgLogoMark}
              className="img-fluid position-absolute imgMark"
              width="111px"
              height="74px"
              alt=""
            />
          </div>}
        </div>

        {!!clientSecret && <Elements options={options}  stripe={stripePromise}>
          <InjectedCheckoutForm clientSecret={clientSecret} data={data} history={props.history}/>
        </Elements>}
      </div>
    </div>
  </>);
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

Purchase.propTypes = {
   history:PropTypes.any,
};


export default Purchase;
