import React from "react";
import { Col, Container, Row} from 'reactstrap';
import { MetaTags } from "react-meta-tags";
import  totalPayment1  from 'assets/images/payment1.png';
import  totalPayment2  from 'assets/images/payment2.png';
import  totalPayment3  from 'assets/images/payment3.png';
import  file1  from 'assets/images/file1.png';
import  file2  from 'assets/images/file2.png';
import  file3  from 'assets/images/file3.png';
import  file4  from 'assets/images/file4.png';
import "./style.scss";

const AdminDashBoard = () => {
  return (
    <>
      <div className="admin-container">
        <MetaTags>
          <title> Dashboard </title>
        </MetaTags>
        <Container fluid  className="bgGray pb-5 pt-3 px-lg-5">
          <div className="d-md-flex justify-content-between pb-4 pb-md-2">
            <div>
              <h4 className="fw-bold">Dashboard</h4>
              <p className="textSecondary opacity-50">Description Here Description Here Description Here Description Here </p>
            </div>
            <div>
              <label className="textSecondary">Showing results for</label>
              <input type="date" className="form-control mt-2"/>
              {/* <Calendar
                date={new Date()}
                onChange={this.handleSelect}
              /> */}
            </div>
          </div>
          <h4 className="fw-bold">Payments</h4>
          <Row className="pt-2">
            <Col lg={4} md={4} sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={totalPayment3} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">$2,568 <sub>USD</sub></h4>
                      <p className="text-muted fw-bold mb-0">Total Payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={totalPayment2} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">$2,568 <sub>AED</sub></h4>
                      <p className="text-muted fw-bold mb-0">Paid Payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={totalPayment1} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">$2,568 <sub>SAR</sub></h4>
                      <p className="text-muted fw-bold mb-0">Pending Payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h4 className="fw-bold pt-3">Prrojects</h4>
          <Row className="pt-2">
            <Col lg={3}  sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={file1} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">85</h4>
                      <p className="text-muted fw-bold mb-0">Active Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}  sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={file2} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">60</h4>
                      <p className="text-muted fw-bold mb-0">Completed Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}  sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={file3} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">62</h4>
                      <p className="text-muted fw-bold mb-0">In progress Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}  sm={6}>
              <div className="card shadow border-0 rounded-3 mb-2">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="pt-2">
                      <img className="img-fluid" src={file4} />
                    </div>
                    <div className="ps-3">
                      <h4 className="fw-bold">21</h4>
                      <p className="text-muted fw-bold mb-0">Pending Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="card border-0 shadow mt-4">
            <p className="card-header p-3 px-4 textSecondary fw-bold">Total Active Projects 20</p>
            <div className="card-body px-md-4 py-0">
              <Row>
                <Col lg="3" md="6" className="borderEnd pt-4 pe-md-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="fw-bold pb-3">Brief</h6>
                      <p className="textGreen">In Progress</p>
                      <p className="textGreen">Pending</p>
                    </div>
                    <div>
                      <h6 className="fw-bold pb-3">5 Projects</h6>
                      <p className="textGreen">2</p>
                      <p className="textGreen">3</p>
                    </div>
                  </div>
                </Col>
                <Col lg="3" md="6" className="borderEnd pt-4 px-md-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="fw-bold pb-3">Content</h6>
                      <p className="textGreen">In Progress</p>
                      <p className="textGreen">Pending</p>
                    </div>
                    <div>
                      <h6 className="fw-bold pb-3">5 Projects</h6>
                      <p className="textGreen">2</p>
                      <p className="textGreen">3</p>
                    </div>
                  </div>
                </Col>
                <Col lg="3" md="6" className="borderEnd pt-4 px-md-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="fw-bold pb-3">Feedback</h6>
                      <p className="textGreen">In Progress</p>
                      <p className="textGreen">Pending</p>
                    </div>
                    <div>
                      <h6 className="fw-bold pb-3">5 Projects</h6>
                      <p className="textGreen">2</p>
                      <p className="textGreen">3</p>
                    </div>
                  </div>
                </Col>
                <Col lg="3" md="6" className="pt-4 px-md-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="fw-bold pb-3">Publish</h6>
                      <p className="textGreen">In Progress</p>
                      <p className="textGreen">Pending</p>
                    </div>
                    <div>
                      <h6 className="fw-bold pb-3">5 Projects</h6>
                      <p className="textGreen">2</p>
                      <p className="textGreen">3</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdminDashBoard;
