import docImage from "assets/images/doc.png";
import load from "assets/images/load.gif";
// import _ from "lodash";
// import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { CardBody } from "reactstrap";
import OrderService from "../../../../services/Admin/OrderService";
import { toast } from "react-toastify";
// import FeedBack from "./FeedBack";

const ContentIsIncluded = ({ secondStepData, projectData,callSecondStep, updateProjectData }) => {
  // const [value, setValue] = useState('')
  // const [hasData, setHasData] = useState(false)
  const [secondData, setSecondData] = useState([])
  const [date, setDate] = useState('')

  useEffect(()=>{
    // if(secondStepData?.withContent?.feedback_file_url){
    //   setValue(secondStepData?.withContent?.feedback_file_url)
    //   setHasData(true)
    // } else {
    //   setValue('')
    //   setHasData(false)
    // }
    if (secondStepData && secondStepData.without_content[0]?.reservation_date) {
      let data = secondStepData.without_content[0]?.reservation_date
      setDate(data)
    }
    setSecondData(secondStepData?.without_content)
  },[])

  // const {
  //   handleSubmit,
  //   register
  // } = useForm({});
  // On submit second step feedback url
  const orderService = new OrderService();

  // const sendFeedBackUrl = async (values) => {
  //   const res = await orderService.sendFeedBackUrl(projectData.id, values);
  //   if (res.status === 200) {
  //     toast.success("Your feedback url added successfully");
  //     updateProjectData({ second_step_data_status: 3 });
  //     setHasData(true)
  //   }
  // };

  const sendContentUrl = async (ref_url) => {
    let data ={
      order_id: projectData.id,
      reference_url: ref_url,
      media_id: '',
      links: ''
    }
    const res = await orderService.sendContentUrl(data);
    if (res.status === 200) {
      toast.success("Your Content Url added successfully");
      updateProjectData({ second_step_data_status: 2 });
      callSecondStep()
      orderService.changeStepStatus(projectData.id, {
        "step": "second_step_status", "status": 2
      });
    }
  };

  const  convertTo12HourFormat = (time24) => {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(':');

    // Convert hours to 12-hour format
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    // Return the formatted time
    if(minutes < 10){
      return `${formattedHours}:0${minutes} ${ampm}`;
    }
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  return (<div className="px-lg-4">
    {/* <p className="textGreen">
      <i className="fas fa-info-circle"></i> Content is included in your
      plan, we provide content!
    </p> */}
    <div className="justify-content-between pt-3">
      {/* {_.isEmpty(secondStepData?.withContent) ? (<div className="card boxShadow border-0 w-100 mb-3">

        <CardBody className="px-lg-4 px-0">
          <h6 className="fw-bold pb-2">
            <img src={docImage} className="pe-2" alt=""/>
            Website Content
          </h6>
          {secondStepData?.withContent?.google_url ? (<div className="card px-3 overflow-auto">
            <div className="card-body d-flex justify-content-between">
              <p className="text-muted d-flex align-items-center mb-1">
                <i className="fa fa-google-drive me-2 fa-lg"></i>
                <span>Google Drive Content</span>
              </p>
              <p className="mb-0">
                <a
                  href={secondStepData?.withContent?.google_url}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-eye"></i> View Google Content{" "}
                </a>
              </p>
            </div>
          </div>) : ("")}
          {secondStepData?.withContent?.media?.path ? (<div className="card px-3 overflow-auto mt-2">
            <div className="card-body d-flex justify-content-between">
              <p className="text-muted d-flex align-items-center mb-1">
                <i className="fa fa-file-alt me-2 fa-lg"></i>
                <span>Document</span>
              </p>
              <p className="mb-0">
                <a
                  href={secondStepData?.withContent?.media?.path}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-download pe-2"></i>
                  Download Document Content{" "}
                </a>
              </p>
            </div>
          </div>) : ("")}

          <div>
            <form className="formFeedback">
              <div className="row pt-3">
                <div className="col-md-8 col-lg-9">
                  <input
                    {...register("feedback_file_url", { required: true })}
                    type="text"
                    value={value}
                    onChange={(e)=> setValue(e.target.value)}
                    disabled={hasData}
                    className="form-control height-50"
                    placeholder="please enter preview link"/>
                </div>
                {hasData && <div className="col-md-4 col-lg-3">
                  <button type="button" onClick={()=> setHasData(false)} className="btn btn-primary w-100 height-50">Edit</button>
                </div>}  
                {!hasData &&<div className="col-md-4 col-lg-3">
                  <button type="submit" onClick={handleSubmit(sendFeedBackUrl)} className="btn btn-primary w-100 height-50">Send Preview link</button>
                </div>}
              </div>

            </form>
          </div>
        </CardBody>
        <CardBody className="px-lg-4 px-0">
          <FeedBack
            projectData={projectData}
            secondStepData={secondStepData}
          />
        </CardBody>

      </div>) :  <div className="boxShadow pt-4 px-3 mb-2 pb-3">
          <h6 className="fw-bold pb-2">
            <img src={docImage} className="pe-2" alt=""/>
            Website Content
          </h6>
        <div className="mt-3">
            {secondData?.map((data,index)=>{
              return <div key={data.id} className="d-flex justify-content-between align-items-end mb-2">
                  <input 
                    type='text' 
                    className='form-control px-2 height-50 w-75' 
                    placeholder="Content Link"
                    value={data.reference_url}
                    disabled={projectData?.second_step_data_status === 1}
                    onChange={e=> {
                      let update = secondData?.map((item,ind)=>{
                        if(ind === index){
                          return{
                            ...item,
                            reference_url: e.target.value
                          }
                        } else {
                          return item
                        }
                      })
                      setSecondData(update)
                    }}
                    style={{border: ' 1px solid rgba(0, 0, 0, 0.1)', borderRadius: '5px'}} 
                  />
                  {projectData?.second_step_data_status !== 1 && <button className='btn btn-primary px-4 height-50' onClick={()=> sendContentUrl(data.id, data.reference_url)}>Send</button>}
              </div>
            })}
        </div>
      </div>} */}

      {(projectData?.second_step_data_status === 0 && Number(projectData?.session_reserved) === 0) && <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <img src={load} alt="" width={50} height={40}/>
        <span className="pt-3">
          {projectData.assignee?.role === 'Account Manager' ? 'Waiting for Client to schedule a meeting' : 'Kindly assigne an account manager to this project...'}
        </span>
      </div>}

      {projectData?.second_step_data_status !== 0 && 
      (new Date(secondStepData?.without_content[0]?.reservation_date?.split('T')[0]) <= new Date() ? <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <div>
            {new Date(secondStepData?.without_content[0]?.reservation_date) >= new Date() ? 
            <h6 className="fw-bold mb-0 d-flex align-items-center">
            <img
              src={load}
              alt=""
              width={50}
              height={40}
            />
            <span className="fw-bold ps-3 text-black font-size=14">
              Waiting for the scheduled meeting time at (
                <span className="text-primary"> {convertTo12HourFormat(`${new Date(date).getHours()}:${new Date(date).getMinutes()}`)}
                </span> ) 
                and on ( 
                  <span className="text-primary"> {new Date(date).getFullYear()}-{new Date(date).getMonth()+1}-{new Date(date).getDate()}
                  </span> ) {projectData.assignee?.name ? <>with the account manager: <span className="text-primary">{projectData?.assignee?.name}</span></>: ''}
            </span>
            
            </h6>
             : 
             <div className="mt-3">
              <h6 className="fw-bold pb-2">
                <img src={docImage} className="pe-2" alt=""/>
                Website Content
              </h6>
              {secondData?.map((data,index)=>{
                return <div key={data.id} className="d-flex justify-content-between align-items-end mb-2">
                    <input 
                      type='text' 
                      className='form-control px-2 height-50 w-75' 
                      placeholder="Content Link"
                      value={data.reference_url}
                      disabled={projectData?.second_step_data_status !== 3}
                      onChange={e=> {
                        let update = secondData?.map((item,ind)=>{
                          if(ind === index){
                            return{
                              ...item,
                              reference_url: e.target.value
                            }
                          } else {
                            return item
                          }
                        })
                        setSecondData(update)
                      }}
                      style={{border: ' 1px solid rgba(0, 0, 0, 0.1)', borderRadius: '5px'}} 
                    />
                    {projectData?.second_step_data_status === 3 && <button className='btn btn-primary px-4 height-50' onClick={()=> sendContentUrl(data.reference_url)}>Send</button>}
                    {(projectData?.second_step_data_status === 1 || projectData?.second_step_data_status === 2) && <a href={`${data.reference_url}`} target="_blank" rel="noreferrer" className='btn btn-primary px-4'>Preview</a>}
                </div>
              })}
            </div>
          }
        </div>
      </div> : 
        <div className="boxShadow pt-4 px-3 mb-2 pb-3 d-flex align-items-center">
          <img src={load} alt="" width={30}/>
          <span className="fw-bold ps-3 text-black font-size=14">
              Waiting for the scheduled meeting time at (
                <span className="text-primary"> {convertTo12HourFormat(`${new Date(date).getHours()}:${new Date(date).getMinutes()}`)}
                </span> ) 
                and on ( 
                  <span className="text-primary"> {new Date(date).getFullYear()}-{new Date(date).getMonth()+1}-{new Date(date).getDate()}
                  </span> ) {projectData.assignee?.name ? <>with the account manager: <span className="text-primary">{projectData?.assignee?.name}</span></>: ''}
          </span>
        </div>)}
    </div>
  </div>);
};

ContentIsIncluded.propTypes = {
  projectData: PropTypes.object,
  toggleAccordion: PropTypes.func,
  updateProjectData: PropTypes.func,
  callSecondStep: PropTypes.func,
  secondStepData: PropTypes.any
};
export default ContentIsIncluded;