import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row, CardText, Label } from "reactstrap";
import { adminPrefix } from 'configs/routePrefix';
import { EMAIL_PATTERN_VALIDATOR } from "helpers/validators";
import AuthService from "services/Admin/AuthService";
import imgLogo from "assets/images/logo.svg"
import "./login.scss";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidator } from "FormValidations/Admin/Auth/LoginValidator";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { authenticate } from "store/Auth/actions";

const Login = (props) => {
  // const [rememberMe, setRememberMe] = useState(false)
  const form = useForm(
    { resolver: yupResolver(loginValidator) }
  );
  const dispatch = useDispatch()
  const { register, formState: { errors }, handleSubmit } = form
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleValidSubmit = async (values) => {
    setIsSubmitting(true);
    let requestData = {
      email: values.email,
      password: values.password,
      rememberMe: false
    };
    try {
      const res = await AuthService.login(requestData)
      if (res && res.status) {
        dispatch(authenticate(res))
        localStorage.setItem('adminWebkiks', res.id)
        toast.success("Login Successfully")
        setIsSubmitting(false);
        props.history.push(`${adminPrefix}/dashboard`);
      }
    } catch (error) {
      toast.error(error.response.data.error)
      setIsSubmitting(false);
    }
  };

  // useEffect(() => {
  //   const email = localStorage.getItem("email")
  //   const password = localStorage.getItem("password")

  //   if (rememberMe) {
  //     setValue('email', email)
  //     setValue('password', password)
  //   }
  // }, [])

  return (
    <div>
      <div className="bgGray height100">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" lg="6">
              <Card className="overflow-hidden mt-lg-5 mt-3">
                <CardBody>
                  <div className="pt-1 p-2">
                    <Link to={'/home'}>
                      <img src={imgLogo} className="img-fluid mx-auto d-block" alt="" />
                    </Link>
                    <CardText tag="h5" className="text-primary">Login</CardText>

                    <form onSubmit={handleSubmit(handleValidSubmit)}>
                      <div className="mb-3 pt-3">
                        <Label>Email</Label>
                        <input
                          {...register('email', { required: true, pattern: EMAIL_PATTERN_VALIDATOR })}
                          type='email'
                          className='form-control height-47'
                          placeholder='eg.. ahmedamr@gmail.com'
                        />
                        {errors ?.email && <ErrorMessage message={errors ?.email ?.message} />}
                      </div>
                      <div className="mb-3">
                        <Label>Password</Label>
                        <input
                          {...register('password', { required: true })}
                          type='password'
                          className='form-control height-47'
                          placeholder='************'
                        />
                        {errors ?.password && <ErrorMessage message={errors ?.password ?.message} />}
                      </div>
                      <div className="mb-1 d-flex justify-content-between">
                        {/* <Label className="cursor-pointer">
                          <input className="me-2" type="checkbox" onChange={() => setRememberMe(!rememberMe)} />
                          Remember Me
                          </Label> */}
                        <Link to={"/admin/forget-password"}>Forget password ?</Link>
                      </div>
                      <button
                        className='btn btn-primary w-100 mt-3 font-size-14 height-47'
                        disabled={isSubmitting}
                        type='submit'>
                        Login
                        </button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
