import React from 'react'
import imgFail from "assets/images/fail.png"
import PropTypes from "prop-types"

const PaymentFail = ({history}) => {
  return (
    <div className='websitPages'>
        <div className='container'>
            <div className='row pt-5 justify-content-center'>
                <div className='col-lg-9'>
                    <div className='card cardShadow  text-center py-5'>
                        <img src={imgFail} height="100px" className="d-block mx-auto" />
                        <h4 className='fw-bold pt-4'>
                            Error !  <br/>
                            Failed payments
                        </h4>
                        <p className='text-muted pt-3'>Fill information about your business and goals for this website, this data
                        <br/> can vary and time can be from 30 mins to 4 hours </p>
                        <p>
                            <button 
                            className='btn btn-outline-primary mt-4 px-4 btnHeightLg'
                            onClick={()=>{
                                history.push(`/dashboard`)
                            }}
                            >Go to project page</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

PaymentFail.propTypes = {
    history: PropTypes.func,
  
  };
export default PaymentFail