import docImage from "../../../../assets/images/doc.png";
// import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";
import FeedBack from "./FeedBack";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { appendFilters } from "store/Auth/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { clientPrefix } from "configs/routePrefix";

const ContentIsNotIncluded = ({ callSecondStep, secondStepData, projectData, toggleAccordion, updateProjectData,setCompleted }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation();

  return (<div className="px-lg-4">
    {projectData?.second_step_status === 0 ?
    <div>
      <div className="boxShadow pt-4 px-3 mb-2 pb-3">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <h6 className="fw-bold">
              <img src={docImage} className="pe-2" alt=""/>
              {t("Add your content")}
            </h6>
            <p className="text-muted font-14 mb-2 ps-4">
              {t("Ready to bring your website to life? Begin by organizing your content. If you need some guidance on structuring your material, check out our")} 
              
              <Link target="_blank" to={`/site-map`} className="mx-2">Site Map Guide</Link>
            </p>
          </div>
          <div className="col-lg-6 text-lg-center pt-lg-4 pt-2">
            <Link to={`/client/${projectData.id}/content`}

              className="btn btn-primary font-14 ms-2 pt-2 height-42 mb-2 px-xl-3 px-lg-2"
            >
              {t("Upload content")}
            </Link>
            <Link onClick={()=> {
              dispatch(appendFilters({
                from: 'content',
                stripe_id: projectData?.subscription_plan?.content_price?.filter(res=> res?.currency.toLowerCase() === projectData?.subscription_plan?.currency.toLowerCase())[0]?.stripe_id,
                selectedTemplate: projectData,
                planData: {...projectData?.subscription_plan}
              }));
              history.push("/checkout", {plan:{...projectData.subscription_plan},from: 'content', selectedTemplate: projectData})
            }}
              className="btn btn-outline-primary font-14 ms-2 pt-2 height-42 mb-2 px-xl-3 px-lg-2"
            >
              {t("I don't have content")}
            </Link>
          </div>
        </div>
      </div> 
      <p className='textRed mb-1 font-14'><i className='fa fa-info-circle fa-lg mx-2'></i> 
      Feeling stuck? No worries. Upgrade to our copywriting plan and let us take care of the content creation for you.
      </p>
      <p 
        onClick={()=> {
          dispatch(appendFilters({
            from: 'content',
            stripe_id: projectData?.subscription_plan?.content_price?.filter(res=> res?.currency.toLowerCase() === projectData?.subscription_plan?.currency.toLowerCase())[0]?.stripe_id,
            selectedTemplate: projectData,
            planData: {...projectData?.subscription_plan}
          }));
          history.push("/checkout", {plan:{...projectData.subscription_plan},from: 'content', selectedTemplate: projectData})
        }}
        className="mt-3"
        style={{
          background:'#FFC007',
          borderRadius: '9px',
          cursor:'pointer',
          padding: '7px 14px',
          fontSize:'14px',
          color: '#6E5619',
          width: 'fit-content'
        }}
        >
        Purchase Content for {projectData.subscription_plan.content_price.filter(res=> res.currency === projectData.subscription_plan?.currency)?.length > 0 ? projectData.subscription_plan.content_price.filter(res=> res.currency === projectData.subscription_plan?.currency)[0]?.amount : ''} {projectData.subscription_plan?.currency}
      </p>
    </div>
      : ""}
    <div className="justify-content-between pt-3">
      {secondStepData?.length > 0 && projectData?.second_step_status > 0 ? (<div className="card boxShadow border-0 w-100 mb-3">
        <CardBody className="px-lg-4 px-0">
          <h6 className="fw-bold pb-2">
            <img src={docImage} className="pe-2" alt=""/>
            {t("Website Content")}
          </h6>
          {/* {secondStepData?.withContent?.google_url ? (<div className="card px-3 overflow-auto">
            <div className="card-body d-flex justify-content-between">
              <p className="text-muted d-flex align-items-center mb-1">
                <i className="fa fa-google-drive me-2 fa-lg"></i>
                <span>Google Drive Content</span>
              </p>
              <p className="mb-0">
                <a
                  href={secondStepData?.withContent?.google_url}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-eye"></i> View Google Content{" "}
                </a>
              </p>
            </div>
          </div>) : ("")}
          {secondStepData?.withContent?.media?.path ? (<div className="card px-3 overflow-auto mt-2">
            <div className="card-body d-flex justify-content-between">
              <p className="text-muted d-flex align-items-center mb-1">
                <i className="fa fa-file-alt me-2 fa-lg"></i>
                <span>Document</span>
              </p>
              <p className="mb-0">
                <a
                  href={secondStepData?.withContent?.media?.path}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-download pe-2"></i>
                  {t("Download Document Content")}{" "}
                </a>
              </p>
            </div>
          </div>) : ("")} */}

        </CardBody>
        <CardBody className="px-lg-4 px-0">
          <FeedBack
            projectData={projectData}
            setCompleted={setCompleted}
            secondStepData={secondStepData}
            toggleAccordion={(tab) => toggleAccordion(tab)}
            callSecondStep={callSecondStep}
            updateProjectData={(updateElements) => updateProjectData(updateElements)}
          />
        </CardBody>

      </div>) : null}
    </div>
  </div>);
};

ContentIsNotIncluded.propTypes = {
  projectData: PropTypes.object,
  toggleAccordion: PropTypes.func,
  updateProjectData: PropTypes.func,
  callSecondStep: PropTypes.func,
  setCompleted: PropTypes.any,
  secondStepData: PropTypes.any
};
export default ContentIsNotIncluded;