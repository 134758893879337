import * as yup from "yup";
import {
    TITLE_PATTERN_VALIDATOR,
} from "helpers/validators";
export const AddBlogValidator = yup.object().shape({
    name:  yup.object().shape({
        ar: yup.string()
            .required(),
        en:  yup
        .string()
        .matches(
          TITLE_PATTERN_VALIDATOR.value,
          TITLE_PATTERN_VALIDATOR.errorMessage
        )
        .required(),
    }),
    description:  yup.object().shape({
        ar: yup.object()
            .required(),
        en: yup.object().required()
    }),
    Category:  yup.object().required(),
 

});