import BaseService from "../BaseService";
import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";

const apiEndpoint = API_BASE_URL_ENV() + "/admin/user/team";
const apiToggleStatusEndpoint = API_BASE_URL_ENV() + "/admin/user/toggle-activation/team";

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    toggleStatus(id) {
        return http.post(`${apiToggleStatusEndpoint}/${id}`);
    }
}
