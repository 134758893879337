import React, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, InputGroupText, Label } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Filterswrapper from "components/Common/filterswrapper";
import ModalAdd from "components/Admin/Manager/ModalAdd"
import Paginator from 'components/Common/Paginator/Paginator'
import { Circles } from "react-loader-spinner"
import ManagerService from "services/Admin/ManagerService"
import CardItem from "components/Admin/Manager/CardItem"
import "./style.scss"
import Select from "react-select";

const Managers = () => {
  const [modalAdd, setModalAdd] = useState(false);
  const [teamManager, setTeamManager] = useState([]);
  const [editItem, setEditItem] = useState(null)
  const [getData, setGetData] = useState(false)
  const [rolesOptions, setRolesOptions] = useState([
    { label: "Admin", value: "Admin" },
    { label: "Account Manager", value: "Account Manager" },
    { label: "Developer", value: "Developer" },
    { label: "Copy Writer", value: "Copy Writer" }
  ])
  const managerService = new ManagerService()
  const [filtersParams, setFilterParams] = useState(null)
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
  })
  const [keyword, setKeyword] = useState("");
  const [formState, setFormState] = useState({
    keyword: "",
    role: ''
  })

  const toggleAdd = () => {
    setEditItem(null)
    setModalAdd(modalAdd => !modalAdd);
  };

  const toggleEdit = (item) => {
    setEditItem(item)
    setModalAdd(!modalAdd);

  }

  useEffect(() => {
    let params = {}
    // const debounceHandler = setTimeout(() => {
      if (formState.keyword.length) {
        params["keyword"] = formState.keyword
      }
      if (formState.role?.label) {
        params["role"] = formState.role?.label
      }
      setFilterParams({ ...params })
    // }, 1500);

    // return () => clearTimeout(debounceHandler)
    
  }, [formState])

  return (
    <>
      <div className="admin-container pt-2">
        <MetaTags>
          <title> Team Members</title>
        </MetaTags>
        <Container fluid className="bgGray pb-5 pt-3 px-lg-5">
          <Filterswrapper
            AddHandler={() => toggleAdd()}
            title={`Team Members`}
            subTitle={
              "List of Team Members on webkicks"
            }
            addbtnName={"Add a Team Member"}
          ></Filterswrapper>
          <Row>
            <Col lg={10} md={9} sm={8} xs={12}>
              <InputGroup className="inputGroup mb-2 px-2 px-md-0">
                <InputGroupText
                  addonType="prepend"
                  className="bg-white iconSearch"
                >
                  <i className="fa fa-search fa-lg"></i>
                </InputGroupText>
                <input
                  className={`form-control border-start-0 btnHeightLg mb-2`}
                  placeholder="Search by name of team member"
                  type="text"
                  // value={formState.keyword}
                  // onChange={(e) => setFormState({ keyword: e.target.value })}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col lg={2} md={3} sm={4} xs={12}>
              <div className="px-2 px-md-0">
                <button
                  type="button"
                  className="btn btn-outline-primary btnHeightLg px-3 w-100"
                  onClick={() => {
                    setFormState((state) => ({ ...state, keyword }));
                    }}
                >
                  <i className="fa fa-search me-2"></i>
                  Search
                </button>
              </div>
            </Col>
            <Col md={4} className='d-flex align-items-center'>
              <Label className="pe-2 mb-0 py-2 text-muted" style={{ width: "100px" }}>
                Filter by
              </Label>
              <Select
                value={formState.role}
                options={rolesOptions}
                name='role'
                className={`w-100`}
                placeholder='Select Role...'
                onChange={e=> setFormState({...formState, role: e})}
                setOptions={(options) => setRolesOptions(options)}
              />
            </Col>
            <Col md={2}>
                <button 
                  onClick={()=> {
                    setFormState({ ...formState, role: ''})
                  }}
                  className="btn border-0 fw-bold btn-outline-secondary height-42">
                        Reset
                </button>
              </Col>
          </Row>
          <div className="table-responsive mt-3">
            <table className="table table-borderless bg-white">
              <thead className="border-0 rounded-3" style={{ background: "#edf0f3", height: "62px", verticalAlign: "middle" }}>
                <tr>
                  <th className="opacity-75 rounded-start ps-4">Name</th>
                  <th className="opacity-75">Email</th>
                  <th className="opacity-75">Role</th>
                  <th className="opacity-75">Live Projects</th>
                  <th className="opacity-75">Status</th>
                  <th className="rounded-end opacity-75"></th>
                </tr>
              </thead>
              <tbody>
                
                {teamManager.length ? 
                <>
                  {!getData && teamManager.map((manager, index) =>
                    <CardItem
                      key={index}
                      index={index}
                      manager={manager}
                      setEditItem={toggleEdit}
                      setModalAdd={setModalAdd}
                      setGetData={setGetData}
                    />
                  )}
                </> :  
                <>
                  {getData &&
                    <div className='loaderStyle'>
                      <Circles color='#00BFFF' />
                    </div>
                  } 
                </>
                }
              </tbody>
            </table>
          </div>

          {modalAdd && (
            <ModalAdd
              editItem={editItem}
              modalAdd={modalAdd}
              toggleAdd={toggleAdd}
              setGetData={setGetData}
            />
          )}
          <div className="bg-white mt-3">
            <div className="d-flex justify-content-end" md={8} sm={12}>
              <Paginator
                service={managerService}
                pageOptions={pageOptions}
                setData={(data) => {
                  setTeamManager([...data])
                  setGetData(false)
                }}
                setPageOptions={setPageOptions}
                filtersParams={filtersParams}
                shouldGetData={getData}
                setShouldGetData={() => { }}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Managers;
