import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import metra from "../../../../assets/images/metra.png"
import logic from "../../../../assets/images/logic.png"
import preproduction from "../../../../assets/images/preproduction1.png"
import onehealth from "../../../../assets/images/onehealth.png"
import mirqah from "../../../../assets/images/mirqah.png"
import engexporteirctory from "../../../../assets/images/engexporteirctory.png"
import { 
	CarouselControl, 
	Carousel, 
	CarouselItem, 
	CarouselIndicators, 
} from 'reactstrap'; 

function Slider() { 

	// State for Active index 
	const [activeIndex, setActiveIndex] = React.useState(0); 

	// State for Animation 
	const [animating, setAnimating] = React.useState(false); 

	// Sample items for Carousel 
	const items = [
        {
          src: metra,
          altText: 'Slide 1',
          caption: 'Client Name Here',
          url:"https://metragroup.com/"
        },
        {
          src: logic,
          altText: 'Slide 2',
          caption: 'Slide 2',
          url:"https://logic-consulting.com/"
        },
        {
          src: preproduction,
          altText: 'Slide 3',
          caption: 'Client Name Here',
          url:"https://preproductionperfumery.com/"
        },
        {
          src: onehealth,
          altText: 'Slide 4',
          caption: 'Client Name Here',
          url:"https://one-health.com/en/"
        },
        {
          src: mirqah,
          altText: 'Slide 4',
          caption: 'Client Name Here',
          url:"https://mirqah.com/"
        },
        {
          src: engexporteirctory,
          altText: 'Slide 5',
          caption: 'Client Name Here',
          url:"https://engexportdirectory.org/"
        },
      ];

	// Items array length 
	const itemLength = items.length - 1 

	// Previous button for Carousel 
	const previousButton = () => { 
		if (animating) return; 
		const nextIndex = activeIndex === 0 ? 
			itemLength : activeIndex - 1; 
		setActiveIndex(nextIndex); 
	} 

	// Next button for Carousel 
	const nextButton = () => { 
		if (animating) return; 
		const nextIndex = activeIndex === itemLength ? 
			0 : activeIndex + 1; 
		setActiveIndex(nextIndex); 
	} 

	// Carousel Item Data 
	const carouselItemData = items.map((item) => { 
		return ( 
			<CarouselItem 
				key={item.src} 
				onExited={() => setAnimating(false)} 
				onExiting={() => setAnimating(true)} 
                className='itemSlider'
			> 
            <a target='blank' href={item.url}>
				<img className='imgSlider' src={item.src} alt={item.altText} /> 
            </a>
            {/* <div className='carousel-caption bg-white'>
                <h1 className='text-dark'>{item.caption}</h1>
            </div> */}
			</CarouselItem> 
		); 
	}); 

	return ( 
		<div style={{ 
			display: 'block', width:"100%" ,padding: 30 
		}}> 
			<Carousel previous={previousButton} next={nextButton} 
				activeIndex={activeIndex}> 
				<CarouselIndicators items={items} 
					activeIndex={activeIndex} 
					onClickHandler={(newIndex) => { 
						if (animating) return; 
						setActiveIndex(newIndex); 
					}} /> 
				{carouselItemData} 
                 <div className='d-flex position-relative flex-row-reverse justify-content-end indecators'>
				<CarouselControl directionText="Prev"
					direction="prev" onClickHandler={previousButton} /> 
				<CarouselControl directionText="Next"
					direction="next" onClickHandler={nextButton} /> 
            </div>
			</Carousel> 
		</div > 
	); 
} 

export default Slider;
