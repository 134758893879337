import React, { useEffect, useState } from "react";
import docImage from "assets/images/load.gif";
import avatarImage from "assets/images/avatar.png";
import PropTypes from "prop-types";
import { SuccessOk } from "../../../../configs/statusCode";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import OrderServices from "../../../../services/Client/OrderServices";
import { useTranslation } from 'react-i18next';
import { PopupButton } from "react-calendly";
// import { API_CALENDLY_URL_ENV } from "helpers/common";

const ThirdStepData = ({ projectData, updateProjectData, toggleAccordion,setCompleted }) => {
  const { id } = useParams();
  const orderService = new OrderServices();
  const [url, setUrl] = useState('');

  const confirmThirdStep = async () => {
    const res = await orderService.updateOrder(id, { step_confirmed: "third_step_status" });
    if (res.status === SuccessOk) {
      toggleAccordion("4");
      updateProjectData({ third_step_status: 1 });
      toast.success("Project Feedback and Design Confirmed successfully");
      setCompleted(prev => prev+25);
    } else {
      toast.error("Whoops something went wrong");
    }
  };
  const { t } = useTranslation();
  // const wantToEditThirdStep = async () => {
  //   const res = await orderService.updateOrder(id, { step_confirmed: "third_step_status", status:4 });
  //   if (res.status === SuccessOk) {
  //     updateProjectData({ third_step_status: 4 });
  //     toast.success("Your request sent Successfully to Account Manager");
  //   } else {
  //     toast.error("Whoops something went wrong");
  //   }
  // };

  useEffect(()=>{
    // let obj = API_CALENDLY_URL_ENV?.filter(res=> res.assignee?.id === projectData.assignee?.id)
    // if(obj?.length > 0) {
    //   setUrl(obj[0].url)
    // }
    setUrl(projectData.assignee?.calendly_data?.calendlyAcc)
  },[])

  return (<div>
    {projectData.third_step_status === 0 ? <div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row justify-content-between">
        <div className="col-lg-12 ">
          <h6 className="fw-bold mb-0 d-flex">
            <span>
              <img src={docImage} alt="" width={50} height={40}/>
            </span>
            <span className="fw-bold ps-1 text-primary font-size=14">
              {!!projectData?.third_step_data?.reservation_date && new Date(projectData?.third_step_data?.reservation_date?.split('T')[0]) >= new Date() ? `${t("the delivery date will be entered after meeting")}` : `${t("Hold on as your account manager estimates the number of working days required to complete your website's development.")}`}
            </span>
          </h6>
          {/* <p className="text-muted font-14 mb-2 ps-5 ">
            {t("You’ll receive an email once your design is ready.")}
          </p> */}
          <p className="fw-bold font-size-14 ps-5 ms-2 pt-2">{t("Check back soon")}</p>
        </div>
      </div>
    </div> : ""}
    {projectData.third_step_status === 2 ? <div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row justify-content-between">
        <div className="col-lg-12 ">
          <h6 className="fw-bold mb-0">
            <img src={docImage} alt="" width={50} height={40}/>
            {new Date(projectData.third_step_data?.start_date) > new Date() ? 
            <span className="fw-bold ps-2 text-primary font-size=14">
              {t("Your website's development will be complete within ")} 
              <span className="text-success">{projectData.third_step_data?.number_of_days}</span> 
              {t(" days starting ")}
              <span className="text-success">{projectData.third_step_data?.start_date}</span>
            </span> : <span className="fw-bold ps-1 text-primary font-size=14">
              {t("Hold on as we put your website together...")}  <br/>
              <span className="ps-5 ms-1">{t("You’ll receive an email once your website is ready. Expected delivery within")}</span>
              
              <span className="me-1">{projectData.third_step_data?.number_of_days}</span>
              {t("Days")}
            </span>}
          </h6>
          <span className="text-primary ps-5 ms-2">We will send you an email notification once it is finished.</span>
          <p className="fw-bold font-size-14 ps-5 ms-2 pt-2">{t("Check back soon")}</p>
        </div>
      </div>
    </div> : ""}
    {projectData.third_step_status === 3 ? 
      <div className="boxShadow pt-4 px-3 mb-2 pb-3">
      <div className="row">
        <div className="col-md-4 col-lg-3">
          <a>
            <img src={avatarImage} className="imageDesign mb-2 rounded-3"/>
          </a>
        </div>
        <div className="col-md-8 col-lg-9">
          {/* <p className="fw-bold text-success"> {projectData?.third_step_data?.number_of_days} Days to finish the Design</p> */}
          <div className="d-md-block d-flex">
            <a href={projectData.design_feedback_url}
               target="_blank"
               rel="noreferrer"
               className="btn btn-primary height-45 mb-2 me-3 pt-2"><i className="fa fa-eye"></i> {t("View Design")}</a>
          </div>
          <h6 className="pt-1">{t("Your website is ready for preview, check it out now.")}</h6>
          
          <div className="d-md-flex d-block gap-2 pt-2">
            <button className="btn btn-primary height-45 mb-2 w-100" onClick={() => confirmThirdStep()}>
               {t("I am Happy with my website")}
            </button>
            {(!!url && (parseInt(projectData?.session_reserved) === 0)) && <div className="session2 w-100">
                <PopupButton url={url}
                  rootElement={document.getElementById("root")}
                  text="I want to edit something"
                />
              </div>}
            </div>
        </div>
      </div>
    </div>: ""
    }
    {projectData.third_step_status === 1 ?
    <div className="boxShadow pt-4 px-3 mb-2 pb-3">
    <div className="row">
      <div className="col-md-4">
        <a>
          <img src={avatarImage} className="imageDesign mb-2 rounded-3"/>
        </a>
      </div>
      <div className="col-md-8">
        <div className="d-md-block d-flex pt-3">
          <a href={projectData.design_feedback_url}
             target="_blank"
             rel="noreferrer"
             className="btn btn-primary height-45 me-3"><i className="fa fa-eye"></i> {t("View Design")}</a>
          {/* <button className="btn btn-success height-45 mb-2" onClick={() => confirmThirdStep()}><i
            className="fa fa-smile"></i> I am happy with my
            design
          </button> */}
        </div>
        <h6 className="pt-4">{t("Your website's development has been successfully completed")}</h6>
      </div>
    </div>
  </div> : ""}
  </div>);
};
ThirdStepData.propTypes = {
  projectData: PropTypes.object,
  updateProjectData: PropTypes.func,
  toggleAccordion: PropTypes.func,
  setCompleted: PropTypes.func
};
export default ThirdStepData;