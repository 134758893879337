import React from "react";
import businessImage from "assets/images/card.png";
import websiteImage from "assets/images/store.png";
import fileImage from "assets/images/documents.png";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
// import OrderServices from "services/Client/OrderServices";
// import { SuccessOk } from "configs/statusCode";
// import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const FirstStepData = ({projectData, data, edit}) => { //projectData, edit
  // const orderServices = new OrderServices()
  // const [status, setStatus] = useState(null)
  const auth = useSelector(state => state.Auth)

  // useEffect(()=>{
  //   setStatus(projectData.request_edit)
  // },[])

  // const requestEdit = () =>{
  //   let data = {
  //     order_id: projectData?.id
  //   }
  //   orderServices.firstStepEdit(data)?.then(res=>{
  //     if(res?.status === SuccessOk){
  //       toast.success(res?.data[0])
  //       setStatus(1)
  //     }
  //   })
  // }

  const { t } = useTranslation();
  return (<div className="pt-1">
    <div className="row">
      <div className="col-md-12">
        <div className="card boxShadow">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-md-8">
                <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <img src={businessImage} alt=""/>
                    <span className="text-muted pe-4 font-14"> {t("Business Name")} </span>
                  </div>
                  <div className="pb-2">
                    <span className="fw-bold font-14">{data.business_name}</span>
                  </div>
                </div>
                <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <img src={websiteImage} alt=""/>
                    <span className="text-muted pe-4 font-14"> {t("Website Type")} </span>
                  </div>
                  <div className="pb-2">
                    {/* <span className="fw-bold font-14">{data.website_type}</span> */}
                    <span className="fw-bold font-14">{data?.type}</span>
                  </div>
                </div>
                {/* <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <img src={fileImage} alt=""/>
                    <span className="text-muted pe-4 font-14"> {t("File(s)")} </span>
                  </div>
                  <div className="pb-2 overflow-auto">
                    {data.documents.length ? data.documents.map(document => (
                      <span className="fw-bold font-14" key={document.id}>
                        <a href={document.path} target="_blank" rel="noreferrer">File Content </a>
                      </span>)) : null}
                  </div>
                </div> */}
                <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <i className="fa-solid fa-envelope text-muted font-20" style={{opacity: '.75'}}></i>
                    <span className="text-muted pe-4 font-14"> {t("Business Email")} </span>
                  </div>
                  <div className="pb-2">
                    <span className="fw-bold font-14">{data?.contact_email}</span>
                  </div>
                </div>
                <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <i className="fa-regular fa-envelope text-muted font-20" style={{opacity: '.75'}}></i>
                    <span className="text-muted pe-4 font-14"> {t("Email")} </span>
                  </div>
                  <div className="pb-2">
                    <span className="fw-bold font-14">{auth?.email}</span>
                  </div>
                </div>
                <div className="pb-2 d-sm-flex">
                  <div className="pb-2 minWidthCol">
                    <img src={fileImage} alt=""/>
                    <span className="text-muted pe-4 font-14"> {t("Number of Pages")} </span>
                  </div>
                  <div className="pb-2">
                    <span className="fw-bold font-14">{data?.pages_num}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                {/* {status === 0 ?
                  <button onClick={() => { requestEdit()}} className="btn btn-primary height-42 w-100 mb-2">Request Edit</button> : ""} */}
                {/* {(status === 1 && projectData.first_step_status !== 3) && <span className="text-muted pe-4 font-16"> {t("Wait Admin Response")} </span>} */}
                {projectData.first_step_status === 0  ?
                  <button onClick={() => { edit()}} className="btn btn-primary height-42 w-100 mb-2">Edit</button> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
};
FirstStepData.propTypes = {
  data: PropTypes.object,
  projectData: PropTypes.object,
  edit: PropTypes.func
};

export default FirstStepData;