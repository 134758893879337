import { toast } from "react-toastify";
import CategoryService from "services/Admin/CategoryService";
import { SuccessOk, SuccessCreated } from "configs/statusCode";
import _ from "lodash";
import SuccessModal from "components/Common/SuccessModal";
import React, { useState, useEffect } from "react";
import { Row, Col, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import FileUpload from "components/Common/file-upload/file-upload.component";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "components/Common/ErrorMessage/ErrorMessage";
import { AddCategoryValidator } from "FormValidations/Admin/Category/Add";
import CategoryTypeEnum from "Enums/CategoryTypeEnum";
import ErrorMessageList from "components/Common/ErrorMessage/ErrorMessageList";
import { Circles } from "react-loader-spinner"

const ModalAdd = ({ modalAdd, toggleAdd, setGetData, editItem }) => {
  const [photoModal, setPhotoModal] = useState(false);
  const [imageIndex, setImageIndex] = useState("");
  const [serverErrors, setServerErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, control, handleSubmit, reset, formState: { errors }, watch, getValues } = useForm({
    resolver: yupResolver(AddCategoryValidator),
    defaultValues: {
      subCategory: [
        {
          name: "",
          media_id: "",
          media_id_path: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "subCategory",
    control,
  });

  const toggleSuccess = () => {
    setModalSuccess(!modalSuccess);
    setGetData(true);
  };

  const validData = (arr) => {
    let result = true;
    arr.forEach((category) => {
      if (category.media_id_path ?.length === 0) {
        result = false;
      }
    });
    return result;
  };

  const onSubmit = async (formData) => {
    const arrayData = formData.subCategory;
    if (!validData(arrayData)) {
      toast.error("insert All images");
      return;
    } else {
      setIsSubmitting(true);
      let data = {
        name: formData.display_name,
        type: formData.type,
        values: formData.subCategory.map((category) => ({
          id: !editItem ? null : category.id ? category.id : null,
          media_id: category.media_id,
          name: category.name,
        })),
      };
      try {
        if (editItem) {
          const userData = await categoryService.update(editItem.id, data);
          if (userData.status == SuccessOk) {
            toggleAdd();
            setIsSubmitting(false);
            setGetData(true);
            toast.success("Category Updated Successfully");
          }
        } else {
          const userData = await categoryService.create(data);
          if (userData.status == SuccessCreated) {
            setModalSuccess(true);
          }
        }
      } catch (err) {
        setIsSubmitting(false);
        setServerErrors(err.response.data);
      }
    }
  };

  const updatedAttachment = (attachments, type) => {
    const mediaObj = {
      [`${type}`]: attachments[0] ?.id,
      [`${type}_path`]: attachments[0] ?.path,
    };
    const valuesArr = getValues().subCategory;
    const updatedArray = valuesArr.map((item, index) => {
      if (index === imageIndex) {
        return {
          ...item,
          ...mediaObj,
        };
      } else {
        return item;
      }
    });
    reset({
      ...getValues(),
      subCategory: updatedArray,
    });
  };

  const deleteAttachment = () => {
    reset({ ...getValues(), media: null });
  };
  const categoryService = new CategoryService();

  const getEditItem = async () => {
    setIsLoading(true)
    const res = await categoryService.find(editItem.id);
    if (res.status === SuccessOk) {
      setIsLoading(false)
      reset({
        display_name: res.data.name,
        type: res.data.type,
        subCategory: res.data.values.map((item) => ({
          id: item.id,
          name: item.name,
          media_id: item.media.id,
          media_id_path: item.media.path,
        })),
      });
    } else {
      setIsLoading(false)
    }
  };
  useEffect(() => {
    if (editItem) {
      getEditItem();
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={modalAdd}
        className="modal-dialog-scrollable"
        toggle={() => toggleAdd()}
      >
        <ModalHeader className="modalHead" toggle={toggleAdd}>
          {editItem ? "Update Category" : "Create New Category"}
        </ModalHeader>
       
          <ModalBody className="border-0">
          {isLoading ?
          <div className="d-flex justify-content-center my-4">
            <Circles color='#00BFFF' />
          </div>
          : <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                {!_.isEmpty(serverErrors) && (
                  <ErrorMessageList error={serverErrors} />
                )}
              </Row>
              <Row>
                <Col md={6} className="pb-2">
                  <label className="pb-2">Category Type</label>
                  <select
                    className={`form-select ${
                      errors ?.type ? "border-danger" : ""
                  }`}
                    {...register("type")}
                  >
                    <option value={""}>Select</option>
                    {CategoryTypeEnum.ALL.map((type) => {
                      return (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      );
                    })}
                  </select>
                  {errors ?.type && (
                    <ErrorMessage message={errors ?.type ?.message} />
                  )}
                </Col>
                <Col md={6}>
                  <div className="pb-2">
                    <Label>Display Name</Label>
                    <input
                      {...register("display_name")}
                      placeholder="Type Name"
                      className={`form-control ${
                        errors ?.display_name && "border-danger"
                    }`}
                      type="text"
                    />
                    {errors ?.display_name && (
                      <ErrorMessage message={errors ?.display_name ?.message} />
                    )}
                  </div>
                </Col>
                {watch("type") ?.length
                  ? fields.map((item, i) => (
                    <div key={i} className="list-group list-group-flush">
                      <div className="list-group-item bgGray pb-3">
                        <Row>
                          <Col md={2} xs={4}>
                            <div className="mt-0 d-block">
                              <Label className="mt-0 d-block">Photo</Label>
                              {item ?.media_id_path ? (
                                <img
                                  onClick={() => {
                                    setPhotoModal(true);
                                  }}
                                  style={{
                                    height: "40px",
                                    width: "60px",
                                    objectFit: "cover",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                  }}
                                  src={item ?.media_id_path}
                                />
                              ) : (
                                  <button
                                    type="button"
                                    className="py-0  btn-upload  font-size-18 font-weight-bold"
                                    style={{
                                      height: "40px",
                                      width: "60px",
                                      background: "none",
                                      border: "1px #cadff7 dashed",
                                      borderRadius: "8px",
                                    }}
                                    onClick={() => {
                                      setImageIndex(i);
                                      setPhotoModal(true);
                                    }}
                                  >
                                    +
                                </button>
                                )}
                            </div>
                          </Col>
                          <Col md={4} xs={6} className="form-group col-6">
                            <label className="pb-2">Name (en)</label>
                            <input
                              placeholder={"Name"}
                              name={`subCategory[${i}].name.en`}
                              {...register(`subCategory.${i}.name.en`)}
                              type="text"
                              className={`form-control ${
                                errors.subCategory ?.[i] ?.name.en
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.subCategory ?.[i] ?.name.en ?.message && (
                              <ErrorMessage
                                message={errors.subCategory ?.[i] ?.name ?.en ?.message}
                              />
                            )}
                          </Col>
                          <Col md={4} xs={6} className="form-group col-6">
                            <label className="pb-2">Name (ar)</label>
                            <input
                              placeholder={"Name"}
                              name={`subCategory[${i}].name.ar`}
                              {...register(`subCategory.${i}.name.ar`)}
                              type="text"
                              className={`form-control ${
                                errors.subCategory ?.[i] ?.name.ar
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.subCategory ?.[i] ?.name ?.ar ?.message && (
                              <ErrorMessage
                                message={errors.subCategory ?.[i] ?.name ?.ar ?.message}
                              />
                            )}
                          </Col>
                          <Col md={2} xs={2} className="pt-4 pt-lg-4">
                            <Label></Label>
                            {i !== 0 ? (
                              <button
                                onClick={() => remove(i)}
                                className="bg-danger btn px-2 mt-2"
                                type="button"
                              >
                                <i className="fa fa-trash-o text-white"></i>
                              </button>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))
                  : null}
                <div>
                  {watch("type") ?.length ? (
                    <Col md={12}>
                      <button
                        type="button"
                        onClick={() => {
                          append({
                            media_id: "",
                            media_id_path: "",
                            name: "",
                          });
                        }}
                        className="btn btn-outline-primary w-100 fw-bold my-3"
                      >
                        + Add Sub Categories
                    </button>
                    </Col>
                  ) : null}
                  <div className="modal-footer pb-0 text-end mt-3 px-0">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={toggleAdd}
                    >
                      Cancel
                  </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => {
                        console.log(errors)
                      }}
                    >
                      {" "}
                      {editItem ? "Update Category" : "Add Category"}
                    </button>
                  </div>
                </div>
                <SuccessModal
                  modalSuccess={modalSuccess}
                  entiityName="Category"
                  toggleSuccess={() => {
                    toggleSuccess(), toggleAdd();
                  }}
                />
                {photoModal && (
                  <Modal
                    isOpen={photoModal}
                    toggle={() => setPhotoModal((c) => !c)}
                  >
                    <ModalHeader
                      className="modalHead"
                      toggle={() => setPhotoModal((c) => !c)}
                    >
                      SubCategory Image
                  </ModalHeader>
                    <ModalBody className="border-0 py-0">
                      <div className="sectionUpload">
                        <FileUpload
                          accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                          label="photo"
                          attachmentType="media_id"
                          attachmentData={[
                            {
                              id: getValues().subCategory[`${imageIndex}`]
                                .media_id,
                              path: getValues().subCategory[`${imageIndex}`]
                                .media_id_path,
                            },
                          ]}
                          updatedAttachment={updatedAttachment}
                          deleteAttachment={deleteAttachment}
                          setUploadState={() => { }}
                        />
                      </div>
                      <div className="modal-footer pb-0 text-end mt-3">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setPhotoModal((c) => !c)}
                        >
                          Cancel
                      </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => setPhotoModal((c) => !c)}
                        // disabled={uploadState ? true : isSubmitting ? true : false}
                        >
                          {" "}
                          Close
                      </button>
                      </div>
                    </ModalBody>
                  </Modal>
                )}
              </Row>
            </form>}
          </ModalBody>
      </Modal>
    </>
  );
};

ModalAdd.propTypes = {
  modalAdd: PropTypes.bool,
  toggleAdd: PropTypes.func,
  setGetData: PropTypes.func,
  editItem: PropTypes.object,
};

export default ModalAdd;
