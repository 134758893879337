import React, { useState, useEffect } from 'react'
import {
    Modal,
    ModalHeader, ModalBody, Label, Row, Col,
} from "reactstrap"
import PropTypes from 'prop-types';
import arrowImg from "assets/images/arrow.png"
import SuccessModal from "components/Common/SuccessModal";
import { AddClientValidator } from 'FormValidations/Admin/Client/Add';
import { useForm } from "react-hook-form";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-toastify";
import { SuccessCreated, SuccessOk } from "configs/statusCode";
import ClientService from "services/Admin/ClientService"


const ModalAdd = ({ modalAdd, toggleAdd, setGetData, editItem }) => {
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur' ,
        resolver: yupResolver(AddClientValidator)
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(true);
    const [modalSuccess, setModalSuccess] = useState(false);
    const toggleSuccess = () => {
        setModalSuccess(!modalSuccess);
        setGetData(true);
    };

    useEffect(() => {
        if (editItem) {
            new ClientService().find(editItem.id).then(res => {
                setValue("name", res.data.name)
                setValue("email", res.data.email)
                setStatus(res.data.status)

            })
        }
    }, [])


    const handleValidSubmit = async (values) => {
        setIsSubmitting(true);
        let requestData = {
            ...values,
            status
        };

        try {
            if (editItem) {
                const userData = await new ClientService().update(editItem.id, requestData);
                if (userData.status == SuccessOk) {
                    toggleAdd();
                    setIsSubmitting(false);
                    setGetData(true);
                    toast.success("User Updated Successfully");
                }
            } else {
                const userData = await new ClientService().create(requestData);
                if (userData.status == SuccessCreated) {
                    setModalSuccess(true);
                }
            }

        } catch (err) {
            setIsSubmitting(false);
        }
        // toggleAdd();
    };

    return (
        <Modal isOpen={modalAdd} className="modal-lg container" toggle={() => toggleAdd()}>
            <ModalHeader className='modalHead me-2' toggle={() => toggleAdd()}>
                <div>
                    <h6 className='fw-bold'>{editItem ? "Edit a User" : "Add a User"}</h6>
                    <p className='font-14 text-muted mb-0'>Fill information about your business and goals for this website</p>
                </div>

            </ModalHeader>
            <ModalBody className='border-0 py-4'>
                <form className="g-3 needs-validation" onSubmit={handleSubmit(handleValidSubmit)}>
                    <Row>
                        <Col lg={6}>
                            <div className='pb-2'>
                                <Label>Client Name</Label>
                                <input
                                    {...register("name")}
                                    placeholder="Name"
                                    className={`form-control height-47 ${errors ?.name && "border-danger" }`}
                                    type="text"
                                />
                            </div>
                            {errors ?.name && <ErrorMessage message={errors ?.name ?.message} />}

                        </Col>
                        <Col lg={6}>
                            <div className='pb-2'>
                                <Label>Email address</Label>
                                <input
                                    {...register("email")}
                                    className={`form-control height-47 ${errors ?.email && "border-danger" }`}
                                    type="email"
                                    disabled={editItem}
                                    placeholder="Email Address"
                                />
                            </div>
                            {errors ?.email && <ErrorMessage message={errors ?.email ?.message} />}

                        </Col>
                        <Col
                            lg={6}
                            className="my-2 justify-content-between align-items-center"
                        >
                            <label className="form-check-label d-inline">
                                Status
                            </label>
                            <div className="form-check form-switch py-2">
                                <input
                                    className={`form-check-input`}
                                    type="checkbox"
                                    id="status"
                                    checked={status}
                                    onChange={() => setStatus(c => !c)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className='pb-0 mt-3 text-end'>
                        <div className='row w-100'>
                            <div className='col-md-12'>
                                <button
                                    className='btn btn-outline-primary height-42 me-3 px-3'
                                    onClick={() => toggleAdd()}
                                    type="submit"
                                >
                                    Cancel
                                 </button>
                                <button
                                    className="btn btn-primary px-3 ms-auto text-start height-42"
                                    type="submit"
                                    disabled={isSubmitting ? true : false}
                                >
                                    {editItem ? "Update" : "Invite"}
                                    <img className='float-end pt-2 ps-5' src={arrowImg} alt="" />
                                </button>
                            </div>
                        </div>
                        {/* <button type='button' className='btn btn-light' onClick={toggleAdd}>Cancel</button> */}
                    </div>
                    <SuccessModal
                        modalSuccess={modalSuccess}
                        entiityName="User"
                        toggleSuccess={() => {
                            toggleSuccess(), toggleAdd();
                        }}
                    />
                </form>
            </ModalBody>
        </Modal>
    );
}
ModalAdd.propTypes = {
    modalAdd: PropTypes.bool,
    toggleAdd: PropTypes.func,
    setGetData: PropTypes.func,
    editItem: PropTypes.object,
};
export default ModalAdd;
