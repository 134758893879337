import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Progress } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { appendFilters } from "store/Auth/actions";
import copyrightImage from "assets/images/copyright.png";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CopyRight = ({ setStep, next, back }) => {
  const [completed, setCompleted] = useState(60);
  const [itemSelected, setItemSelected] = useState(false);
  const { t, i18n } = useTranslation();
  const data = useSelector(state => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setItemSelected(data.has_content);
    setInterval(() => setCompleted(70), 1000);
  }, []);

  const onRadioBtnClick = (itemSelected) => {
    setItemSelected(itemSelected);
  };

  const handleSubmit = () => {
    if (itemSelected !== false) {
      dispatch(appendFilters({ has_content: itemSelected }));
      setStep(next);
    } else {
      toast.error("Answer the question");
    }
  };

  return (
    <div className="card mb-4 mt-3">
      <div className="card-header border-0 p-0">
        <Progress value={completed}/>
      </div>
      <div className="card-body text-center pt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={copyrightImage} className="img-fluid" alt="domain"/>
            <h3 className="pb-2 pt-3 fw-bold">
              {t("Do you have your own copywriter? or do you want us to")} <br/>
              {t("provide website content for you?")}
            </h3>
            <p className="text-muted pb-3">
              {t("Fill information about your business and goals for this website, this data")} <br/>
              {t("can vary and time can be from 30 mins to 4 hours")}
            </p>
            <form>
              <ButtonGroup className="d-grid copyRight">
                <Button
                  color="outline-secondary text-start ps-5"
                  className="mb-3"
                  onClick={() => onRadioBtnClick(1)}
                  active={itemSelected === 1}
                >
                  <span>{t("Yes, I want you to provide content (Included)")}</span>
                </Button>
                <Button
                  color="outline-secondary text-start ps-5"
                  onClick={() => onRadioBtnClick(0)}
                  active={itemSelected === 0}
                >
                  <span>{t("No, I will write my own content (Not Included)")}</span>
                </Button>
              </ButtonGroup>
              <div className="d-flex justify-content-center mt-4 gap-3">
                <button
                  className="btn btnHeightLg px-3 py-2"
                  onClick={() => setStep(back)}
                  type={"button"}
                >
                   {t("Back")} {i18n.language !== "ar" ? <i className="fa fa-sign-out-alt px-2 rotateDegree float-start pt-1 pe-3"></i>
                    : <i className="fa fa-sign-out-alt px-2 float-end pt-1 ps-3"></i>}
                </button>
                <button
                  className="btn btn-primary btnHeightLg px-3 py-2"
                  onClick={handleSubmit}
                  type="button"
                >
                  {t("Continue")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right px-2 float-end pt-1 ps-5"></i>
                    : <i className="fa fa-long-arrow-left px-2 float-start pt-1 pe-5"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
CopyRight.propTypes = {
  setStep: PropTypes.func,
  next: PropTypes.string, 
  back: PropTypes.string

};

export default CopyRight;
