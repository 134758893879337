import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, InputGroup, InputGroupText } from "reactstrap";
import Select from "react-select";
import CategoryService from "services/Admin/CategoryService";
import { SuccessOk } from "configs/statusCode";

const types = ["design", "type", "industry", "size"];

const Filters = ({ formState, setFormState }) => {
  // const [designOptions, setDesignOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [industryOptions, seIndustryOptions] = useState([]);
  // const [sizeOptions, setSizeOptions] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    types.forEach((type) => {
      new CategoryService()
        .getDropDown("categoryValues", { type })
        .then((res) => {
          if (res.status === SuccessOk) {
            const options = res.data.data.map((item) => ({
              label: item.name.en,
              value: item.id,
            }));
            if (type === "type") {
              setTypeOptions([...options]);
            } else if (type === "industry"){
              seIndustryOptions([...options]);
            }
            // else if (type === "design") {
            //   setDesignOptions([...options]);
            // } else if (type === "size") {
            //   setSizeOptions([...options]);
            // } 
          }
        });
    });
  }, []);

  useEffect(()=>{
    if(!keyword){
      setFormState((state) => ({ ...state, keyword: '' }));
    }
  }, [keyword])

  return (
    <>
      <Row>
        <Col lg={10} md={9} sm={8} xs={12}>
          <InputGroup className="inputGroup mb-2 px-2 px-md-0">
            <InputGroupText
              addonType="prepend"
              className="bg-white iconSearch"
            >
              <i className="fa fa-search fa-lg"></i>
            </InputGroupText>
            <input
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className={`form-control border-start-0 btnHeightLg mb-2`}
              placeholder="Search templates by name or tag"
              type="text"
            />
          </InputGroup>
        </Col>
        <Col lg={2} md={3} sm={4} xs={12}>
            <div className="px-2 px-md-0">
                <button
                    type="button"
                    onClick={() => {
                    setFormState((state) => ({ ...state, keyword }));
                    }}
                    className="btn btn-outline-primary btnHeightLg px-3 w-100"
                >
                    <i className="fa fa-search me-2"></i>
                    Search
                </button>
            </div>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col lg={12}>
          <div className="d-lg-flex pt-2 pe-lg-3">
            <div>
              <label className="pe-2 py-2" style={{ width: "100px" }}>
                Filter by
              </label>
            </div>
            <div className="row w-100">
              {/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Design"
                  options={designOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedDesign: data })
                  }
                  value={formState.selectedDesign}
                  setOptions={(options) => setDesignOptions(options)}
                  isMulti={true}
                />
              </div> */}
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Industry"
                  options={industryOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedindustry: data })
                  }
                  value={formState.selectedindustry}
                  setOptions={(options) => seIndustryOptions(options)}
                  isMulti={true}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Type"
                  options={typeOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedType: data })
                  }
                  value={formState.selectedType}
                  setOptions={(options) => setTypeOptions(options)}
                  isMulti={true}
                />
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pb-3 ps-3 pe-0">
                <Select
                  placeholder="Size"
                  options={sizeOptions}
                  className="borderSelect"
                  onChange={(data) =>
                    setFormState({ ...formState, selectedSize: data })
                  }
                  value={formState.selectedSize}
                  setOptions={(options) => setSizeOptions(options)}
                  isMulti={true}
                />
              </div> */}
            </div>
          </div>
        </Col>
        {/* <Col lg={3} className="py-2 pb-3">
          <div className="d-lg-flex justify-content-between">
            <div>
              <label
                className="text-muted py-2 font-size-14"
                style={{ width: "70px" }}
              >
                Filter by
              </label>
            </div>
            <div className="w-100  pe-lg-0 ">
              <div className="row">
                <div className="col-lg-12 col-md-4 col-sm-6 ps-lg-4">
                  <div className="pe-3 pe-md-0">
                    <input
                      type="date"
                      value={formState.date}
                      className="form-control"
                      onChange={(e) =>
                        setFormState((state) => ({
                          ...state,
                          date: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
    </>
  );
};

Filters.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
};

export default Filters;
