import React, { useState, useEffect } from "react";
import {
  Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import docImage from "assets/images/doc.png";
import "./style.scss";
import FirstStepData from "./First-Step/FirstStepData";
import SecondStepData from "./Second-Step/SecondStepData";
import PropTypes from "prop-types";
import ModalDetails from "../Projects/ModalDetails";
import ThirdStepData from "./Third-Step/ThirdStepData";
import FourthStepData from "./Fourth-Step/FourthStepData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { adminPrefix } from "configs/routePrefix";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import OrderService from "services/Admin/OrderService";
import { toast } from "react-toastify";


const StepDetails = ({ projectDataProps, firstStepData, secondStepData, callSecondStep, fourthStepData }) => {
  const [projectData, setProjectData] = useState(projectDataProps);
  const [firstData, setFirstData] = useState(firstStepData);
  const [modalDetails, setModalDetails] = useState(false);
  const history = useHistory()
  const toggleDetails = () => {
    setModalDetails(c => !c);
  };
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [statusTwoDropdownOpen, setStatusTwoDropdownOpen] = useState(false);
  const [thirdDropdownOpen, setThirdDropdownOpen] = useState(false);
  const toggle = (e) => {
    e.stopPropagation();
    setStatusDropdownOpen(prevState => !prevState)
  };
  const toggle2 = (e) => {
    e.stopPropagation();
    setStatusTwoDropdownOpen(prevState => !prevState)
  };
  const thirdToggle = (e) => {
    e.stopPropagation();
    setThirdDropdownOpen(prevState => !prevState)
  };
  const [open, setOpen] = useState("");
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const updateProjectData = (updateElements) => {
    setProjectData({ ...projectData, [Object.keys(updateElements)[0]]: Object.values(updateElements)[0] });
  };

  useEffect(() => {
    if (projectData.first_step_data_status !== 1) {
      setOpen("1");
    } else if (projectData.second_step_data_status !== 1) {
      setOpen("2");
    } else if (projectData.third_step_data_status !== 1) {
      setOpen("3");
    } else if (projectData.fourth_step_data_status !== 1) {
      setOpen("4");
    }

  }, []);

  const orderService = new OrderService();
  const changeStepStatus = async (data ,status) => {
    const res = await orderService.changeStepStatus(projectData.id, {
      "step": data.step, "status": status
    });
    if (res.status === 200) {
      toast.success("Update Status Successfully");
      if(data.step==='first_step_status') setFirstData({...firstData, [data.step]: status})
      if(data.step==='second_step_status' && status === 0){
        setProjectData({...projectData, [data.update]: status, session_reserved: 0});
      } else {
        updateProjectData({ [data.update]: status });
      }
    }
  };

  return (<div className="px-lg-3 pt-4 accordionPage mb-4">
    <Accordion open={open} toggle={toggleAccordion}>
      {/* Accordion Brief */}
      <AccordionItem>
        <AccordionHeader targetId="1" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData.first_step_data_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  1
                </p>)}
                Project Brief
              </div>
              
              <Dropdown className="dropdownWidth mx-4 position-absolute" style={{left: '13%'}} isOpen={statusDropdownOpen} toggle={toggle}>
                        <DropdownToggle color="white" className="border-0 py-0">
                          {(firstStepData && projectData.first_step_data_status === 1) ? 
                            <span className="badge badgeCompleted">
                              Completed
                              <i className="fa fa-angle-down ms-2"></i>
                            </span> : 
                            <span className="badge badgeCompleted">
                              In Progress
                              <i className="fa fa-angle-down ms-2"></i> 
                            </span>}
                        </DropdownToggle>
                        <DropdownMenu className="mt-1">
                          <DropdownItem onClick={() => changeStepStatus({update: 'first_step_data_status', step: "first_step_status"}, 1)}>Complete</DropdownItem>
                          <DropdownItem onClick={() => changeStepStatus({update: 'first_step_data_status', step: "first_step_status"}, 0)}>In Progress</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
              {/* {firstStepData && projectData.first_step_data_status === 1 ? (<span
                className="badge badgeCompleted mx-4">Completed</span>) : projectData.first_step_data_status === 3 ? (
                <span className="badge badgeCompleted mx-4">In Progress</span>) : null}
              {projectData.first_step_data_status === 0 ? (<span className="status-badge mx-md-4 mx-0">
                    Pending Client Action
                  </span>) : ("")} */}
            </div>
            {firstStepData && projectData.first_step_data_status === 1 ? (
              <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
                Data For Project Brief is completed successfully
              </p>) : (<p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
              Fill information about your business and goals for this
              website
            </p>)}
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="1" className="px-0 pt-0">
          <Card className="border-0">
            <CardBody className="px-lg-4 px-0">
              {firstData ? (<FirstStepData
                data={firstData}
                orderId={projectDataProps.id}
                setModalDetails={() => setModalDetails(true)}
                projectData={projectData}
                updateProjectData={(updateElements) => updateProjectData(updateElements)}
              />) : (<>
                <div className="boxShadow pt-4 px-3 mb-2 pb-3">
                  <div className="row justify-content-between">
                    <div className="col-lg-12 text-center">
                      
                      <img src={docImage} alt=""/>
                      <h6 className="fw-bold pt-3">
                            Waiting For Client Brief
                      </h6>
                      
                      <p className="f-14 ps-4 ms-2 mb-0 text-black-50">
                        Fill information about your business and goals for
                        this website, this data can vary and time can be
                        from 30 mins to 4 hours
                      </p>
                      <Link 
                        // to={`${adminPrefix}/project/${projectData.id}/first-step`}
                        // state= {{order_id: projectData?.id, data: projectData}}
                        className="btn btn-primary mt-3"
                        onClick={() => {
                          // toggleAdd();
                          history.push(`${adminPrefix}/project/${projectData.id}/first-step`, {order_id: projectData?.id, data: projectData})
                        }}
                        >
                        Submit Client Brief
                      </Link>
                    </div>
                  </div>
                </div>
              </>)}
            </CardBody>
            {modalDetails && (<ModalDetails
              modalDetails={modalDetails}
              toggleDetails={toggleDetails}
              data={firstStepData}
            />)}
          </Card>
        </AccordionBody>
      </AccordionItem>

      {/* Accordion Content */}
      <AccordionItem>
        <AccordionHeader targetId="2" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData?.second_step_data_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  2
                </p>)}
                Content Brief
              </div>

              <Dropdown className="dropdownWidth mx-4 " isOpen={statusTwoDropdownOpen} toggle={toggle2}>
                        <DropdownToggle color="white" className="border-0 py-0 px-0">
                          {(secondStepData && projectData.second_step_data_status === 1) ? 
                            <span className="badge badgeCompleted">
                              Completed
                              <i className="fa fa-angle-down ms-2"></i>
                            </span> : projectData.second_step_data_status === 2 ?
                            <span className="badge badgeCompleted">
                            In Progress
                            <i className="fa fa-angle-down ms-2"></i> 
                          </span> : (projectData.second_step_data_status === 3 && projectData.content_included) ? 
                            <span className="status-badge">
                              Pending Account Manager Action
                              <i className="fa fa-angle-down ms-2"></i> 
                            </span> : <span className="status-badge">
                              {projectData.content_included ? 'Pending Client Action' : 'Pending'}
                              <i className="fa fa-angle-down ms-2"></i> 
                            </span>}
                        </DropdownToggle>
                        <DropdownMenu className="mt-1">
                          <DropdownItem onClick={() => changeStepStatus({update: 'second_step_data_status', step: "second_step_status"}, 0)}>
                            {projectData.content_included ? 'Pending Client Action' : 'Pending'}
                          </DropdownItem>
                          {projectData.content_included && <DropdownItem onClick={() => changeStepStatus({update: 'second_step_data_status', step: "second_step_status"}, 3)}>Pending Account Manager Action</DropdownItem>}
                          <DropdownItem onClick={() => changeStepStatus({update: 'second_step_data_status', step: "second_step_status"}, 2)}>In Progress</DropdownItem>
                          <DropdownItem onClick={() => changeStepStatus({update: 'second_step_data_status', step: "second_step_status"}, 1)}>Complete</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
              {/* {secondStepData && projectData.second_step_data_status === 1 ? (<span
                className="badge badgeCompleted mx-4">Completed</span>) : projectData.second_step_data_status === 2 || projectData.second_step_data_status === 3 ? (
                <span className="badge badgeCompleted mx-4">In Progress</span>) : null} */}
              {/* {projectData.second_step_data_status === 0 ? (<span className="status-badge mx-md-4 mx-0">
                    Pending Client Action
                  </span>) : ("")} */}

              {projectData && !projectData?.content_included ? (<>
                    <span className="badge badgeWarning ms-2">
                    {`Copywriter Services isn't included in your package`}
                    </span>
              </>) : null}
            </div>
            {secondStepData && projectData.second_step_data_status === 1 ? (
              <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
                Data For Content Brief is completed successfully
              </p>) : (<p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
              Fill information about your business and goals for this
              website
            </p>)}
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="2" className="px-0 pt-0">
          <SecondStepData
            projectData={projectData}
            toggleAccordion={(tab) => toggleAccordion(tab)}
            updateProjectData={(updateElements) => updateProjectData(updateElements)}
            secondStepData={secondStepData}
            callSecondStep={callSecondStep}
          />
        </AccordionBody>
      </AccordionItem>

      {/* Accordion Design & feedback */}
      <AccordionItem>
        <AccordionHeader targetId="3" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData?.third_step_data_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  3
                </p>)}
                Design & Feedback
              </div>
              <Dropdown className="dropdownWidth mx-4" isOpen={thirdDropdownOpen} toggle={thirdToggle}>
                <DropdownToggle color="white" className="border-0 py-0 px-0">
                  {projectData.third_step_data_status === 1 ? 
                      <span className="badge badgeCompleted">
                        Completed <i className="fa fa-angle-down ms-2"></i>
                      </span> : projectData.third_step_data_status === 2 ?
                      <span className="badge badgeCompleted">
                        In Progress<i className="fa fa-angle-down ms-2"></i> 
                      </span> : projectData.third_step_data_status === 3 ? 
                      <span className="badge badgeWarning">
                        Ready to Review<i className="fa fa-angle-down ms-2"></i> 
                      </span> : <span className="status-badge">
                        Waiting<i className="fa fa-angle-down ms-2"></i> 
                      </span>}
                        </DropdownToggle>
                        <DropdownMenu className="mt-1">
                          {/* <DropdownItem onClick={() => changeStepStatus({update: 'third_step_data_status', step: "third_step_status"}, 0)}>Waiting</DropdownItem> */}
                          <DropdownItem onClick={() => changeStepStatus({update: 'third_step_data_status', step: "third_step_status"}, 2)}>In Progress</DropdownItem>
                          <DropdownItem onClick={() => changeStepStatus({update: 'third_step_data_status', step: "third_step_status"}, 3)}>Ready to Review</DropdownItem>
                          <DropdownItem onClick={() => changeStepStatus({update: 'third_step_data_status', step: "third_step_status"}, 1)}>Complete</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
              {/* {projectData.third_step_data_status === 1 ? (<span
                className="badge badgeCompleted mx-4">Completed</span>) : projectData.third_step_data_status === 2 ? <span className="badge badgeCompleted mx-md-4 ms-md-4">In Progress</span> : projectData.third_step_data_status === 3 ? (
                <span className="badge badgeWarning mx-4">
                    Ready to Review
                  </span>) : (<span className="status-badge mx-md-4 mx-0">Waiting</span>)} */}
            </div>
            {secondStepData && projectData.third_step_data_status === 1 ? (
              <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
                Data For Design & FeedBack is completed successfully
              </p>) : (<p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
              Fill information about your business and goals for this
              website
            </p>)}
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="3" className="px-0 pt-0">
          <Card className="border-0">
            <CardBody className="px-lg-4 px-0">
              <Card className="border-0">
                <CardBody className="px-lg-4 px-0">
                  <ThirdStepData
                    projectData={projectData}
                    updateProjectData={updateProjectData}
                  />
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </AccordionBody>
      </AccordionItem>

      {/* Accordion Publish */}
      <AccordionItem>
        <AccordionHeader targetId="4" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData?.fourth_step_data_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  4
                </p>)}
                Publishing / Go-live
              </div>
              {projectData.fourth_step_data_status === 0 ? <span
                className="status-badge mx-md-4 mx-0">Pending Client Action</span> : (projectData.fourth_step_data_status === 1 ?
                <span className="badgeCompleted mx-4 fw-bold">Completed</span> :
                <span className="status-badge mx-md-4 mx-0">Pending Account Manager Action</span>)}
            </div>

            <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
              Fill information about your business and goals for this website
            </p>
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="4" className="px-0 pt-0">
          <Card className="border-0">
            <CardBody className="px-lg-4 px-0">
              <FourthStepData
                fourthStepData={fourthStepData}
                projectData={projectData}
                updateProjectData={(updateElements) => updateProjectData(updateElements)}
              />
            </CardBody>
          </Card>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  </div>);
};

StepDetails.propTypes = {
  orderId: PropTypes.string,
  projectDataProps: PropTypes.object,
  firstStepData: PropTypes.any,
  secondStepData: PropTypes.any,
  fourthStepData: PropTypes.any,
  callSecondStep: PropTypes.func
};
export default StepDetails;
