import React, { useState, useEffect } from "react";
import OrderService from "services/Admin/OrderService";
import { Container } from "reactstrap";
import Paginator from "components/Common/Paginator/Paginator";
import noProject from "assets/images/noProject.png";
import CardItem from "components/Admin/Projects/CardItem";
import { Circles } from "react-loader-spinner";
import Filterswrapper from "components/Common/filterswrapper";
import "./style.scss";
import Filters from "components/Admin/Projects/Filters";
import { MetaTags } from "react-meta-tags";
import ModalAssign from "components/Admin/Projects/ModalAssign";
import { GET_AUTH_USER } from "helpers/common";

const Projects = () => {
  const userRole = GET_AUTH_USER();
  const orderService = new OrderService();
  const [getData, setGetData] = useState(false);
  const [orders, setOrders] = useState([]);
  const [modalAssign, setModalAssign] = useState(false);
  const [filtersParams, setFilterParams] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [formState, setFormState] = useState({
    selectedDesign: [],
    selectedType: [],
    selectedSize: [],
    selectedindustry: [],
    keyword: "",
    date: "",
    accountManager: ""
  });

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0
  });

  const toggleAssign = () => {
    setModalAssign(!modalAssign);
  };

  useEffect(() => {
    let params = {};
    let category_ids = [];
    if(formState?.accountManager?.label && formState?.accountManager?.label !== 'Not Assigned') params[`assignee_ids`] = formState?.accountManager?.value
    if(formState?.accountManager?.label === 'Not Assigned') params[`not_assigned`] = 1
    for (const key in formState) {
      if (key === "date" || key === "keyword") {
        if (formState[`${key}`].length) params[`${key}`] = formState[`${key}`].trim();
      } else {
        if (formState[`${key}`].length) category_ids.push(formState[`${key}`].map(item => item.value).join());
      }
    }

    if (category_ids.length) {
      setFilterParams({ ...params, category_ids: category_ids.join() });

    } else {
      setFilterParams({ ...params });

    }
  }, [formState]);


  return (
    <div className="admin-container pt-2">
      <MetaTags>
        <title> Projects </title>
      </MetaTags>
      <Container fluid className="bgGray pb-5 pt-3 px-lg-5">
        <div className="dNoneBtn">
        {(userRole.role === "Admin" || userRole.role === "Account Manager") ?
          <Filterswrapper
            title={`Projects`}
            subTitle={"This should help the customer understand what to expect next"}
            addbtnName={"Add a new project"}
          >
            <Filters formState={formState} setFormState={setFormState}/>
          </Filterswrapper> : 
          <div className="hideBtnAdd">
            <Filterswrapper
              title={`Projects`}
              subTitle={"This should help the customer understand what to expect next"}
              addbtnName={"Add a new project"}
            >
              <Filters formState={formState} setFormState={setFormState}/>
            </Filterswrapper>
          </div>
          }
        </div>
        <div>
          {getData &&
            <div className="loaderStyle">
              <Circles color="#00BFFF"/>
            </div>
          }
          {orders.length ? (
            <div className="bg-white boxShadow">
              {!getData && orders.map((order, index) =>
                <CardItem
                  assignProject={(id) => {
                    setEditItem(id);
                    setModalAssign(!modalAssign)
                  }}
                  key={index}
                  order={order}
                />
              )}
            </div>
          ) : !getData && (
            <div className="text-center">
              {/* <div className="loaderStyle">
                <Circles color="#00BFFF"/>
              </div> */}
              <img src={noProject} alt=""/>
              <p className="pt-2 mb-2"> Thers is No Projects </p>
            </div>
          )}
        </div>

        {modalAssign && (
          <ModalAssign
            editItem={editItem}
            modalAssign={modalAssign}
            toggleAssign={toggleAssign}
            setGetData={setGetData}
          />
        )}
        <div className="bg-white mt-3">
          <div className="d-flex justify-content-end" md={8} sm={12}>
            <Paginator
              service={orderService}
              pageOptions={pageOptions}
              setData={(data) => {
                setOrders([...data]);
                setGetData(false);
              }}
              setPageOptions={setPageOptions}
              filtersParams={filtersParams}
              shouldGetData={getData}
              setShouldGetData={() => {
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};


export default Projects;
