import * as yup from "yup";
import {
    TITLE_PATTERN_VALIDATOR,
    // EMAIL_PATTERN_VALIDATOR
} from "helpers/validators";
// const url  =/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/

export const BriefValidator = yup.object().shape({
    // business_name: yup.string().required("Business name is required"),
    // description: yup.string().required("Description is required"),
    name: yup.string().matches(
            TITLE_PATTERN_VALIDATOR.value,
            TITLE_PATTERN_VALIDATOR.errorMessage
        )
        .required("Name is required"),
    // email: yup.string().matches(
    //         EMAIL_PATTERN_VALIDATOR.value,
    //         EMAIL_PATTERN_VALIDATOR.errorMessage
    // ).required("Email is required"),
    
    // kind_of_website: yup.object(),
    pages: yup.array().of(
        yup.object().shape({
            title: yup.string().required("title is required"),
            description: yup.string()
        }),
    ),
    // social: yup.array().of(
    //     yup.object().shape({
    //         name: yup
    //             .string()
    //             .matches(
    //                 TITLE_PATTERN_VALIDATOR.value,
    //                 TITLE_PATTERN_VALIDATOR.errorMessage
    //             )
    //             .required(" name is required"),
    //         link: yup.string().matches(url,'Link is not valid')
    //     }),
    // ),

    // secondary_color: yup.string(),
    // primary_color : yup.string(),
});

