import React from "react";
import { Col,Row,Container } from "reactstrap";
import "./styles.scss";
import Navbar from "components/Website/Layouts/Header";
import { useTranslation } from 'react-i18next';
import GeneralFooter from "components/Website/Layouts/Footer/GeneralFooter";
import guideImg from "assets/images/guide.png";
import guideImgPlane from "assets/images/guidePlane.png";
import guideImgPlane2 from "assets/images/guidePlane2.png";
import opacityImg from "assets/images/bgOpacity.png";

const Guide = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className={t("websitPages container-fluid ms-0 p-0")}>
        <div className="py-5 mt-4">
          <Container fluid className="pb-4 guide">
            <Row className="justify-content-center">
              <Col xxl="6" lg="8" className="text-center">
                <h4 className="pt-5 fw-bold pb-3 px-lg-4">
                  {t("What to Expect & How to Prepare for Your Meeting with Our Copywriter")}
                </h4>
                <p className="text-muted pb-2">{t("We're looking forward to collaborating with you to create compelling content for your website.")}</p>
                <p className="text-muted">{t("Here's an overview of what to expect during our initial content strategy meeting and how you can best prepare.")}</p>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col lg="8">
                <div className="timeGuide d-flex px-lg-4 pt-4">
                  <img src={guideImg} className="img-fluid" />
                  <div>
                    <h5 className="fw-bold pt-3">{t("What to Expect")}</h5>
                    <p className="text-muted pb-2">{t("Think of our meeting as a relaxed and creative brainstorming session where all ideas are welcome. We're excited to learn about your vision and explore the possibilities together!")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Introduction")}</h6>
                    <p className="text-muted pb-2">{t("We'll take time to get to know you and your business goals for the website.")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Content Discussion")}</h6>
                    <p className="text-muted pb-2">{t("We'll ask questions to understand your products/services, target customers, brand positioning.")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Review of Website Pages")}</h6>
                    <p className="text-muted pb-2">{t("We'll review each page outlined in your website sitemap to determine content needs.")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Content Strategy")}</h6>
                    <p className="text-muted">{t("We'll outline a content strategy and tone for each page.")}</p>
                  </div>
                </div>
              </Col>
              <Col lg="4" className="pt-lg-5 mt-lg-5">
                <img src={guideImgPlane} className="img-fluid" />
              </Col>
            </Row>
            <Row className="pt-5">
              <Col lg="8">
                <div className="timeGuide d-flex px-lg-4">
                  <img src={guideImgPlane2} className="img-fluid" />
                  <div>
                    <h5 className="fw-bold pt-3">{t("How to Prepare")}</h5>
                    <p className="text-muted pb-2">{t("Don’t worry if you don’t have everything on this list. It's completely fine to bring whatever you have – we're here to help fill in the gaps together!")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Have Your Site Map Ready")}</h6>
                    <p className="text-muted pb-2">{t("If you don't have a sitemap yet, that's okay — we'll help you outline one during our chat.")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Gather Brand Assets")}</h6>
                    <p className="text-muted pb-2">{t("Any existing brand guidelines, product/service info, competitor analysis etc. is helpful.")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Know Your Audience")}</h6>
                    <p className="text-muted pb-2">{t("Who are your ideal customers?")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Identify Business Goals")}</h6>
                    <p className="text-muted">{t("What is most important for your website content to achieve?")}</p>
                    <h6 className="fw-bold pt-lg-4">{t("Prepare Questions")}</h6>
                    <p className="text-muted">{t("Note any content questions you have so we can address them.")}</p>
                  </div>
                </div>
                <div className="px-lg-5 px-3">
                  <p className="textBlueDark fw-normal">{t("We want to create content tailored precisely to your brand and goals. Thorough preparation allows us to have a productive, collaborative session and set your website content up for success.")}</p>
                  <p className="text-primary fw-bold pt-4">{t("Please let us know if you have any other questions heading into our meeting!")}</p>
                </div>
              </Col>
              <Col lg="4" className="pt-5 mt-lg-5">
                <div className="pt-lg-5 mt-lg-5">
                  <img src={opacityImg} className="img-fluid mt-lg-5" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <GeneralFooter/>
      </div>
    </>
  );
};

export default Guide;
