import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import BaseService from "services/BaseService";
import PropTypes from 'prop-types';
import {   ContentState, EditorState } from "draft-js";
 
const TextEditor = ({ name, error, watch, setValue ,editItem }) => {

    const [getState, setgetState] = useState(true)
    const [editorState, setEditorState] = useState()

    useEffect(() => {
        setValue(name, editorState)
    }, [editorState])

    useEffect(() => {
        if (getState && watch(name) && editItem) {
            const { contentBlocks, entityMap } = watch(name);
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            setgetState(false)
        }
    }, [watch(name)])
    
    const uploadCallback = (file) => {
        return new Promise((resolve) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const res = await new BaseService().postUpload(file)
                resolve({ data: { link: res.data.data.path } });
                const inputFile = document.querySelector(".rdw-image-modal-upload-option-input")
                const inputDev = document.querySelector(".rdw-image-modal-upload-option")
                inputDev.style.outline = "none"
                inputFile.remove()
            };
            reader.readAsDataURL(file);
        });
    };
    const config = {
        image: { uploadCallback: uploadCallback },
    };

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

   

    return (
        <div className='py-2 '>
            <Editor
                toolbar={config}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperStyle={{ width: "100%", border: "1px solid black" }}
            />
            {error&& <ErrorMessage message={error} />}
        </div>
    )
}


TextEditor.propTypes = {
    control: PropTypes.any,
    name: PropTypes.string,
    error: PropTypes.string,
    watch: PropTypes.func,
    setValue: PropTypes.func,
    editItem:PropTypes.object

};

export default TextEditor