import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row, CardText, Label } from "reactstrap";
import { adminPrefix } from 'configs/routePrefix';
import AuthService from "services/Admin/AuthService";
import imgLogo from "assets/images/logo.svg"
import "./login.scss";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from "@hookform/resolvers/yup";
import { forgetValidator } from "FormValidations/Admin/Auth/ForgetValidator"
import PropTypes from 'prop-types';
import { API_BASE_URL_ENV } from "helpers/common";
 
const apiForgetPasswordEndpoint =
  API_BASE_URL_ENV() + "/admin/user/forget-password";

const ForgetPassword = (props) => {
  const form = useForm(
    { resolver: yupResolver(forgetValidator) }
  );
  const { register, formState: { errors }, handleSubmit } = form
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleValidSubmit = async (values) => {
    setIsSubmitting(true);
     try {
      const res = await AuthService.forgetPassword(apiForgetPasswordEndpoint,{
        email: values.email,
       })
       if (res && res.success==="Email Sent to your email") {
        toast.success("Email Sent to your email")
        setIsSubmitting(false);
        props.history.push(`${adminPrefix}/login`);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

 

  return (
    <div>
      <MetaTags>
        <title> Forget Password </title>
      </MetaTags>
      <div className="bgGray height100">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" lg="6">
              <Card className="overflow-hidden mt-lg-5 mt-3">
                <CardBody>
                  <div className="pt-1 p-2">
                    <img src={imgLogo} className="img-fluid mx-auto d-block" alt="" />
                    <CardText tag="h5" className="text-primary">Forget Password</CardText>
                    <form onSubmit={handleSubmit(handleValidSubmit)}>
                      <div className="mb-3 pt-3">
                        <Label>Email</Label>
                        <input
                          {...register('email')}
                          type='email'
                          className='form-control height-47'
                          placeholder='eg.. ahmedamr@gmail.com'
                        />
                        {errors ?.email && <ErrorMessage message={errors ?.email ?.message} />}
                      </div>
                      <button
                        className='btn btn-primary w-100 mt-3 font-size-14 height-47'
                        disabled={isSubmitting}
                        onClick={()=>{
                          console.log(errors)
                        }}
                        type='submit'>
                        Submit
                        </button>
                    </form>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

ForgetPassword.propTypes = {
  history: PropTypes.object,
};

export default ForgetPassword;