import { AUTHENTICATE, LOGOUT ,APPENDFILTERS } from "./actionTypes";


export const authenticate = (payload) => {
    return {
        type: AUTHENTICATE,
        payload: payload
    }
}
export const appendFilters = (payload) => {
    return {
        type: APPENDFILTERS,
        payload: payload
    }
}
 
 

export const logout = () => {
    return {
        type: LOGOUT
    }
}
