import React from 'react'
import imgSuccess from "assets/images/tick.png"
import PropTypes from "prop-types"


const PaymentSuccess = ({history}) => {
  return (
    <div className='websitPages'>
        <div className='container pt-5'>
            <div className='row pt-5 justify-content-center'>
                <div className='col-lg-9'>
                    <div className='card cardShadow text-center py-5'>
                        <img src={imgSuccess} className="img-fluid d-block mx-auto" />
                        <h4 className='fw-bold pt-4'>
                            You are all set!
                        </h4>
                        <p className='text-muted pt-3'>Your payment has been successfully confirmed, and now it is time to start building. <br/> Click the button below to access your project dashboard and dive right in. </p>
                        <p>
                            <button 
                            className='btn btn-primary mt-4 px-4 btnHeightLg'
                            onClick={()=>{
                                history.push(`/client/project/${history?.location?.state?.project_id}`)
                            }}
                            >Go to Project Page <i className='fa fa-long-arrow-right mx-2'></i></button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

PaymentSuccess.propTypes = {
    history: PropTypes.func,
  
  };

export default PaymentSuccess