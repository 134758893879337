import "./styles.scss"
import React, { useState } from "react"
import PropTypes from 'prop-types'
import classnames from "classnames"
import { useTranslation } from 'react-i18next'
// import rocketImage from "assets/images/rocket.png"
import logoWhite from "assets/images/logowhite.png"
import imgBlog1 from "assets/images/imgBlog1.png"
import imgBlog2 from "assets/images/imgBlog2.png"
import imgBlog3 from "assets/images/imgBlog3.png"
import imgBlog4 from "assets/images/imgBlog4.png"
import imgBlog5 from "assets/images/imgBlog5.png"
import {
    NavItem,
    Nav,
    NavLink,
    TabPane,
    TabContent
} from "reactstrap";
import './styles.scss'
import { Link } from 'react-router-dom';
import BlogService from "services/Website/BlogService"
import NavbarHeader from "components/Website/Layouts/Header"
import Paginator from 'components/Common/Paginator/Paginator'
import { Helmet } from "react-helmet";

const Blog = ({ history }) => {
    const { t, i18n } = useTranslation();
    const [activeTabs, setActiveTabs] = useState("1");
    const tabSections = (tab) => setActiveTabs(tab);
    const blogService = new BlogService()
    const [blogs, setBlogs] = useState([])
    const [getData, setGetData] = useState(true)
    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
    });

    return (
        <>
            <NavbarHeader />
            <div className="pt-5 mt-3">
                {blogs.length > 0 && <Helmet>
                    <title>{blogs.map(blog => `${blog.title[`${i18n.language}`]} ,`)}</title>
                    <meta
                        name="description"
                        content={blogs.map(blog => `${blog.category.name[`${i18n.language}`]},`)}
                    />
                </Helmet>}
            </div>
            <div className="container">
                <hr className="border-top" />
                <Nav tabs className="bg-white newTabs border-0">
                    <NavItem>
                        <NavLink className="disabled"
                        >
                            Filter by
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTabs === "1" })}
                            onClick={() => {
                                tabSections("1");
                            }}
                        >
                            All
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTabs === "2" })}
                            onClick={() => {
                                tabSections("2");
                            }}
                        >
                            Web Design
                            </NavLink>
                    </NavItem>
                </Nav>
                <hr className="border-top" />
                <TabContent className="pt-2 px-0" activeTab={activeTabs}>
                    <TabPane tabId="1">
                        {blogs.map((blog, index) => {
                            return (
                                <div className="row justify-content-between my-3 py-2 shadow" key={index}>
                                    <div className="col-lg-5">
                                        <img src={blog.media.path ?? imgBlog4} className="img-blog rounded-3" />
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="pt-2 text-warning mb-2">{blog.title[`${i18n.language}`]}</p>
                                        <h2 className="fw-bold py-2">{blog.category.name[`${i18n.language}`]}</h2>
                                        {/* <p className="text-muted font-16 mb-1">Webkicks is an AI-powered, drag-and-drop, visual app builder that lets you build your own multi-platform apps with no prior coding experience.</p> */}
                                        <Link to={`/blog-details/${blog.id}`}>
                                            <button className='btn btn-outline-primary font-14 mt-3 btnHeightLg fw-bold px-3'>Read Article
                                            <i className="fa fa-long-arrow-right float-end pt-1 ps-5 ms-5"></i></button>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="row justify-content-between">
                            <div className="col-lg-5">
                                <img src={imgBlog4} className="img-fluid rounded-3" />
                            </div>
                            <div className="col-lg-6">
                                <p className="pt-2 text-warning mb-2">Artificial Intelligence</p>
                                <h2 className="fw-bold py-2">10 Modern ways to build an online business with good UX</h2>
                                <p className="text-muted font-16 mb-1">Webkicks is an AI-powered, drag-and-drop, visual app builder that lets you build your own multi-platform apps with no prior coding experience.</p>
                                <button className='btn btn-outline-primary font-14 mt-3 btnHeightLg fw-bold px-3'>Read Article
                                        <i className="fa fa-long-arrow-right float-end pt-1 ps-5"></i></button>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
            <div className="bg-white mt-3">
                <div className="d-flex justify-content-end" md={8} sm={12}>

                    <Paginator
                        service={blogService}
                        pageOptions={pageOptions}
                        setData={(data) => {
                            setBlogs([...data])
                            setGetData(false)
                        }}
                        setPageOptions={setPageOptions}
                        filtersParams={null}
                        shouldGetData={getData}
                        setShouldGetData={() => { }}
                    />
                </div>
            </div>
            <div className="bg-white pb-4">
                <div className="latestArticle pt-5">
                    <div className="container">
                        <h6 className="fw-bold border-bottom pb-2">Latest Articles</h6>
                        <div className="row border-bottom">
                            <div className="col-lg-4 col-md-6 pb-3">
                                <div className="border-end" data-aos='fade-up' data-aos-delay='100' data-aos-duration='1000'>
                                    <p className="pt-2 text-primary mb-2">Artificial Intelligence</p>
                                    <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                    <p className="text-muted font-14 mb-1">Business processes in any organization could always benefit from automation and optimization.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 pb-3">
                                <div className="border-end" data-aos='fade-up' data-aos-delay='150' data-aos-duration='1500'>
                                    <p className="pt-2 text-success mb-2">Learning Resources</p>
                                    <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                    <p className="text-muted font-14 mb-1">Business processes in any organization could always benefit from automation and optimization.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 pb-3">
                                <div data-aos='fade-up' data-aos-delay='200' data-aos-duration='2000'>
                                    <p className="pt-2 text-primary mb-2">Clonable Projects</p>
                                    <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                    <p className="text-muted font-14 mb-1">Business processes in any organization could always benefit from automation and optimization.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-lg-4 col-md-6 pb-3">
                                <div data-aos='fade-up' data-aos-delay='100' data-aos-duration='1000'>
                                    <img src={imgBlog1} className="imageSameHeight" />
                                    <p className="pt-2 text-warning mb-2">Artificial Intelligence</p>
                                    <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                    <p className="text-muted font-14">Business processes in any organization could always benefit from automation and optimization.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 pb-3">
                                <div data-aos='fade-up' data-aos-delay='150' data-aos-duration='1500'>
                                    <img src={imgBlog2} className="imageSameHeight" />
                                    <p className="pt-2 text-warning mb-2">Artificial Intelligence</p>
                                    <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                    <p className="text-muted font-14">Business processes in any organization could always benefit from automation and optimization.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 pb-3">
                                <div data-aos='fade-up' data-aos-delay='200' data-aos-duration='2000'>
                                    <img src={imgBlog3} className="imageSameHeight" />
                                    <p className="pt-2 text-warning mb-2">Artificial Intelligence</p>
                                    <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                    <p className="text-muted font-14">Business processes in any organization could always benefit from automation and optimization.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pb-3">
                    <hr className="border-top" />
                    <div className="row pt-3">
                        <div className="col-lg-7">
                            <div data-aos='fade-in' data-aos-delay='100' data-aos-duration='1000'>
                                <img src={imgBlog4} className="img-fluid" />
                                <p className="pt-2 text-warning mb-2">Artificial Intelligence</p>
                                <h6 className="fw-bold">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                <p>Business processes in any organization could always benefit from automation and optimization.
                                    Almost all businesses and startups have a digital component, be it a mobile app or a web app. Business processes in any organization could always.</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div>
                                <div className="card border-0 pb-3" data-aos='fade-in' data-aos-delay='100' data-aos-duration='1000'>
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={imgBlog1} className="imgLearning" />
                                        </div>
                                        <div className="col-md-8 ps-3">
                                            <p className="mb-2 text-primary font-12">Artificial Intelligence</p>
                                            <h6 className="fw-bold font-14">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                            <p className="mb-1 text-muted font-12">Business processes in any organization could always benefit from automation and optimization</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-0 pb-3" data-aos='fade-in' data-aos-delay='100' data-aos-duration='1000'>
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={imgBlog5} className="imgLearning" />
                                        </div>
                                        <div className="col-md-8 ps-3">
                                            <p className="mb-2 text-secondary font-12">Learning Resources</p>
                                            <h6 className="fw-bold font-14">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                            <p className="mb-1 text-muted font-12">Business processes in any organization could always benefit from automation and optimization</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-0 pb-3" data-aos='fade-in' data-aos-delay='100' data-aos-duration='1000'>
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={imgBlog1} className="imgLearning" />
                                        </div>
                                        <div className="col-md-8 ps-3">
                                            <p className="mb-2 text-primary font-12">Artificial Intelligence</p>
                                            <h6 className="fw-bold font-14">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                            <p className="mb-1 text-muted font-12">Business processes in any organization could always benefit from automation and optimization</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card border-0 pb-3" data-aos='fade-in' data-aos-delay='100' data-aos-duration='1000'>
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={imgBlog2} className="imgLearning" />
                                        </div>
                                        <div className="col-md-8 ps-3">
                                            <p className="mb-2 text-secondary font-12">Learning Resources</p>
                                            <h6 className="fw-bold font-14">Build your own UI kit for apps using React components on iRithm’s platform</h6>
                                            <p className="mb-1 text-muted font-12">Business processes in any organization could always benefit from automation and optimization</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pb-4">
                    <h3 className="text-center fw-bold pt-5">Learning Resources.</h3>
                    <p className="text-center text-muted pt-3 mb-0 pb-3">Webkicks is a drag and drop app builder integrated with machine <br /> learning that makes application development up to 4x faster and so <br /> much easier </p>
                    <div className="row pt-4">
                        <div className="col-lg-4 col-md-6 pb-2">
                            <div className="card cardLEarn" data-aos='fade-in' data-aos-delay='100' data-aos-duration='1000'>
                                <div className="card-body">
                                    <img src={imgBlog1} className="imgLearning" />
                                    <h5 className="pt-2 fw-bold font-18">
                                        Develop Tax Calculator Application for a business
                                    </h5>
                                    <p className="text-muted font-14 mb-1">5 min read</p>
                                    <button className="btn text-primary font-14 p-0">Learn more</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pb-2">
                            <div className="card cardLEarn" data-aos='fade-in' data-aos-delay='150' data-aos-duration='1500'>
                                <div className="card-body">
                                    <img src={imgBlog2} className="imgLearning" />
                                    <h5 className="pt-2 fw-bold font-18">
                                        Develop Tax Calculator Application for a business
                                    </h5>
                                    <p className="text-muted font-14 mb-1">5 min read</p>
                                    <button className="btn text-primary font-14 p-0">Learn more</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pb-2">
                            <div className="card cardLEarn" data-aos='fade-in' data-aos-delay='200' data-aos-duration='2000'>
                                <div className="card-body">
                                    <img src={imgBlog3} className="imgLearning" />
                                    <h5 className="pt-2 fw-bold font-18">
                                        Develop Tax Calculator Application for a business
                                    </h5>
                                    <p className="text-muted font-14 mb-1">5 min read</p>
                                    <button className="btn text-primary font-14 p-0">Learn more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-center pt-5"><button className="btn btn-outline-primary px-5">View More</button></p>
                </div>
            </div>

            {/* What are you waiting for? */}
            <div className="rocket p-4 position-relative">
                <div className="text-center pb-5">
                    {/* <img src={rocketImage} className="rocketImageAbsolute d-none d-lg-block" /> */}
                    <div data-aos='fade-up' data-aos-delay='100' data-aos-duration='2000'>
                        <h2 className="fw-bold pb-3 pt-2 mt-lg-5">
                            What are you waiting for? <br />
                            Get started now and have your <br /> website up and running in no time
                        </h2>
                        <p className="text-muted pt-2">
                            Refersion helps merchants connect with customers at scale through <br /> affiliate and influencer marketing program custom commission structures <br /> for e-commerce brands.
                        </p>
                        <button
                            className="btn btn-primary btnHeightLg p-2 mb-5 mt-4 px-4 font-14 text-start"
                            onClick={() => history.push("create-website")}
                        >
                            {t("Create your website now")} {i18n.language !== "ar" ? <i className="fa fa-long-arrow-right float-end pt-1 ms-5"></i>
                                : <i className="fa fa-long-arrow-left pe-3 me-5"></i>}
                        </button>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="footerHome p-4">
                <div className="container pt-4">
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <img src={logoWhite} alt="" height="30px" />
                            <p className="text-muted pt-4">Refersion helps merchants connect with customers at scale through affiliate and influencer marketing program custom commission structures for e-commerce brands.</p>
                        </div>
                        <div className="col-lg-3">
                            <p className="text-muted">Connect with us</p>
                            <p>
                                <Link className="text-white text-decoration-none px-2" to={""} alt="">
                                    <i className="fa fa-facebook-f fa-lg"></i>
                                </Link>
                                <Link className="text-white text-decoration-none px-2" to={""} alt="">
                                    <i className="fa fa-instagram fa-lg"></i>
                                </Link>
                                <Link className="text-white text-decoration-none px-2" to={""} alt="">
                                    <i className="fa fa-twitter fa-lg"></i>
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="border-top mt-4 border-secondary d-sm-flex justify-content-between text-start" dir="rtl">
                        <div className="pt-3">
                            <Link className="text-white opacity-75 text-decoration-none px-2" to={""} alt="">How it works</Link>
                            <Link className="text-white opacity-75 text-decoration-none px-2" to={"/contact-us"} alt="">Contact</Link>
                            <Link className="text-white opacity-75 text-decoration-none px-2" to={"/privacy-policy"} alt="">Privacy</Link>
                        </div>
                        <p className="text-white pt-3 opacity-75">Webkicks {(new Date().getFullYear())}, All rights reserved</p>
                    </div>
                </div>
            </div>
        </>
    );
}

Blog.propTypes = {
    history: PropTypes.object,

};

export default Blog
