import React, { useEffect, useState } from "react";
import { Row, Col, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import OrderTemplateService from "services/Website/OrderTemplateService";
import { toast } from "react-toastify";

const CustomModal = ({ modalAdd, toggleAdd}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: ''
  })
  const [loading, setLoading] = useState(false)
  const data = useSelector(state=> state.Auth)
  const orderTemplateService = new OrderTemplateService()

  useEffect(()=>{
    setFormData({
      ...formData,
      email: data.email || '',
      name: data.user_name || '',
    })
  }, [data])

  const onSubmit = (e) => {
    e.preventDefault()
    if(!formData.description){
      toast.error("Enter Description")
      return
    }
    setLoading(true)
    orderTemplateService.customTemplate(formData).then(res=>{
      if(res?.status === 200){
        toast.success("Email Send Successfully ")
        toggleAdd()
      }
      setLoading(false)
    })
  };

  return (
    <>
      <Modal
        isOpen={modalAdd}
        className="modal-dialog-scrollable"
        toggle={() => toggleAdd()}
      >
        <ModalHeader className="modalHead" toggle={toggleAdd}>
          {"Send us your request"}
        </ModalHeader>
       
          <ModalBody className="border-0">
            <form onSubmit={onSubmit}>
              <Row>
                <Col md={6}>
                  <div className="pb-2">
                    <Label>Client Name</Label>
                    <input
                      placeholder="Client Name"
                      className={`form-control w-100`}
                      value={formData.name}
                      name='name'
                      required
                      onChange={(e)=> setFormData({...formData, name: e.target.value})}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pb-2">
                    <Label>Phone</Label>
                    <input
                      placeholder="Phone"
                      className={`form-control w-100`}
                      value={formData.phone}
                      // required
                      onChange={(e)=> setFormData({...formData, phone: e.target.value})}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="pb-2">
                    <Label>Email</Label>
                    <input
                      placeholder="Email"
                      className={`form-control w-100`}
                      value={formData.email}
                      required
                      onChange={(e)=> setFormData({...formData, email: e.target.value})}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="pb-2">
                    <Label>Description</Label>
                    <textarea
                      placeholder="Description"
                      className={`form-control w-100`}
                      value={formData.description}
                      rows="6"
                      required
                      onChange={(e)=> setFormData({...formData, description: e.target.value})}
                    />
                  </div>
                </Col>
                
              </Row>
              <div className="d-flex mt-3 justify-content-between">
                <button className='btn btn-outline-primary' type="button" onClick={toggleAdd}>
                  Cancel
                </button>
                <button className='btn btn-primary' disabled={loading} type="submit">
                  Submit
                </button>
              </div>
            </form>
          </ModalBody>
      </Modal>
    </>
  );
};

CustomModal.propTypes = {
  modalAdd: PropTypes.bool,
  toggleAdd: PropTypes.func,
  data: PropTypes.any,
  setCustomTemplate: PropTypes.any,
  setStep: PropTypes.any,
  setSelectedTemplate: PropTypes.any,
  history: PropTypes.object
};

export default CustomModal;
