import React, {useEffect , useState} from "react";
import PropTypes from 'prop-types';

const ChatItem=({ message, email } )=>{
    const [messageData,setMessageData]=useState(null)
    useEffect(()=>{
        if(message.type==="media"){
            message.media.getContentTemporaryUrl().then(url=>{
                setMessageData({
                    ...message,
                    url
                })
            })
        }else{
            setMessageData(message)
        }
 
    },[message])
     return (
        <div className="w-100 d-flex flex-column">
        <div className="w-100">
            <div className="me-2 ms-2">
                <div className="mt-2 w-75 p-2 pt-1" style={{color: message.author === email ? "white" : "#63697b" ,float: message.author === email ? "right" : "left", background: message.author === email ? "linear-gradient(103deg, #3f60fe 1%, #3e1648 145%)" : "#eaecf2", borderRadius: "5px" }}>
                    {message?.type !=="media" ? <p className="my-1 font-14">{messageData?.body}</p> :
                    <img src={messageData?.url} style={{width:"100%",height:"100%"}} />}
                </div>
            </div>
        </div>
        <div className="font-size-12 w-100  mb-2 mt-1 d-block pe-2 ps-2" style={{ textAlign: message.author === email ? "right" : "left" }} >
            {new Date(
                messageData?.dateCreated?.toISOString()
            )?.toLocaleString()}
        </div>
    </div>
    )
}

ChatItem.propTypes = {
    email: PropTypes.string,
    message: PropTypes.any,

};
export default ChatItem;
