import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import { Provider } from "react-redux";
import store from "store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Provider store={store}>
  <GoogleOAuthProvider
    clientId="263971465947-1d3pu8lutlioamikt6dg4b6v1h5utd4g.apps.googleusercontent.com">
    <App/>
  </GoogleOAuthProvider>
</Provider>);

reportWebVitals();
