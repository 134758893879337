import BaseService from "../BaseService";
// import http from "../HttpService";
import { API_BASE_URL_ENV } from "../../helpers/common";
import http from "../HttpService";

const apiEndpoint = API_BASE_URL_ENV() + "/website/order/orders";
const apiSubscribeEndpoint = API_BASE_URL_ENV() + "/website/order/subscribe";
const apiSessionReservedEndpoint = API_BASE_URL_ENV() + "/website/order/session-reserved";
const apiCustomPaymentEndpoint = API_BASE_URL_ENV() + "/website/order/custom-payment";
const apiPaymentEndpoint = API_BASE_URL_ENV() + "/website/order/payment-status";

export default class extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    subscribe(data) {
        return http.post(apiSubscribeEndpoint, data);
    }

    sessionReserved(data) {
        return http.post(apiSessionReservedEndpoint, data);
    }

    updatePayment(data) {
        return http.put(apiPaymentEndpoint, data);
    }

    updateOrder(id, data) {
        return http.put(`${apiEndpoint}/${id}`, data);
    }

    customPayment(data) {
        return http.post(apiCustomPaymentEndpoint, data);
    }

    
}
