import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row, CardText, Label } from "reactstrap";
import { adminPrefix } from 'configs/routePrefix';
 import AuthService from "services/Admin/AuthService";
import imgLogo from "assets/images/logo.svg"
import "./login.scss";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ErrorMessage from 'components/Common/ErrorMessage/ErrorMessage';
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "FormValidations/Admin/Auth/ResetPassword"
 import PropTypes from 'prop-types';
import { API_BASE_URL_ENV } from "helpers/common";
 
const apiResetPasswordEndpoint =
  API_BASE_URL_ENV() + "/admin/user/reset-password";

const ResetPassword = ({history}) => {
  const form = useForm(
    { resolver: yupResolver(resetPassword) }
  );
  const { register,watch, formState: { errors }, handleSubmit } = form
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleValidSubmit = async (values) => {
    setIsSubmitting(true);
    let requestData = {
      code: watch("code"),
      password: values.password,
      password_confirmation:values.confirm_password,
    };
    try {
      const res = await AuthService.resetPassword(apiResetPasswordEndpoint,requestData)
       if (res && res.status) {
        toast.success("Password reset successffuly")
        setIsSubmitting(false);
        history.push(`${adminPrefix}/login`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error)
      setIsSubmitting(false);
    }
  };



  return (
    <div>
      <div className="bgGray height100">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" lg="6">
              <Card className="overflow-hidden mt-lg-5 mt-3">
                <CardBody>
                  <div className="pt-1 p-2">
                    <img src={imgLogo} className="img-fluid mx-auto d-block" alt="" />
                    <CardText tag="h5" className="text-primary">Reset Password</CardText>
                    <form onSubmit={handleSubmit(handleValidSubmit)}>
                      <div className="mb-3 pt-3">
                        <Label>Code</Label>
                        <input
                          {...register('code')}
                          type='number'
                          className='form-control height-47'
                          placeholder='Type Code'
                        />
                        {errors?.code && <ErrorMessage message={errors?.code?.message} />}
                      </div>
                      <div className="mb-3">
                        <Label>Password</Label>
                        <input
                          {...register('password')}
                          type='password'
                          className='form-control height-47'
                          placeholder='**********'
                        />
                        {errors?.password && <ErrorMessage message={errors?.password?.message} />}
                      </div>
                      <div className="mb-3">
                        <Label>Confirm Password</Label>
                        <input
                          {...register('confirm_password')}
                          type='password'
                          className='form-control height-47'
                          placeholder='Type Password Confirmatuion'
                        />
                        {errors?.confirm_password && <ErrorMessage message={errors?.confirm_password?.message} />}
                      </div>
                      <button
                        className='btn btn-primary w-100 mt-3 font-size-14 height-47'
                        disabled={isSubmitting}
                        onClick={() => {
                          console.log(errors)
                        }}
                        type='submit'>
                        Submit
                      </button>
                    </form>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default ResetPassword;