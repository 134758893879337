import Pages500 from "pages/Utility/pages-500"
import Home from "pages/Website/Home" 
import CreateWebsite from "pages/Website/CreateWebsite"
import Plans from "pages/Website/Plans"
import Templates from "pages/Website/Templates"
import Blog from "pages/Website/Blog"
import BlogDetails from "pages/Website/Blog/BlogDetails"
import Stripe from "pages/Website/Stripe/index"
import TemplatePreview from "pages/Website/TemplatePreview"
import Subscriptions from "pages/Website/Subscriptions"
import Contact from "pages/Website/Contact"
import Privacy from "pages/Website/Privacy"
import Terms from "pages/Website/Terms"
import Guide from "pages/Website/Guide"
import SiteMap from "pages/Website/SiteMap"

const websiteAuthRoutes = [ 

  { path: "/", component: Home }, 
  { path: "/home", component: Home }, 
  { path: "/pages-500", component: Pages500 },
  { path: "/create-website", component: CreateWebsite },
  { path: "/plans", component: Plans },
  // { path: "/templates/:industry?/:size?/:website_type?/:name?/:description?/:domain?/:content?", component: Templates },
  { path: "/templates", component: Templates },
  { path: "/contact-us", component: Contact },
  { path: "/subscriptions", component: Subscriptions },
  { path: "/blog", component: Blog },
  { path: "/blog-details/:id?", component: BlogDetails },
  { path: "/stripe", component: Stripe },
  { path: "/template-view", component: TemplatePreview },
  { path: "/privacy-policy", component: Privacy },
  { path: "/terms", component: Terms },
  { path: "/guide", component: Guide },
  { path: "/site-map", component: SiteMap },


];

export { websiteAuthRoutes };
