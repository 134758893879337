import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Modal,
  ModalHeader, ModalBody, Container,Row,Col
} from "reactstrap";
import PropTypes from "prop-types";
import "./style.scss";
import { useState } from "react";
import { useEffect } from "react";

const ModalDetails = ({ data, modalDetails, toggleDetails }) => {
  const [social,setSocial] = useState([]);
  useEffect(() => {
    let update = data.social_media_links.map(obj => {
      const key = Object.keys(obj)[0];
      return { type: key, link: obj[key] };
    });
    setSocial(update)
  }, []);

  return (
    <Modal
      isOpen={modalDetails}
      className="modal-dialog-scrollable modal-lg container"
      toggle={() => toggleDetails()}
    >
      <ModalHeader className="modalHeadBg me-2 w-100 pb-1">
        <div className="d-flex justify-content-between w-100">
          <h6 className="fw-bold">
            <button className="btn" onClick={() => toggleDetails()}>
              <i className="fa fa-long-arrow-left fa-lg"></i>
            </button>
            Back to Project
          </h6>
        </div>
      </ModalHeader>
      <ModalBody className="border-0 py-4">
        <Container>
          <Row>
            <Col md={6}>
              <h6 className="fw-bold mb-1">Business Name</h6>
              <p>{data.business_name}</p>
            </Col>
            <Col md={6}>
              <h6 className="fw-bold mb-1">Business Description</h6>
              <p>{data.business_description}</p>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col md={6}>
              <h6 className="fw-bold mb-1">Email</h6>
              <p>{data.contact_email}</p>
            </Col>
            <Col md={6}>
              <h6 className="fw-bold mb-1">Documents</h6>
              {data.documents.length
                ? data.documents.map((document) => (
                    <span className="fw-bold font-14" key={document.id}>
                      <a href={document.path} target="_blank" rel="noreferrer">
                        File Content{" "}
                      </a>
                    </span>
                  ))
                : null}
            </Col>
          </Row>
          {/* <Row className="pt-2">
            <Col md={6}>
              <h6 className="fw-bold mb-1">Primary Color</h6>
              <p>{data.primary_color}</p>
            </Col>
            <Col md={6}>
              <h6 className="fw-bold mb-1">Secondary Color</h6>
              <p>{data.secondary_color}</p>
            </Col>
          </Row> */}
          <h6 className="fw-bold mb-1">Social Media</h6>
          <Row className="pt-2">
            {social.map((item,index)=>(
              <Col key={index} md={12}>
                <div className="d-lg-flex">
                  <p className="text-primary">{item.type} : </p>
                  <p><a  href={item.link}>{item.link}</a></p>
                </div>
            </Col>)
              )}
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};
ModalDetails.propTypes = {
  modalDetails: PropTypes.bool,
  data: PropTypes.object,
  toggleDetails: PropTypes.func,
};

export default ModalDetails;
