import * as yup from "yup";

const url = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/;

export const ContentValidator = yup.object().shape({
  reference_url: yup.string().matches(url, "Link is not valid"),
  title: yup.string().required("title is required")
  // .matches(
  //     TITLE_PATTERN_VALIDATOR.value,
  //     TITLE_PATTERN_VALIDATOR.errorMessage
  // )
  ,
  description: yup.string().required("description is required ")
});

export const oneLangValidator = yup.object().shape({
  reference_url: yup.string().matches(url, "Link is not valid"), pages: yup.array().of(yup.object().shape({
    title: yup
      .string()
      // .matches(
      //     TITLE_PATTERN_VALIDATOR.value,
      //     TITLE_PATTERN_VALIDATOR.errorMessage
      // )
      .required("title is required"), description: yup.string().required("description is required ")
  }))
});