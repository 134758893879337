import React, { useState } from "react";
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import DeleteModal from 'components/Common/DeleteModal';
import PropTypes from 'prop-types';
import ManagerService from "services/Admin/ManagerService"
import ModalInvite from "components/Admin/Manager/ModalInvite";
import ModalChangeStatus from "components/Common/ModalChangeStatus";
import { SuccessOk } from "configs/statusCode";
import { toast } from "react-toastify";

const CardItem = ({ manager, index, toggleAdd, setEditItem, setGetData }) => {
    const managerService = new ManagerService();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [status, setStatus] = useState(manager?.status);
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [deletedItem, setDeletedItem] = useState(null);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modalInvite, setModalInvite] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseModal = (action) => {
        setDeleteModal(false);
        if (action === "delete") {
            setGetData(true)
        }
    };

    const changeStatus = ()=>{
        setLoading(true)
        managerService.toggleStatus(manager.id).then(res=>{
          if (res?.status == SuccessOk) {
            setStatus(!status);
            toast.success(`${manager.role} Status Updated Successfully`);
            setChangeStatusModal(false)
            }
            setLoading(false)
        })
    }
    return (
        <tr key={index} >
            <td className="ps-4">{manager.name}</td>
            <td>{manager.email}</td>
            <td>{manager.role}</td>
            <td>{manager.projects} Project</td>
            <td onClick={()=>setChangeStatusModal(true)} className={`${status ? 'textSuccess' : 'text-danger'} fw-bold cursor-pointer`}>
                {status ? 'Active' : 'Deactive'}
            </td>
            <td>
                <div className="d-flex">
                    <div>
                        <button className="btn btn-primary px-4 height-42" onClick={() => setModalInvite(!modalInvite)}>Assign a project</button>
                    </div>
                    <div className="removebtnhover position-relative moreOptions">
                        <Dropdown className="bgWhite w-100" isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="border-0 pt-0">
                                <button className="btn btn-outline-primary fw-bold font-14 px-5 height-42 ms-3">More</button>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem className="text-primary" onClick={() => {
                                    setEditItem(manager);
                                    toggleAdd()
                                }}><i className="fa fa-link"></i> Edit Team Members</DropdownItem>
                                <DropdownItem className="text-danger" onClick={() => {
                                    setDeleteModal(!deleteModal);
                                    setDeletedItem(manager);
                                }}><i className="fas fa-trash-can"></i> Delete Team Members</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <DeleteModal
                        open={deleteModal}
                        titleMsg={deletedItem ?.name}
                        deletedItem={deletedItem}
                        modelService={managerService}
                        onCloseModal={(action) => handleCloseModal(action)}
                    />
                    {modalInvite && (
                        <ModalInvite
                            modalInvite={modalInvite}
                            toggleInvite={() => setModalInvite(c => !c)}
                        />
                    )}
                </div>
            </td>

            {changeStatusModal && <ModalChangeStatus
                modal={changeStatusModal}
                toggle={()=> setChangeStatusModal(false)}
                loading={loading}
                changeStatus={changeStatus}
            />}
        </tr>
    )
}
CardItem.propTypes = {
    manager: PropTypes.object,
    index: PropTypes.number,
    toggleAdd: PropTypes.func,
    setEditItem: PropTypes.func,
    setGetData: PropTypes.func
};

export default CardItem;
