import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="container-fluid px-md-5 pt-3 pb-2 px-3">
            <footer>
                <div className="d-sm-flex justify-content-between text-start border-top pt-3 text-center">
                    <p className="text-muted font-14 text-center pt-4 pt-md-0">Webkicks {(new Date().getFullYear())}, All rights reserved</p>
                    <div>
                        <Link className="text-muted text-decoration-none px-2 font-14" to={""} alt="">How it works</Link>
                        <Link className="text-muted text-decoration-none px-2 font-14" to={"/contact-us"} alt="">Contact</Link>
                        <Link className="text-muted text-decoration-none px-2 font-14" to={"/privacy-policy"} alt="">Privacy</Link>
                        <Link className="text-muted text-decoration-none px-2 font-14 d-md-none" to={"/terms"} alt="">Terms</Link>
                        <Link className="text-muted text-decoration-none px-2 font-14 d-none d-md-inline" to={"/terms"} alt="">Terms & Conditions</Link>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default Footer
