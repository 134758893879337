import React, { useState, useEffect } from "react";
import {
  Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody
} from "reactstrap";
import FirstStepModal from "./First-Step/FirstStepModal";
import docImage from "assets/images/doc.png";
import "./style.scss";
import FirstStepData from "./First-Step/FirstStepData";
import SecondStepData from "./Second-Step/SecondStepData";
import ThirdStepData from "./Third-Step/ThirdStepData";
import PropTypes from "prop-types";
import FourthStepData from "./Fourth-Step/FourthStepData";
import { useTranslation } from 'react-i18next';
import { useCalendlyEventListener } from "react-calendly";
import OrderService from "services/Website/OrderService";
import StepsService from "services/Client/StepsService";
import { SuccessOk } from "configs/statusCode";
import { toast } from "react-toastify";
import OrderServices from "services/Client/OrderServices";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const StepDetails = ({ orderId, projectDataProps, firstStepData, secondStepData, callFirstStep, callSecondStep ,fourthStepData,setCompleted, otherProps}) => {
  const [modalAdd, setModalAdd] = useState(false);
  const [projectData, setProjectData] = useState(projectDataProps);
  const orderService = new OrderService();
  const stepsService = new StepsService();
  const toggleAdd = () => {
    setModalAdd(c => !c);
  };

  const [open, setOpen] = useState("");
  const toggleAccordion = id => {
    if(
      (parseInt(id) === 2 && projectData.first_step_status !== 1) ||
      (parseInt(id) === 3 && projectData.second_step_status !== 1) ||
      (parseInt(id) === 4 && projectData.third_step_status !== 1)
    ){
      return
    }
    if (open === id) setOpen(); else setOpen(id);
  };

  const updateProjectData = (updateElements) => {
    // [Object.keys(updateElements)[0]]: Object.values(updateElements)[0]
    setProjectData({ ...projectData, ...updateElements});
  };

  const { t } = useTranslation();

  useEffect(()=>{
    const plan = JSON.stringify(projectData?.subscription_plan)
    localStorage.setItem("plan", plan)
  },[])

  useEffect(() => {
    if (projectData.first_step_status !== 1) {
      setOpen("1");
    } else if (projectData.second_step_status !== 1) {
      setOpen("2");
    } else if (projectData.third_step_status !== 1) {
      setOpen("3");
    } else if (projectData.fourth_step_status !== 1) {
      setOpen("4");
    }
  }, [firstStepData, secondStepData]);

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      let data = {
        order_id: projectData?.id
      }
      if(open === '2'){
        axios.get(e.data.payload.event.uri, {
          headers:{
          Authorization: `Bearer ${projectData?.assignee?.calendly_data?.calendlyToken}`,
          // Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzAwMzg4MzAzLCJqdGkiOiJmNGE5NjkwZC0wZjlkLTQ5OWQtODdiNy0wNDFlYWZiNDNhOGQiLCJ1c2VyX3V1aWQiOiI3MmNjYTYzMS0yNjFmLTRlNmItYmNiYi1kOGQyNzhkNzUxN2MifQ.Y6QdRsKOGWH7JCjEHIVOMkbwhMbREIdOEeOrExNEPO7fb5sl2oc3Zqsp1hGlsfLkL4PIW4NVUcxPsWr_gMm7Wg`,
        }}).then(res1=> {
          Promise.all([
            stepsService.update("second-step", {...data, reservation_date: new Date(res1.data?.resource?.start_time)}),
            orderService?.sessionReserved(data),
            new OrderServices().updateOrder(projectData?.id, { step_confirmed: "second_step_status", status:3 })
          ]).then(([res1, res2, res3]) => {
            if(res1?.status === SuccessOk && res2?.status === SuccessOk && res3?.status === SuccessOk){
              toast.success("Session Reserved Successfully.")
              callSecondStep()
              setProjectData({...projectData, second_step_status: 3, session_reserved: 1})
            }
          })
          // stepsService.update("second-step", {...data, reservation_date: res1.data?.resource?.start_time})?.then(res=>{
          //   if(res?.status === SuccessOk){
          //     orderService?.sessionReserved(data).then(res=>{
          //       if(res?.status === SuccessOk){
          //         new OrderServices().updateOrder(projectData?.id, { step_confirmed: "second_step_status", status:3 }).then(res2=>{
          //           if(res2.status === SuccessOk){
                      
          //           }
          //         })
          //       }
          //     })
          //   }
          // })
        })
      }
      if(open === '3'){
        axios.get(e.data.payload.event.uri, {
          headers:{
          Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzAwMzg4MzAzLCJqdGkiOiJmNGE5NjkwZC0wZjlkLTQ5OWQtODdiNy0wNDFlYWZiNDNhOGQiLCJ1c2VyX3V1aWQiOiI3MmNjYTYzMS0yNjFmLTRlNmItYmNiYi1kOGQyNzhkNzUxN2MifQ.Y6QdRsKOGWH7JCjEHIVOMkbwhMbREIdOEeOrExNEPO7fb5sl2oc3Zqsp1hGlsfLkL4PIW4NVUcxPsWr_gMm7Wg`,
        }}).then(res1=> {
        orderService?.sessionReserved(data).then(res=>{
          if(res?.status === SuccessOk){
            new OrderService().updateOrder(projectData?.id, 
              {
                third_step_status:0, 
                business_name: projectData.business_name,
                order_id: projectData.id,
                client_id: projectData.client?.id,
                subscription_plan_id: projectData.subscription_plan.id,
                third_step_data: {
                  reservation_date: res1.data?.resource?.start_time
                }
                // step_confirmed: "third_step_status", status: 0
              }).then(res2=>{
              if (res2.status === SuccessOk) {
                setProjectData({...projectData, third_step_status: 0,session_reserved: 1, reservation_date: res1.data?.resource?.start_time });
                toast.success("Session Reserved Successfully");
              } else {
                toast.error("Whoops something went wrong");
              }
            })
          }
        })
      })
      }
    },
  });

  return (<div className="px-lg-3 pt-4 accordionPage mb-4">
    <Accordion open={open} toggle={toggleAccordion}>
      {/* Accordion Brief */}
      <AccordionItem>
        <AccordionHeader targetId="1" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData?.first_step_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">1
                </p>)}
                {t("Share your business info")}
              </div>

              {firstStepData && projectData.first_step_status === 1 ? (<span
                className="badge badgeCompleted mx-md-4 ms-md-4">{t("Completed")}</span>) :  (<span className="badge badgeCompleted mx-md-4 ms-md-4">{t("In Progress")}</span>)}
              {/* {firstStepData && projectData.first_step_status === 0 || projectData?.first_step_status === 0 ? (
                  <span className="status-badge mx-md-4 mx-0">
                    Pending Client Action
                  </span>
                ) : (
                  ""
                )} */}
            </div>
            {firstStepData && projectData.first_step_status === 1 ? (
              <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
                {t("Your business info is completed successfully")}
              </p>) : (<p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
              {t("Help us build your website by sharing some details.")}
            </p>)}
          </div>
          {modalAdd && (<FirstStepModal
            updateProjectData={(updateElements) => updateProjectData(updateElements)}
            orderId={orderId}
            modalAdd={modalAdd}
            toggleAdd={toggleAdd}
            setCompleted={setCompleted}
            data={firstStepData}
            projectData={projectData}
            callFirstStep={callFirstStep}
          />)}
        </AccordionHeader>
        <AccordionBody accordionId="1" className="px-0 pt-0">
          <Card className="border-0">
            <CardBody className="px-lg-4 px-0">
              {firstStepData ? (<FirstStepData
                setModalAdd={() => setModalAdd(true)}
                data={firstStepData}
                edit={()=> otherProps.history.push(`/client/project/${projectData.id}/first-step`, {order_id: orderId, data: projectData, edit: 'edit',firstStepData: firstStepData})}
                projectData={projectData}
              />) : (<>
                <div className="boxShadow pt-4 px-3 mb-2 pb-3">
                  <div className="row justify-content-between">
                    <div className="col-lg-7">
                          <span className="fw-bold">
                            <img src={docImage} className="pe-2" alt=""/>
                            {t("Brief not submitted")}
                          </span>
                      <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
                        {t("Please submit your brief to provide us with the necessary information to start building your website")}
                      </p>
                      <p className="textRed font-14 mb-2 ps-md-4 pt-3">
                        <span className="fw-bold bgBadgeDanger px-4 py-2 rounded-pill">{t("Without brief, your project can’t start")}</span>
                      </p>
                    </div>
                    <div className="col-lg-3 text-lg-center pt-lg-4 pt-2">
                      <button
                        className="btn btn-primary font-14 ms-lg-4 ms-lg-1 height-42 px-xl-3 px-lg-2"
                        onClick={() => {
                          // toggleAdd();
                          otherProps.history.push(`/client/project/${projectData.id}/first-step`, {order_id: orderId, data: projectData})
                        }}
                      >
                        {t("Share your details")}
                      </button>
                    </div>
                  </div>
                </div>
              </>)}
            </CardBody>
          </Card>
        </AccordionBody>
      </AccordionItem>

      {/* Accordion Content */}
      <AccordionItem>
        <AccordionHeader targetId="2" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData?.second_step_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  2
                </p>)}
                {!projectData?.content_included ?
                <span>{t("DIY Content")}</span> : <span>{t("Let’s create your website content ")}</span>}
                
              </div>
              {secondStepData && projectData.second_step_status === 1 ? (<span
                className="badge badgeCompleted mx-md-4 ms-md-4">{t("Completed")}</span>) : projectData.second_step_status === 2 ? (
                <span className="badge badgeCompleted mx-md-4 ms-md-4">{t("In Progress")}</span>) : projectData.second_step_status === 0 ? (<span className="status-badge mx-md-4 mx-0">
                    {projectData.content_included ? t("Pending Client Action") : t("Pending")}
                  </span>) : <span className="status-badge mx-md-4 mx-0">
                    {t("Pending Account Manager Action")} 
                  </span>}

              {projectData && !projectData?.content_included ? (<>
                    <span className="badge badgeWarning ms-lg-4 ms-md-2 mt-2 mt-md-0">
                      {t("Copywriter Services isn't included in your package")}
                    </span>
              </>) : null}
            </div>
            <div className="d-md-flex align-items-center">

              {secondStepData && (projectData.second_step_status === 1) ? (
                <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50 text-start mb-2">
                  {t("Your content is uploaded successfully")}
                </p>) : projectData.content_included ? <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50 text-start">
                  Let our team of professional writers craft your brand story.   
                </p> : <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50 text-start">
                {t("Provide content details for your website's pages.")}
              </p>}

              {projectData?.content_included ?
                <Link target="_blank" to="/guide" className="text-decoration-none mx-md-3 mt-2"><i className="fa fa-book"></i> How it Works</Link>:
                <Link target="_blank" to="/site-map" className="text-decoration-none mx-md-3 mt-2"><i className="fa fa-book"></i> How it Works</Link>}
            </div>
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="2" className="px-0 pt-0">
          <SecondStepData
            projectData={projectData}
            setCompleted={setCompleted}
            toggleAccordion={(tab) => toggleAccordion(tab)}
            updateProjectData={(updateElements) => updateProjectData(updateElements)}
            secondStepData={secondStepData}
            callSecondStep={callSecondStep}
          />
        </AccordionBody>
      </AccordionItem>

      {/* Accordion Design & feedback */}
      <AccordionItem>
        <AccordionHeader targetId="3" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
                {projectData?.third_step_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  3
                </p>)}
                {t("Feedback")}
              </div>
              <div>
                  {projectData.third_step_status === 1 ? (<span
                    className="badge badgeCompleted mx-md-4 ms-md-4">{t("Completed")}</span>) :
                    projectData.third_step_status === 2 ? (
                    <span className="badge badgeCompleted mx-md-4 ms-md-4">{t("In Progress")}</span>) : projectData.third_step_status === 0 ? (<span className="status-badge mx-md-4 mx-0">
                        {t("Waiting")}
                      </span>) : <span className="badge badgeWarning mx-md-4 ms-lg-4">{t("Ready to Review")}</span>}
                  </div>
            </div>

              <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50 text-start">
                {projectData.third_step_status === 1 ? <span>{t("Your website is completed")}</span> 
                : <span>{t("Sit back and let our team of expert designers work their magic on your website.")}</span>}
              </p>
            
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="3" className="px-0 pt-0">
          <Card className="border-0">
            <CardBody className="px-lg-4 px-0">
              <Card className="border-0">
                <CardBody className="px-lg-4 px-0">
                  <ThirdStepData
                    setCompleted={setCompleted}
                    projectData={projectData}
                    updateProjectData={updateProjectData}
                    toggleAccordion={(tab) => toggleAccordion(tab)}
                  />
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </AccordionBody>
      </AccordionItem>

      {/* Accordion Publish */}
      <AccordionItem>
        <AccordionHeader targetId="4" className="fw-bold">
          <div>
            <div className="d-md-flex">
              <div className="h6 text-dark font-16 fw-bold mb-0">
              {projectData?.fourth_step_status === 1 ? (
                  <p className="badge badgeNumber bg-primary mb-2 rounded-circle me-2">
                    <i className="fa fa-check text-white"></i>
                  </p>) : (<p className="badge badgeNumber mb-2 rounded-circle me-2">
                  {" "}
                  4
                </p>)}
                {t("Publishing / Go-live")}
              </div>
              {projectData.fourth_step_status === 1 ? (<span
                    className="badge badgeCompleted mx-md-4 ms-md-4">{t("Completed")}</span>) : 
                    projectData.fourth_step_status === 4 ?
                  <span className="status-badge mx-md-4 mx-0">
                        {t("Pending Account Manager Action")}
                      </span> : <span className="status-badge mx-md-4 mx-0">
                        {t("Pending Client Action")}
                      </span>}
            </div>

            <p className="f-14 ps-md-4 ms-md-2 mb-0 mt-2 text-black-50">
              {t("Choose the hosting option that suits you best. Your plan includes 1-year free hosting.")}
            </p>
          </div>
        </AccordionHeader>
        <AccordionBody accordionId="4" className="px-0 pt-0">
          <Card className="border-0">
            <CardBody className="px-lg-4 px-0">
              <FourthStepData
                projectData={projectData}
                fourthStepData={fourthStepData}
                updateProjectData={updateProjectData}
                toggleAccordion={(tab) => toggleAccordion(tab)}
              />
            </CardBody>
          </Card>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  </div>);
};

StepDetails.propTypes = {
  orderId: PropTypes.string,
  projectDataProps: PropTypes.object,
  setCompleted: PropTypes.any,
  firstStepData: PropTypes.any,
  fourthStepData: PropTypes.any,
  otherProps: PropTypes.any,
  secondStepData: PropTypes.any,
  callFirstStep: PropTypes.func,
  callSecondStep: PropTypes.func
};
export default StepDetails;
