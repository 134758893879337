import React from "react";
import Navbar from "components/Website/Layouts/Header";
import Footer from "components/Website/Layouts/Footer";
import InjectedCheckoutForm from "components/Website/Stripe/InjectedCheckoutForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51KBFffC71jeR6DgNS4KGfY6ISW0u5zkqvJX81zRuCcjrrCJOZ0GEQXUgqCqN5eVvpQcuUWRnUDtoACAgVa618tNt00eSUdwWPO");

const Stripe = () => {

  return (<>
    <Navbar/>
    <div className="container-fluid px-lg-5 px-2">
      <Elements stripe={stripePromise}>
        <InjectedCheckoutForm/>
      </Elements>
    </div>
    <Footer/>
  </>);
};

export default Stripe;
