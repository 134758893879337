import React from "react";
import PropTypes from "prop-types";
import ContentIsIncluded from "./ContentIsIncluded";
import ContentIsNotIncluded from "./ContentIsNotIncluded";

const SecondStepData = ({ projectData, secondStepData, callSecondStep, toggleAccordion, updateProjectData,setCompleted }) => {

  return (<div>
    {projectData && projectData.content_included ? <ContentIsIncluded callSecondStep={callSecondStep}
                                                                      setCompleted={setCompleted}
                                                                      toggleAccordion={(tab) => toggleAccordion(tab)}
                                                                      updateProjectData={(updateElements) => updateProjectData(updateElements)}
                                                                      projectData={projectData}
                                                                      secondStepData={secondStepData}/> :
      <ContentIsNotIncluded projectData={projectData}
                            secondStepData={secondStepData}
                            setCompleted={setCompleted}
                            toggleAccordion={(tab) => toggleAccordion(tab)}
                            updateProjectData={(updateElements) => updateProjectData(updateElements)}
                            callSecondStep={callSecondStep}/>}
  </div>);
};

SecondStepData.propTypes = {
  projectData: PropTypes.object,
  callSecondStep: PropTypes.func,
  toggleAccordion: PropTypes.func,
  updateProjectData: PropTypes.func,
  setCompleted: PropTypes.any,
  secondStepData: PropTypes.any
};

export default SecondStepData;
