import React from "react";
import { Link } from "react-router-dom";
import logoWhite  from 'assets/images/logoHome.svg';
import face  from 'assets/images/face.svg';
import insta  from 'assets/images/insta.svg';
import twitter  from 'assets/images/x.svg';
import "./style.scss";

const GeneralFooter = () => {
    return (
      <div className="footerHome p-4">
        <div className="container pt-4">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-md-6">
              <img src={logoWhite} alt="" height="30px" />
              <p className="text-white pt-4 smallText">
              From concept all the way to launch, every step is handled by experts, from design to content creation, backed by a dedicated account manager for personalized guidance.
              </p>
            </div>
            <div className="col-lg-2 col-md-5">
              <p className="text-white smallText mb-2">Connect with us</p>
              <p>
                <a
                  className="text-white text-decoration-none px-2"
                  href='https://www.facebook.com/webkicksonline/'
                  target='_blank' 
                  rel="noreferrer"
                >
                  <img src={face} className="img-fluid" />
                </a>
                <Link
                  className="text-white text-decoration-none px-2"
                  href='https://www.instagram.com/webkicksonline/'
                  target='_blank' 
                  rel="noreferrer"
                >
                  <img src={insta} className="img-fluid" />
                </Link>
                <Link
                  className="text-white text-decoration-none px-2"
                  href='https://twitter.com/Webkicksonline'
                  target='_blank' 
                  rel="noreferrer"
                >
                  <img src={twitter} className="img-fluid" />
                </Link>
                <Link
                  className="text-white text-decoration-none px-2"
                  href='https://www.linkedin.com/company/webkicksonline/'
                  target='_blank' 
                  rel="noreferrer"
                >
                  <i className='fa fa-linkedin'></i>
                </Link>
              </p>
            </div>
          </div>
          <div
            className="row border-top mt-4 linkFooter border-secondary justify-content-between text-start"
          >
            <div className="col-lg-6 col-md-5">
              <p className="text-white pt-3 d-none d-md-block">
                Webkicks {(new Date().getFullYear())}, All rights reserved
              </p>
            </div>

            <div className="col-lg-5 col-md-6 pt-3">
              <div className="d-flex justify-content-end">
                <div>
                  <a
                    className="text-white  text-decoration-none"
                    href={"/home#how"}
                    alt=""
                  >
                    How it works
                  </a>
                  <Link
                    className="text-white  text-decoration-none px-2"
                    to={"/contact-us"}
                    alt=""
                  >
                    Contact
                  </Link>
                  <Link
                    className="text-white  text-decoration-none px-2"
                    to={"/privacy-policy"}
                    alt=""
                  >
                    Privacy
                  </Link>
                  <Link
                    className="text-white  text-decoration-none px-2"
                    to={"/Terms"}
                    alt=""
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
            </div>
            
          </div>
          <div className="linkFooter d-block d-md-none border-top text-center mt-3 border-secondary">
            <div className="row">
              <div className="col">
              <p className="text-white pt-3 smallText">
                  Webkicks {(new Date().getFullYear())}, All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default GeneralFooter
